import { gql } from '@apollo/client';
import {
  COMMON_TRUSTED_CONTACT_UPDATE_REQUESTS_FRAGMENT,
  COMMON_USER_INVESTOR_INFORMATION_DISCRETIONARY_FRAGMENT,
  COMMON_USER_INVESTOR_INFORMATION_NON_DISCRETIONARY_FRAGMENT,
  COMMON_USER_PROFILE_REQUEST_PARAMETERS_FRAGMENT,
} from '../admin/common/fragments';

export const GET_USER_SHARES_EDGE = gql`
  fragment UserSharesEdge on UserAccountPortfolioAssetEdge {
    node {
      id
      costBasis
      balances {
        pending
        liability
        pendingLiability
        unsettled
        settled
        activeBalance
        tradeable
      }
      asset {
        id
        name
        symbol
        createTime
        updateTime
        ... on SecurityAsset {
          __typename
          id
          color
          logoURI
          sector {
            id
          }
          securityType
          market {
            last
            previousDayClose
          }
        }
      }
    }
  }
`;

export const GET_USER_SHARES_V2 = gql`
  query GetUserSharesV2($portfolioId: ID!) {
    userSecurityAssets: userAccountPortfolioAssets(
      where: {
        hasPortfolioWith: { id: $portfolioId }
        hasAssetWith: { type: ASSET_TYPE_SECURITY }
        hideZeroBalance: true
      }
    ) {
      edges {
        ...UserSharesEdge
      }
    }
    userFiatAssets: userAccountPortfolioAssets(
      where: {
        hasPortfolioWith: { id: $portfolioId }
        hasAssetWith: { type: ASSET_TYPE_FIAT }
        hideZeroBalance: false
      }
    ) {
      edges {
        ...UserSharesEdge
      }
    }
  }
  ${GET_USER_SHARES_EDGE}
`;

export const GET_USER_DETAILS = gql`
  query GetUserDetails {
    users {
      edges {
        node {
          id
          firstName
          lastName
          username
          legacyID
          isActive
          isLoginMfaEnabled
          profile {
            firstName
            lastName
            dateOfBirth
            email
            phoneNumber
            phoneNumberType
            country
            city
            streetAddress
            additionalStreetAddress
            state
            zipCode
            unverifiedEmail
            unverifiedPhoneNumber
            controlPersonDetails {
              companySymbols
              isControlPerson
            }
            politicallyExposedDetails {
              isPoliticallyExposed
              organizationName
              familyMembers
            }
            affiliatedExchangeOrFinraDetails {
              isAffiliated
              firmName
            }
            marketDataAnswers {
              personalUse
              gettingDataFromExternalSource
              registeredWithSecOrCftc
              requiresSecOrCftcRegistration
              investForOther
              investForTheBenefitOfOther
              advisorOrConsultant
              sharesProfit
              registeredWithEntity
            }
          }
        }
      }
    }
    discretionaryAccountKycs {
      edges {
        node {
          id
          riskTolerance
          account {
            id
          }
        }
      }
    }
    nonDiscretionaryAccountKycs {
      edges {
        node {
          id
          account {
            id
          }
          riskTolerance
          investmentExperience
          investmentObjective
          timeHorizon
          liquidityNeeds
          maritalStatus
          employmentStatus
          annualIncome
          netWorth
          employmentDetails {
            EmployerName
            Position
          }
        }
      }
    }
  }
`;

export const GET_REPEAT_INVESTMENT_SECTORS_QUERY = gql`
  query GetRepeatInvestmentSectors {
    users {
      edges {
        node {
          user_id: id
          recurringRules {
            edges {
              node {
                id
                createTime
                updateTime
                type
                fundingMethod
                isActive
                interval
                amount
                start
                sector {
                  id
                  name
                  type
                  largeImage
                  smallImage
                  order
                  security {
                    id
                    name
                    symbol
                    description
                    market {
                      open
                      bidSize
                      last
                      askSize
                      baseMin
                      close
                      previousDayClose
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_USER_ACCOUNTS_STATUS_FRAGMENT = gql`
  fragment GetAllUserAccountsStatusFragment on UserAccount {
    id
    type
    status
    portfolios {
      edges {
        node {
          id
          account {
            createTime
            externalID
          }
        }
      }
    }
    beneficiariesPrimary {
      id
      beneficiaries {
        id
        additionalStreetAddress
        beneficiarySet {
          id
          isPending
        }
        city
        country
        createTime
        dateOfBirth
        email
        firstName
        lastName
        percentage
        phoneNumber
        relationship
        ssn
        state
        streetAddress
        updateTime
        zipCode
      }
    }
    beneficiariesContingent {
      id
      beneficiaries {
        id
        additionalStreetAddress
        beneficiarySet {
          id
          isPending
        }
        city
        country
        createTime
        dateOfBirth
        email
        firstName
        lastName
        percentage
        phoneNumber
        relationship
        ssn
        state
        streetAddress
        updateTime
        zipCode
      }
    }
  }
`;

export const GET_ALL_USER_ACCOUNTS_STATUS = gql`
  query GetAllUserAccountsStatus {
    userAccounts {
      edges {
        node {
          ...GetAllUserAccountsStatusFragment
        }
      }
    }
  }
  ${GET_ALL_USER_ACCOUNTS_STATUS_FRAGMENT}
`;

export const GET_GENERAL_INVESTING_PORTFOLIOS = gql`
  query GetGeneralInvestingPortfolios {
    roboGeneralInvestments {
      edges {
        node {
          id
          createTime
          targetPortfolioID
          accountPortfolioID
          initialInvestmentAmount
          recurringInfusion
          infusionFrequency
          lastRebalanceTime
          totalDividends
          totalInfusions
          totalInterests
          totalWithdrawals
          targetPortfolio {
            fiatPct
            stockPct
            bondPct
            id
          }
          accountPortfolio {
            totalValue
            account {
              id
              externalID
            }
          }
          isAutoInfusionPaused
          allocation {
            stockPct
            fiatPct
            bondPct
            drift
            totalValue
            allocation {
              asset {
                name
              }
              balance
              value
              percentage
            }
            allocationBySector {
              value
              percentage
              sector {
                name
                category
                id
                assets {
                  targetPct
                  asset {
                    id
                    symbol
                  }
                }
              }
              assets {
                asset {
                  name
                }
                balance
                value
              }
            }
          }
        }
      }
    }
  }
`;

export const TRUSTED_CONTACT_DETAILS_FRAGMENT = gql`
  fragment TrustedContactDetails on TrustedContact {
    id
    firstName
    lastName
    dateOfBirth
    email
    phoneNumber
    status
  }
`;

export const GET_USER_DETAILS_V2 = gql`
  query GetUserDetailsV2 {
    users {
      edges {
        node {
          createTime
          allowedAchRelationships
          username
          id
          firstName
          lastName
          username
          legacyID
          isActive
          isLoginMfaEnabled
          accounts {
            edges {
              node {
                ...GetAllUserAccountsStatusFragment
                trustedContacts {
                  ...TrustedContactDetails
                }
              }
            }
          }
          profile {
            unverifiedEmail
            unverifiedPhoneNumber
            firstName
            lastName
            dateOfBirth
            email
            phoneNumber
            taxID
            phoneNumberType
            country
            otherSourcesOfWealth
            citizenshipCountry {
              id
              name
              alpha3Code
            }
            visaType
            birthCountry {
              id
              name
              alpha3Code
            }
            visaExpirationDate
            city
            streetAddress
            additionalStreetAddress
            state
            zipCode
            filingStatus
            annualGrossIncome
            controlPersonDetails {
              companySymbols
              isControlPerson
            }
            politicallyExposedDetails {
              organizationName
              familyMembers
              isPoliticallyExposed
            }
            affiliatedExchangeOrFinraDetails {
              firmName
              isAffiliated
            }
            marketDataAnswers {
              personalUse
              gettingDataFromExternalSource
              registeredWithSecOrCftc
              requiresSecOrCftcRegistration
              investForOther
              investForTheBenefitOfOther
              advisorOrConsultant
              sharesProfit
              registeredWithEntity
            }
            updateRequests(orderBy: { direction: DESC, field: create_time }) {
              edges {
                node {
                  createTime
                  status
                  requestParameters {
                    ...CommonUserProfileRequestParameters
                  }
                }
              }
            }
          }
          portfolios {
            edges {
              node {
                id
                account {
                  apexAccountMeta {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
    nonDiscretionaryAccountKycs {
      edges {
        node {
          ...CommonUserInvestorInformationNonDiscretionaryDetails
        }
      }
    }
    discretionaryAccountKycs {
      edges {
        node {
          ...CommonUserInvestorInformationDiscretionaryDetails
        }
      }
    }
    trustedContacts {
      edges {
        node {
          id
          id
          firstName
          lastName
          dateOfBirth
          email
          phoneNumber
          isActive
          __typename
        }
      }
    }
    trustedContactUpdateRequests {
      edges {
        node {
          id
          ...CommonTrustedContactUpdateRequestsDetails
          __typename
        }
      }
    }
  }
  ${GET_ALL_USER_ACCOUNTS_STATUS_FRAGMENT}
  ${COMMON_USER_PROFILE_REQUEST_PARAMETERS_FRAGMENT}
  ${COMMON_USER_INVESTOR_INFORMATION_NON_DISCRETIONARY_FRAGMENT}
  ${COMMON_USER_INVESTOR_INFORMATION_DISCRETIONARY_FRAGMENT}
  ${COMMON_TRUSTED_CONTACT_UPDATE_REQUESTS_FRAGMENT}
  ${TRUSTED_CONTACT_DETAILS_FRAGMENT}
`;

export const GET_USER_ACCOUNTS = gql`
  query getUserAccounts {
    userAccounts {
      edges {
        node {
          id
          externalID
          type
          ascendMeta {
            accountID
            accountNumber
          }
          apexAccountMeta {
            id
          }
        }
      }
    }
  }
`;
