import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';

export default {
  bankLink: {
    title: 'Linked bank accounts',
    description: `Change or update your bank linkage by using Plaid. Select a bank account below or add a new account to enter Plaid.\n\nManage your linked bank accounts for ${FinTronConfig.robo.name} and ${FinTronConfig.selfDirected.name} by using Plaid, a third-party bank verification tool.`,
    account: '{{ account }} account',
    discretionaryDescription: '',
  },
  profileUpdates: {
    title: 'Update info',
    description: `This information update will be applied to all your open ${FinTronConfig.clearingFirm}. accounts, including those associated with ${FinTronConfig.robo.name} and ${FinTronConfig.selfDirected.name}.`,
    yesUpdate: 'Yes, Update',
  },
  security: {
    title: 'Security',
    password: 'Update password',
    twoFactorAuth: 'Two-factor Authentication',
    login: 'Login',
    loginDescription:
      'We will send you an email or text with the two-factor authentication code for each login.',
  },
};
