import React from 'react';
import Image from 'next/image';
import { WebBodyText18, WebHeading28 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';

import { useSidePanel } from '@/context/SidePanelContext';

import { SuccessCircleIcon } from '@/assets/icons';

import { Button } from '@/components/common/Button';
import { SheetContent, SheetFooter } from '@/components/common/Sheet';
import { useRouter } from 'next/router';
import { capitalizeFirstLetter } from '@/utils/strings';
import { TransferTypes } from './schema';

type TransferSidePanelSuccessProps = {
  formTransferType: TransferTypes | undefined;
};

const displayStrings = {
  congratulations: 'Congratulations!',
  checkActivityHistory: 'Check your activity history for updates to your funds availability.',
  depositStatus: (transferType: TransferTypes) => `${capitalizeFirstLetter(transferType)} status`,
  done: 'Done',
};

export const TransferSidePanelSuccess: React.FC<TransferSidePanelSuccessProps> = ({
  formTransferType,
}) => {
  const { closeSidePanel } = useSidePanel();
  const router = useRouter();
  const { type } = router.query;

  const handleClose = () => closeSidePanel();
  const handleNavigateToDepositStatus = () => {
    handleClose();
    router.push(`/accounts/${type}/activity-history`);
  };

  return (
    <SheetContent>
      <div className="mt-20 flex flex-col items-center gap-7 text-center">
        <Image alt="Success icon" width={50} height={50} src={SuccessCircleIcon.src} />
        <WebHeading28.Bold className="text-darkestBlue">
          {displayStrings.congratulations}
        </WebHeading28.Bold>
        <WebBodyText18.Regular className="text-grey72">
          {displayStrings.checkActivityHistory}
        </WebBodyText18.Regular>
      </div>

      <SheetFooter className="my-5 text-center">
        <div className="flex w-full flex-col gap-2">
          <Button onClick={handleNavigateToDepositStatus}>
            {formTransferType ? displayStrings.depositStatus(formTransferType) : null}
          </Button>
          <Button onClick={handleClose}>{displayStrings.done}</Button>
        </div>
      </SheetFooter>
    </SheetContent>
  );
};
