import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
} from '@apollo/client';
import { ApolloLink } from '@apollo/client/link/core';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import fLogger from '@fintronners/react-utils/src/fLogger';
import { API_LOGGING_LEVEL } from './BaseApi';

const IS_DEV_MODE = process.env.NODE_ENV !== 'production';

class GraphQlClient {
  _apolloClient: ApolloClient<NormalizedCacheObject>;
  _token?: string | null = null;
  _cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          achTransfers: {
            merge: true,
          },
          trades: {
            merge: true,
          },
          securityAssets: {
            merge: true,
          },
        },
      },
      AssetMarketData: {
        merge: false,
      },
    },
  });
  _authMiddleware = new ApolloLink((operation, forward) => {
    if (!this._token) {
      return new Observable((observer) => {
        observer.error(new Error('No access token set'));
      });
    }

    operation.setContext({
      headers: {
        authorization: this._token ? `Bearer ${this._token}` : '',
      },
    });
    return forward(operation);
  });

  get hasToken() {
    return !!this._token;
  }

  /**
   * Custom fetch function for the HttpLink. Can we used to log request data.
   */
  _customFetch = (input: RequestInfo | URL, init?: RequestInit) => {
    if (IS_DEV_MODE) {
      const body = init?.body && JSON.parse(init.body.toString());
      fLogger.trace(`🚀 Request - ${body?.operationName ?? 'unknown'}`);
      if (API_LOGGING_LEVEL > 1) {
        fLogger.trace(`🚀 Request details - ${JSON.stringify(init, null, 2) ?? 'unknown'}`);
      }
    }
    return fetch(input, init);
  };

  /**
   * Response logger for gql. Should only be on in dev
   */
  _responseLogger = new ApolloLink((operation, forward) => {
    const startTime = new Date().getTime();

    if (!IS_DEV_MODE) return forward(operation);
    try {
      return forward(operation).map((response) => {
        const endTime = new Date().getTime();
        const elapsedTime = (endTime - startTime) / 1000;

        let timeIcon = '🟢';
        if (elapsedTime > 1) timeIcon = '🟡';
        if (elapsedTime > 3) timeIcon = '🔴';

        fLogger.trace(`🚀 Response: ${operation.operationName} ${timeIcon} time=${elapsedTime}s`);
        response.errors && fLogger.trace(`🚀 Errors: ${JSON.stringify(response.errors)}`);
        return response;
      });
    } catch (e) {
      fLogger.trace(e, '_responseLogger has thrown an error. Proceeding without the logs.');
      return forward(operation);
    }
  });

  _errorLogger = onError((errorResponse: ErrorResponse) => {
    if (!IS_DEV_MODE) return;
    try {
      const operationName = errorResponse.operation.operationName;

      if (errorResponse?.networkError) {
        const networkError = errorResponse.networkError;
        const statusCode = 'statusCode' in networkError ? networkError.statusCode : 'unknown';
        const result = 'result' in networkError ? networkError.result : 'unknown';
        fLogger.trace(
          `🚀 Network Error ${operationName} - statusCode=${statusCode} result=${JSON.stringify(
            result,
          )}`,
        );
      }
      if (errorResponse?.graphQLErrors) {
        const graphQLError = errorResponse.graphQLErrors;
        const statusCode = 'statusCode' in graphQLError ? graphQLError.statusCode : 'unknown';
        const result = 'result' in graphQLError ? graphQLError.result : 'unknown';
        fLogger.trace(
          `🚀 graphQLError Error ${operationName} - statusCode=${statusCode} result=${JSON.stringify(
            result,
          )}`,
        );
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      import('./graphql/types/graphql')
        .then((module) => {
          const gqlVariables = JSON.stringify(errorResponse.operation.variables, null, 2);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const gqlQuery = module[operationName + 'Document'].loc?.source.body;
          // intentionally info so that it doesn't get exposed when used as SDK
          fLogger.info(`🚀 ${operationName}\nVariables:\n${gqlVariables}\nQuery: ${gqlQuery}`);
        })
        .catch((error) => {
          console.log('Error during dynamic import:', error);
        });
    } catch (e) {
      fLogger.trace(e, 'gql _errorLogger has thrown an error');
    }
  });

  constructor() {
    const httpLink = new HttpLink({
      uri: process.env.GRAPHQL_API_URL,
      fetch: this._customFetch,
    });
    this._apolloClient = new ApolloClient({
      name: 'fintron-client',
      link: ApolloLink.from([
        this._authMiddleware,
        ...(IS_DEV_MODE ? [this._responseLogger, this._errorLogger] : []),
        httpLink,
      ]),
      cache: this._cache,
      defaultOptions: {
        // Disabling caching for now until we start learning how to
        // gql properly and the need for optmization arises
        query: {
          fetchPolicy: 'no-cache',
        },
      },
    });
  }

  get client() {
    return this._apolloClient;
  }

  /**
   * Sets the auth token for the GQL client instance.
   *
   * @param token  The token to set.
   * @param clearStore Whether to clear the store or not.
   */
  async setAuthToken(token?: string | null, clearStore = true) {
    if (clearStore) {
      await this._apolloClient.clearStore().then(() => {
        this._token = token;
      });
      return;
    }
    this._token = token;
  }
}

export default new GraphQlClient();
