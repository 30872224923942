import { gql } from '@apollo/client';

export const GET_TRUSTED_CONTACT_REQUESTS = gql`
  query GetTrustedContactRequests(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $startDate: Time
    $endDate: Time
    $criteria: String = ""
    $allFilters: [TrustedContactUpdateRequestWhereInput!]
    $orderDirection: OrderDirection = DESC
    $orderField: TrustedContactUpdateRequestOrderField!
    $statusIn: [TrustedContactUpdateRequestUpdateRequestStatus!]
  ) {
    response: trustedContactUpdateRequests(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        and: $allFilters
        createTimeGTE: $startDate
        createTimeLTE: $endDate
        statusIn: $statusIn
        or: [
          {
            hasDiscretionaryUpdateRequestWith: {
              hasDiscretionaryAccountKycWith: {
                hasAccountWith: {
                  hasUserWith: {
                    or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
                  }
                }
              }
            }
          }
          {
            hasNonDiscretionaryUpdateRequestWith: {
              hasNonDiscretionaryAccountKycWith: {
                hasAccountWith: {
                  hasUserWith: {
                    or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
                  }
                }
              }
            }
          }
        ]
      }
      orderBy: { direction: $orderDirection, field: $orderField }
    ) {
      edges {
        cursor
        node {
          id
          createTime
          updateTime
          status
          requestDetails {
            admin {
              firstName
              lastName
            }
          }
          nonDiscretionaryUpdateRequest {
            nonDiscretionaryAccountKyc {
              account {
                user {
                  id
                  firstName
                  lastName
                }
                externalID
              }
            }
          }
          discretionaryUpdateRequest {
            discretionaryAccountKyc {
              account {
                user {
                  id
                  firstName
                  lastName
                }
                externalID
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_TRUSTED_CONTACT_REQUESTS_COUNT = gql`
  query GetTrustedContactRequestsCount {
    new: trustedContactUpdateRequests(where: { statusIn: [UPDATE_REQUEST_STATUS_PENDING] }) {
      totalCount
    }
    completed: trustedContactUpdateRequests(where: { statusIn: [UPDATE_REQUEST_STATUS_APPROVED] }) {
      totalCount
    }
    contacted: trustedContactUpdateRequests(
      where: { statusIn: [UPDATE_REQUEST_STATUS_CONTACATED] }
    ) {
      totalCount
    }
  }
`;
