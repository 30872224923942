import { memo } from 'react';
import { WebCaption12 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import Image from 'next/image';
import { InfoIcon } from '~assets/icons';
import cn from '@/utils/tailwind';
import type { ClassValue } from 'clsx';

type InfoWithIconProps = {
  message: string;
  className?: ClassValue;
  containerClassName?: ClassValue;
  IconSrc?: any;
};

const InfoInline = ({ message, className, containerClassName, IconSrc }: InfoWithIconProps) => {
  const src = !IconSrc ? InfoIcon.src : IconSrc;

  return (
    <div className={cn('flex gap-2', containerClassName)}>
      <Image alt="Info icon" width={14} height={14} src={src} />

      <WebCaption12.Regular className={cn('flex items-center gap-1 text-grey41', className)}>
        {message}
      </WebCaption12.Regular>
    </div>
  );
};

export default memo(InfoInline);
