import React, { useState } from 'react';
import { SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import { WebHeading28 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { Legal30 } from '@fintronners/react-ui/src/GlobalStyling';
import { useSidePanel } from '~context/SidePanelContext';
import { Button } from '@/components/common/Button';
import ConfirmPopup from '@/components/common/Popups/ConfirmPopup';

const SidePanelTest: React.FC = () => {
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const { currentFlowStep, goTo, enableInteractionWithModal } = useSidePanel();

  const handleConfirmHandler = () => {
    setIsConfirmModalOpened(false);
    console.log('confirm');
  };

  const handleDeclineHandler = () => {
    setIsConfirmModalOpened(false);
    console.log('decline');
  };

  const handleOpenModal = () => {
    enableInteractionWithModal();
    setIsConfirmModalOpened(true);
  };

  return (
    <>
      {isConfirmModalOpened && (
        <ConfirmPopup
          title="Are you sure?"
          onConfirm={handleConfirmHandler}
          onDecline={handleDeclineHandler}
        >
          Something something.
        </ConfirmPopup>
      )}
      <SheetContent
        onInteractOutside={(e) => {
          if (isConfirmModalOpened) {
            e.preventDefault();
          }
        }}
      >
        <SheetHeader>
          <SheetTitle>Retirement - self-directed</SheetTitle>
        </SheetHeader>
        <Button onClick={() => goTo(1)}>Next {currentFlowStep}</Button>
        {currentFlowStep === 0 && (
          <div className="flex flex-col justify-center items-start">
            <WebHeading28.Bold>Main content for page 1</WebHeading28.Bold>
            <SheetFooter className="text-center">
              <Legal30.Regular>Footer for page 1</Legal30.Regular>
            </SheetFooter>
          </div>
        )}
        {currentFlowStep === 1 && (
          <div className="flex flex-col justify-center items-start">
            <WebHeading28.Bold>Main content for page 2</WebHeading28.Bold>
            <Button onClick={handleOpenModal}>Save</Button>
            <SheetFooter className="text-center">
              <Legal30.Regular>Footer for page 2</Legal30.Regular>
            </SheetFooter>
          </div>
        )}
      </SheetContent>
    </>
  );
};

export default SidePanelTest;
