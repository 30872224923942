import React, { MouseEventHandler } from 'react';

// Trabian
import { Button } from '@/components/common/Button';
import PopupBase, { PopupProps } from '@/components/common/Popups/base/PopupBase';
import PopupHeader from '@/components/common/Popups/base/PopupHeader';
import PopupContent from '@/components/common/Popups/base/PopupContent';
import { useConfigStore } from '@/stores/config-store';

// FinTron
import { Heading75, PopUpBodyText45 } from '@fintronners/react-ui/src/GlobalStyling';

// FinTron SVGs
import SvgCircleExclamation from '@fintronners/react-ui/src/Images/autoIndexedImages/tintableSvgs/CircleExclamation';

interface ConfirmPopupProps extends PopupProps {
  children: React.ReactNode;
  title: string;
  confirmButtonText?: string;
  declineButtonText?: string;
  onConfirm: MouseEventHandler;
  onDecline: MouseEventHandler;
}

const ConfirmPopup: React.FC<ConfirmPopupProps> = (props) => {
  const { theme } = useConfigStore();

  return (
    <PopupBase>
      <PopupHeader onCloseButtonClick={props.onDecline} />
      <PopupContent>
        <div className="mb-4 text-center">
          <div className="inline-flex">
            <SvgCircleExclamation width={50} height={50} color={theme.colors.primary} />
          </div>
        </div>
        <div className="mb-4 text-center">
          <Heading75.Bold>{props.title}</Heading75.Bold>
        </div>
        <div className="mb-12 text-center">
          <PopUpBodyText45.Regular>{props.children}</PopUpBodyText45.Regular>
        </div>
        <div className="flex flex-col nowrap items-center">
          <Button className="w-[420px] max-w-full mb-6" onClick={props.onConfirm}>
            {props.confirmButtonText ?? 'Confirm'}
          </Button>
          <Button variant="secondary" className="w-[420px] max-w-full" onClick={props.onDecline}>
            {props.declineButtonText ?? 'Decline'}
          </Button>
        </div>
      </PopupContent>
    </PopupBase>
  );
};

export default ConfirmPopup;
