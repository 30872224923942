import React, { useState, useMemo, useEffect, forwardRef } from 'react';
import Image from 'next/image';
import Skeleton from '@/components/common/Skeleton/Skeleton';
import { clsx, type ClassValue } from 'clsx';
import { getGoogleMapStaticURI } from './utils';

export type MapConfig = typeof DEFAULT_CONFIG;

type GoogleStaticMapProps = {
  location: string | null | undefined;
  customConfig?: Partial<MapConfig>;
  className?: ClassValue;
};

const DEFAULT_CONFIG = {
  width: 400,
  height: 200,
  zoom: 13,
  scale: 2,
  mapType: 'roadmap',
  format: 'png',
};

const GoogleStaticMap = forwardRef<HTMLDivElement, GoogleStaticMapProps>(
  ({ location, customConfig, className }, ref) => {
    const [loading, setLoading] = useState<boolean>(false);

    const config = useMemo<MapConfig>(
      () => Object.assign(DEFAULT_CONFIG, customConfig),
      [customConfig],
    );

    const staticURI = getGoogleMapStaticURI(location, config);

    useEffect(() => setLoading(true), [location]);

    return (
      <>
        {staticURI && (
          <div className={clsx('relative w-full', className)}>
            <Image
              width={config.width}
              height={config.height}
              src={staticURI}
              onLoad={() => setLoading(false)}
              className={loading ? 'invisible' : 'visible'}
              alt="map"
            />

            {loading && (
              <Skeleton
                className="absolute left-0 top-0 z-10"
                width={config.width}
                height={config.height}
              />
            )}
          </div>
        )}

        <div ref={ref} />
      </>
    );
  },
);

GoogleStaticMap.displayName = 'GoogleStaticMap';

export default GoogleStaticMap;
