import { clsx } from 'clsx';
import type { ClassValue } from 'clsx';

type SkeletonProps = {
  width?: number | string;
  height?: number | string;
  className?: ClassValue;
};

const Skeleton = ({ width, height, className }: SkeletonProps) => {
  return (
    <div className={clsx('animate-pulse rounded bg-grey6', className)} style={{ width, height }} />
  );
};

export default Skeleton;
