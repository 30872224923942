import * as yup from 'yup';
import {
  getEmailSchema,
  getPhoneNumberSchema,
  getStringRequiredSchema,
} from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';

export const profileFormSchema = yup.object().shape({
  dateOfBirth: yup.string(),
  firstName: getStringRequiredSchema(),
  lastName: getStringRequiredSchema(),
  phoneNumber: getPhoneNumberSchema(true, true),
  email: getEmailSchema().required(),
  streetAddress: getStringRequiredSchema(),
  additionalStreetAddress: yup.string(),
  city: getStringRequiredSchema(),
  state: getStringRequiredSchema(),
  zipCode: getStringRequiredSchema(),
});

export type ProfileFormData = yup.InferType<typeof profileFormSchema>;
