import { UserProfileUserVisaType } from '@fintronners/react-api/src';
import { InvestorInformationNonDiscretionaryFieldNames } from '@fintronners/react-api/src/utils/formUtils/schemas/investorInformationNonDiscretionary/investorInformationNonDiscretionarySchema';
import { UserUpdateFormNames } from '@fintronners/react-api/src/utils/formUtils/schemas/profile/profileSchema';
import { InvestorInformationDiscretionaryFieldNames } from './investorInformationDiscretionary/investorInformationDiscretionarySchema';
import { TrustedContactFormNames } from './trustedContactValidationSchema';

export enum FormErrorTypes {
  DUPLICATE = 'duplicate',
}

export type FormErrorModel<t> = {
  type: FormErrorTypes;
  name: t;
  message: string;
};

export interface FormFieldMarkdown<fieldName> {
  labelMarkdown: string;
  name: fieldName;
  markdownValue: string;
  isPending?: boolean;
  disabled?: boolean;
}

export interface FormFieldMarkdownProfile extends FormFieldMarkdown<UserUpdateFormNames> {
  type?: 'datepicker' | 'textfield';
}
export interface FormFieldMarkdownInvestorInformationNonDiscretionary
  extends FormFieldMarkdown<InvestorInformationNonDiscretionaryFieldNames> {
  dropdownValues?: string[];
}

export interface FormFieldMarkdownInvestorInformationDiscretionary
  extends FormFieldMarkdown<InvestorInformationDiscretionaryFieldNames> {
  dropdownValues?: string[];
}

export interface FormFieldMarkdownInvestorInformationDiscretionary
  extends FormFieldMarkdown<InvestorInformationDiscretionaryFieldNames> {
  dropdownValues?: string[];
  hide?: boolean;
}

export interface FormFieldMarkdownTrustedContact
  extends FormFieldMarkdown<TrustedContactFormNames> {
  type?: 'datepicker' | 'textfield';
}

export enum CITIZEN_TYPES_ADMIN {
  US_CITIZENSHIP = 'US_CITIZENSHIP',
  VISA = 'VISA',
  GREEN_CARD = 'GREEN_CARD',
}

export const READIBLE_VISA_TYPES = {
  [UserProfileUserVisaType.UserVisaTypeE1]: 'E1',
  [UserProfileUserVisaType.UserVisaTypeE2]: 'E2',
  [UserProfileUserVisaType.UserVisaTypeE3]: 'E3',
  [UserProfileUserVisaType.UserVisaTypeF1]: 'F1',
  [UserProfileUserVisaType.UserVisaTypeG4]: 'G4',
  [UserProfileUserVisaType.UserVisaTypeH1B]: 'H1B',
  [UserProfileUserVisaType.UserVisaTypeL1]: 'L1',
  [UserProfileUserVisaType.UserVisaTypeO1]: 'O1',
  [UserProfileUserVisaType.UserVisaTypeTn1]: 'Tn1',
  [UserProfileUserVisaType.UserVisaTypeOther]: 'Other',
};

export const READIBLE_CITIZEN_TYPE = {
  [CITIZEN_TYPES_ADMIN.US_CITIZENSHIP]: 'U.S Citizenship',
  [CITIZEN_TYPES_ADMIN.VISA]: 'Visa',
  [CITIZEN_TYPES_ADMIN.GREEN_CARD]: 'Green Card',
};

export type FormFieldValue = string | undefined | null;

export type FieldUpdateRequests<t> = {
  requestParameters: t | undefined;
  isPending: boolean;
}[];

export enum YES_OR_NO {
  YES = 'YES',
  NO = 'NO',
}
