import { gql } from '@apollo/client';

export const GET_ADMIN_EXTERNAL_ACCOUNTS = gql`
  query GetAdminExternalAccounts(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $startDate: Time
    $endDate: Time
    $criteria: String = ""
    $allFilters: [ExternalAccountWhereInput!]
  ) {
    response: externalAccounts(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        and: $allFilters
        createTimeGTE: $startDate
        createTimeLTE: $endDate
        or: [
          { accountNumberContainsFold: $criteria }
          {
            hasUserWith: {
              or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
            }
          }
        ]
      }
    ) {
      edges {
        node {
          id
          user {
            firstName
            lastName
          }
          accountNumber
          connectionDetails {
            ... on ExternalAccountConnectionDetailsPlaid {
              bankOwnerName
            }
          }
          brokerageCanUse
          advisoryCanUse
          connectionStatus
          institution {
            id
            name
          }
          mask
          createTime
          updateTime
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;
