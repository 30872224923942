import { useQuery } from '@apollo/client';
import {
  GET_WITHDRAWABLE_AMOUNT,
  GetWithdrawableAmountQuery,
  UserAccountAccountType,
} from '@fintronners/react-api/src';
import useUserAccount from '@/hooks/api/useUserAccount';
import useCachedMarketData from '@fintronners/react-widgets/src/hooks/market/useCachedMarketData';

export const useWithdrawableAmount = (goalUid?: string) => {
  const { userAccount } = useUserAccount(
    goalUid
      ? UserAccountAccountType.AccountTypeBrokerageDiscretionary
      : UserAccountAccountType.AccountTypeBrokerage,
  );

  const { fiatAsset } = useCachedMarketData();

  return useQuery<GetWithdrawableAmountQuery>(GET_WITHDRAWABLE_AMOUNT, {
    variables: {
      portfolioID: userAccount?.firstPortfolioId,
      assetID: fiatAsset?.id,
    },
    fetchPolicy: 'network-only',
    skip: Boolean(goalUid),
  });
};
