import {
  DiscretionaryUpdateRequestUpdateRequestStatus,
  GetLatestDiscretionaryUpdateRequestsQuery,
  GetLatestNonDiscretionaryUpdateRequestsQuery,
  GetLatestProfileUpdateRequestsQuery,
  NonDiscretionaryUpdateRequestUpdateRequestStatus,
  UserProfileUpdateRequestUpdateRequestStatus,
} from '../graphql/types/graphql';
import { UserAccountAccountType } from '@fintronners/react-api/src';
import { FinTronProduct } from '@fintronners/react-utils/src';

/**
 * Checks if there are pending update requests in the provided data.
 *
 * @param data - The data containing non-discretionary or discretionary update requests.
 * @returns True if there are pending update requests, otherwise false.
 */
export const isApexRequestPending = (
  data?:
    | GetLatestNonDiscretionaryUpdateRequestsQuery
    | GetLatestDiscretionaryUpdateRequestsQuery
    | GetLatestProfileUpdateRequestsQuery,
) => {
  if (!data) return false;
  let requests;
  let statusArr: (
    | NonDiscretionaryUpdateRequestUpdateRequestStatus
    | DiscretionaryUpdateRequestUpdateRequestStatus
    | UserProfileUpdateRequestUpdateRequestStatus
  )[] = [];

  if ('nonDiscretionaryUpdateRequests' in data) {
    requests = data.nonDiscretionaryUpdateRequests;
    statusArr = [
      NonDiscretionaryUpdateRequestUpdateRequestStatus.UpdateRequestStatusPending,
      NonDiscretionaryUpdateRequestUpdateRequestStatus.UpdateRequestStatusSubmitted,
    ];
  } else if ('discretionaryUpdateRequests' in data) {
    requests = data.discretionaryUpdateRequests;
    statusArr = [
      DiscretionaryUpdateRequestUpdateRequestStatus.UpdateRequestStatusPending,
      DiscretionaryUpdateRequestUpdateRequestStatus.UpdateRequestStatusSubmitted,
    ];
  } else {
    requests = data.userProfileUpdateRequests;
    statusArr = [
      UserProfileUpdateRequestUpdateRequestStatus.UpdateRequestStatusPending,
      UserProfileUpdateRequestUpdateRequestStatus.UpdateRequestStatusSubmitted,
    ];
  }

  const status = requests?.edges?.[0]?.node?.status;
  if (!status) return false;

  if (statusArr.includes(status)) {
    return true;
  }

  return false;
};

/**
 * Helper function to get the UserAccountAccountType for the product
 * @param product - The product to get the account type for.
 */
export const getAccountTypeFromProduct = (product: FinTronProduct) => {
  switch (product) {
    case FinTronProduct.SelfDirected:
      return UserAccountAccountType.AccountTypeBrokerage;
    case FinTronProduct.RoboGoals:
    case FinTronProduct.RoboGeneralInvesting:
      return UserAccountAccountType.AccountTypeBrokerageDiscretionary;
  }
};
