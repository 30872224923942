import { useState } from 'react';
import useUserAccount from '@/hooks/api/useUserAccount';
import { type UseExternalAccount } from '@fintronners/react-widgets/src/hooks/useExternalAccounts';
import {
  V1CreateDepositSummaryResponse,
  V1CreateTransferRequest,
  V1TransferType,
} from '@fintronners/react-api/src';
import fLogger from '@fintronners/react-utils/src/fLogger';

import useTransferService from './useTransferService';
import useCurrentAccountType, { useIsIRAAccount } from '../utils/useCurrentAccountType';
import { RetirementContributionInfoRetirementContributionType } from '@fintronners/react-api/src';

type CreateTransferParams = {
  amount: string;
  account?: UseExternalAccount | null;
  taxYear?: number;
};

type FetchSummaryParams = {
  amount: string;
  externalAccountUid?: string;
};

export const useTransfer = () => {
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [summary, setSummary] = useState<V1CreateDepositSummaryResponse | null>(null);
  const isIRAAccount = useIsIRAAccount();

  const transferService = useTransferService();
  const accountType = useCurrentAccountType();
  const { userAccount } = useUserAccount(accountType);

  const portfolioUid = userAccount?.firstPortfolioId || '';

  const fetchSummary = async ({ amount, externalAccountUid = '' }: FetchSummaryParams) => {
    setIsSummaryLoading(true);
    try {
      const response = await transferService.transferServiceCreateDepositSummary({
        externalAccountUid,
        portfolioUid,
        amount,
      });

      setSummary(response.data);
    } catch (error) {
      //TODO: Use Sentry to handle error
      fLogger.error('Error fetching deposit summary', error);
    } finally {
      setIsSummaryLoading(false);
    }
  };

  const createTransfer = async ({ amount, account, taxYear }: CreateTransferParams) => {
    const externalAccountUid = account?.id;

    const retirementInfo = {
      contribution: {
        type: RetirementContributionInfoRetirementContributionType.RetirementContributionTypeRegular,
        taxYear,
      },
    };

    const requestBody: V1CreateTransferRequest = {
      externalAccountUid,
      type: V1TransferType.Deposit,
      amount,
      portfolioUid,
      ...(isIRAAccount && {
        retirementInfo,
      }),
    };

    return await transferService.transferServiceCreateTransfer(requestBody);
  };

  return {
    summary,
    fetchSummary,
    isSummaryLoading,
    createTransfer,
  };
};
