import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';

export default {
  sorry: 'Desculpe',
  oops: 'Ops!',
  existingUser:
    'Parece que você já tem algumas informações registradas. Por razões de segurança, uma pessoa pode ter apenas um nome de usuário.',
  existingAccount:
    'Parece que algumas de suas informações já estão registradas. Tente fazer login para continuar.',
  createUser: `Não foi possível criar seu perfil neste momento. Por favor, confirme se todas as informações foram inseridas corretamente. Se o problema persistir, entre em contato com o suporte em ${FinTronConfig.supportEmail}.`,
  createAccount: `Não foi possível criar sua conta neste momento. Por favor, confirme se todas as informações foram inseridas corretamente. Se o problema persistir, entre em contato com o suporte em ${FinTronConfig.supportEmail}.`,
  enrollPreferences:
    'Houve um erro ao salvar suas preferências de inscrição. Por favor, verifique suas configurações e tente novamente. Se o problema persistir, entre em contato com o suporte.',
  required: 'Este campo é obrigatório',
  minLength: 'No mínimo {{minLength}} caracteres',
  maxLength: 'No máximo {{maxLength}} caracteres',
  minAmount: 'No mínimo ${{minAmount}}',
  maxAmount: 'No máximo ${{maxAmount}}',
  minPercentAmount: 'A porcentagem deve ser no mínimo {{minAmount}}%',
  maxPercentAmount: 'A porcentagem deve ser menor que {{maxAmount}}%',
  invalidUsername: 'O nome de usuário não pode conter caracteres especiais',
  invalidSSN: 'O SSN deve ter exatamente 9 dígitos',
  duplicateSSN: 'O SSN já existe',
  duplicateEmail: 'O e-mail já existe',
  duplicatePhoneNumber: 'O número de celular já existe',
  passwordDoNotMatch: 'As senhas não correspondem',
  invalidPassword: 'A senha deve conter pelo menos 1 caractere especial (! @ # $…)',
  invalidPasswordUppercase: 'A senha deve conter pelo menos uma letra maiúscula',
  invalidPasswordLowercase: 'A senha deve conter pelo menos uma letra minúscula',
  invalidPasswordNumber: 'A senha deve conter pelo menos um número',
  invalidPasswordSpecialCharacter: 'A senha deve conter pelo menos um caractere especial',
  invalidPasswordLeadingTrailingSpace: 'A senha não pode ter espaços em branco no início ou no fim',
  invalidName: '{{field}} não pode conter caracteres especiais e espaços',
  invalidCode: 'O código deve ter exatamente 6 dígitos',
  invalidEmail: 'Formato de e-mail inválido',
  invalidPhoneNumber: 'Número de celular inválido',
  invalidPhoneNumberAdmin: 'O número de celular deve ter 10 dígitos e incluir +1',
  birthCountryError:
    'Enquanto estiver com visto, não pode definir o país de nascimento como Estados Unidos',
  citizenshipCountryError:
    'Enquanto estiver com visto, não pode definir o país de cidadania como Estados Unidos',
  invalidDate: 'Data inválida',
  duplicatedField: '{{type}} já está em uso. Por favor, utilize outro.',
  phoneNumberMismatch: 'O número de celular não corresponde ao país selecionado',
  phoneNumberFormatBR: 'O número de celular deve seguir o formato +55 (AA) 9NNNN-NNNN',
  areaCodeInvalid: 'O código de área não é válido para o país selecionado',
  phoneNumberFormatInvalid: 'Formato de número de celular inválido',
  phoneNumberFormatUS: 'O número de celular deve seguir o formato +1 (NNN) NNN-NNNN',
  wrongCode: 'O código de verificação inserido está incorreto.',
  noAddressProvided: 'Por favor, forneça um endereço',
};
