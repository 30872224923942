import { useEffect } from 'react';
import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { thousandFormatNumbroCurrency } from '@fintronners/react-utils/src/numberUtilsTSX';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from '~components/common/Button';
import { TradeFormData, TradeSide } from '../schema';
import { TradeCurrency } from './TradeCurrency';

const displayStrings = {
  holdings: (amount?: string) => `Current holdings: ${amount ?? '--'}`,
  sellAll: 'Sell All',
};

interface TradeMarketSellFormProps {
  tradableInCash: number;
  isMutualFund?: boolean;
}

export const TradeMarketSellForm = ({ tradableInCash, isMutualFund }: TradeMarketSellFormProps) => {
  const { setValue } = useFormContext<TradeFormData>();
  const amount = useWatch({ name: 'amount' });

  useEffect(() => {
    if (isMutualFund && amount) {
      const sellPercentage = (parseFloat(amount) / tradableInCash) * 100;
      // TODO: Remove this once we have a global config solution: https://fintroninvest.atlassian.net/browse/CA-7893
      if (sellPercentage >= 90 && sellPercentage < 100) {
        setValue('amount', tradableInCash.toString());
      }
    }
  }, [amount, tradableInCash, isMutualFund, setValue]);

  return (
    <div>
      <TradeCurrency side={TradeSide.SELL} />

      <WebBodyText14.Regular className="text-primary">
        {displayStrings.holdings(thousandFormatNumbroCurrency(tradableInCash))}
      </WebBodyText14.Regular>
      <Button
        variant="secondary"
        className="mt-2"
        onClick={() => setValue('amount', tradableInCash.toString())}
      >
        {displayStrings.sellAll}
      </Button>
    </div>
  );
};
