import axios, { AxiosInstance, AxiosResponse } from 'axios';
import GRPCApi from './GRPCApi';
import fLogger from '@fintronners/react-utils/src/fLogger';
import { IS_PROD } from '@fintronners/react-utils/src/envConstants';

export const API_LOGGING_LEVEL = 1;

/**
 * Singleton class for the Api configuration.
 *
 */
class BaseApi {
  _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      timeout: 15000,
    });
    GRPCApi.setAxiosInstance(this._api);

    if (IS_PROD) return;
    this._configureLogging(this._api, '🌐️');
  }

  get api() {
    return this._api;
  }

  setAuthToken(token?: string | null) {
    if (token) {
      const bearerToken = 'Bearer ' + token;
      fLogger.trace({ token: token });
      this._api.defaults.headers.common.Authorization = bearerToken;
      return;
    }
    delete this._api.defaults.headers.common.Authorization;
  }

  get hasToken() {
    return !!this._api.defaults.headers.common.Authorization;
  }

  _configureLogging(axiosInstance: AxiosInstance, prefix = '') {
    axiosInstance.interceptors.request.use(
      (config) => {
        let log = '';
        if (API_LOGGING_LEVEL >= 1) {
          log = log.concat(`Request ${config.method} ${config.url}`);
        }
        if (API_LOGGING_LEVEL >= 2) {
          log = log.concat(` - Detailed=${JSON.stringify(config)}`);
        }
        if (log.length > 0) {
          fLogger.trace(`${prefix}${log}`);
        }
        return config;
      },
      (error) => {
        if (API_LOGGING_LEVEL >= 1) {
          fLogger.trace(`${prefix}Request error ${error.url}: ${JSON.stringify(error.message)}`);
        }
        return Promise.reject(error);
      },
    );

    axiosInstance.interceptors.response?.use(
      // NOTE: For some reason, response can be undefined but TS doesn't like
      // it when setting it as optional.
      (response: AxiosResponse) => {
        let log = '';
        if (API_LOGGING_LEVEL >= 1) {
          log = log.concat(
            `Response ${response?.status} ${response?.config.method} ${response?.config.url}`,
          );
        }
        if (API_LOGGING_LEVEL >= 2) {
          log = log.concat(` - Body ${JSON.stringify(response?.data)}`);
        }
        if (log.length > 0) {
          fLogger.trace(`${prefix}${log}`);
        }
        return response;
      },
      (error) => {
        let log = '';
        if (API_LOGGING_LEVEL >= 1) {
          fLogger.trace(
            `Response error ${error.response?.status} error ${error.config.url}: AxiosError=${
              error.message
            } ServerMessage=${JSON.stringify(error.response?.data.message)}`,
          );
        }
        if (API_LOGGING_LEVEL >= 2) {
          log = log.concat(` - Detailed=${JSON.stringify(error)}}`);
        }
        if (log.length > 0) {
          fLogger.trace(`${prefix}${log}`);
        }
        return Promise.reject(error);
      },
    );
  }
}

export default new BaseApi();
