import React, { PropsWithChildren, useId, useMemo } from 'react';
import { MaskProps, useMask } from '@react-input/mask';
import { dateMatchModifiers, Matcher } from 'react-day-picker';
import * as dateFns from 'date-fns';

import cn from '@/utils/tailwind';
import { Button } from '../../Button';
import { Calendar, CalendarProps } from '../../Calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../../Popover';
import { DatepickerProvider, useDatepicker } from './Datepicker.context';
import { defaultMask } from './datepicker.consts';

type DatepickerRootProps = {
  children: React.ReactNode;
  defaultValue?: Date | null;
  format?: string;
  value?: Date | null;
  onChange?: (newDate?: Date | null) => void;
  className?: string;
  disableDates?: Matcher | Matcher[];
  placeholder?: string;
  defaultOpen?: boolean;
};

const DatepickerRoot: React.FC<DatepickerRootProps> = ({ children, ...rest }) => {
  return (
    <DatepickerProvider {...rest}>
      <DatepickerRootPopover>{children}</DatepickerRootPopover>
    </DatepickerProvider>
  );
};

DatepickerRoot.displayName = 'DatepickerRoot';

type DatepickerRootPopoverProps = {
  children: React.ReactNode;
};

const DatepickerRootPopover: React.FC<DatepickerRootPopoverProps> = ({ children }) => {
  const { isOpen, handleOpenChange } = useDatepicker();

  return (
    <Popover open={isOpen} onOpenChange={handleOpenChange}>
      {children}
    </Popover>
  );
};

DatepickerRootPopover.displayName = 'DatepickerRootPopover';

const DatepickerTrigger = ({ children }: PropsWithChildren) => {
  return (
    <PopoverTrigger asChild>
      <div>{children}</div>
    </PopoverTrigger>
  );
};

DatepickerTrigger.displayName = 'DatepickerTrigger';

type DatepickerContentProps = {
  className?: string;
  children: React.ReactNode;
};

const DatepickerContent: React.FC<DatepickerContentProps> = ({ className, children }) => {
  return (
    <PopoverContent className={cn('w-auto bg-white p-5 flex flex-col gap-3', className)}>
      {children}
    </PopoverContent>
  );
};

DatepickerContent.displayName = 'DatepickerContent';

type DatepickerInputProps = {
  className?: string;
  maskOptions?: MaskProps;
};

const DatepickerInput = React.forwardRef<HTMLInputElement, DatepickerInputProps>(
  ({ className, maskOptions }, ref) => {
    const {
      inputValue,
      handleChangeInput,
      innerProps: { format },
    } = useDatepicker();

    const inputRef = useMask({ mask: defaultMask, replacement: { _: /\d/ }, ...maskOptions });

    React.useImperativeHandle(ref, () => inputRef.current!, []);

    const uuid = useId();
    const inputId = `datepicker-trigger-${uuid}`;
    const descriptionId = `datepicker-trigger-desc-${uuid}`;

    return (
      <>
        <label className="sr-only" htmlFor={inputId}>
          Type a date
        </label>

        <input
          aria-describedby={descriptionId}
          id={inputId}
          ref={inputRef}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          placeholder="- / - / -"
          value={inputValue}
          onChange={handleChangeInput}
          className={cn(
            'border-primary rounded-md border h-9 px-3 focus:border-primary focus:border-2 outline-none',
            className,
          )}
        />

        <div className="sr-only" id={descriptionId}>
          The date should be given in the format {format}
        </div>
      </>
    );
  },
);

DatepickerInput.displayName = 'DatepickerInput';

type DatepickerCalendarProps = {
  className?: string;
  classNames?: CalendarProps['classNames'];
  showMonthAndYear: boolean;
};

const DatepickerCalendar: React.FC<DatepickerCalendarProps> = ({
  className,
  classNames,
  showMonthAndYear,
}) => {
  const {
    month,
    currentDate,
    handleCalendarSelect,
    handleMonthChange,
    innerProps: { disableDates },
  } = useDatepicker();

  return (
    <Calendar
      className={className}
      classNames={classNames}
      mode="single"
      required
      month={month}
      showMonthAndYear={showMonthAndYear}
      onMonthChange={handleMonthChange}
      disabled={disableDates}
      selected={currentDate ?? undefined}
      onSelect={handleCalendarSelect}
    />
  );
};

DatepickerCalendar.displayName = 'DatepickerCalendar';

type DatepickerApplyButtonProps = {
  className?: string;
};

const DatepickerApplyButton: React.FC<DatepickerApplyButtonProps> = () => {
  const {
    handleApply,
    currentDate,
    innerProps: { disableDates },
  } = useDatepicker();

  const isOutsideEnabledRange = useMemo(() => {
    if (!disableDates || !currentDate) {
      return false;
    }

    return dateMatchModifiers(currentDate, disableDates, {
      ...dateFns,
      Date: Date,
    });
  }, [disableDates, currentDate]);

  return (
    <Button
      className="text-xs"
      onClick={handleApply}
      disabled={!currentDate || isOutsideEnabledRange}
    >
      Apply
    </Button>
  );
};

DatepickerApplyButton.displayName = 'DatepickerApplyButton';

type DatepickerResetButtonProps = {
  className?: string;
};

const DatepickerResetButton: React.FC<DatepickerResetButtonProps> = () => {
  const { handleReset, currentDate } = useDatepicker();

  return (
    <Button variant="ghost" onClick={handleReset} className="text-xs" disabled={!currentDate}>
      Reset
    </Button>
  );
};

DatepickerResetButton.displayName = 'DatepickerResetButton';

type DatepickerActionContainerProps = {
  children: React.ReactNode;
  className?: string;
};

const DatepickerActionContainer: React.FC<DatepickerActionContainerProps> = ({
  children,
  className,
}) => {
  return <div className={cn('flex gap-1', className)}>{children}</div>;
};

DatepickerActionContainer.displayName = 'DatepickerActionContainer';

export {
  DatepickerActionContainer,
  DatepickerApplyButton,
  DatepickerCalendar,
  DatepickerContent,
  DatepickerInput,
  DatepickerResetButton,
  DatepickerRoot,
  DatepickerTrigger,
};
export type { DatepickerRootProps };
