import { ConfigStore } from '@/stores/config-store';

export type ThemeType = ConfigStore['theme'];
export type ConfigType = ConfigStore['config'];

export const transformToOldThemeConfig = (theme: ThemeType, config: ConfigType) => {
  const fontFamily = [theme.fonts.regular, theme.fonts.medium, theme.fonts.bold]
    .filter(Boolean)
    .map((font) => `"${font}"`)
    .join(', ');

  return {
    text: {
      fontFamily,
    },
    colors: {
      background: theme.colors.background,
      primary: theme.colors.primary,
      primaryDark: theme.colors.primaryDark,
    },
    ui: {
      buttonBorderRadius: parseInt(theme.borderRadius, 10), // Convert the border radius to an integer for compatibility
    },
    images: {
      lightBrandLogoL: {
        logoRatio: config.images.lightBrandLogoL.logoRatio,
        uri: config.images.lightBrandLogoL.uri,
      },
      darkBrandLogoL: {
        logoRatio: config.images.darkBrandLogoL.logoRatio,
        uri: config.images.darkBrandLogoL.uri,
      },
      colorBrandLogoL: {
        logoRatio: config.images.colorBrandLogoL.logoRatio,
        uri: config.images.colorBrandLogoL.uri,
      },
      darkBrandLogoS: {
        logoRatio: config.images.darkBrandLogoS.logoRatio,
        uri: config.images.darkBrandLogoS.uri,
      },
      mobileLogo: {
        logoRatio: config.images.mobileLogo?.logoRatio,
        uri: config.images.mobileLogo?.uri,
      },
    },
  };
};
