import { WebBodyText14, WebCaption12 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import React, { HTMLAttributes, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import cn from '@/utils/tailwind';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';

type FormTextFieldProps = CurrencyInputProps & {
  label?: string;
  fieldError?: FieldError;
  isPending?: boolean;
  placeholder?: string;
  containerClassName?: HTMLAttributes<HTMLDivElement>['className'];
};

const PercentageTextField = forwardRef<HTMLInputElement, FormTextFieldProps>(
  (
    { label = '', placeholder, type = 'text', fieldError, isPending, containerClassName, ...props },
    ref,
  ) => {
    return (
      <div>
        <div className={cn('flex flex-col flex-nowrap', containerClassName)}>
          {label && (
            <WebBodyText14.Medium
              className={cn('block mb-1', isPending ? 'text-primary' : 'text-grey72')}
            >
              {`${label}${isPending ? ' *' : ''}`}
            </WebBodyText14.Medium>
          )}
          <div className="flex items-center gap-5">
            <CurrencyInput
              {...props}
              id={props.name}
              ref={ref}
              placeholder={placeholder}
              type={type}
              className={cn(
                'w-full h-[35px] rounded border px-3 py-2 text-sm text-darkestBlue disabled:cursor-not-allowed disabled:bg-grey7',
                fieldError?.message && 'border-red',
              )}
              suffix="%"
              decimalsLimit={2}
              aria-errormessage={fieldError?.message}
              intlConfig={{ locale: 'en-US' }}
            />
          </div>
        </div>
        {fieldError?.message && (
          <div className="mt-1 ml-1">
            <WebCaption12.Regular className="text-red">{fieldError.message}</WebCaption12.Regular>
          </div>
        )}
      </div>
    );
  },
);

export default PercentageTextField;
