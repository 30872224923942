import {
  calculateChartWidthForTradingDay,
  StartDatePeriodMap,
  TimePeriodMap,
} from '@fintronners/react-widgets/src/Widgets/Charts/chartUtils';
import { useEffect, useMemo, useState } from 'react';
import { ChartDuration } from '@fintronners/react-utils/src/durationUtils';
import useCachedMarketStatus from '@fintronners/react-widgets/src/hooks/useCachedMarketStatus';
import { GetMarketStatusQuery } from '@fintronners/react-api';

/**
 * Gets the time range for the given duration to send to the
 * charts query.
 *
 * @param duration A ChartDuration to get the time range for
 * @param marketStatus Cached market status from the BE
 * @param earliestChartTime The earliest chart time to go back to
 */
const getTimeRange = (
  duration: ChartDuration,
  marketStatus?: GetMarketStatusQuery['marketHours']['status'],
  earliestChartTime?: string,
) => {
  if (duration === ChartDuration.TRADING_DAY) {
    return {
      start: marketStatus?.open,
      end: marketStatus?.close,
    };
  } else {
    if (earliestChartTime) {
      const earliestDate = new Date(earliestChartTime);
      // ms is randomized to trigger a rerender in case the previous duration results in the same time range.
      earliestDate.setMilliseconds(earliestDate.getMilliseconds() + Math.random() * 100);
      const chartDate = new Date(StartDatePeriodMap[duration]());
      const newerDate = earliestDate > chartDate ? earliestDate : chartDate;
      return {
        start: newerDate,
        end: undefined,
      };
    }
    return {
      start: StartDatePeriodMap[duration](),
      end: undefined,
    };
  }
};

/**
 * Creates the state object for the given duration. The state is
 * intended to be set only once to make it more predictable for the
 * caller to only expect a single state change.
 *
 * @param duration A ChartDuration to get the time range for
 * @param marketStatus Cached market status from the BE
 * @param earliestChartTime The earliest chart time to go back to
 */
export const calculateState = (
  duration: ChartDuration,
  marketStatus?: GetMarketStatusQuery['marketHours']['status'],
  earliestChartTime?: string,
  containerRef?: React.RefObject<HTMLElement>,
) => {
  const timeRange = getTimeRange(duration, marketStatus, earliestChartTime);
  const chartWidth = calculateChartWidthForTradingDay(
    timeRange.end,
    new Date().toISOString(),
    containerRef,
  );
  return {
    timeRange: timeRange,
    period: TimePeriodMap[duration],
    chartWidth: chartWidth,
  };
};

/**
 * Custom hook for managing time range state for the given duration.
 *
 * @param duration A ChartDuration to get the time range for
 * @param waitForEarliestDate Used to let this hook know if it needed to wait for the
 * earliest chart time before calculating the chart data.
 * @param earliestChartTime The earliest chart time to go back to */
export const useStateForChart = (
  duration: ChartDuration,
  waitForEarliestDate = false,
  earliestChartTime?: string,
) => {
  const { latestMarketStatus } = useCachedMarketStatus();

  const initialState = useMemo(() => calculateState(duration, latestMarketStatus), [duration]);
  /**
   * Returns all the data at once so that the caller will only get one state update
   */
  const [singleState, setSingleState] = useState(initialState);

  useEffect(() => {
    if (!latestMarketStatus) return;
    if (waitForEarliestDate && !earliestChartTime) return;
    setSingleState(calculateState(duration, latestMarketStatus, earliestChartTime));
  }, [duration, latestMarketStatus, earliestChartTime]);

  return {
    timeRange: singleState.timeRange,
    period: singleState.period,
    chartWidth: singleState.chartWidth,
  };
};
