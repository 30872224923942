import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_MARKET_DATA, GetMarketDataQuery } from '@fintronners/react-api';

/**
 * This hook is used to fetch the market data from the cache.
 */
const useCachedMarketData = () => {
  const [fiatAsset, setFiatAsset] =
    useState<NonNullable<NonNullable<GetMarketDataQuery['fiatAssets']['edges']>[0]>['node']>();
  const [sectors, setSectors] = useState<
    NonNullable<NonNullable<GetMarketDataQuery['securitySectors']['edges']>[0]>['node'][]
  >([]);

  const { data, loading } = useQuery<GetMarketDataQuery>(GET_MARKET_DATA, {
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (data && !loading) {
      setFiatAsset(data?.fiatAssets.edges?.[0]?.node);
      const _sectors = data?.securitySectors?.edges?.map((sectorEdge) => sectorEdge?.node);
      setSectors(_sectors ?? []);
    }
  }, [data, loading]);

  return {
    fiatAsset,
    sectors,
    loading,
  };
};

export default useCachedMarketData;
