import { differenceInYears } from 'date-fns';
import { UserAccountAccountType, UserProfileUserFilingStatus } from '@fintronners/react-api/src';
import { useGetRetirementInfo } from '@fintronners/react-api/src/hooks/useGetRetirementInfo';
import useUserDetails from '@fintronners/react-widgets/src/hooks/useUserDetails';
import useCurrentAccountType from '../utils/useCurrentAccountType';

type UseRetirementInfoProps = {
  taxYear?: string;
  skip?: boolean;
};

export const useRetirementInfo = ({ taxYear, skip = false }: UseRetirementInfoProps) => {
  const accountType = useCurrentAccountType();

  //TODO: Get user details from global store. Check: https://github.com/FinTronners/AKOiOS/pull/4441#discussion_r1815643083
  const { userDetails } = useUserDetails();
  const dateOfBirth = userDetails?.profile?.dateOfBirth;
  const age = differenceInYears(new Date(), new Date(dateOfBirth));
  const currentTaxYear = taxYear || new Date().getFullYear().toString();

  const retirementInfo = useGetRetirementInfo({
    skip,
    state: userDetails?.profile?.state || 'CA',
    annualGrossIncome: userDetails?.profile?.annualGrossIncome || 0,
    age,
    filingStatus:
      userDetails?.profile?.filingStatus || UserProfileUserFilingStatus.UserFilingStatusSingle,
    fetchPolicy: 'no-cache',
  });

  const limit =
    accountType === UserAccountAccountType.AccountTypeIra
      ? retirementInfo.retirementFundamentalInfo?.contribution?.limits?.traditionalIRALimit
      : retirementInfo.retirementFundamentalInfo?.contribution?.limits?.rothIRALimit;

  const currentContributions = retirementInfo?.userRetirementContributions?.find(
    (contribution) => contribution?.year === Number(currentTaxYear),
  );

  const rothIraContributions = {
    current: currentContributions?.rothIRAWithPending,
  };

  const traditionalIraContributions = {
    current: currentContributions?.traditionalIRAWithPending,
  };

  const contributed =
    accountType === UserAccountAccountType.AccountTypeIra
      ? traditionalIraContributions
      : rothIraContributions;

  const limitLeft =
    parseFloat(retirementInfo.retirementFundamentalInfo?.contribution?.limits?.totalLimit) -
    (parseFloat(rothIraContributions.current) + parseFloat(traditionalIraContributions.current));

  return {
    ...retirementInfo,
    overall: {
      limit,
      currentContributions,
      rothIraContributions,
      traditionalIraContributions,
      contributed,
      limitLeft,
    },
  };
};
