import { UserRiskTolerance } from '@fintronners/react-api/src';
import * as yup from 'yup';

const displayStrings = {
  getToleranceOptions: () => {
    return [
      {
        tolerance: UserRiskTolerance.UserRiskToleranceLow,
        label: 'Conservative',
        description:
          'Conservative Risk Tolerance investors may prioritize the safety of their investment principal over the potential of higher returns.',
      },
      {
        tolerance: UserRiskTolerance.UserRiskToleranceMedium,
        label: 'Moderate',
        description:
          'Moderate Risk Tolerance investors may be comfortable with moderate market fluctuations and prefer investments with a moderate level of risk.',
      },
      {
        tolerance: UserRiskTolerance.UserRiskToleranceHigh,
        label: 'Aggressive',
        description:
          'Aggressive Risk Tolerance investors may prioritize the potential for high returns over the safety of their investment principal.',
      },
    ];
  },
};

export type RiskToleranceAnswerType = {
  label: string;
  description: string;
  value: UserRiskTolerance;
};

const getRiskTolerancesSurvey = () => {
  const answers: RiskToleranceAnswerType[] = displayStrings.getToleranceOptions().map((option) => ({
    label: option.label,
    description: option.description,
    value: option.tolerance,
  }));

  const getAnswerFromValue = (value: UserRiskTolerance) => {
    const answer = answers.find((ans) => ans.value === value);
    return answer;
  };

  return { answers, getAnswerFromValue };
};

export const riskTolerancesSurvey = getRiskTolerancesSurvey();

export const riskProfileSchema = yup.object({
  riskTolerance: yup.string().oneOf(Object.values(UserRiskTolerance)),
});

export type RiskProfileSchemaType = yup.InferType<typeof riskProfileSchema>;
