import * as yup from 'yup';
import { FORM_ERROR_MESSAGES } from './constants';
import {
  V1UserAnnualIncome,
  V1UserEmploymentStatus,
  V1UserInvestmentObjective,
  V1UserNetWorth,
} from '@fintronners/react-api/src/tsoai';
import { genericStringWhen, getOneOfEnumSchema } from './commonSchemas';

export enum ApexExtendedFieldNames {
  EMPLOYMENT_STATUS = 'employmentStatus',
  EMPLOYER_NAME = 'employerName',
  JOB_POSITION = 'jobPosition',
  INVESTMENT_OBJECTIVE = 'investmentObjective',
  ANNUAL_INCOME_RANGE = 'annualIncomeRange',
  NET_WORTH_RANGE = 'netWorthRange',
}

export type ApexExtendedFormType = yup.InferType<typeof apexExtendedSchema>;

export const apexExtendedSchema = yup.object().shape({
  [ApexExtendedFieldNames.EMPLOYMENT_STATUS]: getOneOfEnumSchema(V1UserEmploymentStatus),
  [ApexExtendedFieldNames.EMPLOYER_NAME]: genericStringWhen(
    ApexExtendedFieldNames.EMPLOYMENT_STATUS,
    V1UserEmploymentStatus.Employed,
    FORM_ERROR_MESSAGES.required,
  ),
  [ApexExtendedFieldNames.JOB_POSITION]: genericStringWhen(
    ApexExtendedFieldNames.EMPLOYMENT_STATUS,
    V1UserEmploymentStatus.Employed,
    FORM_ERROR_MESSAGES.required,
  ),
  [ApexExtendedFieldNames.INVESTMENT_OBJECTIVE]: getOneOfEnumSchema(V1UserInvestmentObjective),
  [ApexExtendedFieldNames.ANNUAL_INCOME_RANGE]: getOneOfEnumSchema(V1UserAnnualIncome),
  [ApexExtendedFieldNames.NET_WORTH_RANGE]: getOneOfEnumSchema(V1UserNetWorth),
});
