import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_VALUES } from '@fintronners/react-api/src';
import { ChartDuration } from '@fintronners/react-utils/src/durationUtils';
import { NumberNoNaN } from '@fintronners/react-utils/src/numberUtilsTSX';
import { useMemo } from 'react';
import { useStateForChart } from '@fintronners/react-widgets/src/Widgets/Charts/chartHooks';

/**
 * A hook used to get the price data for a users account
 *
 * @param portfolioId The portfolioId to get the price data for
 * @param isFocused Used to skip if the query if needed. Defaults to true
 * @returns The priceData, timerange and period, canSkip, and refetch. PriceData contains the gain losses
 */
const useAccountPriceData = (portfolioId: string | undefined, isFocused: boolean = true) => {
  const { timeRange, period } = useStateForChart(ChartDuration.TRADING_DAY);

  const canSkip = !portfolioId || !isFocused || !timeRange;

  const { data, refetch: refetchAccountValues } = useQuery(GET_ACCOUNT_VALUES, {
    skip: canSkip,
    fetchPolicy: 'no-cache',
    pollInterval: 60000,
    variables: {
      portfolioId: portfolioId,
      period: period,
      start: timeRange.start,
      end: timeRange.end,
    },
  });

  const priceData = useMemo(() => {
    const valueHistory = data?.userAccountPortfolios?.edges?.at(0)?.node?.valueHistory;
    const values = valueHistory?.values ?? [];
    const gainLosses = valueHistory?.gainLosses ?? [];
    const returnRates = valueHistory?.returnRates ?? [];
    const totalValue = data?.totalValue?.edges?.at(0)?.node?.totalValue ?? 0;

    return {
      gainLoss: NumberNoNaN(gainLosses[values.length - 1]) ?? 0,
      gainLossPercent: NumberNoNaN(returnRates[values.length - 1]),
      price: totalValue,
    };
  }, [data]);

  return { priceData, timeRange, period, canSkip, refetchAccountValues };
};

export default useAccountPriceData;
