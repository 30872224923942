import type { SupplementalQuestionsOnboardingData } from '@/api/getOnboardingData';

// NOTE: remove this data interceptor when data for BE is stable
export const onboardingDataInterceptor = (
  serverOnboardingData: SupplementalQuestionsOnboardingData,
  userId: string,
): SupplementalQuestionsOnboardingData => {
  let localOnboardingRawData = localStorage.getItem('onboardingStoredData');
  if (!localOnboardingRawData) localOnboardingRawData = '{}';

  const allLocalOnboardingData: Record<string, SupplementalQuestionsOnboardingData> =
    JSON.parse(localOnboardingRawData);

  const userLocalOnboardingData: SupplementalQuestionsOnboardingData =
    allLocalOnboardingData[userId] ?? {};

  const hasSecurityProfessionalDataStored = Boolean(
    Object.keys(userLocalOnboardingData.answers?.onboarding ?? {}).length,
  );

  const hasMarketDataStored = Boolean(
    Object.keys(userLocalOnboardingData.answers?.marketDataAnswers ?? {}).length,
  );

  const hasFilingStatusAndAGIDataStored = Boolean(
    Object.keys(userLocalOnboardingData.answers?.filingStatusAndAGI ?? {}).length,
  );

  return {
    hasRothIRAAccount: serverOnboardingData.hasRothIRAAccount,
    hasSecurityProfessionalDataStored,
    hasMarketDataStored,
    hasFilingStatusAndAGIDataStored,
    answers: userLocalOnboardingData?.answers ?? {},
  };
};

export const getShouldByPassSupplementalQuestions = (): boolean => {
  const bypassSupplementalQuestions = localStorage.getItem('bypassSQ');
  return bypassSupplementalQuestions === 'true' ? true : false;
};
