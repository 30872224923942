import React from 'react';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { clsx } from 'clsx';
import type {
  RadioGroupProps,
  RadioGroupItemProps,
  RadioGroupIndicatorProps,
} from '@radix-ui/react-radio-group';

const OptionSet = ({ className, ...props }: RadioGroupProps) => {
  return <RadioGroup.Root className={clsx('flex flex-col gap-5', className)} {...props} />;
};

OptionSet.Field = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={clsx(
        'bg-grey6 rounded-f0 flex items-center justify-between py-4 pl-3 pr-5',
        className,
      )}
      {...props}
    />
  );
};

OptionSet.Label = ({
  className,
  ...props
}: React.PropsWithChildren & React.LabelHTMLAttributes<HTMLLabelElement>) => {
  return <label className={clsx('text-grey72 cursor-pointer select-none', className)} {...props} />;
};

OptionSet.Item = ({ className, ...props }: RadioGroupItemProps) => {
  return (
    <RadioGroup.Item
      className={clsx(
        'hover:bg-violet3 h-[17px] w-[17px] cursor-pointer',
        'rounded-f0 bg-white shadow outline-none',
        className,
      )}
      {...props}
    />
  );
};

OptionSet.Indicator = ({ className, ...props }: RadioGroupIndicatorProps) => {
  return (
    <RadioGroup.Indicator
      className={clsx(
        'after:bg-blue80 relative flex h-full w-full items-center justify-center',
        "after:block after:h-[9px] after:w-[9px] after:rounded-[2px] after:content-['']",
        className,
      )}
      {...props}
    />
  );
};

export default OptionSet;
