import fLogger from './fLogger';
import i18next from '@fintronners/react-language/src/Lang/lang';

const isEnglish = i18next.language === 'en';

export const YEAR_DATE_TIME_FORMAT_OPTION: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: isEnglish,
};

export const YEAR_DATE_TIME_FORMAT_MS: Intl.DateTimeFormatOptions & {
  fractionalSecondDigits?: number;
} = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  fractionalSecondDigits: 3,
  hour12: isEnglish,
};

export const YEAR_DATE_FORMAT_OPTION: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

/**
 * Takes a Date object and returns a string with the date formatted
 * with the given format options
 *
 * @param date date to be parsed to string
 * @param formatOption format options in which the date string will be returned
 * @param fallbackString string to be returned if the date is invalid
 * @param showToday boolean to show 'Today' if the date is the current date
 */
export const getLocalDateTimeDisplayString = (
  date: Date | string,
  formatOption: Intl.DateTimeFormatOptions = {},
  fallbackString?: string,
  showToday?: boolean,
) => {
  const locale = isEnglish ? 'en-US' : 'pt-BR';

  let dateObj: Date;
  if (typeof date === 'string') {
    dateObj = new Date(date);
  } else {
    dateObj = date;
  }
  const dateString = dateObj.toLocaleDateString(locale, {
    ...formatOption,
  });

  if (fallbackString !== undefined && dateString === 'Invalid Date') {
    return fallbackString;
  }
  if (showToday && dateString !== 'Invalid Date') {
    const currentDate = new Date();
    if (
      dateObj.getDate() === currentDate.getDate() &&
      dateObj.getMonth() === currentDate.getMonth() &&
      dateObj.getFullYear() === currentDate.getFullYear()
    ) {
      return 'Today';
    }
  }

  return dateString;
};

/**
 * Convert ISO date string to local timezone
 *
 * @param isoDateString date string or date object in ISO format
 * @returns string with the date formatted in EST
 */
export const convertISOToLocal = (isoDateString: string | Date | undefined | null) => {
  if (!isoDateString) {
    return '';
  }
  const isoDate = new Date(isoDateString);

  const estOptions: Intl.DateTimeFormatOptions = {
    timeZoneName: 'short',
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };

  const estFormatter = new Intl.DateTimeFormat('en-US', estOptions);
  const estDateString = estFormatter.format(isoDate);

  return estDateString;
};

/**
 * Get the current fetch date
 * @returns string with the format of "As of 07:00 AM 01/01/21"
 */
export const getFetchDateString = (date?: Date) => {
  const currentDate = date ?? new Date();

  return i18next.t('common.asOf', {
    time: `${currentDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    })} ${currentDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
    })}`,
  });
};

/**
 * Formats a given date into the format 'MM/DD/YYYY - hh:mm AM/PM'.
 * @param {Date} isoDateString - The date object to be formatted.
 * @returns {string} - The formatted date string in the 'MM/DD/YYYY - hh:mm AM/PM' format.
 */
export const convertISOToDateTimeDisplayString = (isoDateString?: Date | string) => {
  if (!isoDateString) {
    fLogger.error('Failed to create a valid Date object from:', isoDateString);
    return 'N/A';
  }

  const date = new Date(isoDateString);

  if (isNaN(date.getTime())) {
    fLogger.error('Failed to create a valid Date object from:', isoDateString);
    return 'N/A';
  }

  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  return new Intl.DateTimeFormat('en-US', options).format(new Date(date)).replace(', ', ' - ');
};
