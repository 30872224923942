import clsx from 'clsx';
import { ReactNode } from 'react';
import { DefaultTextStyle } from './types';

type FontWeight = 'Regular' | 'Medium' | 'Bold';

interface Props {
  children: ReactNode | string;
  Component?: keyof JSX.IntrinsicElements;
  className?: string;
}

type TypographyText = {
  Regular: (props: Props) => JSX.Element;
  Medium: (props: Props) => JSX.Element;
  Bold: (props: Props) => JSX.Element;
};

const fontWeights: Record<FontWeight, string> = {
  Regular: '',
  Medium: 'font-medium',
  Bold: 'font-bold',
};

const generateStyles = (
  Tag: keyof JSX.IntrinsicElements,
  defaultClassName?: string,
): TypographyText => {
  return Object.keys(fontWeights).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: ({ children, Component, className }: Props) =>
        Component ? (
          <Component className={clsx(fontWeights[cur as FontWeight], defaultClassName, className)}>
            {children}
          </Component>
        ) : (
          <Tag className={clsx(fontWeights[cur as FontWeight], defaultClassName, className)}>
            {children}
          </Tag>
        ),
    }),
    {} as TypographyText,
  );
};

let WebHeading48: TypographyText;
let WebHeading38: TypographyText;
let WebHeading32: TypographyText;
let WebHeading28: TypographyText;
let WebHeading24: TypographyText;

let WebInputAmount60: TypographyText;
let WebInputAmount40: TypographyText;

let WebButton30: TypographyText;
let WebButton26: TypographyText;
let WebButton23: TypographyText;
let WebButton20: TypographyText;
let WebButton18: TypographyText;
let WebButton16: TypographyText;
let WebButton14: TypographyText;
let WebButton10: TypographyText;

let WebBodyText20: TypographyText;
let WebBodyText18: TypographyText;
let WebBodyText16: TypographyText;
let WebBodyText14: TypographyText;
let WebBodyText12: TypographyText;
let WebBodyText10: TypographyText;

let WebCaption12: TypographyText;
let WebCaption10: TypographyText;
let WebCaption9: TypographyText;

/**
 * Regenerates all the styles for on the fly theming.
 *
 * @param _ migrated from RN so so it needs to be fixed.
 */
export const regenerateAllStyles = (_?: DefaultTextStyle) => {
  WebHeading48 = generateStyles('h1', 'text-5xl');
  WebHeading38 = generateStyles('h2', 'text-38');
  WebHeading32 = generateStyles('h3', 'text-32');
  WebHeading28 = generateStyles('h4', 'text-28');
  WebHeading24 = generateStyles('h5', 'text-2xl');

  WebInputAmount60 = generateStyles('span', 'text-60');
  WebInputAmount40 = generateStyles('span', 'text-40');

  WebButton30 = generateStyles('span', 'text-3xl capitalize tracking-wide');
  WebButton26 = generateStyles('span', 'text-26 capitalize tracking-wide');
  WebButton23 = generateStyles('span', 'text-23 capitalize tracking-wide');
  WebButton20 = generateStyles('span', 'text-xl capitalize tracking-wide');
  WebButton18 = generateStyles('span', 'text-lg capitalize tracking-wide');
  WebButton16 = generateStyles('span', 'text-base capitalize tracking-wide');
  WebButton14 = generateStyles('span', 'text-sm capitalize tracking-wide');
  WebButton10 = generateStyles('span', 'text-10 capitalize tracking-wide');

  WebBodyText20 = generateStyles('p', 'text-xl');
  WebBodyText18 = generateStyles('p', 'text-lg');
  WebBodyText16 = generateStyles('p', 'text-base');
  WebBodyText14 = generateStyles('p', 'text-sm');
  WebBodyText12 = generateStyles('p', 'text-xs');
  WebBodyText10 = generateStyles('p', 'text-10');

  WebCaption12 = generateStyles('span', 'text-xs tracking-wider');
  WebCaption10 = generateStyles('span', 'text-10 tracking-wider');
  WebCaption9 = generateStyles('span', 'text-9 tracking-wider');
};

regenerateAllStyles();

export {
  WebHeading48,
  WebHeading38,
  WebHeading32,
  WebHeading28,
  WebHeading24,
  WebInputAmount60,
  WebInputAmount40,
  WebButton30,
  WebButton26,
  WebButton23,
  WebButton20,
  WebButton18,
  WebButton16,
  WebButton14,
  WebButton10,
  WebBodyText20,
  WebBodyText18,
  WebBodyText16,
  WebBodyText14,
  WebBodyText12,
  WebBodyText10,
  WebCaption12,
  WebCaption10,
  WebCaption9,
};
