import React from 'react';
import { Alert, AlertDescription, AlertTitle } from '@/components/common/Alert/Alert';
import { ExclamationBubbleOrange } from '@/assets/icons';
import Image from 'next/image';
import {
  WebBodyText14,
  WebBodyText18,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';

const displayStrings = {
  title: 'Required minimum distribution',
  description_one:
    'Your RMD is based off of the balance in your Traditional IRA at the end of the previous year and your age.',
  description_two:
    'You may receive tax penalties for not withdrawing your annual required minimum distribution. If you have any questions, it is recommended you seek guidance from a competent tax advisor.',
};

const RmdWarning: React.FC = () => {
  return (
    <Alert variant="warning">
      <Image src={ExclamationBubbleOrange} width={15} height={15} alt={displayStrings.title} />
      <AlertTitle>
        <WebBodyText18.Bold>{displayStrings.title}</WebBodyText18.Bold>
      </AlertTitle>
      <AlertDescription>
        <WebBodyText14.Regular>{displayStrings.description_one}</WebBodyText14.Regular>
        <WebBodyText14.Regular className="mt-4">
          {displayStrings.description_two}
        </WebBodyText14.Regular>
      </AlertDescription>
    </Alert>
  );
};

export default RmdWarning;
