/**
 * Helper when a user select NO for isSecuritiesProfessional.
 * Everything but isForPersonalUse needs to be set to false.
 */
export const FOR_PERSONAL_USE = {
  isForPersonalUse: true,
  receivesDataFromExternalSource: false,
  isRegisteredWithSecOrCftc: false,
  requiresSecOrCftcRegistration: false,
  investForOther: false,
  investForTheBenefitOfOther: false,
  isAdvisorOrConsultant: false,
  sharesProfit: false,
  isRegisteredWithEntity: false,
};
