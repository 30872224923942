import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TradeFormData, TradeSide } from '../schema';
import { getMinimumTradeAmount } from '../constants';

const displayStrings = {
  title: (min: number) => `Enter amount (greater than or equal to $${min}):`,
};

type TradeCurrencyProps = {
  side?: TradeSide;
};

export const TradeCurrency = ({ side }: TradeCurrencyProps) => {
  const { control } = useFormContext<TradeFormData>();
  const amount = useWatch({ control, name: 'amount' });

  const min = side ? getMinimumTradeAmount(side) : 10;

  return (
    <div>
      <WebBodyText14.Regular className="text-grey72">
        {displayStrings.title(min)}
      </WebBodyText14.Regular>
      <Controller
        control={control}
        name="amount"
        render={({ field: { value, onChange } }) => (
          <CurrencyInput
            className="w-full text-[60px] text-primary outline-none"
            prefix="$"
            value={value}
            defaultValue={amount}
            decimalsLimit={2}
            intlConfig={{ locale: 'en-US' }}
            onValueChange={(_value) => onChange(_value ?? '0')}
            allowNegativeValue={false}
          />
        )}
      />
    </div>
  );
};
