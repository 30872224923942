import { StateWithholdingClass, StateWithholdingRateType } from '@/hooks/api/useWithholdings';
import { FORM_ERROR_MESSAGES } from '@fintronners/react-api/src/utils/formUtils/schemas/constants';
import * as yup from 'yup';

export const withholdingInfoSchema = (
  minStatePercentValue: number,
  withholdingType: StateWithholdingClass,
  rateType: StateWithholdingRateType,
) => {
  return yup.object().shape({
    federalWithholding: yup.number().when('isWaivingFederalWithholding', {
      is: false,
      then: (schema) =>
        schema
          .typeError('Amount must be a number')
          .required(FORM_ERROR_MESSAGES.required)
          .min(1, FORM_ERROR_MESSAGES.minPercentAmount(1))
          .max(99, FORM_ERROR_MESSAGES.maxPercentAmount(100)),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
    stateWithholding: yup
      .number()
      .when(
        ['isWaivingStateWithholding', 'isWaivingFederalWithholding', 'federalWithholding'],
        ([isWaivingStateWithholding, isWaivingFederalWithholding, federalWithholding], schema) => {
          if (withholdingType !== StateWithholdingClass.MNBE && !isWaivingStateWithholding) {
            let minValue =
              rateType === StateWithholdingRateType.PCT_OF_FEDERAL
                ? isWaivingFederalWithholding
                  ? 0
                  : Math.ceil(minStatePercentValue * federalWithholding) / 100
                : minStatePercentValue;

            if (minValue === 0) minValue = 1;

            return schema
              .typeError('Amount must be a number')
              .required(FORM_ERROR_MESSAGES.required)
              .min(minValue, FORM_ERROR_MESSAGES.minPercentAmount(minValue))
              .max(99, FORM_ERROR_MESSAGES.maxPercentAmount(100));
          }

          return schema.notRequired().nullable();
        },
      ),
    isWaivingFederalWithholding: yup.boolean().default(false),
    isWaivingStateWithholding: yup.boolean().default(false),
  });
};

export type WithholdingInfoFormData = yup.InferType<ReturnType<typeof withholdingInfoSchema>>;
