import { fsElliotProBold, fsElliotProRegular, openSansExtraBold } from './fonts';
import { defaultTheme } from './defaultTheme';
import { defaultConfig } from './defaultConfig';

export const tenantConfigs = {
  default: {
    theme: defaultTheme,
    config: defaultConfig,
  },
  Alight: {
    theme: {
      colors: {
        background: '#ffffff',
        primary: '#266de2',
        primaryDark: '#0000ff',
      },
      fonts: {
        regular: fsElliotProRegular.style.fontFamily,
        bold: fsElliotProBold.style.fontFamily,
      },
      borderRadius: '40',
    },
    config: {
      images: {
        lightBrandLogoL: {
          logoRatio: 1,
          uri: ``,
        },
        darkBrandLogoL: {
          logoRatio: 1,
          uri: `/images/login-logo-alight.svg`,
        },
        colorBrandLogoL: {
          logoRatio: 1,
          uri: ``,
        },
        darkBrandLogoS: {
          logoRatio: 2,
          uri: `/images/logo-alight.svg`,
        },
        mobileLogo: {
          logoRatio: 1,
          uri: `/images/mobile-logo-alight.svg`,
        },
      },
    },
  },
  Tenant2: {
    theme: {
      colors: {
        primary: '#2196f3',
        primaryDark: '#718096',
        background: '#ffffff',
      },
      fonts: {
        bold: openSansExtraBold.className,
      },
      borderRadius: '20',
    },
    config: {
      images: {
        lightBrandLogoL: {
          logoRatio: 1,
          uri: `/images/login-logo-tenant2.svg`,
        },
        darkBrandLogoL: {
          logoRatio: 1,
          uri: `/images/login-logo-tenant2-dark.svg`,
        },
        colorBrandLogoL: {
          logoRatio: 1,
          uri: `/images/color-logo-tenant2.svg`,
        },
        darkBrandLogoS: {
          logoRatio: 2,
          uri: `/images/logo-tenant2.svg`,
        },
        mobileLogo: {
          logoRatio: 1,
          uri: `/images/mobile-logo-tenant2.svg`,
        },
      },
    },
  },
};
