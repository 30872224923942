import { useAccountValues } from '@/hooks/api/useAccountValues';
import { UserAccountAccountType } from '@fintronners/react-api';
import { WebCaption12 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';

type GainsAndLossesParams = { type: string };

export const GainsAndLosses = ({ type }: GainsAndLossesParams) => {
  let accountType;

  switch (type) {
    case 'trad-ira':
      accountType = UserAccountAccountType.AccountTypeIra;
      break;
    case 'roth-ira':
      accountType = UserAccountAccountType.AccountTypeRothIra;
      break;
    default:
      accountType = UserAccountAccountType.AccountTypeBrokerage;
  }

  const { gainsLosses, returnRate, formattedGainLoss, formattedReturnRate } =
    useAccountValues(accountType);

  return (
    <div className="flex items-center gap-2">
      <WebCaption12.Bold className={gainsLosses >= 0 ? 'text-green' : 'text-red'}>
        {formattedGainLoss}
      </WebCaption12.Bold>
      <WebCaption12.Bold className={returnRate >= 0 ? 'text-green' : 'text-red'}>
        ({formattedReturnRate})
      </WebCaption12.Bold>
    </div>
  );
};
