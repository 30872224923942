import React from 'react';
import { WebBodyText16, WebCaption10 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import cn from '@/utils/tailwind';

interface InvestmentValuesListItemProps {
  label: string;
  value: string;
  isPending?: boolean;
}

export const InvestmentValuesListItem = ({
  label,
  value,
  isPending,
}: InvestmentValuesListItemProps) => {
  return (
    <div
      className={cn(
        'rounded p-2 flex flex-col justify-center h-[50px]',
        isPending ? 'bg-primary/20' : 'bg-grey6',
      )}
    >
      <WebCaption10.Regular className={isPending ? 'text-primary' : 'text-grey55'}>
        {`${label}${isPending ? '*' : ''}`}
      </WebCaption10.Regular>
      <WebBodyText16.Regular className={isPending ? 'text-primary' : 'text-darkestBlue'}>
        {value}
      </WebBodyText16.Regular>
    </div>
  );
};
