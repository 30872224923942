import React, { CSSProperties, ReactNode } from 'react';

// FinTron
import { InputFieldLabel35 } from '@fintronners/react-ui/src/GlobalStyling';

const LabelText: React.FC<{
  htmlFor?: string | undefined;
  style?: CSSProperties;
  className?: string;
  children: ReactNode;
}> = (props) => {
  return (
    <InputFieldLabel35.Regular>
      <label htmlFor={props.htmlFor} style={props.style} className={props.className}>
        {props.children}
      </label>
    </InputFieldLabel35.Regular>
  );
};

export default LabelText;
