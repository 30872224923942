import React, { useMemo, forwardRef } from 'react';
import { filterCountries } from './utils';

import {
  PhoneInput,
  type PhoneInputProps,
  type PhoneInputRefType,
} from 'react-international-phone';

import { clsx, type ClassValue } from 'clsx';

type InputPhoneProps = PhoneInputProps & {
  className?: ClassValue;
  allowedCountries?: Array<string>;
};

const InputPhone = forwardRef<PhoneInputRefType, InputPhoneProps>(
  ({ className, inputProps, countrySelectorStyleProps, allowedCountries, ...props }, ref) => {
    const displayCountries = useMemo(() => filterCountries(allowedCountries), [allowedCountries]);

    return (
      <PhoneInput
        countries={displayCountries}
        className={clsx('fintron-input-phone w-full', className)}
        inputProps={{
          ...inputProps,
          className: clsx(
            'rounded border text-sm text-darkestBlue focus-visible:outline-primary disabled:cursor-not-allowed disabled:bg-grey7',
            'w-full px-[14px] py-[6px] pl-[52px]',
            inputProps?.className,
          ),
        }}
        countrySelectorStyleProps={{
          ...countrySelectorStyleProps,
          buttonClassName: clsx(
            'absolute left-[10px] !border-none !bg-transparent !py-[18px] !pl-[10px]',
            countrySelectorStyleProps?.className,
          ),
        }}
        {...props}
        ref={ref}
      />
    );
  },
);

export default InputPhone;
