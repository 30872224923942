import React, { useState } from 'react';
import { useSidePanel } from '@/context/SidePanelContext';
import { InvestorProfileSummary } from './InvestorProfileSummary';
import { InvestingQuestionsForm } from './parts/InvestimentQuestionsForm/InvestingQuestionsForm';
import { PersonalQuestionsForm } from './parts/PersonalQuestionsForm/PersonalQuestionsForm';
import { ControlPersonForm } from './parts/ControlPersonForm/ControlPersonForm';
import { PoliticallyExposedForm } from './parts/PoliticallyExposedForm/PoliticallyExposedForm';
import { FinraStockExchangeMemberForm } from './parts/FinraStockExchangeMemberForm/FinraStockExchangeMemberForm';
import { SecuritiesProfessionalForm } from './parts/SecuritiesProfessionalForm/SecuritiesProfessionalForm';

export enum InvestorFormKey {
  INVESTING = 'Investing',
  PERSONAL = 'Personal',
  CONTROL_PERSON = 'Control person',
  POLITICALLY_EXPOSED = 'Politically exposed',
  FINRA_STOCK_EXCHANGE_MEMBER = 'FINRA/Stock exchange member',
  SECURITIES_PROFESSIONAL = 'Securities professional',
}

const InvestorProfileSidePanelForms = {
  [InvestorFormKey.INVESTING]: <InvestingQuestionsForm />,
  [InvestorFormKey.PERSONAL]: <PersonalQuestionsForm />,
  [InvestorFormKey.CONTROL_PERSON]: <ControlPersonForm />,
  [InvestorFormKey.POLITICALLY_EXPOSED]: <PoliticallyExposedForm />,
  [InvestorFormKey.FINRA_STOCK_EXCHANGE_MEMBER]: <FinraStockExchangeMemberForm />,
  [InvestorFormKey.SECURITIES_PROFESSIONAL]: <SecuritiesProfessionalForm />,
};

export const InvestorProfileSidePanel = () => {
  const { currentFlowStep, goTo } = useSidePanel();
  const [currentlyEditing, setCurrentlyEditing] = useState<InvestorFormKey>(
    InvestorFormKey.INVESTING,
  );

  const onEdit = (formToEdit: InvestorFormKey) => {
    setCurrentlyEditing(formToEdit);
    goTo(1);
  };

  return (
    <>
      {currentFlowStep === 0 && (
        <InvestorProfileSummary onEdit={(formToEdit) => onEdit(formToEdit)} />
      )}
      {(currentFlowStep === 1 && InvestorProfileSidePanelForms[currentlyEditing]) ?? null}
    </>
  );
};
