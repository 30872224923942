module.exports = {
  content: ['./**/*.{js,jsx,ts,tsx}'],
  mode: 'jit',
  theme: {
    extend: {
      fontSize: {
        9: '0.5625rem',
        10: '0.625rem',
        sm: '0.875rem',
        base: '1rem',
        lg: '1.125rem',
        xl: '1.25rem',
        23: '1.4375rem',
        '2xl': '1.5rem',
        26: '1.625rem',
        '3xl': '1.875rem',
        28: '1.75rem',
        32: '2rem',
        38: '2.375rem',
        40: '2.5rem',
        '5xl': '3rem',
        60: '3.75rem',
      },
      colors: {
        grey84: '#282828',
        grey72: '#474747',
        grey55: '#737373',
        grey41: '#969696',
        grey25: '#BFBFBF',
        grey6: '#F0F0F0',
        grey7: '#F7F7F7',
        blue: '#26A8DF',
        blue5: '#F4FBFD',
        blue10: '#E9F6FC',
        blue20: '#D4EDF9',
        blue40: '#A8DCF2',
        blue60: '#7DCAEC',
        blue80: '#51B9E5',
        primaryDarkBlue: '#266DE2',
        darkestBlue: '#0C243F',
        darkestBlue10: '#E7E9EC',
        darkestBlue60: '#6D7C8C',
        darkestBlue80: '#3C5065',
        darkerBlue: '##25386F',
        darkBlue: '#3E4B9E',
        darkBlue10: '#EAF1F8',
        darkBlue60: '#8B93C5',
        darkBlue70: '#6FA0D1',
        darkBlue80: '#656FB1',
        middleDarkBlue: '#3278BE',
        linkBlue: 'rgb(28, 126, 171)',
        grayishLightBlue: '#e6e9eb',
        red: '#B01700',
        red10: '#F7E8E5',
        green: '#009E00',
        green10: '#E5F5E5',
        orange: '#FF9D0B',
        orange15: '#FFF0DA',
        gold: '#FCCD12',
        bronze: '#CE7F33',
        primary: 'rgb(from var(--primary-color) r g b / <alpha-value>)',
        primaryDark: 'rgb(from var(--primary-dark-color) r g b / <alpha-value>)',
        background: 'rgb(from var(--background-color) r g b / <alpha-value>)',
        purple: '#8766AC',
      },
      boxShadow: {
        26: '0px 1px 2px 0px #00000026',
        33: '0px 0.5px 1px 0px #00000033',
        40: '0px 0.7px 1.4px 0px #00000040',
      },
      borderRadius: {
        f0: '5px',
        f1: '10px',
        custom: 'var(--border-radius)',
      },
      fontFamily: {
        normal: ['var(--font-regular)', 'sans-serif'],
        medium: ['var(--font-medium)', 'sans-serif'],
        bold: ['var(--font-bold)', 'sans-serif'],
      },
      keyframes: {
        slideDown: {
          from: { height: '0px' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        slideUp: {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0px' },
        },
      },
      animation: {
        slideDown: 'slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1)',
        slideUp: 'slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1)',
      },
    },
  },
  plugins: [],
};
