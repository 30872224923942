import React, { MouseEventHandler, useMemo } from 'react';

export interface PopupProps {
  onCloseButtonClick?: MouseEventHandler;
}

interface PopupBaseProps extends PopupProps {
  children: React.ReactNode;
}

const PopupBase: React.FC<PopupBaseProps> = (props) => {
  const baseClasses = ['w-full', 'max-w-full', 'max-h-full', 'bg-white', 'shadow-md', 'z-[1000]'];

  const mobileClasses = [
    'max-lg:p-6',
    'max-lg:fixed',
    'max-lg:right-0',
    'max-lg:bottom-0',
    'max-lg:left-0',
    'max-lg:rounded-t-2xl',
  ];

  const desktopClasses = [
    'lg:w-[650px]',
    'lg:fixed',
    'lg:top-[50%]',
    'lg:left-[50%]',
    'lg:p-8',
    'lg:transform',
    'lg:transform-origin-center',
    'lg:translate-x-[-50%]',
    'lg:translate-y-[-50%]',
  ];

  const id: string = useMemo(() => Math.floor(Date.now() / 1000).toString(), []);

  return (
    <React.Fragment>
      <div className="bg-black bg-opacity-50 fixed top-0 right-0 bottom-0 left-0 z-[900]" />
      <div
        id={`popup-id-${id}`}
        className={`${baseClasses.join(' ')} ${mobileClasses.join(' ')} ${desktopClasses.join(' ')}`}
      >
        {props.children}
      </div>
    </React.Fragment>
  );
};

export default PopupBase;
