declare module '@fintronners/react-api/src';

import BaseApi from './BaseApi';
import GRPCApi from './GRPCApi';
import GraphQlClient from './GraphQlClient';

export * from './graphql/queries';
export * from './graphql/admin';
export * from './graphql/types/graphql';
export * from './graphql/rest';
export * from './displayStrings';
export * from './tsoai';

export { BaseApi, GRPCApi, GraphQlClient };
