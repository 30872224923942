import { WebBodyText18, WebHeading28 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import React from 'react';
import Image from 'next/image';
import { InfoPrimaryIcon } from '@/assets/icons';
import { Button } from '@/components/common/Button';
import { SheetContent, SheetFooter, SheetTitle } from '@/components/common/Sheet';
import { useSidePanel } from '@/context/SidePanelContext';

const displayStrings = {
  title: 'Error',
  body: 'There was a problem processing your request, please try again later. If this error persists, you can try to un-link and re-link your Bank account from the Bank link Settings page.',
  close: 'Close',
};

interface AddACHAccountSidePanelErrorProps {
  onClose?: () => void;
}

export const AddACHAccountSidePanelError = ({ onClose }: AddACHAccountSidePanelErrorProps) => {
  const { closeSidePanel } = useSidePanel();

  return (
    <SheetContent>
      <SheetTitle className="sr-only">{displayStrings.title}</SheetTitle>
      <div className="flex flex-col items-center gap-7 text-center mt-40">
        <Image alt="Success icon" width={50} height={50} src={InfoPrimaryIcon.src} />
        <WebHeading28.Bold className="text-darkestBlue">{displayStrings.title}</WebHeading28.Bold>
        <WebBodyText18.Regular className="text-grey72">{displayStrings.body}</WebBodyText18.Regular>
      </div>
      <SheetFooter className="text-center mb-5">
        <Button
          className="w-full"
          onClick={() => {
            onClose && onClose();
            closeSidePanel();
          }}
        >
          {displayStrings.close}
        </Button>
      </SheetFooter>
    </SheetContent>
  );
};
