import { getSimpleEnumKeysAsArray } from '@fintronners/react-utils/src';
import {
  CommonUserInvestorInformationNonDiscretionaryRequestParametersDetailsFragment,
  V1UserInvestmentExperience,
  V1UserInvestmentObjective,
  V1UserLiquidityNeeds,
  V1UserTimeHorizon,
} from '@fintronners/react-api';

interface formOptionsType {
  title: string;
  dialogTitle?: string;
  dialogContent?: string;
  items: any;
  name: keyof CommonUserInvestorInformationNonDiscretionaryRequestParametersDetailsFragment;
}

export const displayStrings = {
  title: 'Investing questions',
  experienceLevelQuestion: 'What is your investing level of experience?',
  experienceLevelAnswers: {
    none: 'None',
    limited: 'Limited',
    good: 'Good',
    extensive: 'Extensive',
  },
  investmentObjectiveQuestion: 'What is your investment objective?',
  investmentObjectiveAnswers: {
    growth: 'Growth',
    income: 'Income',
    speculation: 'Speculation',
    capitalpreservation: 'Capital Preservation',
    other: 'Other',
  },
  investmentObjectiveInfoTitle: 'Investment Objectives',
  investmentObjectiveInfoContent:
    'Preservation: This objective focuses on the safe keeping of your money. Investments should be tailored to stocks with the least amount of risk as possible.\n\n' +
    'Income: This objective focuses on investing in securities that generate higher than average dividends and long term capital appreciation. (Dividends are quarterly payouts of profits to shareholders.)\n\n' +
    'Growth: This objective focuses on investing in securities that experience short-term capital appreciation (stock price goes up in a short amount of time!). This investment objective is inherently riskier than the preservation and income objectives. \n\n' +
    'Speculation: This objective refers to the act of buying a security that has substantial risk of losing value but also carries the expectation of increasing in value significantly. This is by far the most risky objective. \n',
  timeHorizonQuestion: 'What is your investment time horizon?',
  timeHorizonAnswers: {
    short: 'Short',
    average: 'Average',
    longest: 'Longest',
  },
  timeHorizonInfoTitle: 'Your Investing Time Horizon',
  timeHorizonInfoDescription:
    'Short: A short term investment time horizon implies you plan on buying and selling securities (financial asset, etc) frequently for a quick return on your investment (less than a year). \n\n' +
    'This could have tax implication. Short term gains are taxed at a higher rate. We recommend meeting with a tax professional to learn more. \n\n' +
    'Medium: A medium term investment time horizon means you have some securities in your portfolio that you plan on holding for longer than 1 year . However, you may also have investment that you intend on flipping for short term gains. \n\n' +
    'Long: A long term investment time horizon implies you are investing for the future and not immediate income needs. Long term capital gains are taxed at a much lower rate!',
  liquidityNeedsQuestion: 'How important is having cash on hand?',
  liquidityNeedsAnswers: {
    veryimportant: 'Very Important',
    somewhatimportant: 'Somewhat Important',
    notimportant: 'Not Important',
  },
  liquidityNeedsInfoTitle: 'Liquidity Needs',
  liquidityNeedsInfoDescription:
    'The ability to quickly and easily convert all or a portion of the account assets into cash without experiencing significant loss.',
  save: 'Save',
  back: 'Back',
};

export const formOptions: formOptionsType[] = [
  {
    title: displayStrings.experienceLevelQuestion,
    items: getSimpleEnumKeysAsArray(
      V1UserInvestmentExperience,
      displayStrings.experienceLevelAnswers as Record<string, string>,
    ),
    name: 'investmentExperience',
  },
  {
    title: displayStrings.investmentObjectiveQuestion,
    items: getSimpleEnumKeysAsArray(
      V1UserInvestmentObjective,
      displayStrings.investmentObjectiveAnswers as Record<string, string>,
    ),
    name: 'investmentObjective',
    dialogTitle: displayStrings.investmentObjectiveInfoTitle,
    dialogContent: displayStrings.investmentObjectiveInfoContent,
  },
  {
    title: displayStrings.timeHorizonQuestion,
    items: getSimpleEnumKeysAsArray(
      V1UserTimeHorizon,
      displayStrings.timeHorizonAnswers as Record<string, string>,
    ),
    name: 'timeHorizon',
    dialogTitle: displayStrings.timeHorizonInfoTitle,
    dialogContent: displayStrings.timeHorizonInfoDescription,
  },
  {
    title: displayStrings.liquidityNeedsQuestion,
    items: getSimpleEnumKeysAsArray(
      V1UserLiquidityNeeds,
      displayStrings.liquidityNeedsAnswers as Record<string, string>,
    ),
    name: 'liquidityNeeds',
    dialogTitle: displayStrings.liquidityNeedsInfoTitle,
    dialogContent: displayStrings.liquidityNeedsInfoDescription,
  },
];
