import {
  getOneOfEnumSchema,
  getStringRequiredSchema,
} from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';
import { YES_OR_NO } from '@fintronners/react-api/src/utils/formUtils/schemas/types';
import * as yup from 'yup';

export const finraStockExchangeMemberFormSchema = yup.object({
  isAffiliatedExchangeOrFINRA: getOneOfEnumSchema(YES_OR_NO).required(),
  firmName: getStringRequiredSchema(),
  letter: yup.mixed().required('A file is required'),
});

export type FinraStockExchangeMemberFormData = yup.InferType<
  typeof finraStockExchangeMemberFormSchema
>;
