import { WebBodyText14, WebCaption12 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import React, { HTMLAttributes, InputHTMLAttributes, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import { Input } from '~components/common/Input/Input';
import cn from '@/utils/tailwind';

type FormTextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  fieldError?: FieldError;
  isPending?: boolean;
  placeholder?: string;
  rightComponent?: React.ReactNode;
  containerClassName?: HTMLAttributes<HTMLDivElement>['className'];
};

const FormTextField = forwardRef<HTMLInputElement, FormTextFieldProps>(
  (
    {
      label = '',
      placeholder,
      type = 'text',
      fieldError,
      rightComponent,
      isPending,
      color,
      containerClassName,
      ...props
    },
    ref,
  ) => {
    return (
      <div>
        <div className={cn('flex flex-col flex-nowrap', containerClassName)}>
          {label && (
            <WebBodyText14.Medium
              className={cn('block mb-1', isPending ? 'text-primary' : 'text-grey72')}
            >
              {`${label}${isPending ? ' *' : ''}`}
            </WebBodyText14.Medium>
          )}
          <div className="flex items-center gap-5">
            <Input
              {...props}
              color={color}
              id={props.name}
              ref={ref}
              placeholder={placeholder}
              type={type}
              className={cn(
                isPending && 'bg-primary/20 text-primary',
                fieldError?.message && 'border-red',
                props.className,
              )}
              aria-errormessage={fieldError?.message}
            />
            {rightComponent}
          </div>
        </div>
        {fieldError && (
          <div className="mt-1 ml-1">
            <WebCaption12.Regular className="text-red">{fieldError.message}</WebCaption12.Regular>
          </div>
        )}
      </div>
    );
  },
);

export default FormTextField;
