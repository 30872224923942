import { getCurrentTenant } from '@/config';
import { useAppStore } from '@/stores/app-store';

const displayStrings = {
  Alight: {
    title: 'Alight',
    selfDirected: 'Alight Self-Managed Traditional IRA',
    retirement: 'Alight Self-Managed Traditional IRA',
    account: 'Alight Self-Managed Traditional IRA',
    bankLink: 'Alight Self-Managed Account',
    accounts: {
      'self-directed': 'Alight Self-Managed Traditional IRA',
      'trad-ira': 'Alight Self-Managed Traditional IRA',
      'roth-ira': 'Alight Self-Managed Roth IRA',
    },
    brokerDealer: {
      name: 'Alight Financial Solutions',
    },
    clearingFirm: {
      name: 'Apex Clearing Corporation',
    },
  },
  Fintron: {
    title: 'FinTron',
    selfDirected: 'Self-directed',
    retirement: 'Retirement self-directed',
    bankLink: 'Fintron',
    accounts: {
      'self-directed': 'Self-directed account',
      'trad-ira': 'Traditional IRA self-directed',
      'roth-ira': 'Roth IRA self-directed',
    },
    brokerDealer: {
      name: 'FinTron Invest',
    },
    clearingFirm: {
      name: 'Apex Clearing Corporation',
    },
  },
};

export const useTenantBasedStrings = () => {
  const newApiUrl = useAppStore((state) => state.newApiUrl);
  const currentTenant = getCurrentTenant(newApiUrl) as keyof typeof displayStrings;

  if (currentTenant) {
    return displayStrings[currentTenant];
  }

  return displayStrings.Fintron;
};
