export const splitCamelCase = (text: string) => {
  return text ? text.replace(/(?<=[a-z])(?=[A-Z])/g, ' ') : text;
};

export const firstLetterToLower = (str: string) => {
  return str ? str.charAt(0).toLowerCase() + str.slice(1) : str;
};

export const removeNonNumeric = (str: string) => {
  return str.replace(/\D/g, '');
};

export const formatSSN = (ssn: string): string => {
  const numbers = removeNonNumeric(ssn);
  if (numbers.length !== 9) return ssn;
  return `${numbers.slice(0, 3)}-${numbers.slice(3, 5)}-${numbers.slice(5)}`;
};
