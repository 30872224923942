import { SecurityMutualFundDetails } from '@fintronners/react-api/src';
import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { thousandFormatNumbroCurrency } from '@fintronners/react-utils/src/numberUtilsTSX';

type MutualFundsDetailsWidgetProps = {
  data: SecurityMutualFundDetails;
};

const displayStrings = {
  grossExpRatio: 'Exp ratio (gross):',
  netExpRatio: 'Exp ratio (net):',
  distributionFee: 'Distribution/service fee (12b-1):',
  initialInvestment: 'Minimum initial investment:',
  subsequentInvestment: 'Minimum subsequent investment:',
  transactionFee: 'Transaction fee:',
  loadType: 'Load type:',
  load: 'Load:',
  noFee: 'No Fee',
};

const MutualFundDetailsWidget = ({ data }: MutualFundsDetailsWidgetProps) => {
  return (
    <div className="flex flex-col gap-1 bg-grey7 p-2.5 rounded-lg">
      <div className="flex justify-between">
        <WebBodyText14.Regular className="text-grey72">
          {displayStrings.grossExpRatio}
        </WebBodyText14.Regular>
        <WebBodyText14.Bold className="text-darkestBlue">
          {`${data?.expenseRatioGross}%`}
        </WebBodyText14.Bold>
      </div>
      <div className="flex justify-between">
        <WebBodyText14.Regular className="text-grey72">
          {displayStrings.netExpRatio}
        </WebBodyText14.Regular>
        <WebBodyText14.Bold className="text-darkestBlue">
          {`${data?.expenseRatioNet}%`}
        </WebBodyText14.Bold>
      </div>
      <div className="flex justify-between">
        <WebBodyText14.Regular className="text-grey72">
          {displayStrings.distributionFee}
        </WebBodyText14.Regular>
        <WebBodyText14.Bold className="text-darkestBlue">
          {`${data?.distributionServiceFee}%`}
        </WebBodyText14.Bold>
      </div>
      <div className="flex justify-between">
        <WebBodyText14.Regular className="text-grey72">
          {displayStrings.initialInvestment}
        </WebBodyText14.Regular>
        <WebBodyText14.Bold className="text-darkestBlue">
          {thousandFormatNumbroCurrency(data?.initialInvestmentMinimum)}
        </WebBodyText14.Bold>
      </div>
      <div className="flex justify-between">
        <WebBodyText14.Regular className="text-grey72">
          {displayStrings.subsequentInvestment}
        </WebBodyText14.Regular>
        <WebBodyText14.Bold className="text-darkestBlue">
          {thousandFormatNumbroCurrency(data?.additionalInvestmentMinimum)}
        </WebBodyText14.Bold>
      </div>
      <div className="flex justify-between">
        <WebBodyText14.Regular className="text-grey72">
          {displayStrings.transactionFee}
        </WebBodyText14.Regular>
        <WebBodyText14.Bold className="text-darkestBlue">
          {data?.transactionFee > 0
            ? thousandFormatNumbroCurrency(data?.transactionFee)
            : displayStrings.noFee}
        </WebBodyText14.Bold>
      </div>
      <div className="flex justify-between">
        <WebBodyText14.Regular className="text-grey72">
          {displayStrings.loadType}
        </WebBodyText14.Regular>
        <WebBodyText14.Bold className="text-darkestBlue">{data?.loadType}</WebBodyText14.Bold>
      </div>
      <div className="flex justify-between">
        <WebBodyText14.Regular className="text-grey72">{displayStrings.load}</WebBodyText14.Regular>
        <WebBodyText14.Bold className="text-darkestBlue">{`${data?.loadPct}%`}</WebBodyText14.Bold>
      </div>
    </div>
  );
};

export default MutualFundDetailsWidget;
