/**
 * Constants for the application
 *
 *
 * @file defines the constants for the NextJS application
 *
 */
const ACCOUNTS = '/accounts';

export const PATHS = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  REDIRECT: '/redirect',
  REAUTH: '/reauth',
  AFTER_LOGIN_SUCCESS: ACCOUNTS,
  HOME: ACCOUNTS,
} as const;
