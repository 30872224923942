import OptionSet from '@/components/common/OptionSet/OptionSet';
import { SkeletonTable } from '@/components/common/SkeletonTable';
import cn from '@/utils/tailwind';
import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import type { RadioGroupProps } from '@radix-ui/react-radio-group';
import type { ClassValue } from 'clsx';
import { PlaidAccount } from 'react-plaid-link';

type AccountSelectorPanelProps = {
  className: ClassValue;
  accounts: PlaidAccount[] | undefined | null;
  onValueChange?: RadioGroupProps['onValueChange'];
  loading?: boolean;
  isSaving?: boolean;
  disabled?: boolean;
};

const AccountSelectorPanel = ({
  className,
  accounts,
  onValueChange,
  loading = false,
  isSaving = false,
  disabled = false,
  ...props
}: AccountSelectorPanelProps) => {
  const disableAction = disabled || loading || isSaving || !accounts?.length;

  return (
    <div className={cn('flex max-w-md flex-col justify-between', className)} {...props}>
      {loading && (
        <SkeletonTable rows={3} columns={1} cellHeight={53} rowStyle={{ paddingBottom: 20 }} />
      )}

      {!loading && !!accounts?.length && (
        <OptionSet
          defaultValue={accounts[0].id}
          onValueChange={onValueChange}
          disabled={disableAction}
        >
          {accounts.map((acc) => (
            <OptionSet.Field key={acc.id}>
              <OptionSet.Label htmlFor={acc.id}>
                <WebBodyText14.Regular>{`${acc.name} ${acc.subtype.toUpperCase()} ********${acc.mask}`}</WebBodyText14.Regular>
              </OptionSet.Label>

              <OptionSet.Item value={acc.id} id={acc.id}>
                <OptionSet.Indicator className={cn({ 'after:bg-grey41': isSaving })} />
              </OptionSet.Item>
            </OptionSet.Field>
          ))}
        </OptionSet>
      )}

      {!loading && !accounts?.length && (
        <WebBodyText14.Regular className="text-grey72">No accounts to show.</WebBodyText14.Regular>
      )}
    </div>
  );
};

export default AccountSelectorPanel;
