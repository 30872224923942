import {
  GET_ALL_USER_ACCOUNTS_STATUS,
  GetAllUserAccountsStatusFragmentFragment,
  GetAllUserAccountsStatusQuery,
  UserAccountAccountStatus,
  UserAccountAccountType,
} from '@fintronners/react-api';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  AllNavRoutes,
  NavRoutes,
} from '@fintronners/react-experience/src/Constants/NavigationConstant';
import {
  FinTronNavProp,
  FinTronParamListBase,
} from '@fintronners/react-experience/src/Navigation/NavigationTypes';
import i18next from '@fintronners/react-language/src/Lang/lang';

export const ROUTES_WITHOUT_ACCOUNT_CHECK: AllNavRoutes[] = [NavRoutes.Search];

export enum ActionableAccountStatus {
  Loading = 'Loading',
  Active = 'Active',
  Pending = 'Pending',
  CanOpen = 'CanOpen',
  Closed = 'Closed',
}

export type UserAccountType = GetAllUserAccountsStatusFragmentFragment & {
  firstPortfolioId: string;
};

/**
 * Helper function to get the actionable status of the account such as
 * whether an account can be opened or not.
 */
const getActionableAccountStatus = (status?: UserAccountAccountStatus): ActionableAccountStatus => {
  if (!status) return ActionableAccountStatus.CanOpen;
  switch (status) {
    case UserAccountAccountStatus.AccountStatusActive:
      return ActionableAccountStatus.Active;
    case UserAccountAccountStatus.AccountStatusPending:
    case UserAccountAccountStatus.AccountStatusNew:
    case UserAccountAccountStatus.AccountStatusSubmitted:
    case UserAccountAccountStatus.AccountStatusContacted:
    case UserAccountAccountStatus.AccountStatusRejected:
    case UserAccountAccountStatus.AccountStatusSuspended:
    case UserAccountAccountStatus.AccountStatusAppealed:
    case UserAccountAccountStatus.AccountStatusError:
    case UserAccountAccountStatus.AccountStatusDenied:
      return ActionableAccountStatus.Pending;
    case UserAccountAccountStatus.AccountStatusClosed:
    case UserAccountAccountStatus.AccountStatusCancelled:
      return ActionableAccountStatus.Closed;
  }
};

/**
 * Helper function to get the notice to be displayed based on the actionable status.
 */
export const getAccountStatusNotice = (actionableStatus: ActionableAccountStatus) => {
  switch (actionableStatus) {
    case ActionableAccountStatus.Active:
      return undefined;
    case ActionableAccountStatus.Pending:
      return {
        title: i18next.t('accounts.accountPendingTitle'),
        description: i18next.t('accounts.accountPendingDescription'),
      };
    case ActionableAccountStatus.CanOpen:
      return {
        title: i18next.t('accounts.accountCanOpenTitle'),
        description: i18next.t('accounts.accountCanOpenDescription'),
        buttonText: i18next.t('accounts.accountCanOpenButton'),
      };
    case ActionableAccountStatus.Closed:
      return {
        title: i18next.t('accounts.accountClosedTitle'),
        description: i18next.t('accounts.accountClosedDescription'),
      };
    case ActionableAccountStatus.Loading:
      return undefined;
  }
};

/**
 * Hook to get the user account status as well as other details such as portfolio ids. This hook
 * should be used to get very basic information about the user account such as status or ids used
 * in other queries.
 */
const useUserAccount = (accountType: UserAccountAccountType) => {
  const [userAccount, setUserAccount] = useState<UserAccountType | null | undefined>();
  const [actionableStatus, setActionableStatus] = useState<ActionableAccountStatus>(
    ActionableAccountStatus.Loading,
  );

  const { data, loading, refetch } = useQuery<GetAllUserAccountsStatusQuery>(
    GET_ALL_USER_ACCOUNTS_STATUS,
    {
      fetchPolicy: 'cache-first',
    },
  );

  /**
   * Navigates to the given route if the account status is active.
   * If the account status is not active, it blocks navigation and
   * shows a BSM.
   */
  const navigateWithAccountStatusCheck = async <NavType extends AllNavRoutes>(
    route: NavType,
    navigation: FinTronNavProp | undefined,
    params?: FinTronParamListBase[NavType],
  ) => {
    const response = await refetch();
    const { actionableStatus: _actionableStatus } = getAndSetStatus(response.data);
    if (
      _actionableStatus === ActionableAccountStatus.Active ||
      ROUTES_WITHOUT_ACCOUNT_CHECK.includes(route)
    ) {
      // Ignoring the lint errors here because the type defintion of
      // of the function does not match the library definition.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigation?.navigate(route, params);
    }
  };

  /**
   * parses the actionable status from the data and sets the state.
   */
  const getAndSetStatus = (_data: GetAllUserAccountsStatusQuery) => {
    const _userAccount = _data?.userAccounts?.edges?.find(
      (edge) => edge?.node?.type === accountType,
    )?.node;
    if (_userAccount) {
      const firstPortfolioId = _userAccount?.portfolios?.edges?.[0]?.node?.id ?? '';
      const beneficiariesPrimary = _userAccount.beneficiariesPrimary;
      const beneficiariesContingent = _userAccount.beneficiariesContingent;
      setUserAccount({
        ..._userAccount,
        firstPortfolioId,
        beneficiariesPrimary,
        beneficiariesContingent,
      });
    } else {
      setUserAccount(_userAccount);
    }
    const status = _userAccount?.status;
    const _actionableStatus = getActionableAccountStatus(status);
    setActionableStatus(_actionableStatus);
    return {
      status,
      actionableStatus: _actionableStatus,
    };
  };

  useEffect(() => {
    if (!data || loading) return;
    getAndSetStatus(data);
  }, [data, loading, accountType]);

  return {
    userAccount,
    actionableStatus,
    navigateWithAccountStatusCheck,
    refetch,
    loading,
  };
};

export default useUserAccount;
