import { gql } from '@apollo/client';

export const GET_USER_ALLOCATIONS_BY_ROBO_ID = gql`
  query GetUserAllocationsByRoboID($roboID: ID!) {
    roboGoals(where: { id: $roboID }) {
      edges {
        node {
          parameters {
            riskProfile
          }
          allocation {
            fiatPct
            stockPct
            bondPct
            totalValue
            drift
            allocationBySector {
              sector {
                id
                name
                category
                assets {
                  targetPct
                  asset {
                    id
                    symbol
                  }
                }
              }
              percentage
            }
          }
          targetPortfolio {
            bondPct
            fiatPct
            stockPct
            externalReferenceID
            id
            sectors {
              id
              targetPct
              sector {
                id
                name
                description
                category
                assets {
                  targetPct
                  asset {
                    id
                    symbol
                    name
                    ... on CryptoAsset {
                      imageLogo
                    }
                    ... on FiatAsset {
                      imageLogo
                    }
                    ... on SecurityAsset {
                      imageLogo
                    }
                  }
                }
              }
            }
          }
          accountPortfolio {
            totalValue
            id
            assetBalances {
              edges {
                node {
                  balances {
                    activeBalance
                    value
                    total
                  }
                  costBasis
                  asset {
                    id
                    name
                    symbol
                    ... on SecurityAsset {
                      market {
                        last
                        previousDayClose
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    roboGeneralInvestments(where: { id: $roboID }) {
      edges {
        node {
          allocation {
            fiatPct
            stockPct
            bondPct
            totalValue
            drift
            allocationBySector {
              sector {
                id
                name
                category
                assets {
                  targetPct
                  asset {
                    id
                    symbol
                  }
                }
              }
              percentage
            }
          }
          targetPortfolio {
            bondPct
            fiatPct
            stockPct
            externalReferenceID
            id
            sectors {
              id
              targetPct
              sector {
                id
                name
                description
                category
                assets {
                  targetPct
                  asset {
                    id
                    symbol
                    name
                    ... on CryptoAsset {
                      imageLogo
                    }
                    ... on FiatAsset {
                      imageLogo
                    }
                    ... on SecurityAsset {
                      imageLogo
                    }
                  }
                }
              }
            }
          }
          accountPortfolio {
            totalValue
            id
            assetBalances {
              edges {
                node {
                  balances {
                    activeBalance
                    value
                    total
                  }
                  costBasis
                  asset {
                    id
                    name
                    symbol
                    ... on SecurityAsset {
                      market {
                        last
                        previousDayClose
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_STOCKS_HOLDINGS_QUERY = gql`
  query GetUserStocksHoldings($portfolioID: ID!) {
    userAccountPortfolios(where: { id: $portfolioID }) {
      edges {
        node {
          id
          totalValue
          account {
            id
          }
          assetBalances(
            where: {
              hideZeroBalance: true
              hasAssetWith: { typeIn: [ASSET_TYPE_SECURITY, ASSET_TYPE_FIAT] }
            }
          ) {
            edges {
              node {
                balances {
                  activeBalance
                  value
                  total
                  tradeable
                  liability
                }
                costBasis
                asset {
                  id
                  type
                  name
                  symbol
                  ... on SecurityAsset {
                    name
                    imageLogo
                    securityType
                    market {
                      last
                      previousDayClose
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
