import React, { createContext, useContext, useState, ReactNode } from 'react';
import { SidePanelName } from '@/components/common/SidePanels/utils/SidePanelsRegistration';
import { Sheet } from '@/components/common/Sheet';

type SidePanelRegistry = {
  [key in SidePanelName]?: React.FunctionComponent<any>;
};

const sidePanelRegistry: SidePanelRegistry = {};

// Register a side panel component
export const registerSidePanel = (name: SidePanelName, component: React.FunctionComponent<any>) => {
  sidePanelRegistry[name] = component;
};

type SidePanelContextType = {
  goBack: () => void;
  goTo: (step: number) => void;
  currentFlowStep: number;
  showBackLink: boolean;
  showCloseButton: boolean;
  openSidePanel: (name: SidePanelName, props?: any | null, callback?: SidePanelName) => void;
  closeSidePanel: () => void;
  activeSidePanel: SidePanelName | null;
  setShowBackLink: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCloseButton: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveSidePanel: React.Dispatch<React.SetStateAction<SidePanelName | null>>;
  enableInteractionWithModal: () => void;
  disableInteractionWithModal: () => void;
  sidePanelProps: any | null;
};

const SidePanelContext = createContext<SidePanelContextType | undefined>(undefined);

const SidePanelManager = () => {
  const { activeSidePanel, closeSidePanel, sidePanelProps } = useSidePanel();

  if (!activeSidePanel) return null;

  const SidePanelComponent = sidePanelRegistry[activeSidePanel];

  return (
    <Sheet open onOpenChange={closeSidePanel}>
      {SidePanelComponent && <SidePanelComponent {...sidePanelProps} />}
    </Sheet>
  );
};

export const SidePanelProvider = ({ children }: { children: ReactNode }) => {
  /**
   * The side panel component that is being presented
   */
  const [activeSidePanel, setActiveSidePanel] = useState<SidePanelName | null>(null);

  /**
   * The side panel callback used to open another side panel after closing the current one
   */
  const [callbackSidePanel, setCallbackSidePanel] = useState<SidePanelName | null>(null);

  /**
   * Side panel props
   */
  const [sidePanelProps, setSidePanelProps] = useState<any>(null);

  /**
   * The current step in a multi-step flow.
   */
  const [currentFlowStep, setCurrentFlowStep] = useState(0);

  /**
   * Whether to show the back link.
   */
  const [showBackLink, setShowBackLink] = useState(false);

  /**
   * Whether to show the close button.
   */
  const [showCloseButton, setShowCloseButton] = useState(true);

  /**
   * Goes back one step in the flow.
   */
  const goBack = () => {
    const newFlowStep = currentFlowStep - 1;
    setCurrentFlowStep(newFlowStep >= 0 ? newFlowStep : 0);
    setShowBackLink(newFlowStep > 0);
    setShowCloseButton(newFlowStep <= 0);
  };

  /**
   * Goes to the specified step in the flow.
   *
   * @param step
   */
  const goTo = (step: number) => {
    const isFirstStep = step === 0;
    setShowBackLink(!isFirstStep);
    setShowCloseButton(isFirstStep);
    setCurrentFlowStep(step);
  };

  /**
   * Open specific Side Panel by name
   *
   * @param name
   */
  const openSidePanel = (name: SidePanelName, props: any = null, callback?: SidePanelName) => {
    if (!sidePanelRegistry[name]) {
      console.error(`SidePanel "${name}" is not registered.`);
      return;
    }

    if (callback) {
      setCallbackSidePanel(callback);
    }

    setSidePanelProps(props);
    setActiveSidePanel(name);
  };

  /**
   * Reset Side Panel
   */
  const resetSidePanel = () => {
    setActiveSidePanel(null);
    setCallbackSidePanel(null);
    setShowBackLink(false);
    setShowCloseButton(true);
    setCurrentFlowStep(0);
  };

  /**
   * Close Side Panel
   */
  const closeSidePanel = () => {
    resetSidePanel();
    if (callbackSidePanel) {
      openSidePanel(callbackSidePanel);
    }
  };

  /**
   * Enable interaction with modals e.g confirmation modals
   */
  const enableInteractionWithModal = () => {
    document.body.style.pointerEvents = 'auto';
  };

  /**
   * Disable interaction with modals e.g confirmation modals
   */
  const disableInteractionWithModal = () => {
    document.body.style.pointerEvents = 'none';
  };

  return (
    <SidePanelContext.Provider
      value={{
        goBack,
        goTo,
        currentFlowStep,
        showBackLink,
        showCloseButton,
        setShowBackLink,
        setShowCloseButton,
        openSidePanel,
        closeSidePanel,
        activeSidePanel,
        setActiveSidePanel,
        enableInteractionWithModal,
        disableInteractionWithModal,
        sidePanelProps,
      }}
    >
      {children}
      <SidePanelManager />
    </SidePanelContext.Provider>
  );
};

export const useSidePanel = () => {
  const context = useContext(SidePanelContext);
  if (!context) {
    throw new Error('useSidePanel must be used within a SidePanelProvider');
  }
  return context;
};
