import { Button } from '@mui/material';
import React, { useEffect } from 'react';

// Trabian
import Submenu from '@/components/common/Layout/Submenu';
import BodyText from '~components/shims/BodyText';
import useSubmenu, { TSubmenuEntry } from '@/hooks/utils/useSubmenu';

// FinTron SVGs
import { ChevronLeftSvg } from '@fintronners/react-ui/src/Images';
import SvgIconBank from '@fintronners/react-ui/src/Images/autoIndexedImages/tintableSvgs/IconBank';
import SvgIconRoth from '@fintronners/react-ui/src/Images/autoIndexedImages/tintableSvgs/IconRoth';
import SvgIconSecurity from '@fintronners/react-ui/src/Images/autoIndexedImages/tintableSvgs/IconSecurity';
import SvgIconSettings from '@fintronners/react-ui/src/Images/autoIndexedImages/tintableSvgs/IconSettings';
import SvgIconSupport from '@fintronners/react-ui/src/Images/autoIndexedImages/tintableSvgs/IconSupport';
import SvgIconTraditional from '@fintronners/react-ui/src/Images/autoIndexedImages/tintableSvgs/IconTraditional';
import SvgStocks from '@fintronners/react-ui/src/Images/autoIndexedImages/tintableSvgs/Stocks';
import useAllAccountsData from '@fintronners/react-widgets/src/hooks/market/useAllAccountsData';

const selfDirectedEntry = {
  icon: <SvgStocks width={14} height={14} />,
  title: 'Self-directed account',
  url: '/settings/self-directed',
  children: [
    {
      title: 'Activity history',
      url: '/accounts/self-directed/activity-history',
      children: [],
    },
    {
      title: 'Trusted contact',
      url: '/settings/self-directed/trusted-contact',
      children: [],
    },
    {
      title: 'Documents',
      url: '/accounts/self-directed/documents',
      children: [],
    },
    {
      title: 'Investment questions',
      url: '/settings/investment-questions',
      children: [],
    },
    {
      title: 'Apex Clearing programs',
      url: '/settings/self-directed/apex-clearing-programs',
      children: [],
    },
    {
      title: 'Close account',
      url: '/settings/self-directed/close-account',
      style: {
        color: '#ff0000',
      },
      children: [],
    },
  ],
};

const tradIRAEntry = {
  icon: <SvgIconTraditional width={14} height={14} />,
  title: 'Traditional IRA self-directed',
  url: '/settings/trad-ira',
  children: [
    {
      title: 'Activity history',
      url: '/accounts/trad-ira/activity-history',
      children: [],
    },
    {
      title: 'Trusted contact',
      url: '/settings/trad-ira/trusted-contact',
      children: [],
    },
    {
      title: 'Beneficiaries',
      url: '/settings/trad-ira/beneficiaries',
      children: [],
    },
    {
      title: 'Documents',
      url: '/accounts/trad-ira/documents',
      children: [],
    },
    {
      title: 'Investment questions',
      url: '/settings/investment-questions',
      children: [],
    },
    {
      title: 'Withholding information',
      url: '/settings/trad-ira/withholding-information',
      children: [],
    },
    {
      title: 'Apex Clearing programs',
      url: '/settings/trad-ira/apex-clearing-programs',
      children: [],
    },
    {
      title: 'Close account',
      url: '/settings/trad-ira/close-account',
      children: [],
      style: {
        color: '#ff0000',
      },
    },
  ],
};

const rothIRAEntry = {
  icon: <SvgIconRoth width={14} height={14} />,
  title: 'Roth IRA self-directed',
  url: '/settings/roth-ira',
  children: [
    {
      title: 'Activity history',
      url: '/accounts/roth-ira/activity-history',
      children: [],
    },
    {
      title: 'Trusted contact',
      url: '/settings/roth-ira/trusted-contact',
      children: [],
    },
    {
      title: 'Beneficiaries',
      url: '/settings/roth-ira/beneficiaries',
      children: [],
    },
    {
      title: 'Documents',
      url: '/accounts/roth-ira/documents',
      children: [],
    },
    {
      title: 'Investment questions',
      url: '/settings/investment-questions',
      children: [],
    },
    {
      title: 'Filing status & annual adjusted gross income',
      url: '/settings/roth-ira/filing-status',
      children: [],
    },
    {
      title: 'Apex Clearing programs',
      url: '/settings/roth-ira/apex-clearing-programs',
      children: [],
    },
    {
      title: 'Close account',
      url: '/settings/roth-ira/close-account',
      children: [],
      style: {
        color: '#ff0000',
      },
    },
  ],
};

const submenuEntries: Array<TSubmenuEntry[]> = [
  [
    // We're not working on Robo accounts at the moment.
    // {
    //   icon: <SvgIconRobo width={14} height={14} />,
    //   title: 'Robo accounts',
    //   url: '/settings/robo-accounts',
    //   children: [],
    // },
    {
      icon: <SvgIconSettings width={14} height={14} />,
      title: 'Profile settings',
      url: '/settings/profile',
      children: [],
    },
    {
      icon: <SvgIconSupport width={14} height={14} />,
      title: 'Support',
      url: '/settings/support',
      children: [],
    },
    {
      icon: <SvgIconSecurity width={14} height={14} />,
      title: 'Security',
      url: '/settings/security',
      children: [],
    },
    {
      icon: <SvgIconBank width={14} height={14} />,
      title: 'Bank link',
      url: '/settings/bank-link',
      children: [],
    },
  ],
];

type TSettingsMenuProps = { closeHandler: () => void };

const SettingsMenu: React.FC<TSettingsMenuProps> = (props) => {
  const {
    entriesArray,
    setEntriesArray,
    selectedIndex,
    setSelectedIndex,
    selectedPathIndices,
    setSelectedPathIndices,
    goBack,
  } = useSubmenu();

  const { selfDirected, ira, rothIra, loading } = useAllAccountsData();

  useEffect(() => {
    let accountTypeEntries = [];

    if (loading) return;

    if (selfDirected) accountTypeEntries.push(selfDirectedEntry);
    if (ira) accountTypeEntries.push(tradIRAEntry);
    if (rothIra) accountTypeEntries.push(rothIRAEntry);

    if (accountTypeEntries.length > 0) {
      setEntriesArray([[...accountTypeEntries, ...submenuEntries[0]]]);
    } else {
      setEntriesArray(submenuEntries);
    }

    setSelectedIndex(0);
  }, [loading]);

  return (
    <Submenu
      closeHandler={props.closeHandler}
      entriesArray={entriesArray}
      topLevelIndex={selectedIndex}
      pathIndices={selectedPathIndices}
      setPathIndices={setSelectedPathIndices}
      backButton={
        <Button
          className="flex w-full flex-row justify-start rounded-md bg-transparent p-2 hover:bg-gray-50"
          onClick={goBack}
        >
          <div className="mr-2 mt-[2px]">
            <span className="text-gray-400">
              <ChevronLeftSvg width={10} height={10} />
            </span>
          </div>
          <BodyText className="text-sm capitalize">Back</BodyText>
        </Button>
      }
    />
  );
};

export default SettingsMenu;
