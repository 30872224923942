import { useQuery } from '@apollo/client';
import {
  GET_LATEST_DISCRETIONARY_UPDATE_REQUESTS,
  GET_LATEST_NON_DISCRETIONARY_UPDATE_REQUESTS,
  GET_LATEST_PROFILE_UPDATE_REQUESTS,
  GetLatestDiscretionaryUpdateRequestsQuery,
  GetLatestNonDiscretionaryUpdateRequestsQuery,
  GetLatestProfileUpdateRequestsQuery,
  UserAccountAccountStatus,
  UserAccountAccountType,
} from '@fintronners/react-api';
import { isApexRequestPending } from '@fintronners/react-api/src/utils/accountUtils';
import { useEffect, useState } from 'react';
import useUserAccount from '@/hooks/api/useUserAccount';

export enum ApexRequestType {
  STOCK = 'Stock',
  ROBO = 'Robo',
  ALL_PROFILE = 'All Profile',
  STOCK_PROFILE = 'Stock Profile',
  ROBO_PROFILE = 'Robo Profile',
}

const queryMap = {
  [ApexRequestType.STOCK]: GET_LATEST_NON_DISCRETIONARY_UPDATE_REQUESTS,
  [ApexRequestType.ROBO]: GET_LATEST_DISCRETIONARY_UPDATE_REQUESTS,
  [ApexRequestType.ALL_PROFILE]: GET_LATEST_PROFILE_UPDATE_REQUESTS,
  [ApexRequestType.STOCK_PROFILE]: GET_LATEST_PROFILE_UPDATE_REQUESTS,
  [ApexRequestType.ROBO_PROFILE]: GET_LATEST_PROFILE_UPDATE_REQUESTS,
};

/**
 * Custom hook to determine where the target account profile can be updated
 * based on the account and APEX status (web version).
 *
 * Very long convo here https://fintron-workspace.slack.com/archives/C01PJ3TU1D1/p1712686916788859
 *
 * @param requestType - A enum indicating whether the request is for stock, robo or profile.
 * @returns An object containing the status of apex requests.
 */
const useCanUpdateProfile = (requestType: ApexRequestType) => {
  const { data, refetch, loading } = useQuery<
    | GetLatestNonDiscretionaryUpdateRequestsQuery
    | GetLatestDiscretionaryUpdateRequestsQuery
    | GetLatestProfileUpdateRequestsQuery
  >(queryMap[requestType], {
    fetchPolicy: 'no-cache',
  });

  //Stock accounts
  const { userAccount: stockAccount } = useUserAccount(UserAccountAccountType.AccountTypeBrokerage);
  const { userAccount: tradIraStockAccount } = useUserAccount(
    UserAccountAccountType.AccountTypeIra,
  );
  const { userAccount: rothIraStockAccount } = useUserAccount(
    UserAccountAccountType.AccountTypeRothIra,
  );

  const { userAccount: roboAccount } = useUserAccount(
    UserAccountAccountType.AccountTypeBrokerageDiscretionary,
  );

  const { userAccount: tradIraRoboAccount } = useUserAccount(
    UserAccountAccountType.AccountTypeIraDiscretionary,
  );

  const { userAccount: rothIraRoboAccount } = useUserAccount(
    UserAccountAccountType.AccountTypeRothIraDiscretionary,
  );

  const [singleState, setSingleState] = useState<{
    isProfilePending?: boolean;
    refetchUpdateProfileStatus: () => void;
  }>({
    isProfilePending: undefined,
    refetchUpdateProfileStatus: refetch,
  });

  const shouldNotRun =
    loading ||
    !data ||
    !stockAccount ||
    !roboAccount ||
    !rothIraRoboAccount ||
    !tradIraRoboAccount ||
    !rothIraStockAccount ||
    !tradIraStockAccount;

  useEffect(() => {
    if (shouldNotRun) return;

    let isActive = false;
    const activeStatus = [
      UserAccountAccountStatus.AccountStatusActive,
      UserAccountAccountStatus.AccountStatusNew,
    ];

    switch (requestType) {
      case ApexRequestType.ALL_PROFILE:
        isActive = activeStatus.includes(
          stockAccount.status ||
            rothIraStockAccount.status ||
            tradIraStockAccount.status ||
            roboAccount.status ||
            rothIraRoboAccount.status ||
            tradIraRoboAccount.status,
        );
        break;
      case ApexRequestType.STOCK_PROFILE:
        isActive = stockAccount?.status === UserAccountAccountStatus.AccountStatusActive;
        break;
      case ApexRequestType.ROBO_PROFILE:
        isActive = roboAccount?.status === UserAccountAccountStatus.AccountStatusActive;
        break;
      case ApexRequestType.ROBO:
        isActive = activeStatus.includes(
          roboAccount.status || rothIraRoboAccount.status || tradIraRoboAccount.status,
        );
        break;
      case ApexRequestType.STOCK:
        isActive = activeStatus.includes(
          stockAccount.status || rothIraStockAccount.status || tradIraStockAccount.status,
        );
        break;
    }

    setSingleState({
      isProfilePending: isApexRequestPending(data) || !isActive,
      refetchUpdateProfileStatus: refetch,
    });
  }, [loading, data, roboAccount, stockAccount]);

  return singleState;
};

export default useCanUpdateProfile;
