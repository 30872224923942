export const defaultConfig = {
  images: {
    lightBrandLogoL: {
      logoRatio: 1,
      uri: ``,
    },
    darkBrandLogoL: {
      logoRatio: 1,
      uri: `/images/login-logo-fintron.svg`,
    },
    colorBrandLogoL: {
      logoRatio: 1,
      uri: ``,
    },
    darkBrandLogoS: {
      logoRatio: 1,
      uri: `/images/logo-fintron.svg`,
    },
  },
};
