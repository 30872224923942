import React from 'react';

import { useSidePanel } from '@/context/SidePanelContext';

import { SheetContent } from '@/components/common/Sheet';
import { UsernameForm } from './parts/UsernameForm';
import { ErrorSidePanel } from '../ErrorSidePanel/ErrorSidePanel';
import { ResetPasswordForm } from './parts/ResetPasswordForm';
import { Success } from './parts/Success';

const UpdatePasswordSidePanel: React.FC = () => {
  const { currentFlowStep } = useSidePanel();

  return (
    <SheetContent>
      {currentFlowStep === 0 && <UsernameForm />}
      {currentFlowStep === 1 && <ResetPasswordForm />}
      {currentFlowStep === 2 && <Success />}
      {currentFlowStep === 3 && <ErrorSidePanel />}
    </SheetContent>
  );
};

export default UpdatePasswordSidePanel;
