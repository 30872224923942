/**
 * FE Type to help distinguish between different FinTron products
 * until the BE does not make this distinction.
 */
export enum FinTronProduct {
  SelfDirected = 'SelfDirected',
  RoboGeneralInvesting = 'RoboGeneralInvesting',
  RoboGoals = 'RoboGoals',
}

export const WITHDRAWAL_RMD_AGE = 72;
export const WITHDRAWAL_MINIMUM_BANK_ACCOUNT_AGE = 7;
