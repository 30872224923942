import { Commonpbv1Date } from '../tsoai';
import fLogger from '@fintronners/react-utils/src/fLogger';

/**
 * Converts an ISO date string to a date object with year, month, and day properties.
 * @param {string} isoDateString - The ISO date string to convert (e.g., "2023-07-28T12:34:56.789Z").
 * @example
 * const isoDateString = '2023-07-28T12:34:56.789Z';
 * const dateObject = convertISODateStringToObject(isoDateString);
 * // { year: 2023, month: 7, day: 28 }
 * @returns {Commonpbv1Date} The date object with year, month, and day properties.
 */
export const convertISODateStringToObject = (
  isoDateString?: string,
  ignoreTimezone: boolean = false,
): Commonpbv1Date | undefined => {
  if (!isoDateString || isNaN(Date.parse(isoDateString))) {
    return;
  }

  const dateObj = new Date(isoDateString);
  const split = isoDateString.split('-');

  const year = ignoreTimezone ? parseInt(split[0], 10) : dateObj.getFullYear();
  const month = ignoreTimezone ? parseInt(split[1], 10) : dateObj.getMonth() + 1;
  const day = ignoreTimezone ? parseInt(split[2].split('T')[0], 10) : dateObj.getDate();

  return { year, month, day };
};

/**
 * Converts a Commonpbv1Date object to an ISO date string.
 * @param {Commonpbv1Date} v1DateObject - The Commonpbv1Date object to convert (e.g., { year: 2023, month: 7, day: 28 }).
 * @example
 * const v1DateObject = { year: 2023, month: 7, day: 28 };
 * const isoDateString = convertObjectToISODateString(v1DateObject);
 * // "2023-07-28T12:34:56.789Z"
 * @returns {string} The ISO date string.
 */
export const convertObjectToISODateString = (v1DateObject?: Commonpbv1Date): string | undefined => {
  if (!v1DateObject) {
    return;
  }

  if (!v1DateObject.year || !v1DateObject.month || !v1DateObject.day) {
    fLogger.error('Received an invalid V1DateObject', JSON.stringify(v1DateObject));
    return;
  }

  const date = new Date(v1DateObject.year, v1DateObject.month - 1, v1DateObject.day);

  if (isNaN(date.getTime())) {
    fLogger.error('Invalid date created', date.toString());
    return;
  }

  return date.toISOString();
};

/**
 * Converts an date string to a date object with year, month, and day properties.
 * @param {string} dateString - The ISO date string to convert (e.g., "2023-07-28").
 * @example
 * const dateString = '2023-07-28';
 * const dateObject = convertDateStringToV1Date(dateString);
 * // { year: 2023, month: 7, day: 28 }
 * @returns {Commonpbv1Date} The date object with year, month, and day properties.
 */
export const convertISODateStringToV1Date = (isoDate?: string): Commonpbv1Date | undefined => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!isoDate) return;
  if (!regex.test(isoDate)) {
    fLogger.error('Received an invalid ISO date string', isoDate);
    return;
  }

  const [year, month, day] = isoDate.split('-').map(Number);
  return { year, month, day };
};

/**
 * Utility function to check if a string is a valid month/year string.
 * @param {string} monthSlashYear - The month/year string to check (e.g., "07 / 2023").
 */
export const isValidMonthSlashYear = (monthSlashYear: string): boolean => {
  const [month, year] = monthSlashYear.split('/').map((str) => str.trim());

  if (!month || !year) {
    return false;
  }

  const date = new Date(`${year}-${month}-01`);

  if (isNaN(date.getTime())) {
    return false;
  }

  return true;
};

/**
 * Utility function to check if a month/year string is greater than the current month/year.
 * @param {string} monthSlashYear - The month/year string to check (e.g., "07 / 2023").
 */
export const monthSlashYearGreaterThan = (monthSlashYear: string) => {
  const dateToCompare = new Date();

  if (!isValidMonthSlashYear(monthSlashYear)) {
    return false;
  }

  const [month, year] = monthSlashYear.split('/').map((str) => str.trim());

  const inputMonth = parseInt(month, 10);
  const inputYear = parseInt(year, 10);

  if (inputMonth < 1 || inputMonth > 12) {
    return false;
  }

  const currentMonth = dateToCompare.getMonth() + 1;
  const currentYear = dateToCompare.getFullYear();

  if (inputYear > currentYear) {
    return true;
  }
  if (inputYear === currentYear && inputMonth > currentMonth) {
    return true;
  }
  return false;
};

/**
 * A helpful function to get yesterdays date
 * @returns yesterdays date
 */
export const getYesterday = () => {
  return new Date(new Date().setDate(new Date().getDate() - 1));
};
