import { WebBodyText16 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import React from 'react';
import { InvestmentValuesListItem } from './InvestmentValuesListItem';
import { EditIcon } from '@/assets/icons';
import Image from 'next/image';
import { InvestorFormKey } from '../InvestorProfileSidePanel';

interface InvestmentValuesListItemProps {
  title: string;
  items: {
    label: string;
    value: string;
    isPending?: boolean;
  }[];
  form: InvestorFormKey;
  displayAsListItem?: boolean;
  onEdit?: (form: InvestorFormKey) => void;
}

export const InvestmentValuesList = ({
  title,
  form,
  items,
  onEdit,
}: InvestmentValuesListItemProps) => {
  return (
    <div>
      <div className="flex justify-between items-center">
        <WebBodyText16.Bold className="text-darkestBlue">{title}</WebBodyText16.Bold>
        {onEdit && (
          <button type="button" onClick={() => onEdit(form)}>
            <Image alt="Edit icon" width={16} height={16} src={EditIcon.src} />
          </button>
        )}
      </div>

      <div className="flex flex-col gap-2 mt-2">
        {items.map((details, index) => (
          <InvestmentValuesListItem key={index} {...details} />
        ))}
      </div>
    </div>
  );
};
