import React from 'react';
import Image from 'next/image';
import ContactForm from '@/components/forms/ContactForm/ContactForm';
import { SheetContent } from '@/components/common/Sheet';
import { Button } from '@/components/common/Button';
import {
  WebBodyText18,
  WebBodyText20,
  WebHeading28,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';

import { useSidePanel } from '@/context/SidePanelContext';
import { InfoPrimaryIcon, SuccessCircleIcon } from '@/assets/icons';

const MAIL_TO = 'mailto:email@support.com';

const displayStrings: Record<string, string> = {
  title: 'Contact us',
  successTitle: 'Form submitted',
  successText: 'Thanks for submitting the form.',
  doneText: 'Done',
  errorTitle: 'Sorry',
  errorText:
    'It looks like something is wrong. Please try again. If the problem continues, contact ',
  backText: 'Back',
};

// NOTE: this side panel is supposed to be used in apex programs -> contact support
// but a single mailto link will be used at the moment. However, this side panel implementation is done.
const FPSLOptInSidePanel: React.FC = () => {
  const { currentFlowStep: step, goTo, closeSidePanel } = useSidePanel();

  return (
    <SheetContent className="pb-12">
      {step === 0 && (
        <>
          {/* Title */}
          <WebBodyText20.Bold className="mb-3 text-grey55">
            {displayStrings.title}
          </WebBodyText20.Bold>

          {/* Contact Form */}
          <ContactForm onSuccess={() => goTo(1)} onError={() => goTo(2)} />
        </>
      )}

      {/* Success */}
      {step === 1 && (
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col items-center justify-center gap-[30px]">
            <Image src={SuccessCircleIcon} width={50} height={50} alt="success" />
            <WebHeading28.Bold className="text-darkestBlue">
              {displayStrings.successTitle}
            </WebHeading28.Bold>

            <WebBodyText18.Regular className="text-center text-grey72">
              {displayStrings.successText}
            </WebBodyText18.Regular>
          </div>

          <Button className="w-full" onClick={closeSidePanel}>
            {displayStrings.doneText}
          </Button>
        </div>
      )}

      {/* Error */}
      {step === 2 && (
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col items-center justify-center gap-[30px]">
            <Image src={InfoPrimaryIcon} width={50} height={50} alt="success" />
            <WebHeading28.Bold className="text-darkestBlue">
              {displayStrings.errorTitle}
            </WebHeading28.Bold>

            <WebBodyText18.Regular className="text-center text-grey72">
              {displayStrings.errorText}
              <span>
                <a className="underline" href={MAIL_TO}>
                  email@support.com
                </a>
              </span>
            </WebBodyText18.Regular>
          </div>

          <Button className="w-full" onClick={() => goTo(0)}>
            {displayStrings.backText}
          </Button>
        </div>
      )}
    </SheetContent>
  );
};

export default FPSLOptInSidePanel;
