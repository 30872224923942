import { gql } from '@apollo/client';

export const GET_PLAID = gql`
  query GetPlaid {
    externalAccounts {
      edges {
        node {
          id
          mask
          type
          connectionStatus
          institution {
            id
            name
            createTime
            updateTime
          }
        }
      }
    }
  }
`;

export const EXTERNAL_ACCOUNT_FRAGMENT = gql`
  fragment ExternalAccountFragment on ExternalAccount {
    id
    mask
    type
    connectionStatus
    connectionType
    accountNumber
    createTime
    institution {
      id
      name
      createTime
      updateTime
    }
    connectionDetails {
      __typename
      ... on ExternalAccountConnectionDetailsPlaid {
        relationshipOwner {
          id
        }
      }
    }
  }
`;

export const GET_PLAID_V2 = gql`
  query GetPlaidV2 {
    tenant {
      brokerage {
        plaidRelationshipOwner {
          id
        }
      }
      advisory {
        plaidRelationshipOwner {
          id
        }
      }
    }
    externalAccounts {
      edges {
        node {
          ...ExternalAccountFragment
        }
      }
    }
  }
  ${EXTERNAL_ACCOUNT_FRAGMENT}
`;
