import { V1UserRiskTolerance } from '@fintronners/react-api/src/tsoai';
import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';

import robo from '@fintronners/react-language/src/Lang/pt.robo';
import errors from '@fintronners/react-language/src/Lang/pt.errors';
import settings from '@fintronners/react-language/src/Lang/pt.settings';
import widgets from './pt.widgets';
import gi from './pt.gi';

export default {
  common: {
    back: 'Voltar',
    continue: 'Continuar',
    loading: 'Carregando',
    getStarted: 'COMEÇAR',
    submit: 'ENVIAR',
    submitAndContinue: 'ENVIAR E CONTINUAR',
    home: 'INÍCIO',
    login: 'ENTRAR',
    signUp: 'CADASTRAR',
    privacyPolicyText: `Para mais informações, veja a nossa [Política de Privacidade.](${FinTronConfig.privacyPolicyUrl})`,
    exit: 'Sair da configuração',
    rememberMe: 'Lembrar-me',
    forgotPassword: 'Esqueceu a senha?',
    done: 'Concluído',
    exitTitle: 'Tem certeza de que deseja sair?',
    exitDescription:
      'Você está quase lá! Você sempre pode voltar e terminar sua inscrição mais tarde.',
    pendingChangesDesc:
      '*Novos valores foram submetidos, mas ainda não foram confirmados. Você ainda pode continuar a enviar alterações adicionais.',
    yes: 'Sim',
    no: 'Não',
    amount: 'Valor',
    totalAmount: 'Valor total',
    updating: 'Atualizando...',
    saveChanges: 'Salvar Alterações',
    cancel: 'Cancelar',
    save: 'Salvar',
    apply: 'Aplicar',
    processing: 'Processando',
    statements: 'Extratos',
    tradeConfirms: 'Confirmações de Trade',
    profileSettings: 'Configurações de Perfil',
    to: 'até',
    symbolOfCompany: 'Símbolo da empresa',
    accountNumber: 'Número da Conta',
    profileInformation: 'Informações de Perfil',
    personalInformation: 'Informações Pessoais',
    support: 'Suporte',
    selectDate: 'Select date',
    deposit: 'Depósito',
    instantDeposit: 'Depósito instantâneo',
    withdrawal: 'Resgate',
    depositAmount: 'Valor do depósito instantâneo',
    pastDistributions: 'Histórico de Distribuição',
    expensesAndFees: 'Taxas & Despesas',
    minimums: 'Mínimos',
    top10Holdings: 'Top 10 Ativos',
    open: 'Aberto',
    cancelled: 'Cancelado',
    cancelling: 'Cancelando',
    pending: 'Pendente',
    completed: 'Concluído',
    returned: 'Retornado',
    received: 'Recebido',
    marketBuy: 'Compra a mercado',
    marketSell: 'Venda a mercado',
    shares: 'Ações',
    costBasis: 'Custo base',
    perShare: 'por ação',
    dividend: 'Dividendo',
    rollover: 'Roll-over',
    stock: 'Ativo',
    automatedInvestment: 'Investimento Agendado',
    recurring: 'Transferência agendada',
    searchSecurity: 'Buscar ativo',
    status: 'Status',
    type: 'Tipo',
    symbol: 'Simbolo',
    custom: 'Outro',
    withdrawalAmount: 'Valor de resgate',
    withdrawAmount: 'Valor do resgate',
    enterAmount: 'Insira um valor (maior ou igual a ${{amount}})',
    gainLoss: 'Ganho/Perda:  ',
    asOf: 'Atualizado às {{time}}',
    ask: 'Venda: ',
    bid: 'Compra: ',
    notAvailable: 'N/D',
    news: 'Notícias',
    trade: 'Trade',
    by: 'Fonte',
    distributions: 'Distribuições',
    expand: 'Expandir',
    showMore: 'Ver mais',
    showLess: 'Ver menos',
    date: 'Data',
    frequency: {
      weekly: 'Semanal',
      biWeekly: 'Quinzenal',
      monthly: 'Mensal',
    },
    duration: {
      day: '1D',
      tradingDay: '1D',
      extendedTradingDay: '1D',
      week: '1S',
      month: '1M',
      threeMonths: '3M',
      year: '1A',
      fiveYears: '5A',
      all: 'TUDO',
    },
    congratulations: 'Sucesso!',
  },
  selfDirected: {
    single: {
      depositAmount: 'Valor de depósito',
      enterAmount: 'Insira o valor (entre ${{minimum}} e {{maximum}})',
    },
  },
  onboardingWelcome: {
    items: [
      {
        uri: require('@fintronners/react-ui/src/Themes/carouselImages/image1.png'),
        title: 'Lorem Ipsum',
        description:
          'Lorem Ipsum é simplesmente um texto fictício da indústria tipográfica e de impressão.',
      },
      {
        uri: require('@fintronners/react-ui/src/Themes/carouselImages/image2.png'),
        title: 'Lorem Ipsum',
        description:
          'Lorem Ipsum é simplesmente um texto fictício da indústria tipográfica e de impressão.',
      },
      {
        uri: require('@fintronners/react-ui/src/Themes/carouselImages/image3.png'),
        title: 'Lorem Ipsum',
        description:
          'Lorem Ipsum é simplesmente um texto fictício da indústria tipográfica e de impressão.',
      },
    ],
    termsAndPrivacyPolicy: `Ao clicar em "Começar", você iniciará o processo de criação de um perfil com ${FinTronConfig.parentName} e concordará com nossos [Termos de Uso](${FinTronConfig.termsAndConditionsUrl}) e [Política de Privacidade.](${FinTronConfig.privacyPolicyUrl})`,
    extraFooterElement: '',
    accessibilityStatementTitle: '',
    accessibilityStatement: '',
  },
  onboardingAccountVerification: {
    title: 'Confirme o seu e-mail e o seu número de celular.',
    subtitle:
      'Confirme seu e-mail e número de telefone para agilizar o processo de abertura da sua conta.',
    profileNotFound: 'Perfil não encontrado',
    profileNotFoundDescription: `As informações fornecidas não estão associadas a uma conta do ${FinTronConfig.parentName}.`,
    profileFound: 'Perfil encontrado',
    profileFoundDescription: `Ótima notícia! As informações fornecidas estão associadas a uma conta do ${FinTronConfig.parentName}. Você está a poucos minutos de abrir sua conta de investimento.`,
  },
  onboardingPrefill: {
    title: 'Respostas pré-preenchidas',
    description: `*Eu reconheço que as informações pré-preenchidas abaixo foram fornecidas por mim durante o processo de abertura de conta no ${FinTronConfig.parentName}, e entendo que essas informações serão utilizadas para abrir minha conta na ${FinTronConfig.selfDirected.name}. \n\nAo continuar o processo de abertura de conta na ${FinTronConfig.selfDirected.name}, certifico que as informações abaixo são precisas e corretas. Se as informações abaixo não forem mais precisas, não continuarei com o processo de integração e atualizarei minhas informações enviando um e-mail para ${FinTronConfig.selfDirected.supportEmail}`,
  },
  onboardingUsernamePassword: {
    title: 'Vamos manter sua conta segura.',
    subtitle: 'Por razões de segurança, não reutilize senhas para vários sites ou perfis.',
    confirmPasswordFieldHelpText:
      'Sua senha deve ter no mínimo 8 caracteres e incluir pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.',
    username: 'Usuário',
    password: 'Senha',
    confirmPassword: 'Redigite sua senha',
  },
  onboardingBasicInfo: {
    title: 'Vamos começar com algumas informações básicas.',
    subtitle: `A ${FinTronConfig.selfDirected.name} precisa dessas informações para abrir a sua conta.`,
    firstName: 'Nome',
    firstNameHelpText: '',
    lastName: 'Sobrenome Completo',
    email: 'E-mail',
    dateOfBirth: 'Data de nascimento',
    phoneNumber: 'Número de celular',
    checkbox: '',
  },
  onboardingVerification: {
    title: 'Confirme o seu número de celular e e-mail.',
    subtitle: 'Por motivos de segurança, verifique seu número de telefone e e-mail.',
    email: 'E-mail',
    phoneNumber: 'Número de celular',
    verify: 'Verificar',
  },
  onboardingAddress: {
    title: 'Qual é o seu endereço residencial?',
    subtitle: `${FinTronConfig.parentName} é obrigado a coletar essas informações para criar seu perfil.`,
    streetAddress: 'Endereço',
    additionalStreetAddress: 'Apartamento, suíte, unidade, prédio, andar, etc.',
    city: 'Cidade',
    state: 'Estado',
    postalCode: 'CEP',
    footer: '',
  },
  onboardingCitizenship: {
    title: 'Residência Fiscal.',
    subtitle:
      'Confirme que você é um **Não-Residente** e **não tem residência fiscal nos Estados Unidos** ou em territórios americanos.',
    notCitizenTitle: '',
    notCitizenSubtitle: '',
    usCitizen: '',
    greenCard: '',
    visa: '',
    noneApplicable: '',
    nonResident: 'Não Residente',
    yourStatus: 'Seu status{{pendingMark}}:',
  },
  onboardingPlaceOfBirth: {
    title: 'Local de nascimento.',
    subtitle: 'Selecione seu local de nascimento:',
    label: 'Local de nascimento',
  },
  onboardingVisa: {
    title: 'Tipo de visto.',
    subtitle: 'Selecione seu tipo de visto:',
    visaExpirationTitle: 'Data de expiração do visto.',
    visaExpirationSubtitle: 'Insira a data de expiração do seu visto:',
    placeholder: 'Mês / Dia / Ano',
  },
  onboardingSocial: {
    title: 'Verifique a sua identidade.',
    subtitle: 'Confirme seu CPF para abrir a sua conta.',
    checklist: [
      {
        title: 'Criptografia de 256 bits',
        name: 'Utiliza uma chave de 256 bits para criptografar e descriptografar dados ou arquivos. É utilizada na maioria dos algoritmos, protocolos e tecnologias de criptografia modernos.',
      },
    ],
    personalIdNumber: 'CPF',
  },
  onboardingLicense: {
    title: 'Verifique sua identidade com sua carteira de motorista ou passaporte.',
    subtitle: `${FinTronConfig.parentName} é obrigado a coletar essas informações para criar seu perfil.`,
    maxSizeTitle: 'Falha no upload!',
    maxSizeDescription:
      'O tamanho do seu arquivo é muito grande, por favor tente fazer o upload de um menor!',
    driverLicense: 'Carteira de Motorista',
    passport: 'Passaporte',
    uploadFront: 'Carregar Frente',
    uploadBack: 'Carregar Verso',
  },
  onboardingOtherSourceOfWealth: {
    title: 'Outra fonte de renda',
    subtitle:
      'Descreva sua outra fonte de renda aqui. Isso diverge da renda recebida através do seu trabalho.',
    label: 'Outra fonte de renda',
  },
  onboardingCompliance: {
    title: 'Selecione todos que se aplicam a você.',
    questions: {
      isControlPerson:
        'Eu sou um acionista controlador ou tenho controle sobre uma empresa de capital aberto.',
      isControlPersonDescriptionTitle: 'O que é um Controlador?',
      isControlPersonDescription:
        'Um controlador pode ser definido como um diretor, executivo ou outro afiliado de uma empresa de capital aberto, que tem controle sobre a gestão ou políticas. Também pode ser um acionista que possui 10% ou mais de qualquer classe de valores mobiliários com direito a voto de uma corporação.',
      isPoliticallyExposed: 'Eu sou uma pessoa politicamente exposta.',
      isPoliticallyExposedDescriptionTitle: 'Exposição Política',
      isPoliticallyExposedDescription:
        'Uma pessoa exposta politicamente (PEP) é um indivíduo com um papel político de alto perfil ou que tenha sido encarregado de uma função pública proeminente. As PEPs representam um risco maior para instituições financeiras e Atividades e Profissões Não-Financeiras Designadas (APNFDs).',
      isAffiliatedExchangeOrFINRA:
        'Eu sou membro ou associado à alguém que seja afiliado ou funcionário de uma Bolsa de Valores ou FINRA.',
      isAffiliatedExchangeOrFINRADescriptionTitle: 'Trabalhador da FINRA ou Bolsa de Valores',
      isAffiliatedExchangeOrFINRADescription:
        'Você ou algum membro de sua família imediata é empregado ou afiliado a uma bolsa de valores (NASDAQ, Bolsa de Valores de Nova York, etc.) ou à Autoridade Reguladora do Setor Financeiro (FINRA)?',
      isSecuritiesProfessional: 'Eu sou um profissional de valores mobiliários.',
      isSecuritiesProfessionalDescriptionTitle: 'Profissional de Valores Mobiliários',
      isSecuritiesProfessionalDescription:
        'Um profissional de valores mobiliários seria um dos seguintes:\n\nEstá registrado ou qualificado junto à: Comissão de Valores Mobiliários, Comissão de Negociação de Futuros de Commodities, qualquer agência estadual de valores mobiliários, qualquer associação de bolsas, ou qualquer mercado ou associação de futuros de commodities. \n\nAtua como "consultor de investimentos" conforme definido na seção 201 (11) da Lei de Consultores de Investimentos de 1940 (esteja ou não registrado ou qualificado sob essa Lei) nos Estados Unidos. \n\nEstá empregado por um banco ou outra organização isenta de registro sob as leis federais e/ou estaduais dos Estados Unidos, relacionadas a valores mobiliários, para exercer funções ou qualificar-se para exercê-las, caso estivesse atuando em uma organização não isenta.',
    },
  },
  onboardingIsControlPerson: {
    title: 'Informação do controlador.',
    symbolOfCompany: 'Código da empresa',
    description: 'Insira o(s) código/ticker(s) da(s) empresa(s) das quais você é um controlador.',
    addACompany: '+ Adicione uma empresa',
  },
  onboardingIsPoliticallyExposed: {
    title: 'Informação de pessoa politicamente exposta.',
    description: 'Insira a organização política à qual você está afiliado.',
    politicalOrganizationNameTitle:
      'Por favor, insira a organização política com a qual você é afiliado.',
    settingsTitle: 'Você é uma pessoa politicamente exposta?',
    isPoliticallyExposed: 'Eu sou politicamente exposta.',
    namePoliticalOrganization: 'Nome da organização política',
    familyMemberName: 'Nome completo de seus familiares',
    familyMembersFullNameTitle: 'Por favor, insira os nomes de seus familiares imediatos.',
    addFamilyMember: '+ Adc Membro Familiar',
    politicalOrganizationName: 'Nome da organização política',
    familyMembersFullName: 'Nome completo de seus familiares',
  },
  onboardingIsFinra: {
    title: 'Informações da empresa empregadora.',
    form: {
      title: 'Nome do seu empregador.',
      label: 'Nome',
      letterTitle: 'Insira o seu 3210 Letter.',
      letterButtonText: 'Upload da 3210 Letter',
      letterDescription: `Faça o upload de uma imagem da sua 3210 letter do seu empregador afiliado à FINRA, autorizando você a criar uma conta de corretagem com a ${FinTronConfig.selfDirected.name}.`,
      maxSizeTitle: 'Falha no upload!',
      maxSizeDescription:
        'O tamanho do seu arquivo é muito grande, por favor tente fazer o upload de um menor!',
      companyEmployeerTitle: `Eu trabalho na ${FinTronConfig.parentName}.`,
    },
  },
  onboardingSecuritiesProfessional: {
    title: 'Perguntas sobre dados de mercado.',
    subtitle: 'Selecione todos que se aplicam a você.',
    questions: {
      firstQuestionTitle: 'Pretendo utilizar os dados de mercado para além do uso pessoal?',
      firstQuestionInfoTitle: 'Uso Pessoal',
      firstQuestionInfoDescription:
        'Questiona se você pretende utilizar os dados do mercado de ações fornecidos nesta aplicação exclusivamente para investimento e pesquisa na plataforma Bradesco Investments.',
      secondQuestionTitle:
        'Recebo dados do mercado de ações de outro provedor no meu local de trabalho ou em outro lugar?',
      thirdQuestionTitle: 'Estou registrado ou qualificado junto à SEC ou à CFTC?',
      fourthQuestionTitle:
        'Desempenho funções que exigiriam meu registro junto à SEC e/ou à CFTC ou órgãos reguladores similares?',
      fourthQuestionInfoTitle: 'Registro de órgão regulador',
      fourthQuestionInfoDescription:
        'Você está envolvido em alguma atividade relacionada a valores mobiliários (ações)? Se não, selecione não para esta questão.',
      fifthQuestionTitle: 'Eu invisto com o dinheiro de outra pessoa?',
      sixthQuestionTitle: 'Invisto em benefício de outra pessoa/entidade?',
      seventhQuestionTitle:
        'Sou consultor de investimentos, gestor de ativos ou consultor financeiro?',
      seventhQuestionInfoTitle:
        'Consultor de investimentos, gestor de ativos ou consultor financeiro',
      seventhQuestionInfoDescription:
        'Se você negocia em benefício próprio, selecione não para esta questão.',
      eighthQuestionTitle:
        'Entrei em um acordo para compartilhar os lucros das minhas atividades de investimento e/ou receber compensação por negociações?',
      ninthQuestionTitle:
        'Atualmente estou registrado junto a uma agência de valores mobiliários, bolsa de valores ou entidade de commodities/futuros?',
    },
  },
  onboardingSelectAccountType: {
    title: 'Confirme o tipo de conta que gostaria de abrir.',
    stockAccountTypeTitle: 'Construa o seu próprio portfólio.',
    stockAccountTypeDescription: `Abra uma conta de investimentos com a ${FinTronConfig.selfDirected.name} e escolha ativos que se adequem ao seu estilo de vida.`,
    stockAccountTypeCardTitle: 'Investimentos',
    stockAccountTypeCardDescription: 'Selecione ativos que se adequem ao seu estilo de vida.',
    generalInvestingAccountTypeCardTitle: 'Investimento Geral',
    generalInvestingAccountTypeCardDescription: 'Não tem um objetivo em mente, comece aqui.',
    roboAccountTypeTitle: 'Abra uma conta Robo',
    roboAccountTypeDescription: `Quer você queira investir para o futuro ou criar um objetivo específico, deixe ${FinTronConfig.robo.name} ajudá-lo a chegar lá.`,
    roboAccountTypeCardTitle: 'Criar Um Objetivo',
    roboAccountTypeCardDescription: 'Invista para um objetivo específico.',
    retirementAccountTypeCardTitle: 'Aposentadoria',
    retirementAccountTypeCardDescription: 'Invista para a aposentadoria.',
  },
  onboardingStocksLanding: {
    title: 'Investimentos',
    subtitle: `Abra uma conta de investimentos com a ${FinTronConfig.selfDirected.name} e escolha ativos que se adequem ao seu estilo de vida.`,
    legal: `Ao clicar em “Continuar” você inicia o processo de abertura de Conta Individual de Corretora na ${FinTronConfig.selfDirected.name} e concorda com nossos [Termos de Uso](${FinTronConfig.termsAndConditionsUrl}) e [Política de Privacidade](${FinTronConfig.privacyPolicyUrl}).`,
    checklist: [
      { name: 'Abertura de conta grátis', bold: false },
      { name: 'Investimentos a partir de US$100', bold: false },
      { name: '100% Online', bold: false },
    ],
    bodyText: '',
    footer: '',
  },
  onboardingStocks: {
    firstSurvey: {
      title: 'Vamos começar com o básico.',
      subtitle: 'Para abrir sua conta, responda só mais algumas perguntas regulatórias.',
      experienceLevelQuestion: 'Qual é o seu nível de experiência com investimentos?',
      experienceLevelAnswers: {
        none: 'Nenhum',
        limited: 'Limitado',
        good: 'Bom',
        extensive: 'Extenso',
      },
      investmentObjectiveQuestion: 'Qual é o seu objetivo de investimento?',
      investmentObjectiveAnswers: {
        capitalpreservation: 'Preservação de Capital',
        income: 'Renda',
        growth: 'Crescimento',
        speculation: 'Especulação',
        other: 'Outro',
      },
      investmentObjectiveInfoTitle: 'Objetivos de investimento',
      investmentObjectiveInfoDescription:
        '**Preservação de capital**: Tem como objetivo viabilizar o menor risco possível para o seu dinheiro.\n\n**Renda**: Tem como objetivo investir em títulos que geram dividendos acima da média e valorização do capital a longo prazo. (Dividendos são pagamentos trimestrais de lucros aos acionistas.)\n\n**Crescimento**: Tem como objetivo investir em títulos que tem valorização de capital a curto prazo (o preço das ações sobe em um curto período de tempo). Este objetivo de investimento é geralmente mais arriscado do que os objetivos de preservação e renda.\n\n**Especulação**: Esta objetivo se refere ao ato de comprar um ativo que tem um risco substancial de perder valor, mas também carrega a expectativa de aumentar significativamente de valor. Este é, de longe, o objetivo mais arriscado.',
      investmentHorizonQuestion: 'Qual é o seu horizonte de investimento?',
      investmentHorizonAnswers: {
        short: 'Curto',
        average: 'Médio',
        longest: 'Longo',
      },
      investmentHorizonInfoTitle: 'Horizonte de investimento',
      investmentHorizonInfoDescription:
        '**Curto prazo**: menos de 2 anos.\n\n**Médio prazo**: entre 2 e 8 anos.\n\n**Longo prazo**: mais de 8 anos.',
      liquidityNeedsQuestion: 'Quão importante é ter dinheiro em mãos?',
      liquidityNeedsAnswers: {
        notimportant: 'Não é importante',
        veryimportant: 'Muito Importante',
        somewhatimportant: 'Relativamente importante',
      },
      liquidityNeedsInfoTitle: 'Necessidade de Liquidez',
      liquidityNeedsInfoDescription:
        'A capacidade de converter rápida e facilmente toda ou parte dos ativos da conta para dinheiro sem sofrer perdas significativas de valor.',
    },
    secondSurvey: {
      title: 'Questões Pessoais',
      maritalStatusQuestion: 'Você é casado?',
      maritalStatusAnswers: {
        married: 'Casado',
        single: 'Solteiro',
        divorced: 'Divorciado',
        widowed: 'Viúvo',
      },
      employmentStatusQuestion: 'Qual é o seu status de emprego atual?',
      employmentStatusAnswers: {
        employed: 'Empregado',
        unemployed: 'Desempregado',
        retired: 'Aposentado',
        student: 'Estudante',
        selfemployed: 'Autônomo',
      },
      employerNameTitle: 'Qual é o nome do seu empregador?',
      employerName: 'Nome do empregador',
      positionTitle: 'Qual é a sua posição?',
      position: 'Sua posição?',
      annualIncomeRangeQuestion: 'Qual é o seu salário anual?*',
      annualIncomeRangeFirstAnswer: 'Menor ou igual a US$25,000',
      annualIncomeRangeSecondAnswer: 'US$25,001-US$50,000',
      annualIncomeRangeThirdAnswer: 'US$50,001-US$100,000',
      annualIncomeRangeFourthAnswer: 'US$100,001-US$200,000',
      annualIncomeRangeFifthAnswer: 'US$200,001-US$300,000',
      annualIncomeRangeSixthAnswer: 'US$300,001-US$500,000',
      annualIncomeRangeSeventhAnswer: 'US$500,001-US$1,200,000',
      annualIncomeRangeEighthAnswer: 'US$1,200,001-US$9,999,999',
      annualIncomeRangeInfoTitle: 'O que é renda anual',
      netWorthRangeQuestion: 'Qual é o seu patrimônio líquido?',
      netWorthRangeFirstAnswer: 'Menos De US$50,000',
      netWorthRangeSecondAnswer: 'US$50,001-US$100,000',
      netWorthRangeThirdAnswer: 'US$100,001-US$200,000',
      netWorthRangeFourthAnswer: 'US$200,001-US$500,000',
      netWorthRangeFifthAnswer: 'US$500,001-US$1,000,000',
      netWorthRangeSixthAnswer: 'US$1,000,001-US$5,000,000',
      netWorthRangeSeventhAnswer: 'US$5,000,001-US$9,999,999',
      netWorthRangeEighthAnswer: 'Menor ou igual a US$25,000',
      netWorthRangeNinthAnswer: 'US$25,001-US$50,000',
      netWorthRangeTenthAnswer: 'US$200,001-US$300,000',
      netWorthRangeEleventhAnswer: 'US$300,001-US$500,000',
      netWorthRangeInfoDescription:
        'Patrimônio líquido é a quantidade de dinheiro que você tem prontamente disponível para gastar ou investir, excluindo ativos que não podem ser rapidamente convertidos em dinheiro sem perder valor. Inclui itens como dinheiro, contas poupança, ações e títulos - ativos que podem ser facilmente vendidos ou acessados.\n\nImportante, possuir uma casa não é considerado parte do seu patrimônio líquido. Isso porque uma casa não pode ser vendida rapidamente sem esforço significativo. Mesmo que sua casa possa ter valor, esse valor não está imediatamente disponível para emergências ou fins de investimento.',
    },
  },
  commonRiskTolerance: {
    title: 'Escolha sua tolerância ao risco.',
    change: 'Escolha sua tolerância ao risco.',
    riskTolerance: 'Tolerância ao risco',
    subtitleStocks:
      'Essa etapa é necessária para entendermos seu objetivo de investimento e definir adequadamente seu perfil de investidor.',
    subtitleGoals:
      'Os três possíveis perfis de risco determinam a quantidade máxima de exposição a ações em sua carteira a qualquer momento. Alterar esta configuração mudará a quantidade máxima de ações permitida, conforme descrito abaixo. Geralmente, escolher um perfil agressivo resultará em uma alocação de ativos com maior exposição a ações para atender à maior probabilidade de atingir seu objetivo. Isso pode potencialmente criar níveis mais altos de risco e recompensa.',
    stocksData: {
      [V1UserRiskTolerance.Low]:
        'Investidores com tolerância ao risco conservadora podem priorizar a segurança do principal do investimento sobre o potencial de retornos mais altos.',
      [V1UserRiskTolerance.Medium]:
        'Investidores moderados se sentem confortáveis com flutuações moderadas do mercado e preferem investimentos com nível de risco moderado.',
      [V1UserRiskTolerance.High]:
        'Investidores arrojados priorizam o potencial de retornos mais altos sobre a segurança do capital investido.',
    },
    roboData: {
      [V1UserRiskTolerance.Low]: `Um perfil de risco conservador permite exposição a ações de até ${
        (FinTronConfig.robo.goals?.lowRiskPercentage ?? 0) * 100
      }%.`,
      [V1UserRiskTolerance.Medium]: `Um perfil de risco moderado permite exposição a ações de até ${
        (FinTronConfig.robo.goals?.mediumRiskPercentage ?? 0) * 100
      }%.`,
      [V1UserRiskTolerance.High]: `Um perfil de risco arrojado permite exposição a ações de até ${
        (FinTronConfig.robo?.goals?.highRiskPercentage ?? 0) * 100
      }%.`,
    },
    blurb: {
      title: 'Perfil de Risco',
      [V1UserRiskTolerance.Low]:
        'Um **investidor conservador** valoriza mais a estabilidade do que retornos mais altos e prefere menos risco e potencial de queda.',
      [V1UserRiskTolerance.Medium]:
        'Um **investidor moderado** valoriza uma abordagem equilibrada para o crescimento do capital com preservação do capital e aceita algum risco.',
      [V1UserRiskTolerance.High]:
        'Um **investidor agressivo** valoriza mais retornos mais altos do que estabilidade, e está disposto a aceitar maior risco e maior potencial de queda.',
      footer:
        'Você receberá recomendações de investimento que se alinham com seu perfil de risco escolhido para progredir em direção ao valor total de seu objetivo dentro do prazo desejado.',
    },
    surveyTitle: 'Qual é o seu perfil de risco?',
  },
  onboardingProfileConfirmation: {
    title: 'Seu perfil está salvo! Agora, vamos começar a sua jornada de investimentos.',
    subtitle:
      'A seguir, você selecionará o tipo de conta que deseja criar. A abertura de uma conta leva menos de 10 minutos.',
    confirmButton: 'Continuar',
  },
  onboardingAgreement: {
    title: 'Pronto! Agora só falta um passo para a abertura da sua conta.',
    subtitle: 'Leia os documentos abaixo antes de enviar a sua solicitação de abertura de conta.',
    stocksFirstButton: 'Ver Customer Agreement',
    stocksSecondButton: 'Ver Customer Relationship Summary',
    stocksCheckboxes: [
      {
        questionKey: 'apex_paid_security_lending',
        question: `Inscreva-se no programa de Fully Paid Securities Lending da Apex (opcional). Veja o [Apex Master Securities Lending Agreement](https://apexfintechsolutions.com/wp-content/uploads/2023/10/FPSL-Master-Securities-Lending-Agreement_June-2023.pdf) e [Securities Lending Disclosures]((https://apexfintechsolutions.com/wp-content/uploads/2023/10/FPSL-Risk-Disclosure_April-2023.pdf)).`,
      },
      {
        questionKey: 'apex_cash_sweep_service',
        question:
          'Inscreva-se no programa de Cash Sweep da Apex (opcional). Veja os termos para mais informações [Apex Cash Sweep Program Terms](https://apexfintechsolutions.com/wp-content/uploads/2022/05/Apex-FDIC-Sweep-Program-Terms-and-Conditions-1.pdf).',
      },
    ],
    roboCheckboxes: [
      {
        questionKey: '',
        question: '',
      },
    ],
  },
  onboardingYouAreAllSet: {
    title: `Você está pronto!`,
    roboSubtitle: `Parabéns, você enviou sua inscrição para a conta Robo ${FinTronConfig.robo.name}.\n\nAssim que sua conta for aprovada, seu investimento inicial será transferido automaticamente para seu objetivo e faremos os investimentos.`,
    stockSubtitle: `Parabéns, você enviou sua inscrição para a conta de corretagem ${FinTronConfig.selfDirected.name}.`,
    checkListTitle: 'Agora que você completou sua inscrição, terá acesso ao seguinte!',
    goalCreatedTitle: 'Objetivo Criado',
    goalCreatedDescription:
      'Parabéns! Você criou com sucesso um novo objetivo. Veja os detalhes e o histórico do seu objetivo nesta página.',
    stocksChecklist: [
      { name: 'Negociação fracionária sem comissão' },
      { name: 'Investimentos Repetidos Automatizados' },
    ],
  },
  forgotPassword: {
    title: 'Esqueceu a Senha',
    changePassword: 'Atualizar senha',
    description:
      'Insira o e-mail ou número de celular associado a sua conta e enviaremos um código de redefinição de senha.',
    label: 'Email ou Número de celular',
  },
  onboardingApexExtendedQuestions: {
    title: 'Informações Pessoais',
    footer: '',
  },
  resetPassword: {
    title: 'Nova Senha',
    description: 'Por favor, redefina sua senha abaixo.',
    codeLabel: 'Código de Senha',
    newPasswordLabel: 'Digite a Nova Senha',
    confirmPasswordLabel: 'Confirme a Nova Senha',
    helpText:
      'A senha deve conter 8 caracteres. Recomendamos fortemente criar uma senha com uma combinação de letras, números e caracteres especiais (! @ # $...).',
    codeHelpText:
      'Enviamos um código para você por e-mail. Por favor, insira-o acima para continuar com a redefinição da sua senha.',
    passwordSuccessSummary: 'Sua senha foi redefinida.',
  },

  settingsVerification: {
    title: 'Verificação de Perfil',
    subtitle: 'Por favor, verifique seu e-mail e número de telefone.',
  },
  riskTolerance: {
    [V1UserRiskTolerance.High]: 'Arrojado',
    [V1UserRiskTolerance.Low]: 'Conservador',
    [V1UserRiskTolerance.Medium]: 'Moderado',
  },
  errors,
  settingsApexClearingPrograms: {
    title: 'Programas de Compensação Apex',
    fpslProgram: 'Programa de Empréstimo de Títulos Totalmente Pagos (FPSL)',
    fpslDesc: `Você deseja se inscrever no Programa de Empréstimo de Títulos Totalmente Pagos da Apex? Veja o [Acordo Mestre de Empréstimo de Títulos da Apex](${FinTronConfig.apexUrls.apexMasterSecuritiesLendingAgreement}) e [Divulgações de Empréstimo de Títulos](${FinTronConfig.apexUrls.securitiesLendingDisclosure}).`,
    cashSweepProgram: 'Programa de Varredura de Caixa',
    cashSweepDesc: `Você deseja se inscrever no programa de Varredura de Caixa da Apex? Veja os [Termos do Programa de Varredura de Caixa da Apex](${FinTronConfig.apexUrls.apexCashSweep}) para mais informações.`,
    contactSupport: 'Contatar Suporte',
    contactSupportDesc: `Para optar pelo programa FPSL, por favor, contate [${FinTronConfig.selfDirected.supportEmail}](${FinTronConfig.selfDirected.supportEmail}) declarando isso.`,
    pendingChanges: 'Alterações pendentes',
    pendingChangesDesc:
      '*Novos valores foram enviados, mas ainda não confirmados, você ainda pode continuar a enviar alterações adicionais.',
    optOut: 'Cancelar participação',
    optOutDesc: `Você está cancelando a participação no programa FPSL. Será necessário entrar em contato com o suporte para optar por participar novamente.\n\nTem certeza de que deseja cancelar a participação?`,
  },
  support: {
    account: 'Minha Conta Investimentos',
  },
  accounts: {
    active: {
      accountHistory: 'Histórico da Conta',
      trustedContact: 'Contato de Confiança',
      documents: 'Documentos',
      investmentQuestions: 'Questionário do Perfil do Investidor',
      apexClearingPrograms: 'APEX Clearing Programs',
      closeAccount: 'Encerrar Conta',
    },
    settings: {
      title: 'Configurações da Conta',
      profileSettings: 'Configurações de Perfil',
      profileInformation: 'Informação de Perfil',
      support: 'Suporte',
      security: 'Configurações de Segurança',
      bankLink: 'Conta Vinculada',
      logout: 'Sair',
    },
    open: 'Abrir uma conta {{ account }}',
    pending: 'Conta {{ account }} pendente',
    accountPendingTitle: 'Status da conta em revisão',
    accountPendingDescription: `Estamos comprometidos em manter os mais altos padrões de segurança e conformidade. Enquanto monitoramos o status da sua conta, você pode ter acesso limitado a alguns recursos.\n\nPor favor, entre em contato com o suporte em [${FinTronConfig.supportEmail}](mailto:${FinTronConfig.supportEmail}) para mais informações.`,
    accountCanOpenTitle: 'Abrir Uma Conta',
    accountCanOpenDescription: `Vemos que você criou um perfil. Você pode criar uma conta para acessar os recursos de corretagem do ${FinTronConfig.parentName}.\n\nSe tiver algum problema, entre em contato com [${FinTronConfig.supportEmail}](mailto:${FinTronConfig.supportEmail}).`,
    accountCanOpenButton: 'Abrir Uma Conta',
    accountClosedTitle: 'Sua conta foi fechada',
    accountClosedDescription: `Sua conta foi fechada. Você não pode mais realizar ações associadas à sua conta, mas pode visualizar seus documentos, como extratos mensais e formulários fiscais.\n\nPara reabrir esta conta, entre em contato com [${FinTronConfig.supportEmail}](mailto:${FinTronConfig.supportEmail}).`,
    stocks: {
      providedBy: `Por ${FinTronConfig.selfDirected.name}`,
      pendingTitle: `Aplicação de conta ${FinTronConfig.selfDirected.name} pendente`,
      pendingDescription: `Sua aplicação foi enviada e está sendo processada atualmente. Por favor, verifique seu e-mail para atualizações adicionais.\n\nEntre em contato com [${FinTronConfig.selfDirected.supportEmail}](mailto:${FinTronConfig.selfDirected.supportEmail}) para mais informações ou se tiver perguntas adicionais sobre sua aplicação.`,
    },
    robo: {
      providedBy: `Por ${FinTronConfig.robo.name}`,
      pendingTitle: `Aplicação ${FinTronConfig.robo.name} pendente`,
      pendingDescription: `Sua aplicação foi enviada e está sendo processada atualmente. Por favor, verifique seu e-mail para atualizações adicionais.\n\nEntre em contato com [${FinTronConfig.robo.supportEmail}](mailto:${FinTronConfig.robo.supportEmail}) para mais informações ou se tiver perguntas adicionais sobre sua aplicação.`,
      closeAccountTitle: 'Tem certeza que deseja encerrar a sua conta?',
      closeAccountDescription:
        'Para encerrar sua conta de investimentos ({{ accountNumber }}), você deve primeiro certificar-se de:\n\u00A0\u00A01. Cancelar quaisquer transferências automatizadas.\n\u00A0\u00A02. Caso você possua títulos, vender todos os títulos; então,\n\u00A0\u00A03. Retirar todos os fundos restantes da conta.\n\nQuando estas ações estiverem concluídas, você poderá encerrar esta conta.',
      closeAccountExtraDescription: '',
      confirm: 'Sim, Encerrar conta',
    },
  },
  trustedContact: {
    title: 'Contato de confiança.',
    subtitle:
      'Ao escolher fornecer informações sobre uma contato de confiançal, você nos autoriza a contatar o contato listado abaixo e divulgar informações sobre sua conta a essa pessoa nas seguintes circunstâncias: para abordar possível exploração financeira, para confirmar as especificidades de suas informações de contato atuais, estado de saúde ou a identidade de qualquer tutor legal, executor, administrador ou detentor de uma procuração, ou conforme permitido pela Regra 2165 da FINRA (Exploração Financeira de Adultos Especificados).',
    question: 'Você deseja adicionar um contato de confiança a sua conta?',
    learnMore: 'Saiba mais sobre contatos de confiança',
    learnMoreTitle: 'Contato de confiança',
    learnMoreDescription:
      'Um contato de confiança pode atuar como uma camada extra de segurança para sua conta. Essa pessoa será alguém com quem podemos entrar em contato em caso de preocupações sobre seu bem-estar ou segurança da conta. Embora seja totalmente opcional, ter um Contato de Confiança pode nos ajudar a agir rapidamente em situações de emergência e proteger seus interesses.',
    legal: '',
    confirmEmailDescription: 'Por favor, confirme o e-mail do seu contato de confiança.',
    yes: 'Sim',
    no: 'Não',
  },
  settingsAddress: {
    title: 'Endereço Residencial',
    footer: '',
  },
  profileInformation: {
    updateInfo: {
      title: 'Atualizar informações',
      description: 'A atualização das informações será aplicada a todas as contas abertas.',
      confirm: 'Sim, atualizar',
    },
  },
  existingGoal: {
    allocation: 'Alocação',
    projection: 'Projeção',
    progress: 'Progresso',
    progressBsmTitle: 'Progresso do objetivo',
    progressBsmDescription:
      'O progresso do objetivo calcula o valor atual do seu objetivo como uma porcentagem do valor alvo. Não deve ser confundido com a probabilidade do objetivo, que estima a possibilidade de atingir o valor total do seu objetivo dentro do prazo desejado. Veja a probabilidade do objetivo para mais informações.',
    editValues: 'Editar valores',
    lessons: 'Lições',
    accountHistory: 'Histórico da conta',
    footer: `Serviços de consultoria são fornecidos por ${FinTronConfig.robo.name}, um consultor de investimentos registrado na SEC. Serviços de corretagem oferecidos a clientes autodirecionados por ${FinTronConfig.selfDirected.name}. Dinheiro e títulos são custodiados por ${FinTronConfig.clearingFirm}.`,
  },
  roboEtf: {
    stockDetail: {
      seeMore: 'Ver mais',
      seeLess: 'Ver menos',
      marketPrice: 'Preço de mercado:',
      day30SecYield: 'Rendimento de 30 dias SEC:',
      navPrice: 'Preço NAV:',
      expenseRatio: 'Taxa de despesa:',
      yourPosition: 'Sua posição',
      marketValue: 'Valor de mercado:',
      shares: 'Ações:',
      secBsmTitle: 'Rendimento SEC',
      secBsmMessage:
        'O rendimento SEC de um fundo não monetário é baseado em uma fórmula desenvolvida pela SEC. O método calcula a renda anualizada hipotética de um fundo como uma porcentagem de seus ativos.\n\nA renda de um título, para fins deste cálculo, é baseada no rendimento atual de mercado até o vencimento (para títulos) ou rendimento de dividendos projetado (para ações) das participações do fundo ao longo de um período de 30 dias. Esta renda hipotética diferirá (às vezes, significativamente) da experiência real do fundo. Como resultado, as distribuições de renda do fundo podem ser maiores ou menores do que o implícito pelo rendimento SEC.\n\nO rendimento SEC para um fundo do mercado monetário é calculado anualizando suas distribuições de renda diária para os 7 dias anteriores.',
      expenseBsmTitle: 'Índice de despesas',
      expenseBsmMessage: `Expresso como uma porcentagem, os índices de despesas são taxas cobradas dos investidores para cobrir os custos operacionais de um fundo ou ETF. Eles são deduzidos das distribuições de dividendos e ganhos de capital, não do principal.`,
      navBsmTitle: 'Valor líquido dos ativos "NAV"',
      navBsmMessage: `NAV é o valor de cada ação em um fundo de investimento, calculado com base nos ativos e passivos do fundo. Ajuda os investidores a entender o valor de seu investimento. No entanto, o valor de mercado das ações do fundo pode diferir do NAV devido a fatores como oferta e demanda.`,
    },
  },
  allocation: {
    subtitle: 'Investimento inicial',
    subtitle2: 'Valor da conta',
    initialAllocation: 'Alocação inicial',
    initialAllocationDesc:
      'Esta é sua alocação proposta do seu investimento inicial. Nosso mecanismo de aconselhamento usa as entradas que você fornece para projetar um portfólio para ajudá-lo a alcançar seu objetivo.',
    title: 'Alocação',
    allocationDesc: `A alocação de ativos recomendada é a mais apropriada com base nas informações fornecidas por você. Sua alocação de ativos será ajustada periodicamente, com base em movimentos do mercado, reavaliações anuais ou quando você fornecer informações atualizadas, para melhorar a probabilidade de atingir seu objetivo declarado.\n\nUma vez que você tenha atingido seu objetivo dentro do prazo especificado, seus ativos serão investidos na alocação de ativos mais conservadora até que você forneça direção adicional.`,
  },
  connectBank: {
    title: 'Confirme a sua conta.',
    subtitle: `Confirme os detalhes da sua conta bancária do ${FinTronConfig.parentName}.`,
    fundingSource: 'Minha conta',
    addBankAccount: 'Adicionar conta bancária',
    checkbox: '',
  },
  recurringRuleSlider: {
    recurringRules: 'REGRAS RECORRENTES',
    createRepeatInvestment: 'Criar um investimento repetido',
    automateInvestments: 'Automatize seus investimentos em milhares de ações e ETFs',
  },
  commonKycConfirmation: {
    title: 'Por favor, confirme as informações da conta.',
    subtitle: `Abaixo estão os detalhes que você forneceu para sua conta de investimento existente custodiada na ${FinTronConfig.clearingFirm}. Para facilitar para você, reutilizamos as informações que você forneceu para começar com sua aplicação de conta de investimento. Você sempre pode atualizar as informações da sua conta em Configurações. Saiba mais sobre como protegemos seus dados pessoais lendo a [Política de Privacidade do FinTron Invest](${FinTronConfig.selfDirected.privacyPolicyUrl}).`,
    investingTitle: 'Investindo',
    investmentObjectives: 'Objetivo de investimento',
    personalTitle: 'Pessoal',
    employmentStatus: 'Status de emprego',
    netWorth: 'Patrimônio líquido',
    annualIncome: 'Renda anual',
    exposureTitle: 'Exposição',
    isControlPerson: 'Pessoa de controle',
    isPoliticallyExposed: 'Politicamente exposto',
    isAffiliated: 'Membro da FINRA/Bolsa de valores',
    isSecuritiesProfessional: 'Profissional de valores mobiliários',
  },
  transfer: {
    reachedDailyLimitTitle: 'Cannot submit transfer',
    reachedDailyLimitDescription:
      'Your transfer has not been submitted because you have reached your ACH 24 hour velocity limit. This limit exists for your safety. Your remaining limit is {{ remainingLimit }}. If you would like to raise (or lower) your limit, please contact support.',
    cancelUpcoming: {
      title: 'Hold up!',
      body: 'You are canceling your next scheduled transfer! Are you sure you want to break your streak of awesome savings?',
    },
    cancelAll: {
      title: 'Whoa!',
      body: 'You are canceling your automated transfer schedule! Are you sure you want to break your streak of awesome savings?',
    },
    assetTypeSecurity: 'Recurring Investment',
    assetTypeFiat: 'Recurring Deposit',
    error: 'Your request to cancel your repeat transfer was not approved, please try again.',
  },
  securities: {
    searchPlaceholder: 'Search by name or symbol',
    totalSectors: 'Setores',
    top10Holdings: 'Top 10\nAtivos',
  },
  twoFactorAuth: {
    title: 'Autenticação de dois fatores',
    titleEmail: 'Confirmação de e-mail',
    titlePhone: 'Confirmar o celular',
    security: 'Segurança',
    password: 'Atualizar senha',
    method: 'Two-factor authentication method',
    sendText: 'Send text',
    sendEmail: 'Send email',
    descLogin:
      'How would you like to receive your two-factor authentication code for a secure login?',
    descToggle:
      'How would you like to receive your two-factor authentication code to toggle verification?',
    descCloseStockAccount:
      'How would you like to receive your two-factor authentication code to close your Stocks account?',
    descCloseRoboAccount:
      'How would you like to receive your two-factor authentication code to close your Robo account?',
    descVerification: {
      email: 'We will send you an email with the two-factor authentication code.',
      text: 'We will send you a text with the two-factor authentication code.',
    },
    enterCode: 'Insira o código',
    submit: 'Enviar',
    loginTitle: 'Login',
    variant: {
      login:
        'We just sent you a one-time two-factor authentication code to your mobile phone number. Enter the code below.',
      toggle: 'We just sent you a one-time two-factor authentication code. Enter the code below.',
      default:
        'Acabamos de enviar um código de autenticação. Digite o código abaixo para confirmar seu ',
      email: 'código de verificação.',
      phoneNumber: 'número de telefone celular.',
    },
    helpText: `Se não encontrar, verifique sua pasta de spam/lixo eletrônico. Caso precise de assistência, entre em contato com nossa equipe: ${FinTronConfig.supportEmail}.`,
    didntReceiveCode: 'Não recebeu o código?',
    resendCode: 'Clique para reenviar',
    mfaSuccessDescription: 'Two-factor authentication setting has been changed successfully.',
    loginDescription:
      'Enviaremos um e-mail ou SMS com o código de autenticação de dois fatores para cada login.',
  },
  shortcutSlider: {
    header: 'Comece aqui',
    description: 'Buscar Ativos, Transferências e Mais',
    buttonText: 'Menu',
    shortcuts: {
      search: 'Buscar Ativos',
      withdraw: 'Resgate',
      history: 'Histórico de Atividades',
      deposit: 'Depósito',
      investments: 'Automate Investments',
      acat: 'ACAT',
    },
  },
  login: {
    loggedOut: 'Logged out',
    loggedOutBody: 'You have been logged out due to inactivity',
    accountDisabledTitle: 'Account disabled!',
    accountDisabledBody: `Your account has been disabled. \nPlease contact [${FinTronConfig.supportEmail}](${FinTronConfig.contactUrl}) for assistance!`,
    updateAvailableTitle: 'App update available!',
    updateAvailableBody: {
      main:
        "We're very happy to announce that the latest version of the app is available in the " +
        'App Store! Click the button below to download the app, and get access to the latest ' +
        `feature updates and enhancements.`,
      devOnly: 'Dev only note: this dialog is not skippable in the production app.',
    },
    noConnection: 'No internet connection',
    noConnectionBody:
      "It looks like you're having issues with your internet connection! Check your Wifi or cell service and try logging in again.",
    loseSomething: 'Lose something?',
    loseSomethingBody: "We've got your back.",
  },
  forceUpdate: {
    updateAvailableTitle: 'App update available!',
    updateAvailableBody: {
      title:
        "We're very happy to announce that the latest version of the app ({{remote}}) is available in the App Store! Click the button below to download the app, and get access to the latest feature updates and enhancements.",
      remote: 'Current Version:',
      devOnlyNote: 'Dev only note: this dialog is not skippable in the production app.',
    },
    updateButton: 'Update',
    buttonDevOnly: 'DEV ONLY: SKIP',
  },
  retirementBeneficiary: {
    title: 'Want to add a beneficiary to your account?',
    learnMore: 'Learn more',
    addBeneficiaryButton: '+ Add a beneficiary',
    beneficiariesHeaderTitle: 'Primary beneficiaries',
    beneficiariesHeaderSubtile: 'At leamst one Primary Beneficiary is required.',
  },
  allAccountsScreen: {
    welcomeString: `Hi {{username}}`,
    myAccountsTitle: 'My accounts',
    openStocksAccount: 'Open a stocks account',
    createAGoalTitle: `Create a {{roboName}} goal`,
    openARetirementAccountTitle: 'Open a retirement account',
    goalsTitle: 'Goals',
    cashCalendarTitle: 'Cash calendar',
  },
  initialInvestments: {
    initialInvestmentTitle: 'Initial Investment',
    initialInvestmentDescription: 'How much do you plan to invest today towards your goal?',
    targetAmountTitle: 'Target Amount',
    targetAmountDescription:
      'Set an amount that you believe will be achievable. Setting an amount that is too high may lower your probability of achieving your goal.',
    investmentAmountTitle: 'Additional Investments',
    investmentAmountDescription:
      'Increasing your additional investments can greatly improve the probability of achieving your savings goal.',
    recurringInfusionTitle: 'Automated investments',
    recurringInfusionDescription:
      'Increasing your automated investments can greatly improve the probability of achieving your savings goal.',
  },
  miscScreens: {
    common: {
      companyName: 'Nome da empresa',
      ceo: 'CEO',
      sector: 'Setor',
    },
    contactUs: {
      title: 'Fale Conosco',
      submit: 'Enviar',
      successTitle: 'Formulário enviado',
      successTxt: 'Obrigado por enviar o formulário.',
    },
    goals: {
      edit: {
        title: 'Edit your goal details',
        investmentFrequencyTitle: 'Automated Investment\nFrequency',
        investmentFrequencyDescription:
          'Set up automated investments and choose your investment frequency.',
        pauseInvestmentTitle: 'Pause recurring investments',
        pauseInvestmentDescription:
          'Are you sure you want to pause your automated investments?\n\nPausing recurring investments for long periods of time may negatively impact the probability of achieving the full amount of your goal within the desired timeframe.',
        pauseInvestmentBottomText:
          'The potential reallocation triggered by this change may have tax implications.\n\nResume your investments at any time.',
        yesPause: 'Yes, Pause Investments',
        yesResume: 'Yes, Resume Investments',
        noCancel: 'No, cancel',
        resumeInvestmentTitle: 'Resume recurring investments',
        resumeInvestmentDescription:
          'Are you sure you want to resume your recurring investments?\n\nPausing recurring investments for long periods of time may negatively impact the probability of achieving the full amount of your goal within the desired timeframe.',
        resumeInvestmentBottomText: `The potential reallocation triggered by this change may have tax implications.\n\nBy resuming your investments, the next recurring investment will occur on {{date}}.`,
        targetDate: 'Target date',
      },
      close: {
        closeAccountTitle: 'Your goal has been deactivated',
        closeAccountDescription:
          'This goal will no longer appear on your My Accounts page. You can always create a new goal when you want.',
        home: 'Home',
      },
    },
    stocks: {
      accountHistory: 'Account history',
      documents: 'Documents',
      investmentQuestions: 'investment questions',
      closeAccountTitle: 'Your account has been closed',
      closeAccountDescription: `If you ever want to re-open this account, contact ${FinTronConfig.selfDirected.supportEmail}.`,
      home: 'Home',
    },
    myAccounts: {
      title:
        'Holdings is just another word for things that you own. Under this section you will see:\n',
      checklist: [
        { name: 'Securities that you own' },
        { name: 'The cash that you have available to buy securities with (Available cash)' },
        {
          name: 'The cash that is being processed from selling a security or pending deposits (Pending cash)',
        },
      ],
      footer:
        'After the sale of a security, it will take 2 days for the cash to settle in your account and become available for trading or withdrawal.',
      viewData: 'Ver Dados',
      footerLegalText: `Os serviços de corretagem para ativos registrados são oferecidos a clientes self-directed pela ${FinTronConfig.selfDirected.name}. O dinheiro e os ativos são custodiados pela ${FinTronConfig.clearingFirm}.`,
      marketClosedTitle: `Stock Market Closed {{date}}`,
      marketClosedDescription: `The stock market is taking a day off to rest. If you place any trades, ${FinTronConfig.parentName} will submit them the next day the markets are trading. Please note, the stock prices displayed are from the last day the markets were open.`,
      repeatInvestments: 'Repeat investments',
    },
    plaid: {
      linkAccountButton: 'Select a bank account',
      createLinkErrorBody:
        'We are really sorry for the inconvenience, but our secure bank linking partner Plaid has failed to initiate. Please exit the page and try again, we will get you on your way in no time!',
      createAccountErrorBody:
        'Our third party was a little sleepy and timed out when linking your bank account. ' +
        'Please try to link your bank account again. We sorry for the inconvenience!',
    },
    bankLink: {
      removeBankTitle: 'Linked actions',
      removeBankStep3Title: 'Account unlinked',
      removeBankCancelAllAndContinue: 'Cancel all & continue',
      removeBankSuccessTitle: 'Success!',
      removeBankSuccessMessage: 'Your bank account has been unlinked successfully.',
      removeBodyMessage:
        'You have the following automated functions linked to this bank account. In order to remove this bank account, you must either (1) update these automated functions from within the app to pull funds from a different bank account or (2) you must cancel the functions altogether. Please note, **we will cancel any pending transfers associated to this bank account** on your behalf.',
    },
    tradeStopLoss: {
      tradeStopLossEnterText: 'Insira o preço de Stop Loss:',
      tradeStopLossTooLowError:
        'It looks like you have entered a stop price that is over the current market price. In order to place a stop loss  order, please enter a stop price that is lower than the displayed market price.',
      tradeStopLossOrderType: 'Stop loss',
      tradeStopLossSummaryLimitPriceLabel: 'Stop price',
      tradeStopLossSummaryLimitPriceTooHigh:
        'It looks like you have entered a stop loss that is over the current market price. In order to place a stop loss order, please enter a stop loss that is lower than the displayed market price.',
    },
    tradeOptions: {
      tradeOptionsBuy: 'Comprar',
      tradeOptionsBuySubtitle: 'Adicione aos seus ativos.',
      tradeOptionsSell: 'Vender',
      tradeOptionsSellSubtitle: 'Efetue a venda total ou parcial deste ativo.',
      tradeOptionsRepeatInvestment: 'Investimento recorrente',
      tradeOptionsRepeatInvestmentSubtitle: 'Agende investimentos recorrentes.',
      tradeOptionsEditRepeatInvestment: 'Editar investimento recorrente',
      tradeOptionsEditRepeatInvestmentSubtitle: 'Edite investimento recorrente.',
      tradeOptionsRepeatInvestmentActive: 'Ativo',
      durationOptionsGoodForOneDay: 'Válido por 1 dia',
      durationOptionsGoodFor: 'Válido por',
      durationOptionsPopup:
        "This determines how long your order will last for if it doesn't get executed right away.",
      tradeMarketBuySellLessThanADollarTitle: 'Atenção',
      tradeMarketBuySellLessThanADollarDesc: `Após esta ordem de venda, você ficaria com **{{amount}}** em **{{symbol}}**, que é menos do que o mínimo da ordem de venda de $1.\n\nVocê gostaria de incluir os **{{amount}}** restantes da **{{symbol}}** nesta ordem?`,
      tradeMarketBuySellLessThanADollarSellAllButton: 'Sim',
      tradeMarketBuySellLessThanADollarKeepButton: `Manter {{amount}} de {{symbol}}`,
      stopLossPreviewInfo: `This value is what your current holdings (minus any pending sell orders) would be worth at the stop price you specified.\n\nIn this instance, your [{{sharesToBeSold}}] shares in [{{securitySymbol}}] would be worth [{{availableAtStopLossPrice}}] if [{{securitySymbol}}] traded at [{{stopLoss}}], your specified stop price.`,
      stopLossPreviewSharesToBeSold: 'Ações a serem vendidas:',
      stopLossPreviewAvailableAtStopLossPrice: 'Disponível no preço de Stop Loss:',
      tradeLimitBuyOrderType: 'Limit buy',
      tradeLimitBuyOrderLimit: 'Enter limit price',
      tradeLimitBuyOrderLimitDesc: 'Buy when the share price falls below:',
      tradeLimitBuySummaryLimitPriceLabel: 'Limit price',
      tradeLimitBuySummaryLimitPriceTooHigh:
        'It looks like you have entered a limit price that is over the current market price. In order to place a limit order, please enter a limit price that is lower than the displayed market price.',
      tradeOptionsSelector: {
        tradeOptionsBuySubtitle: 'Add to your holdings.',
        tradeOptionsLimitBuySubtitle: 'Buy at the price you set.',
        tradeOptionsSellSubtitle: 'Venda pelo valor atual do mercado.',
        tradeOptionsStopLossSubtitle: 'Venda pelo preço definido por você.',
        tradeOptionsRepeatInvestment: 'Repeat investment',
        tradeOptionsRepeatInvestmentSubtitle: 'Automated scheduled investments.',
      },
    },
    securities: {
      youOwnTitle: 'Você tem:',
      youOwnFooter: 'Ações',
      yourValueTitle: 'Valor:',
      yourValueFooter: 'Hoje',
      portfolioPercentTitle: 'Totalizando:',
      portfolioPercentFooter: 'Do Seu Portfólio',
      costPerShareTitle: 'Custo médio por ação',
      gainLostTitle: 'Seu ganho/perda:',
      dividendRate: 'Taxa de dividendos',
      dividendAmount: 'Montante de dividendos',
      totalDividendsReceived: 'Total de dividendos recebidos:',
      previousDividendReceived: 'Dividendos recebidos anteriormente:',
      securityNotificationRiskQuestion:
        'Estatisticamente falando, 99% do tempo, um conjunto de dados (ou, no nosso caso, o desempenho de um ativo) ficará dentro de 3 desvios padrão da média dos retornos desse conjunto de dados. O limite superior do Intervalo Histórico de 10 Anos que você vê aqui é calculado somando 3 desvios padrão ao retorno médio de 10 anos deste ativo. O limite inferior é calculado subtraindo 3 desvios padrão do retorno médio de 10 anos. Assim, o Intervalo Histórico de 10 Anos apresentado aqui exibirá o intervalo de desempenho de um ativo com 99% de precisão (Estatisticamente falando)!',
      securityNotificationRiskQuestion1:
        'A métrica de tolerância ao risco é o resultado de nossa avaliação proprietária de risco. Fornecemos esse Intervalo de Risco para você como um ponto de referência a considerar, baseado nas respostas que você nos deu ao preencher sua Avaliação de Risco. Acreditamos que assumir riscos excessivos leva a decisões de investimento ruins. Em essência, fornecemos este intervalo para que você se avalie antes de cometer erros! Se você assumir muito risco, talvez venda cedo demais quando deveria ter mantido sua posição. Talvez você simplesmente não tenha estabilidade financeira para arriscar muito em ações de alto risco... Em qualquer caso, observe que isso NÃO é uma recomendação, apenas uma camada extra de consideração que fornecemos a você como cortesia (:',
      tradingVolume: 'Volume do trade',
      tenDay: 'Volume médio (10 dias)',
      bidProvidedBy: 'Fonte: ',
      askProvidedBy: 'Fonte: ',
      mutualFund: {
        transactionFee: 'Taxa de operação',
        noFee: 'Sem taxa',
        nav: 'NAV',
        navChangeDoD: 'Mudança de NAV (DoD)',
        ytdReturns: 'Retornos YTD',
        yield30Day: 'Rendimento de 30d SEC',
        fundCategory: 'Categoria do fundo',
        fundInception: 'Inserção do fundo',
        loadType: 'Tipo de carga',
        load: 'Carga',
        minimumToInvest: 'Mínimo investimento',
        minimumSubsequentInvestment: 'Mínimo investimento seguinte',
        turnoverRate: 'Taxa de rotatividade',
        portfolioNetAssets: 'Ativos líquidos no portfólio',
        shareClassNetAssets: 'Ativos líquidos na classe de ações',
        twelveMonthLowHigh: 'Baixa-alta 12m',
        beta: 'Beta',
        rSquared: 'R2',
        sharpeRatio: 'Índice de Sharpe',
        standardDeviation: 'Volatilidade',
        initialInvestment: 'Investimento inicial',
        additionalInvestment: 'Investimento adicional',
        initialIraInvestment: 'Investimento inicial do IRA',
        additionalIraInvestment: 'Investimento adicional do IRA',
        initialGroupRetirementInvestment: 'Investimento inicial para aposentadoria',
        additionalGroupRetirementInvestment: 'Investimento adicional para aposentadoria',
        expenseRatioGross: 'Taxa de despesa (bruta)',
        expenseRatioNet: 'Taxa de despesa (líquida)',
        contractualExpenseWaiver: 'Isenção de despesa contratual (datada)',
        distributionServiceFee: 'Distribuição/taxa de serviço (12b-1)',
      },
    },
    tradeDisplay: {
      tradeOrderBuy: 'Compra',
      tradeOrderSell: 'Venda',
      tradeOrderSubmitButton: 'Enviar',
      tradeOrderContinueButton: 'Continue',
      tradeOrderSummaryTitle: 'Resumo da ordem',
      tradeOrderTypeLabel: 'Tipos de ordem',
      tradeOrderFrequencyLabel: 'Frequency',
      tradeOrderAmountLabel: 'Dollar amount',
      tradeOrderEstimatedSharesLabel: 'Quantidade estimada de ações',
      tradeOrderOrderDuration: 'Duração da ordem',
      tradeOrderEstimatedValue: 'Valor estimado em dólar',
      tradeOrderEnterAmount: 'Insira o valor (maior ou igual a $1):',
      tradeOrderCashAvailable: `{{amount}} Disponível`,
      tradeOrderEstimatedCashHoldings: `Paticipações atuais: {{amount}}`,
      tradeOrderLimitOneDay: 'Válido por 1 dia',
      tradeOrderLimitTillCancelled: 'Válido até o cancelamento',
      tradeOrderBuySellOrderType: `{{side}} a mercado`,
      tradeOrderMarketRepeatSummary: `Your **{{amount}}** recurring **{{frequency}}** order to buy **{{symbol}}** was submitted.`,
      tradeOrderMarketBuySellSummary:
        'Verifique o seu histórico de atividades para detalhes sobre esta operação.',
      tradeOrderMarketLimitBuyStopLossSummary: `Your **{{amount}} {{orderType}}** to {{side}} **{{symbol}}** was submitted. If the price of the security falls below **{{limit}}**, we will submit a market {{side}} order. The order duration is set as **{{duration}}.**`,
      tradeOrderSellAll: 'Vender Todas',
      tradeOrderStopLoss: 'Stop loss',
      tradeOrderStopLimit: 'Stop limit',
      tradeOrderStopPrice: 'Stop price',
      tradeOrderLimitBuy: 'Limit buy',
      tradeOrderLimitPrice: 'Limit price',
      tradeOrderLimitOrder: 'Limit order',
      tradeOrderRepeatInvestment: 'Repeat investment',
      tradeScreenOrderTypesInfoTitle: 'Tipos de ordem',
      tradeScreenOrderStatus: 'Order status',
      tradeScreenOrderTypesInfoBuySide:
        '**Compra a mercado:** Este tipo de ordem é usado para comprar uma ação ao preço de mercado vigente. Geralmente, as ordens a mercado são executadas imediatamente.\n' +
        '\n' +
        '**Compra Limitada:** Neste tipo de ordem, você pode definir um preço máximo pelo qual está disposto a comprar a ação.  Quando o preço da ação atingir o valor estipulado, enviaremos uma ordem de compra automaticamente.',
      tradeScreenOrderTypesInfoSellSide:
        '**Venda a mercado:** Este tipo de ordem é usado para vender uma ação ao preço de mercado vigente. Geralmente, as ordens a mercado são executadas imediatamente.\n' +
        '\n' +
        '**Stop Loss:** Este tipo de ordem é usado para vender ações caso o preço do ativo cair abaixo de um determinado valor. Você define um preço mínimo de venda para a ação e se a ação atingir esse valor, uma ordem de venda será enviada automaticamente.',
      tradeOrderHighlyVolatile: `{{securitySymbol}} is a highly volatile security! In most cases, the higher a security’s volatility, the higher risk of losing some or all of your investment. Please consider your investment objectives and risk tolerance prior to making an investment.`,
      tradeOrderComplex: `{{securitySymbol}} is a contra, levered or complex security. You must have “Good” to “Extensive” investment experience in order to purchase this security. You may change your investment experience by navigating to your investment profile in the settings page.`,
      tradeOrderRepeatInvestmentSummary: `You are submitting a repeat investment, which means you are instructing ${FinTronConfig.selfDirected.name} to transfer the requested dollar amount from your connected bank account at your requested frequency. Repeat investments will be submitted to ${FinTronConfig.clearingFirm} for execution as a Market Buy order with a One Day time in force instruction at market open on the third banking day following the successful processing of the transfer of funds.\nSecurities are offered via ${FinTronConfig.selfDirected.name} in a fractional capacity and custodied by ${FinTronConfig.clearingFirm}. Investors should be aware that fractional shares represent partial ownership of a security. Investments may lose value and there is a risk of losing money. Brokerage services are offered through ${FinTronConfig.selfDirected.name}, a registered broker dealer (member FINRA & SIPC).`,
      tradeOrderSummaryLegal: `Os títulos são oferecidos pela ${FinTronConfig.selfDirected.name} em caráter fracionário, e custodiados pela ${FinTronConfig.clearingFirm}. Os investidores devem estar cientes de que as ações fracionárias representam propriedade parcial de um título. Os investimentos podem perder valor e existe o risco de perder dinheiro. Os serviços de corretagem são fornecidos pela ${FinTronConfig.selfDirected.name}., Membro FINRA/SIPC. A ${FinTronConfig.selfDirected.name} introduz transações em base totalmente divulgada para nossa empresa de compensação ${FinTronConfig.clearingFirm}.\n\nNada contido ou disponível para você por meio do site deve ser interpretado como uma solicitação ou recomendação para comprar ou vender títulos. Ao prosseguir com uma operação de títulos, você atesta sua avaliação independente dos méritos de investimento e riscos de produto de quaisquer títulos.`,
      bestBidOffer: 'Melhor lance/oferta',
      tradeScreenContinue: 'Página Inicial',
      nbbo: {
        nbboQuoteBSMTitle: 'Melhor lance e oferta nacional (NBBO)',
        nbboQuoteBestBid: 'Melhor lance \n(Bid)',
        nbboQuoteBestAsk: 'Melhor oferta \n(Ask)',
        nbboQuoteBestBidAskDesc:
          'O National Best Bid and Offer (NBBO) é um regulamento da Securities Exchange Commission (SEC) que exige que os corretores negociem com o melhor preço de venda disponível (mais baixo) e o melhor preço de venda disponível (mais alto) ao comprar e vender títulos para clientes. O Melhor Lance e Oferta Nacional (NBBO) é o preço de compra ou venda que o cliente médio verá. O Securities and Exchange Commission Regulation NMS exige que os corretores garantam esse preço aos seus clientes.',
        nbboQuoteBidSize: 'Volume da compra \n(Bid)',
        nbboQuoteBidSizeDesc:
          'O “bid size” representa a quantidade de um ativo que investidores estão dispostos à comprar (bid) no preço de mercado atual. O “bid size” ajuda a determinar a “liquidez”, que indica a abilidade de alguém comprar um ativo rapidamente e a um preço favorável. Se não há “bids”, ninguém está comprando. O volume e preço do Bid são cotados pelas bolsas, que facilitam as vendas de títulos e fornecem cotações públicas de preços, volumes e outras métricas diversas. Abaixo de cada volume de bid você verá o mercado de origem da quotação.',
        nbboQuoteAskSize: 'Volume da oferta \n(Ask)',
        nbboQuoteAskSizeDesc:
          'O “ask size” representa “ a quantidade de um ativo que investidores estão oferecendo (ask) para venda no preço de mercado atual. O “ask size” ajuda a determinar a “liquidez”, que indica a abilidade de alguém vender um ativo rapidamente e a um preço favorável. Se não há “asks”, ninguém está vendendo. O volume e preço do ask são cotados pelas bolsas, que facilitam as vendas de títulos e fornecem cotações públicas de preços, volumes e outras métricas diversas.. Abaixo de cada volume de ask você verá o mercado de origem da quotação.',
        lastTrade: 'Last sale:',
        refreshedAt: 'Refreshed at:',
        refresh: 'Refresh',
      },
      pennyStock: {
        pennyStockDisclosureBSMTitle: 'Divulgação de Penny Stocks',
        importantInformation: 'Informações importantes sobre penny stocks',
        statement:
          'Esta declaração é exigida pela Comissão de Valores Mobiliários dos EUA (SEC) e contém informações importantes sobre penny stocks. Recomendamos que você o leia antes de fazer uma compra ou venda.',
        pennyStocksCanBeRisky: 'Penny stocks podem ter um risco alto',
        pennyStockAreLowPriced: `\u2022 Penny stocks são ações de baixo preço de pequenas empresas não negociadas em bolsa ou cotadas na NASDAQ. Os preços muitas vezes não estão disponíveis. Os investidores em penny stocks muitas vezes não conseguem vender as ações de volta ao revendedor que lhes vendeu as ações. Assim, você pode perder seu investimento. Tenha cuidado com penny stocks recém-emitidas.`,
        pennyStockSalesPerson: `\u2022 Seu vendedor não é um consultor imparcial, mas é pago para lhe vender o estoque. Não confie apenas no vendedor, mas procure aconselhamento externo antes de comprar qualquer estoque. Se você tiver problemas com um vendedor, entre em contato com o responsável pela conformidade da empresa ou com os reguladores listados abaixo.`,
      },
    },
    deposits: {
      recurringTitle: 'Fazer depósito recorrente?',
      recurringDeposit: 'Depósito Recorrente',
      depositLimit: 'Limite de depósito instantâneo:',
      depositStatus: 'Status Do Depósito',
      singleDepositSummary:
        'Verifique o seu histórico de atividades para detalhes sobre esta operação.',
      depositPendingTitle: 'Pending...',
      depositPendingSummary:
        'Your order is still pending, check the transaction history for updates to your funds availability.',
      getNamedFreqDisplayString: `Your **{{amount}}** recurring {{intervalKind}} deposit has been submitted.`,
      depositLimits: 'Limites de depósito',
      depositSummary: 'Resumo do depósito',
      makeAnotherDeposit: 'Make another deposit',
      infuseSummarySuccess: `Your **{{amount}}** deposit will be invested into your portfolio. See your activity history for details.`,
      threeDayDepositAmount: 'Valor de depósito (3 dias)',
      limits: {
        instantDepositLimit: 'Limite de depósito instantâneo: ',
        instantDepositLimitDesc:
          'Calculamos esse limite utilizando uma parte do seu portfólio, descontando transferências recentes que ainda não tenham sido liquidadas em sua conta.',
        dailyLimit: 'Limito diário: ',
        dailyLimitDescription: `Existe um limite diário de {{limit}} para depósitos.`,
        dailyLimitDescription2: `Valores de transferência enviados dentro da janela de horários para depósitos no mesmo dia (7:00am – 4:00pm EST) estarão disponíveis imediatamente para negociação no horário de mercado. Os valores de transferência enviados após a janela de depósito não estarão disponíveis para negociação até o próximo dia útil.`,
      },
      fundingSource: 'Conta Bancária',
    },
    withdrawals: {
      title: 'Are you sure you want to withdraw all funds?',
      description:
        `Withdrawing all funds will require ${FinTronConfig.robo.name} to sell all securities in your account` +
        '\n It is not guaranteed that the dollar amount displayed now on the screen will be equal to the dollar amount in the account after all securities are sold and withdrawn to your bank account.' +
        '\n\n *Withdrawals have tax consequences. Please consult a tax professional or www.irs.gov for more information.',
      withdrawFunds: 'Withdraw funds',
      amounts: {
        available: 'Disponível:',
        withdrawButton: 'Withdraw all',
        selectBank: 'Select bank account',
        withdrawalDelay: 'Withdrawal delay',
        withdrawalDelayText1:
          'To keep money launderers and fraudsters at bay, we place a brief hold on all deposits from leaving. This means that once the cash from a deposit is available for trading, a brief hold is placed on the funds from leaving.',
        withdrawalDelayText2: `If you have any questions about our fraud and anti-money laundering procedures, please feel free to contact [${FinTronConfig.supportEmail}](mailto:${FinTronConfig.supportEmail}) with any questions that you may have!`,
        withdrawAllText: `Withdrawal inputs of **{{value}}** or greater will automatically Withdraw All. \n\nIf your withdrawal is 90% or greater of your portfolio value, then we will automatically Withdraw All funds. \n\nBy submitting this transaction, you are authorizing ${FinTronConfig.clearingFirm} to sell the stated amount from your goal and deposit into your linked bank account. Please allow 3-4 business days for this transaction to be completed.`,
        withdrawStatus: 'Withdrawal status',
        withdrawSummary: `Your request to withdraw **{{value}}** has been received. Funds will generally settle in 3-4 business days. See your activity history for details.`,
        withdrawalSummaryTitle: 'Withdrawal summary',
        withdrawalPendingTitle: 'Pending...',
        withdrawalPendingSummary:
          'Your order is still pending, check the transaction history for updates to your funds availability.',
      },
    },
    transfers: {
      transferTypeTitle: 'Tipo de depósito',
      bankNameTitle: 'Conta bancária',
      dollarValueTitle: 'Valor em dólar',
      estimatedDollarValueTitle: 'Estimated dollar value',
      recurringSummaryDescription: `We will initiate a deposit {{recurringInterval}}, starting on {{userSelectedDate}}. Deposits will settle within 3 business days after it has been initiated.`,
      instantTitle: 'Instantâneo',
      regularTitle: 'Regular',
      estimatedSettlementDescription: `Liquidação Estimada {{settlementDate}}`,
      totalAmountTitle: 'Valor total',
      depositSummaryLegalText: `Os depósitos em dinheiro são custodiados pelo ${FinTronConfig.clearingFirm}, que automaticamente colocará seus depósitos em dinheiro neste programa. Se você optar por não participar do ${FinTronConfig.clearingFirm}, seus depósitos não serão assegurados pelo FDIC. Você pode cancelar a inscrição neste programa a qualquer momento. Os Serviços de Corretagem são oferecidos através da ${FinTronConfig.selfDirected.name}, uma corretora registrada, membro FINRA & SIPC, e não pelo Bradesco Bank. Os produtos de valores mobiliários adquiridos ou vendidos em uma operação não são assegurados pela Federal Deposit Insurance Corporation (FDIC), não são depósitos ou outras obrigações do Bradesco Bank, e não são garantidos pelo Bradesco Bank; e sujeito a riscos de investimento, incluindo possível perda do investimento principal.`,
      returnedTransferFee: 'Taxa de transferência retornada',
    },
    activityHistory: {
      recentActivity: 'Atividades Recentes',
      myHistory: 'Meu Histórico',
      filter: {
        title: 'Filtrar por categoria',
        selectDate: 'Selecione datas',
        to: 'até',
        orders: 'Ordens',
        transfers: 'Transferências',
        deposits: 'Depósitos',
        withdrawal: 'Saque',
        dividends: 'Dividendos',
        interests: 'Juros',
        acat: 'ACAT',
        buy: 'Compra',
        sell: 'Venda',
        all: 'Todas',
        filterButton: 'Filtrar',
        clearButton: 'Limpar',
      },
      popups: {
        transferringFrom: 'Transferindo de',
        transferringTo: 'Transfêrencia para',
        dividendReceived: 'Dividendo recebido',
      },
    },
  },
  generalInvestments: {
    allocation: 'Allocation',
    choosePortfolio: 'Choose Portfolio',
    changePortfolio: 'Change Portfolio',
    currentPortfolio: 'This is Your Current Portfolio',
    saveAndUpdatePortfolio: 'Save And Update Portfolio',
    editQuestionsText: 'Select here to make edits to your investor questions.',
    recommended: 'Recommended',
    recommendedDescription:
      'This risk level is the most recommended based on your responses in your investor profile.',
    notRecommended: 'Not Recommended',
    notRecommendedDescription:
      'This risk level is not recommended based on your responses in your investor profile.',
    notRecommendedSubDescription: 'Select here to go to recommended portfolio.',
    cantRecommendDescription:
      'This risk level is not selectable based on your answers in your investor profile.',
    bsm: {
      recommended: 'Confirm Selection',
      notRecommended: 'Portfolio not recommended',
      recommendedText:
        'You are about to choose the recommended portfolio. The chosen portfolio can always be changed later from your Settings page.',
      notRecommendedText:
        'This portfolio is not recommended based on your investment profile. Are you sure that you want to choose this portfolio?',
      continueBtnText: 'Continue With This Portfolio',
    },
    stockScreen: {
      holdings: 'Ativos',
      topTen: 'Top 10 Holdings',
      viewAll: 'View all',
      other: 'Other',
      availableCash: 'Saldo Disponível:',
      pandingCash: 'Saldo Pendente:',
      costPerShare: 'Custo médio\npor ação:',
      value: 'Valor:',
      gainLoss: 'Ganho/Perda:',
      shares: 'Ações',
    },
    allHoldingsScreen: {
      title: `{{etfName}} holdings`,
    },
    editDetailsScreen: {
      title: 'Edit your automated\ninvestment values',
      pauseInvestments: 'Pause investments',
      resumeInvestments: 'Resume investments',
      saveButtonText: 'Save',
      cancelButtonText: 'Cancel',
      recurringInvestments: 'Recurring investments',
      investmentAmount: 'Amount',
      investmentFrequency: 'Frequency',
      fundingSource: 'Funding source',
      active: 'Active',
      paused: 'Paused',
      targetAmount: 'Target amount',
      target: 'Target',
      initialInvestment: 'Initial investment',
      cancelNext: 'Cancel next',
      canceledUntil: `Canceled until {{date}}`,
    },
  },
  robo,
  settings,
  widgets,
  gi,
};
