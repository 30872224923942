import { format as fnsFormat, isValid, parse } from 'date-fns';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { DatepickerRootProps } from './Datepicker.primitives';
import { defaultFormat } from './datepicker.consts';

type DatepickerContextProps = {
  realValue?: Date | null;
  isOpen?: boolean;
  currentDate?: Date | null;
  inputValue: string;
  month: Date;
  displayValue?: string;
  innerProps: DatepickerRootProps;
  handleOpenChange: (newOpen: boolean) => void;
  handleApply: () => void;
  handleReset: () => void;
  handleCalendarSelect: (selected: Date) => void;
  handleChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleMonthChange: (newMonth: Date) => void;
};

type DatepickerProviderProps = DatepickerRootProps & {
  children: React.ReactNode;
};

const DatepickerContext = createContext<DatepickerContextProps | null>(null);

const useDatepicker = () => {
  return useContext(DatepickerContext as React.Context<DatepickerContextProps>);
};

const DatepickerProvider = (props: DatepickerProviderProps) => {
  const {
    defaultValue,
    format = defaultFormat,
    onChange,
    defaultOpen,
    placeholder,
    value,
    children,
  } = props;

  /**
   * Hold the uncontrolled date for the field
   */
  const [innerValue, setInnerValue] = React.useState<Date | null | undefined>(defaultValue);
  /**
   * Hold the selected date before clicking on apply
   */
  const [currentDate, setCurrentDate] = React.useState<Date | null | undefined>();
  /**
   * Hold the current state of the popover
   */
  const [isOpen, setIsOpen] = React.useState(defaultOpen ?? false);
  /**
   * Hold the value of the input text field
   */
  const [inputValue, setInputValue] = React.useState('');
  /**
   * Hold the current month being showed on the Calendar
   */
  const [month, setMonth] = React.useState(new Date());

  /**
   * Hold the controlled value (value) in case it has been passed
   * Else hold the uncontrolled value (innerValue)
   */
  const realValue = useMemo(() => (value !== undefined ? value : innerValue), [value, innerValue]);
  /**
   * Hold the value that will be displayed in the popover trigger
   */
  const displayValue = useMemo(
    () => (realValue ? fnsFormat(realValue, format) : placeholder),
    [realValue, format, placeholder],
  );

  /**
   * When opening the popover, set the selected date on the calendar and
   * the value of the input field
   */
  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (newOpen) {
        setCurrentDate(realValue);
        setInputValue(realValue ? fnsFormat(realValue, format) : '');
        setMonth(realValue ?? new Date());
      }

      setIsOpen(newOpen);
    },
    [realValue, format],
  );

  /**
   * Callback used when you want to apply changes to the value.
   * Set the real value to the selected date in the calendar
   */
  const handleApply = useCallback(() => {
    if (value === undefined) {
      setInnerValue(currentDate);
    }

    if (onChange) {
      onChange(currentDate);
    }

    setIsOpen(false);
  }, [value, onChange, currentDate]);

  /**
   * Callback used when you want to reset the values of the field to null
   */
  const handleReset = useCallback(() => {
    if (value === undefined) {
      setInnerValue(defaultValue ?? null);
    }

    if (onChange) {
      onChange(defaultValue ?? null);
    }

    setIsOpen(false);
  }, [value, onChange]);

  /**
   * Callback used when the value of the input changes.
   * It checks if the date is valid in the provided format
   * before setting current selected date, else it changes the
   * value of the input and unselected the current selected
   * date on the calendar
   */
  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);

      const parsedDate = parse(e.target.value, format, new Date());

      if (isValid(parsedDate)) {
        setCurrentDate(parsedDate);
        setMonth(parsedDate);
      } else {
        setCurrentDate(null);
      }
    },
    [format],
  );

  /**
   * Callback used when a day is selected in the calendar,
   * it will change the current selected date and input value
   */
  const handleCalendarSelect = useCallback(
    (selected: Date) => {
      setCurrentDate(selected);
      setInputValue(fnsFormat(selected, format));
      setMonth(selected);
    },
    [format],
  );

  /**
   * Callback used when a new month is selected using the
   * navigation from the calendar
   */
  const handleMonthChange = useCallback((newMonth: Date) => {
    setMonth(newMonth);
  }, []);

  return (
    <DatepickerContext.Provider
      value={{
        realValue,
        currentDate,
        handleOpenChange,
        isOpen,
        inputValue,
        month,
        innerProps: props,
        handleApply,
        handleReset,
        handleCalendarSelect,
        handleChangeInput,
        displayValue,
        handleMonthChange,
      }}
    >
      {children}
    </DatepickerContext.Provider>
  );
};

export { DatepickerProvider, useDatepicker };
