import usePlaidApiService from '@/hooks/plaid/usePlaidApiService';
import useGrpcApi from '@/hooks/utils/useGrpcApi';
import { V1Platform } from '@fintronners/react-api/src/tsoai';
import fLogger from '@fintronners/react-utils/src/fLogger';
import { useCallback, useEffect, useState } from 'react';
import {
  PlaidAccount,
  PlaidLinkError,
  PlaidLinkOnExit,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  usePlaidLink,
} from 'react-plaid-link';

const MAX_RETRIES = 2;

export enum PlaidLinkStatus {
  Loading = 'Loading',
  Relinked = 'Relinked',
  LinkCreated = 'LinkCreated',
  Exited = 'Exited',
  Failed = 'Failed',
}

type PlaidData = {
  accounts: PlaidAccount[];
  publicToken: string;
};

type UsePlaidLinkSession = {
  relationshipId?: string;
  externalAccountUid?: string;
};
/**
 * Helper hook to manage the Plaid Link session.
 * The session will start automatically as soon as `relationshipId` is provided.
 *
 * @see https://plaid.com/docs/link/#link-flow-overview
 *
 * @param relationshipId Tenant specific relationship id
 * @param externalAccountUid ACH account id to be used to refresh the account
 *
 * @returns plaidLinkStatus - The current status of the Plaid Link session
 * @returns plaidData - The data returned from the Plaid Link for the caller to manage
 */
const usePlaidLinkSession = ({ relationshipId, externalAccountUid }: UsePlaidLinkSession) => {
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [retry, setRetry] = useState(0);
  const [plaidLinkStatus, setPlaidLinkStatus] = useState<PlaidLinkStatus>(PlaidLinkStatus.Loading);
  const [plaidData, setPlaidData] = useState<PlaidData>();
  const [needsRefresh, setNeedsRefresh] = useState(false);

  const plaidApiService = usePlaidApiService();

  const [plaidServiceRefreshItem] = useGrpcApi([
    plaidApiService,
    plaidApiService.plaidServiceRefreshItem,
  ]);
  const [plaidServiceCreateLinkToken] = useGrpcApi([
    plaidApiService,
    plaidApiService.plaidServiceCreateLinkToken,
  ]);
  const [plaidServiceCreateLinkTokenForUpdateMode] = useGrpcApi([
    plaidApiService,
    plaidApiService.plaidServiceCreateLinkTokenForUpdateMode,
  ]);

  const [plaidServiceShouldRefresh, isCheckingPlaid] = useGrpcApi([
    plaidApiService,
    plaidApiService.plaidServiceShouldRefresh,
  ]);

  const shouldRefresh = async (): Promise<void> => {
    try {
      const response = await plaidServiceShouldRefresh({ externalAccountUid });
      setNeedsRefresh(Boolean(response?.data?.refresh));
    } catch (error) {
      fLogger.error('Error in checking plaid:', error);
    }
  };

  /**
   * Fetches the link token from the backend to be used to start the plaid link session.
   * If the `externalAccountUid` is provided, the link token will be created in update mode,
   * otherwise it will just be created normally.
   */
  const getPlaidLinkToken = async () => {
    try {
      // TODO: Change the platform to web when it's available
      const response = externalAccountUid
        ? await plaidServiceCreateLinkTokenForUpdateMode(externalAccountUid, V1Platform.Ios)
        : await plaidServiceCreateLinkToken(V1Platform.Ios, relationshipId);

      const token = response?.data.linkToken;
      if (!token) throw Error('Link token not found');
      return token;
    } catch (error) {
      console.error('Error in linking:', error);
      throw error;
    }
  };

  /**
   * Callback when the plaid link session is successful.
   *
   * @param public_token Public token used to be exchanged for a permanent access_token
   * @param metadata Data returned by Plaid Link
   */
  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      if (externalAccountUid) {
        await plaidServiceRefreshItem({
          externalAccountUid: externalAccountUid,
        });

        await shouldRefresh();

        setPlaidLinkStatus(PlaidLinkStatus.Relinked);
      } else {
        setPlaidData({
          accounts: metadata.accounts,
          publicToken: public_token,
        });
        setPlaidLinkStatus(PlaidLinkStatus.LinkCreated);
      }
    },
    [externalAccountUid],
  );

  /**
   * Callback when the plaid link session is exited. This can be due to an error or
   * the user exiting the flow.
   *
   * @see https://plaid.com/docs/errors/
   *
   * @param error Error returned by Plaid Link
   * @param metadata Data returned by Plaid Link
   */
  const onExit = useCallback<PlaidLinkOnExit>(
    (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
      fLogger.error('Error in opening plaid link:', error);
      if (metadata.request_id) {
        // TODO: Use Sentry when it's available
        if (error?.error_code === 'INVALID_LINK_TOKEN') {
          if (retry < MAX_RETRIES) {
            setRetry(retry + 1);
          } else {
            setPlaidLinkStatus(PlaidLinkStatus.Failed);
          }
          return;
        }
      }
      setPlaidLinkStatus(PlaidLinkStatus.Exited);
    },
    [],
  );

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess,
    onExit,
  });

  /**
   * Triggers the entire flow as soon as the `relationshipId` is available.
   */
  useEffect(() => {
    if (!relationshipId) return;
    if (retry <= MAX_RETRIES) {
      getPlaidLinkToken()
        .then(setLinkToken)
        .catch(() => {
          setPlaidLinkStatus(PlaidLinkStatus.Failed);
          // TODO: Display a toast notification
        });
    }
  }, [retry, relationshipId]);

  useEffect(() => {
    if (!externalAccountUid) return;

    shouldRefresh();
  }, [externalAccountUid]);

  /**
   * Triggers the Plaid popup (which allows the user to link their bank account)
   * right after the `linkToken` is set.
   */
  // ? Commented because it need to be triggered by user when interact with a button
  /*   useEffect(() => {
    if (!linkToken) return;
    if (ready) {
      open();
    }
  }, [ready, linkToken]); */

  return {
    plaidLinkStatus,
    plaidData,
    openPlaidFlow: open,
    isPlaidReady: ready,
    needsRefresh,
    isCheckingPlaid,
  };
};

export default usePlaidLinkSession;
