import React from 'react';
import { useTheme } from 'next-themes';
import { Toaster as Sonner, toast } from 'sonner';
import Image from 'next/image';
import {
  CloseGreyIcon,
  ExclamationCircleIcon,
  InfoPrimaryIcon,
  SuccessCircleGreenIcon,
} from '@/assets/icons';
import Button, { ButtonProps } from '@/components/common/Button/Button';
import { WebBodyText16 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';

type ToasterProps = React.ComponentProps<typeof Sonner>;

type CustomToastProps = {
  message: string;
  variant?: 'info' | 'success' | 'error';
  showIcon?: boolean;
  showCloseButton?: boolean;
  actionButton?: {
    label: string;
    onClick: () => void;
    variant?: ButtonProps['variant'];
  };
  dismissButton?: {
    label: string;
    onClick: () => void;
    variant?: ButtonProps['variant'];
  };
};

export const showToast = ({
  message,
  variant = 'info',
  showIcon = true,
  showCloseButton = false,
  actionButton,
  dismissButton,
}: CustomToastProps) => {
  toast.custom((t) => (
    <div className="relative border border-grey6 pl-5 pr-12 py-5 flex flex-col bg-white rounded-lg shadow-lg">
      {(variant === 'success' || variant === 'error') && (
        <div
          className={`w-[10px] absolute h-full left-0 top-0 rounded-tl-lg rounded-bl-lg ${
            variant === 'success' ? 'bg-green' : 'bg-red'
          }`}
        />
      )}
      {showCloseButton && (
        <button
          className="flex justify-center items-center absolute right-0 top-0 w-[27px] h-full bg-grey7 rounded-tr-lg rounded-br-lg"
          onClick={() => toast.dismiss(t)}
        >
          <Image alt="Close icon" width={11} height={11} src={CloseGreyIcon.src} />
        </button>
      )}
      <div className="flex items-start justify-between w-full">
        <div className="flex items-start gap-[15px] w-full">
          {showIcon && (
            <>
              {variant === 'info' && (
                <Image alt="Info icon" width={20} height={20} src={InfoPrimaryIcon.src} />
              )}
              {variant === 'success' && (
                <Image alt="Success icon" width={20} height={20} src={SuccessCircleGreenIcon.src} />
              )}
              {variant === 'error' && (
                <Image alt="Error icon" width={20} height={20} src={ExclamationCircleIcon.src} />
              )}
            </>
          )}
          <WebBodyText16.Medium className="text-grey55 w-full">{message}</WebBodyText16.Medium>
        </div>
      </div>

      {(actionButton || dismissButton) && (
        <div className="flex gap-[10px] mt-[15px]">
          {actionButton && (
            <Button
              onClick={actionButton.onClick}
              variant={actionButton.variant || 'primary'}
              size="default"
            >
              {actionButton.label}
            </Button>
          )}
          {dismissButton && (
            <Button
              onClick={dismissButton.onClick}
              variant={dismissButton.variant || 'secondary'}
              size="default"
            >
              {dismissButton.label}
            </Button>
          )}
        </div>
      )}
    </div>
  ));
};

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = 'system' } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps['theme']}
      className="toaster group w-full max-w-[660px]"
      position="top-center"
      duration={8000}
      {...props}
    />
  );
};

export { Toaster };
