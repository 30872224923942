import * as yup from 'yup';
import {
  getBooleanRequiredSchema,
  getEmailSchema,
  getPhoneNumberSchema,
  getStringRequiredSchema,
} from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';
import { FORM_ERROR_MESSAGES } from '@fintronners/react-api/src/utils/formUtils/schemas/constants';

export const beneficiaryFormSchema = yup.object().shape({
  dateOfBirth: yup.string(),
  firstName: getStringRequiredSchema(),
  lastName: getStringRequiredSchema(),
  phoneNumber: getPhoneNumberSchema(true, true),
  email: getEmailSchema().required(),
  streetAddress: getStringRequiredSchema(),
  additionalStreetAddress: yup.string(),
  city: getStringRequiredSchema(),
  state: getStringRequiredSchema(),
  country: getStringRequiredSchema(),
  zipCode: getStringRequiredSchema(),
  isPending: getBooleanRequiredSchema(),
  ssn: yup
    .string()
    .required(FORM_ERROR_MESSAGES.required)
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(9, 'Must be exactly 9 digits')
    .max(9, 'Must be exactly 9 digits'),
  relationship: yup.string().required(FORM_ERROR_MESSAGES.required).max(30),
  beneficiaryType: getStringRequiredSchema(),
  percentage: yup
    .number()
    .min(0, 'Share must be at least 0%')
    .max(100, 'Share cannot exceed 100%')
    .notRequired(),
});

export type BeneficiaryFormData = yup.InferType<typeof beneficiaryFormSchema>;
