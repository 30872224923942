import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { UserAccountAccountType } from '@fintronners/react-api/src';
import { AccountType } from '@/ssr/accounts-type';

const accountTypes: Record<AccountType, UserAccountAccountType> = {
  'self-directed': UserAccountAccountType.AccountTypeBrokerage,
  'trad-ira': UserAccountAccountType.AccountTypeIra,
  'roth-ira': UserAccountAccountType.AccountTypeRothIra,
};

const useCurrentAccountType = () => {
  const router = useRouter();

  const accountType = useMemo(() => {
    const { type } = router.query;

    return accountTypes[type as keyof typeof accountTypes];
  }, [router.asPath]);

  return accountType;
};

export const useIsIRAAccount = () => {
  const accountType = useCurrentAccountType();

  return (
    accountType === UserAccountAccountType.AccountTypeIra ||
    accountType === UserAccountAccountType.AccountTypeRothIra
  );
};

export default useCurrentAccountType;
