import {
  CommonUserInvestorInformationNonDiscretionaryRequestParametersDetailsFragment,
  V1UserAnnualIncome,
  V1UserEmploymentStatus,
  V1UserMaritalStatus,
  V1UserNetWorth,
} from '@fintronners/react-api/src';
import { getSimpleEnumKeysAsArray } from '@fintronners/react-utils/src';

interface formOptionsType {
  title: string;
  dialogTitle?: string;
  dialogContent?: string;
  items: any;
  name: keyof CommonUserInvestorInformationNonDiscretionaryRequestParametersDetailsFragment;
}

export const displayStrings = {
  title: 'Personal questions',
  maritalStatusQuestion: 'Are you married?',
  maritalStatusAnswers: {
    [V1UserMaritalStatus.Married]: 'Married',
    [V1UserMaritalStatus.Single]: 'Single',
    [V1UserMaritalStatus.Divorced]: 'Divorced',
    [V1UserMaritalStatus.Widowed]: 'Widowed',
  },
  employmentStatusQuestion: "What's your current employment status?",
  employmentStatusAnswers: {
    [V1UserEmploymentStatus.Employed]: 'Employed',
    [V1UserEmploymentStatus.Unemployed]: 'Unemployed',
    [V1UserEmploymentStatus.Retired]: 'Retired',
    [V1UserEmploymentStatus.Student]: 'Student',
  },
  annualIncomeQuestion: 'What is your annual income?',
  annualIncomeAnswers: {
    [V1UserAnnualIncome._025000]: 'Less than or equal to $25,000',
    [V1UserAnnualIncome._2500150000]: '$25,001-$50,000',
    [V1UserAnnualIncome._50001100000]: '$50,001-$100,000',
    [V1UserAnnualIncome._100001200000]: '$100,001-$200,000',
    [V1UserAnnualIncome._200001300000]: '$200,001-$300,000',
    [V1UserAnnualIncome._300001500000]: '$300,001-$500,000',
    [V1UserAnnualIncome._5000011200000]: '$500,001-$1,200,000',
    [V1UserAnnualIncome._12000019999999]: '$1,200,001-$9,999,999',
    [V1UserAnnualIncome.Unspecified]: 'Unspecified',
  },
  netWorthQuestion: 'What is your net worth?',
  netWorthAnswers: {
    [V1UserNetWorth._025000]: 'Less than or equal to $25,000',
    [V1UserNetWorth._2500150000]: '$25,001-$50,000',
    [V1UserNetWorth._50001100000]: '$50,001-$100,000',
    [V1UserNetWorth._100001200000]: '$100,001-$200,000',
    [V1UserNetWorth._200001300000]: '$200,001-$300,000',
    [V1UserNetWorth._300001500000]: '$300,001-$500,000',
    [V1UserNetWorth._5000011000000]: '$500,001-$1,000,000',
    [V1UserNetWorth._10000015000000]: '$1,000,001-$5,000,000',
    [V1UserNetWorth._50000019999999]: '$5,000,001-$9,999,999',
    [V1UserNetWorth.Unspecified]: 'Unspecified',
  },
  netWorthRangeInfoTitle: 'Finding Your Net Worth',
  netWorthRangeInfoDescription:
    'Total net worth is the dollar amount of your assets minus all your debts. In this case, exclude the value of your residence in your answer (if you own your home).',
  employerName: "Employer's name",
  employerLabel: 'What is the name of your employer?',
  yourPosition: 'Your position',
  positionLabel: 'What is your position?',
  save: 'Save',
  back: 'Back',
};

export const formOptions: formOptionsType[] = [
  {
    title: displayStrings.maritalStatusQuestion,
    items: getSimpleEnumKeysAsArray(
      V1UserMaritalStatus,
      displayStrings.maritalStatusAnswers as Record<string, string>,
    ),
    name: 'maritalStatus',
  },
  {
    title: displayStrings.employmentStatusQuestion,
    items: getSimpleEnumKeysAsArray(
      V1UserEmploymentStatus,
      displayStrings.employmentStatusAnswers as Record<string, string>,
    ),
    name: 'employmentStatus',
  },
  {
    title: displayStrings.annualIncomeQuestion,
    items: [
      ...(Object.keys(V1UserAnnualIncome) as Array<keyof typeof V1UserAnnualIncome>)
        .map((key) => {
          return {
            displayString: displayStrings.annualIncomeAnswers[V1UserAnnualIncome[key]],
            keyValue: V1UserAnnualIncome[key],
          };
        })
        .filter((item) => item.keyValue !== V1UserAnnualIncome.Unspecified),
    ],
    name: 'annualIncome',
  },
  {
    title: displayStrings.netWorthQuestion,
    items: [
      ...(Object.keys(V1UserNetWorth) as Array<keyof typeof V1UserNetWorth>)
        .map((key) => {
          const enumValue = V1UserNetWorth[key];
          const displayString =
            displayStrings.netWorthAnswers[
              enumValue as keyof typeof displayStrings.netWorthAnswers
            ];

          return displayString
            ? {
                displayString,
                keyValue: enumValue,
              }
            : null;
        })
        .filter(
          (item): item is { displayString: string; keyValue: V1UserNetWorth } =>
            item !== null && item.keyValue !== V1UserNetWorth.Unspecified,
        )
        .sort((a, b) => {
          const order: V1UserNetWorth[] = [
            V1UserNetWorth._025000,
            V1UserNetWorth._2500150000,
            V1UserNetWorth._50001100000,
            V1UserNetWorth._100001200000,
            V1UserNetWorth._200001300000,
            V1UserNetWorth._300001500000,
            V1UserNetWorth._5000011000000,
            V1UserNetWorth._10000015000000,
            V1UserNetWorth._50000019999999,
          ];
          return order.indexOf(a.keyValue) - order.indexOf(b.keyValue);
        }),
    ],
    name: 'netWorth',
    dialogTitle: displayStrings.netWorthRangeInfoTitle,
    dialogContent: displayStrings.netWorthRangeInfoDescription,
  },
];
