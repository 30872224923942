import { gql } from '@apollo/client';

export const GET_NBBO_QUOTE = gql`
  query GetNbboQuote($securityId: ID!, $isBuy: Boolean!) {
    securityAssets(where: { id: $securityId }) {
      edges {
        node {
          id
          nbboQuote(isBuy: $isBuy) {
            AskPrice
            AskSize
            AskExchange {
              logo
              name
            }
            BidPrice
            BidSize
            BidExchange {
              logo
              name
            }
            lastTrade {
              price
              volume
              securityExchange {
                tape
              }
              timestamp
            }
          }
        }
      }
    }
  }
`;
