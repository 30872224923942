import { UseFormSetValue, FieldValues, SetValueConfig, Path, PathValue } from 'react-hook-form';
import { FormFieldValue } from './types';
import { ApexExtendedFieldNames, apexExtendedSchema } from './apexExtendedSchema';

/**
 * Verify form value based on condition
 * @param setValue - function to set value in form
 * @param fieldName - key to set value
 * @param condition - condition to verify
 * @param options - options to set value
 */
export const verifyFormValue = <T extends FieldValues>(
  setValue: UseFormSetValue<T>,
  fieldName: Path<T>,
  condition: PathValue<T, Path<T>>,
  options?: SetValueConfig,
) => {
  setValue(fieldName, condition, options ?? { shouldValidate: true });
};

/**
 *
 * This is the base field difference fn that specific instances can implement
 * Can be provided with a custom transformer fn when basic equality comparison dont suffice
 * @param profileFieldValue - old form value
 * @param updateFieldValue - new form value
 * @param name - form field name
 * @param transformerFn - takes field values as inputs, transforms them, and must return a boolean
 * @returns
 */
export function getFieldDifference<fieldName extends string>(
  dbFieldValue: FormFieldValue,
  updateFieldValue: FormFieldValue,
  name: fieldName,
  hasDifferenceTransformerFn?: (v1: FormFieldValue, v2: FormFieldValue) => boolean,
) {
  const isDifferent = hasDifferenceTransformerFn
    ? hasDifferenceTransformerFn(dbFieldValue, updateFieldValue)
    : dbFieldValue !== updateFieldValue;
  return isDifferent ? { [name]: updateFieldValue } : undefined;
}

/**
 * Check if the key is a valid ApexExtendedFormKey
 * @param key - key to check
 * @returns boolean
 */
export const isApexExtendedFormKey = (
  key: ApexExtendedFieldNames | string,
): key is ApexExtendedFieldNames => {
  return Object.keys(apexExtendedSchema.fields).includes(key);
};
