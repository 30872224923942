import { useAppStore } from '@/stores/app-store';
import { useState, useEffect } from 'react';

type HexColorCode = `#${string}`;

export const useComputedStyle = (
  variable: string,
  defaultValue: HexColorCode = '#000000',
): HexColorCode => {
  const [value, setValue] = useState<HexColorCode>(defaultValue);
  const { tenant } = useAppStore();

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const computedValue = getComputedStyle(document.documentElement)
        .getPropertyValue(variable)
        .trim();

      if (computedValue.startsWith('#')) {
        setValue(computedValue as HexColorCode);
      }
    }
  }, [variable, tenant]);

  return value;
};
