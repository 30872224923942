import React from 'react';
import Image from 'next/image';
import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';

import { ChevronDownGreyIcon } from '@/assets/icons';
import { DatepickerRootProps, DatepickerTrigger } from '@/components/common/Datepicker';
import { useDatepicker } from '@/components/common/Datepicker/base/Datepicker.context';
import { Button } from '@/components/common/Button';
import { GenericDatepicker } from '@/components/common/Datepicker/GenericDatepicker';
import { getLocalDateTimeDisplayString } from '@fintronners/react-utils/src/formatDate';
import { addBusinessDays, addDays } from 'date-fns';

const displayStrings = {
  inlineDatepickerStarts: 'Starts',
};

export const YEAR_DATE_FORMAT_OPTION: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};

export type InlineDatepickerProps = Omit<DatepickerRootProps, 'children' | 'placeholder'>;

const InlineDatepickerTrigger = () => {
  const { realValue, displayValue } = useDatepicker();

  return (
    <DatepickerTrigger>
      <Button variant="ghost" className="mt-1 space-x-2 border-none px-0 opacity-40">
        {realValue ? (
          <WebBodyText14.Bold className="text-grey55 text-sm">
            {`${displayStrings.inlineDatepickerStarts} ${getLocalDateTimeDisplayString(realValue, YEAR_DATE_FORMAT_OPTION)}`}
          </WebBodyText14.Bold>
        ) : (
          <div className="text-grey41 font-regular text-sm tracking-wide">{displayValue}</div>
        )}

        <Image alt="" width={15} height={15} src={ChevronDownGreyIcon.src} />
      </Button>
    </DatepickerTrigger>
  );
};

const InlineDatepicker: React.FC<InlineDatepickerProps> = (props) => {
  return (
    <GenericDatepicker
      trigger={<InlineDatepickerTrigger />}
      disableDates={[
        { after: addBusinessDays(new Date(), 10), before: addDays(new Date(), 1) },
        {
          dayOfWeek: [0, 6],
        },
      ]}
      {...props}
    />
  );
};

InlineDatepicker.displayName = 'InlineDatepicker';

export { InlineDatepicker };
