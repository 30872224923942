import React from 'react';
import { useRouter } from 'next/router';
import Layout from '@/components/common/Layout/Layout';
import { JwtIdToken } from '@/middleware';
import { useConfigStore } from '@/stores/config-store';
import GlobalStyles from '@/styles/GlobalStyles';

// List of paths that should not have the layout
const EXCLUDED_ROUTES = ['/', '/login', '/logout', '/reauth', '/redirect', '/user-info'];

type LayoutProviderProps = React.PropsWithChildren<{
  idToken: JwtIdToken | null;
  statusCode: number;
}>;

const LayoutProvider: React.FC<LayoutProviderProps> = ({ idToken, statusCode, children }) => {
  const { pathname } = useRouter();

  const isExcludedRoute = EXCLUDED_ROUTES.includes(pathname);
  const isStatus404 = statusCode === 404;
  const is404 = ['/404', '/not-found'].includes(pathname);

  const { theme, config } = useConfigStore();

  if (isExcludedRoute || isStatus404 || is404) {
    return (
      <>
        <GlobalStyles theme={theme} />
        <div className="h-full w-full">{children}</div>
      </>
    );
  }

  return (
    <>
      <GlobalStyles theme={theme} />

      <Layout idToken={idToken} theme={theme} config={config}>
        {children}
      </Layout>
    </>
  );
};

export default LayoutProvider;
