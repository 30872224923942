import { gql } from '@apollo/client';

export const GET_RETIREMENT_INFO = gql`
  query getRetirementInfo(
    $annualGrossIncome: Int!
    $filingStatus: UserFilingStatus!
    $age: Int!
    $state: String!
  ) {
    userRetirementContributions {
      userID
      year
      total
      totalLimit
      totalWithPending
      traditionalIRA
      traditionalIRALimit
      traditionalIRAWithPending
      rothIRA
      rothIRALimit
      rothIRAWithPending
    }

    retirementFundamentalInfo {
      contribution {
        limits(annualGrossIncome: $annualGrossIncome, filingStatus: $filingStatus, age: $age) {
          totalLimit
          traditionalIRALimit
          rothIRALimit
        }
      }
      withholding {
        federalWithholding {
          rate
        }
        stateWithholding(state: $state) {
          state
          class
          rate
          rateType
        }
      }
    }
  }
`;
