import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';

export default {
  achAccount: {
    title: 'Minha conta',
    addAccount: 'Adicionar conta bancária',
    loading: 'Carregando...',
  },
  goalSummaryCard: {
    title: 'Taxas Robo',
    description: `${FinTronConfig.robo.name} cobra uma taxa anual de 0,50%, que é faturada mensalmente em sua conta. Isso significa que a taxa anual de 0,50% é dividida em 12 partes, então você é cobrado uma fração da taxa a cada mês.\n
    Por exemplo, se você tem uma conta com um valor médio diário de $1.000 em um mês, sua taxa mensal seria 0,50% dividido por 12 meses, o que é 0,0417%. Isso se traduz em uma taxa mensal de $0,42.\n
    ${FinTronConfig.robo.name} está comprometida em fornecer aconselhamento e suporte de investimento personalizado e de baixo custo para todos os nossos clientes, independentemente do tamanho de seu portfólio.`,
  },
};
