import { getTenants, getLocalFeatureFlags } from '@/config';
import { useAppStore } from '@/stores/app-store';

const useFeatureFlags = () => {
  const newApiUrl = useAppStore((state) => state.newApiUrl);
  const tenants = getTenants(newApiUrl);
  const featureFlags = getLocalFeatureFlags(tenants);

  return { tenants, featureFlags };
};

export default useFeatureFlags;
