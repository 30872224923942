import { gql } from '@apollo/client';
import { GetAllAccountsDataQuery } from '../types/graphql';

export type QueryUser = NonNullable<
  NonNullable<GetAllAccountsDataQuery['users']['edges']>[number]
>['node'];

/**
 * @todo: confirm query for All AccountsChart
 * mocking portfolios and goals for now...
 */

export const GET_USER_ACCOUNT_DETAIL_FRAGMENT = gql`
  fragment UserAccountDetail on User {
    accounts(where: $accountFilter) {
      edges {
        node {
          type
          portfolios {
            edges {
              node {
                id
                name
                managementBy
                totalValue
                fiat {
                  total
                }
                security {
                  total
                }
                crypto {
                  total
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_ROBO_GOAL_DETAIL_FRAGMENT = gql`
  fragment UserRoboGoalDetail on User {
    roboGoals {
      edges {
        node {
          id
          targetPortfolioID
          accountPortfolioID
          name
          isActive
          accountPortfolio {
            totalValue
            account {
              id
              externalID
            }
          }
          isAutoInfusionPaused
          allocation {
            totalValue
          }
          parameters {
            targetAmount
            riskProfile
            goalPriority
          }
          projection {
            goalProbability
          }
          accountPortfolio {
            id
            name
            totalValue
          }
          fundingAccount {
            accountNumber
          }
        }
      }
    }
  }
`;

export const GET_ALL_ACCOUNTS_DATA = gql`
  query GetAllAccountsData($accountFilter: UserAccountWhereInput) {
    users {
      edges {
        node {
          ...UserAccountDetail
          ...UserRoboGoalDetail
        }
      }
    }
  }
  ${GET_USER_ACCOUNT_DETAIL_FRAGMENT}
  ${GET_USER_ROBO_GOAL_DETAIL_FRAGMENT}
`;

export const GET_ALL_ACCOUNTS_CHART_DATA = gql`
  query GetAllAccountsChartData($userId: ID, $accountFilter: UserAccountWhereInput) {
    users(where: { id: $userId }) {
      edges {
        node {
          ...UserAccountDetail
          roboGoals {
            edges {
              node {
                id
                name
                accountPortfolio {
                  totalValue
                }
              }
            }
          }
        }
      }
    }
  }
  ${GET_USER_ACCOUNT_DETAIL_FRAGMENT}
`;
