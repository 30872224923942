import { Radio } from '@/components/common/Radio/Radio';
import { Skeleton } from '@/components/common/Skeleton';
import cn from '@/utils/tailwind';
import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import React, { useId } from 'react';

type RadioTagProps = {
  label: string;
  loading?: boolean;
  pending?: boolean;
} & React.ComponentProps<typeof Radio.Input>;

export const RadioTag = ({ label, loading, pending, ...rest }: RadioTagProps) => {
  const uuid = useId();
  const id = `radio-tag-${uuid}`;

  return (
    <div className="flex-1">
      <Radio.Input id={id} {...rest} />
      {loading && <Skeleton height={30} />}

      {!loading && (
        <Radio.Label
          htmlFor={id}
          variant="secondary"
          className={cn(
            pending && 'peer-checked:bg-blue10 peer-checked:border-primary peer-checked:text-blue',
          )}
        >
          <WebBodyText14.Bold>{label}</WebBodyText14.Bold>
        </Radio.Label>
      )}
    </div>
  );
};
