import { parse } from 'cookie';
import { GetServerSidePropsContext, NextApiRequest, NextApiResponse } from 'next';

export const COOKIE_CONSTANTS = {
  ACCESS_TOKEN: 'accessToken',
  ID_TOKEN: 'idToken',
  REFRESH_TOKEN: 'refreshToken',
  REMEMBERED_USERNAME: 'rememberedUsername',
};

export interface CookieOptions {
  maxAge?: number;
  domain?: string;
  path?: string;
  expires?: Date;
  httpOnly?: boolean;
  secure?: boolean;
  sameSite?: 'Strict' | 'Lax' | 'None';
}

// prepare the cookie string
export const cookieString = (
  name: string,
  value: string | object,
  options: CookieOptions = {},
): string => {
  const stringValue = typeof value === 'object' ? `j:${JSON.stringify(value)}` : String(value);

  if (options.maxAge !== undefined) {
    options.expires =
      options.maxAge === 0 ? new Date(0) : new Date(Date.now() + options.maxAge * 1000);
  }

  const cookie = [
    `${name}=${stringValue}`,
    options.maxAge && `Max-Age=${options.maxAge}`,
    options.domain && `Domain=${options.domain}`,
    options.path && `Path=${options.path}`,
    options.expires && `Expires=${options.expires.toUTCString()}`,
    options.httpOnly && 'HttpOnly',
    options.secure && 'Secure',
    options.sameSite && `SameSite=${options.sameSite}`,
  ]
    .filter(Boolean)
    .join('; ');

  return cookie;
};

export const setResCookie = (
  res: GetServerSidePropsContext['res'],
  name: string,
  value: string | object,
  options: CookieOptions = {},
): void => {
  const cookie = cookieString(name, value, options);

  res.setHeader('Set-Cookie', cookie);
};

const setResCookies = (
  res: GetServerSidePropsContext['res'],
  cookies: { [key: string]: string | undefined },
  options?: CookieOptions,
) => {
  const cookieStrings = Object.entries(cookies).map(([key, value]) => {
    return value
      ? cookieString(key, value, options)
      : cookieString(key, '', { ...options, maxAge: 0 });
  });

  res.setHeader('Set-Cookie', cookieStrings);
};

export const getCookiesFromHeader = (cookie?: string) => {
  const cookies = parse(cookie || '');
  return cookies;
};

export const setAuthCookies = (
  res: GetServerSidePropsContext['res'],
  accessToken: string,
  refreshToken: string,
  idToken: string,
) => {
  setResCookies(
    res,
    {
      [COOKIE_CONSTANTS.ACCESS_TOKEN]: accessToken,
      [COOKIE_CONSTANTS.REFRESH_TOKEN]: refreshToken,
      [COOKIE_CONSTANTS.ID_TOKEN]: idToken,
    },
    {
      maxAge: 60 * 60 * 24 * 30, // 30 days
      httpOnly: true,
      sameSite: 'Strict',
      path: '/',
    },
  );
};

const setResApiCookies = (
  res: NextApiResponse,
  cookies: { [key: string]: string | undefined },
  options?: CookieOptions,
) => {
  const cookieStrings = Object.entries(cookies).map(([key, value]) => {
    return value
      ? cookieString(key, value, options)
      : cookieString(key, '', { ...options, maxAge: 0 });
  });

  res.setHeader('Set-Cookie', cookieStrings);
};

export const setAuthApiCookies = (
  res: NextApiResponse,
  accessToken: string | undefined,
  refreshToken: string | undefined,
  idToken: string | undefined,
  requestCookies?: NextApiRequest['headers']['cookie'],
) => {
  const cookies = getCookiesFromHeader(requestCookies || '') || {};

  setResApiCookies(
    res,
    {
      ...cookies,
      [COOKIE_CONSTANTS.ACCESS_TOKEN]: accessToken,
      [COOKIE_CONSTANTS.REFRESH_TOKEN]: refreshToken,
      [COOKIE_CONSTANTS.ID_TOKEN]: idToken,
    },
    {
      maxAge: 60 * 60 * 24 * 30, // 30 days
      httpOnly: true,
      sameSite: 'Strict',
      path: '/',
    },
  );
};
