import { DEV_LOGS_ENABLED, IS_PROD } from '@fintronners/react-utils/src/envConstants';

if ((typeof __DEV__ !== 'undefined' && !__DEV__) || IS_PROD) {
  console.log = () => {};
  console.info = () => {};
  console.warn = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.trace = () => {};
}

if (!DEV_LOGS_ENABLED) {
  console.log = () => {};
  console.debug = () => {};
  console.trace = () => {};
}

/**
 * Custom FinTron Logger that allows us to log to the console
 * and selectively turn on/off logging.
 */
const fLogger = {
  log: (message?: any, ...optionalParams: any[]) => {
    console.log(message, ...optionalParams);
  },
  error: (message?: any, ...optionalParams: any[]) => {
    console.error(message, ...optionalParams);
  },
  info: (message?: any, ...optionalParams: any[]) => {
    console.info(message, ...optionalParams);
  },
  warn: (message?: any, ...optionalParams: any[]) => {
    console.warn(message, ...optionalParams);
  },
  trace: (message?: any, ...optionalParams: any[]) => {
    console.trace(message, ...optionalParams);
  },
  debug: (message?: any, ...optionalParams: any[]) => {
    console.debug(message, ...optionalParams);
  },
};

export default fLogger;
