import Image from 'next/image';
import React from 'react';
import { SuccessCircleIcon } from '~assets/icons';
import { Button } from '~components/common/Button';

interface VerifyButtonProps {
  isVerified: boolean;
  onVerify: () => void;
}

const displayStrings = {
  verify: 'Verify',
};

const VerifyButton = ({ isVerified, onVerify }: VerifyButtonProps) => {
  if (isVerified) {
    return <Image alt="Success icon" width={15} height={15} src={SuccessCircleIcon.src} />;
  }

  return (
    <Button type="button" onClick={onVerify}>
      {displayStrings.verify}
    </Button>
  );
};

export default VerifyButton;
