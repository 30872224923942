import React, { createContext } from 'react';
import { JwtIdToken } from '~middleware';

type ContextProps = {
  children: React.ReactNode;
  basePath?: string;
  authApiBaseUrl?: string;
  accessToken?: string;
  idToken: JwtIdToken | null;
};

export const GrpcApiV1ConfigContext = createContext({});

export const GrpcApiV1ConfigProvider: React.FC<ContextProps> = ({ children, ...values }) => {
  return (
    <GrpcApiV1ConfigContext.Provider value={values}>{children}</GrpcApiV1ConfigContext.Provider>
  );
};
