import { AppTheme } from '@fintronners/react-utils/types/styled-components';
import { colors } from './colors';
import { scale } from '@fintronners/react-utils/src/dimensions';

export const mainTheme: AppTheme = {
  text: {
    fontFamily: 'Roboto-Regular',
    fontWeight: 'normal',
    color: colors.brandBlack,
  },
  colors: {
    background: '#ffffff',
    primary: colors.blue2,
    primaryDark: colors.blue2,
  },
  ui: {
    buttonBorderRadius: scale(100),
  },
  images: {
    lightBrandLogoL: {
      logoRatio: 1,
      source: require('./brandImages/lightBrandLogoL.png'),
    },
    darkBrandLogoL: {
      logoRatio: 1,
      uri: 'https://s3.us-east-2.amazonaws.com/fintronu.com/Fintron-invest-logo_isotype-color+(2).png',
      source: require('./brandImages/darkBrandLogoL.png'),
    },
    colorBrandLogoL: {
      logoRatio: 1,
      uri: 'https://s3.us-east-2.amazonaws.com/fintronu.com/Fintron-invest-logo_isotype-color+(2).png',
      source: require('./brandImages/darkBrandLogoL.png'),
    },
    darkBrandLogoS: {
      logoRatio: 1,
      uri: 'https://s3.us-east-2.amazonaws.com/fintronu.com/Fintron-invest-logo_isotype-color+(2).png',
      source: require('./brandImages/darkBrandLogoL.png'),
    },
  },
};
