import { gql } from '@apollo/client';

export const GET_WITHDRAWABLE_AMOUNT = gql`
  query GetWithdrawableAmount($portfolioID: ID, $assetID: ID) {
    userAccountPortfolios(where: { id: $portfolioID }) {
      edges {
        node {
          id
          assetBalances(where: { hasAssetWith: { id: $assetID } }) {
            edges {
              node {
                balances {
                  withdrawable
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const RECURRING_RULE_FRAGMENT = gql`
  fragment RecurringRuleFragment on RecurringRule {
    amount
    lifetimeAmount
    createTime
    end
    fundingAccount {
      accountNumber
      id
    }
    fundingMethod
    id
    interval
    isActive
    name
    next
    nextTarget
    note
    reason
    sector {
      id
      largeImage
      name
      order
      security {
        id
        imageLogo
        symbol
        name
        market {
          last
          previousDayClose
        }
      }
      smallImage
      type
    }
    start
    type
    updateTime
    user {
      id
    }
    portfolio {
      id
    }
    asset {
      id
      type
      name
      symbol
      active
      ... on SecurityAsset {
        name
        imageLogo
        securityType
        market {
          last
          previousDayClose
        }
      }
    }
  }
`;

export const GET_RECURRING_RULES_V2 = gql`
  query GetRecurringRulesV2($where: RecurringRuleWhereInput) {
    recurringRules(where: $where) {
      edges {
        node {
          ...RecurringRuleFragment
        }
      }
    }
  }
  ${RECURRING_RULE_FRAGMENT}
`;
