import { SheetContent } from '../../Sheet';
import { WithholdingElectionForm } from '@/components/forms/WithholdingElectionForm/WithholdingElectionForm';

export const WithholdingElectionsSidePanel = () => {
  return (
    <SheetContent>
      <WithholdingElectionForm isSidePanel />
    </SheetContent>
  );
};
