import { WebCaption12 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import Image from 'next/image';
import { InfoIcon } from '~assets/icons';

type InfoWithIconProps = {
  message: string;
};

export const InfoWithIcon = ({ message }: InfoWithIconProps) => {
  return (
    <div className="flex gap-1">
      <Image alt="Info icon" width={14} height={14} src={InfoIcon.src} />
      <WebCaption12.Regular className="text-grey41 flex items-center gap-1">
        {message}
      </WebCaption12.Regular>
    </div>
  );
};
