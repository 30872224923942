import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';

export default {
  bankLink: {
    title: 'Contas bancárias vinculadas',
    description: `Altere ou atualize sua vinculação bancária usando o Plaid. Selecione uma conta bancária abaixo ou adicione uma nova conta para entrar no Plaid.\n\nGerencie suas contas bancárias vinculadas para ${FinTronConfig.robo.name} e ${FinTronConfig.selfDirected.name} usando o Plaid, uma ferramenta de verificação bancária de terceiros.`,
    account: 'Conta {{ account }}',
    discretionaryDescription: '',
  },
  security: {
    title: 'Segurança',
    password: 'Atualizar senha',
    twoFactorAuth: 'Autenticação de dois fatores',
    login: 'Login',
    loginDescription:
      'Enviaremos um e-mail ou SMS com o código de autenticação de dois fatores para cada login.',
  },
};
