import { useGetRetirementInfo } from '@fintronners/react-api/src/hooks/useGetRetirementInfo';
import { useMemo } from 'react';
import { getStateName } from '@/utils/strings';
import { useGetUserSettings } from '@fintronners/react-api/src/hooks/useGetUserSettings';
import useUserDetails from '@fintronners/react-widgets/src/hooks/useUserDetails';
import { differenceInYears } from 'date-fns';
import { UserProfileUserFilingStatus } from '@fintronners/react-api/src';

export enum StateWithholdingClass {
  MNBE = 'STATE_WITHHOLDING_CLASS_MAY_NOT_BE_ELECTED',
  VOLUNTARY = 'STATE_WITHHOLDING_CLASS_VOLUNTARY',
  MANDATORY = 'STATE_WITHHOLDING_CLASS_MANDATORY',
  MANDATORY_OPT_OUT = 'STATE_WITHHOLDING_CLASS_MANDATORY_OPT_OUT',
}

export enum StateWithholdingRateType {
  FIXED = 'STATE_WITHHOLDING_RATE_TYPE_FIXED',
  PCT_OF_FEDERAL = 'STATE_WITHHOLDING_RATE_TYPE_PCT_OF_FEDERAL',
}

export const useWithholdings = () => {
  const { userDetails } = useUserDetails();
  const { userSettings } = useGetUserSettings();

  const userState = userDetails?.profile?.state || 'CA';

  const { retirementFundamentalInfo } = useGetRetirementInfo({
    annualGrossIncome: userDetails?.profile?.annualGrossIncome || 100000,
    filingStatus: UserProfileUserFilingStatus.UserFilingStatusSingle,
    age: differenceInYears(new Date(), userDetails?.profile?.dateOfBirth),
    state: userState || 'CA',
  });

  const stateName = useMemo(() => {
    const stateValue = userSettings?.retirement?.defaultWithholding.state || userState;
    return stateValue ? getStateName(stateValue) : null;
  }, [userSettings, retirementFundamentalInfo]);

  const state = useMemo(() => {
    const stateWithholdingInfo = retirementFundamentalInfo?.withholding?.stateWithholding?.at(0);
    return {
      class: stateWithholdingInfo?.class || StateWithholdingClass.MNBE,
      rate: stateWithholdingInfo?.rate || '0',
      rateType: stateWithholdingInfo?.rateType || StateWithholdingRateType.FIXED,
    };
  }, [retirementFundamentalInfo]);

  const defaultWithholding = userSettings?.retirement?.defaultWithholding || {
    federalWithholding: {
      waive: parseFloat(retirementFundamentalInfo?.withholding?.federalWithholding?.rate) === 0,
      percentage: retirementFundamentalInfo?.withholding?.federalWithholding?.rate || '0',
    },
    stateWithholding: {
      waive:
        parseFloat(retirementFundamentalInfo?.withholding?.stateWithholding?.at(0)?.rate) === 0,
      percentage: retirementFundamentalInfo?.withholding?.stateWithholding?.at(0)?.rate || '0',
    },
  };

  const federalWithholdingRate = parseFloat(
    defaultWithholding?.federalWithholding?.percentage || 0,
  );

  const stateWithholdingRate =
    defaultWithholding?.stateWithholding?.percentage ||
    (state?.rate
      ? Number(parseFloat(defaultWithholding?.stateWithholding?.percentage).toFixed(2))
      : 0);

  const isFederalWaived = defaultWithholding?.federalWithholding?.waive;
  const isStateWaived = defaultWithholding?.stateWithholding?.waive;

  return {
    state: { ...state, name: stateName },
    federalWithholdingRate,
    isFederalWaived,
    stateWithholdingRate,
    isStateWaived,
  };
};
