import { gql } from '@apollo/client';

export const GET_EXTERNAL_ACCOUNT_DATA = gql`
  fragment ExternalAccountData on ExternalAccountEdge {
    node {
      id
      type
      accountNumber
      user {
        id
        firstName
        lastName
      }
      institution {
        id
        createTime
        updateTime
        name
        unusualCount
      }
      mask
      connectionStatus
    }
  }
`;

export const GET_EXTERNAL_ACCOUNTS_QUERY = gql`
  query GetExternalAccounts($userId: ID, $accountStatus: [ExternalAccountConnectionStatus!]) {
    externalAccounts(where: { hasUserWith: { id: $userId }, statusIn: $accountStatus }) {
      edges {
        ...ExternalAccountData
      }
    }
  }
  ${GET_EXTERNAL_ACCOUNT_DATA}
`;

export const GET_EXTERNAL_ACCOUNT_DETAIL = gql`
  query GetExternalAccount($accountId: ID!) {
    externalAccounts(where: { id: $accountId }) {
      edges {
        node {
          id
          type
          mask
          institution {
            name
          }
        }
      }
    }
  }
`;
