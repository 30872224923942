import React, { useEffect, useState, useCallback } from 'react';
import { addBusinessDays } from 'date-fns';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { V1RecurringRuleInterval } from '@fintronners/react-api/src';
import fLogger from '@fintronners/react-utils/src/fLogger';
import useRecurringRules from '@fintronners/react-widgets/src/hooks/useRecurringRules';
import useExternalAccounts, {
  AccountType,
} from '@fintronners/react-widgets/src/hooks/useExternalAccounts';

import { useTransfer } from '@/hooks/api/useTransfer';
import { useDepositInfo } from '@/hooks/api/useDepositInfo';
import { useRecurringTransfer } from '@/hooks/api/useRecurringTransfer';
import { useWithdrawableAmount } from '@/hooks/api/useWithdrawableAmount';
import { useWithdrawal } from '@/hooks/api/useWithdrawal';
import { useSidePanel } from '@/context/SidePanelContext';

import { DepositTypes, TransferFormData, transferSchema, TransferTypes } from './schema';
import { TransferSidePanelForm } from './TransferSidePanelForm';
import { TransferSidePanelConfirmation } from './TransferSidePanelConfirmation';
import { TransferSidePanelSuccess } from './TransferSidePanelSuccess';
import { ErrorSidePanel } from '../ErrorSidePanel/ErrorSidePanel';
import { useIsIRAAccount } from '@/hooks/utils/useCurrentAccountType';
import useFeatureFlags from '@/hooks/config/useFeatureFlags';

const tomorrow = addBusinessDays(new Date(), 1).toISOString();

const defaultFormValues = {
  transferType: TransferTypes.DEPOSIT,
  depositType: DepositTypes.ONE_TIME,
  amount: '0',
  availableWithdrawalAmount: '0',
  interval: V1RecurringRuleInterval.EveryWeek,
  selectedStartDate: tomorrow,
  taxYear: undefined,
};

export const TransferSidePanel = () => {
  const [hasError, setHasError] = useState(false);
  const [shouldDisableEntireForm, setShouldDisableEntireForm] = useState(false);
  const [formTransferType, setFormTransferType] = useState<TransferTypes>();

  const { tenants } = useFeatureFlags();
  const { isAlight } = tenants;

  const { currentFlowStep, goTo, goBack, setShowBackLink } = useSidePanel();

  const { data: depositInfo, loading: isDepositInfoLoading } = useDepositInfo();

  const { firstExternalAccount, fetchingAccounts } = useExternalAccounts(
    AccountType.BROKERAGE,
    undefined,
    false,
    isAlight,
  );
  const { data: withdrawableAmount, loading: isWithdrawableAmountLoading } =
    useWithdrawableAmount();
  const { createTransfer, fetchSummary, summary, isSummaryLoading } = useTransfer();
  const { recurringRules, loading: isRecurringRulesLoading } = useRecurringRules();
  const { createRecurringDeposit } = useRecurringTransfer();
  const { createWithdrawal } = useWithdrawal();
  const isIRAAccount = useIsIRAAccount();

  const targetRecurringRule = recurringRules.at(0)?.node;
  const { amount: existingAmount } = targetRecurringRule || {};

  const availableInstantDepositLimit =
    depositInfo?.userAccountPortfolios.edges?.[0]?.node?.availableInstantDeposit;

  const isLoading =
    isDepositInfoLoading ||
    fetchingAccounts ||
    isRecurringRulesLoading ||
    isWithdrawableAmountLoading;

  const form = useForm({
    resolver: yupResolver(transferSchema),
    defaultValues: defaultFormValues,
    disabled: isLoading || (isIRAAccount && shouldDisableEntireForm),
    values: {
      ...defaultFormValues,
      interval: recurringRules.at(0)?.node?.interval ?? V1RecurringRuleInterval.EveryWeek,
      selectedStartDate: recurringRules.at(0)?.node?.next ?? tomorrow,
      availableWithdrawalAmount:
        withdrawableAmount?.userAccountPortfolios?.edges?.[0]?.node?.assetBalances?.edges?.[0]?.node
          ?.balances?.withdrawable ?? '0',
      taxYear: isIRAAccount ? new Date().getFullYear() : undefined,
    },
  });

  const depositType = useWatch({ control: form.control, name: 'depositType' });

  useEffect(() => {
    if (depositType === DepositTypes.ONE_TIME) {
      form.setValue('amount', '0');
      return;
    }

    form.setValue('amount', existingAmount || '0');
  }, [depositType, existingAmount]);

  const handleToggleFormState = useCallback(
    () => setShouldDisableEntireForm(!shouldDisableEntireForm),
    [],
  );

  const handleSummaryStep = async () => {
    try {
      await fetchSummary({
        amount: form.getValues().amount.toString(),
        externalAccountUid: firstExternalAccount?.id,
      });
      goTo(1);
    } catch (error) {
      fLogger.error('Error fetching summary', error);
      setHasError(true);
    }
  };

  const handleConfirmTransfer = useCallback(
    async ({
      amount,
      interval,
      transferType,
      depositType: _depositType,
      selectedStartDate,
      taxYear,
    }: TransferFormData) => {
      try {
        if (transferType === TransferTypes.WITHDRAWAL) {
          await createWithdrawal({
            amount,
            accountId: firstExternalAccount?.id,
          });
          return;
        }

        if (_depositType === DepositTypes.ONE_TIME) {
          await createTransfer({
            amount,
            account: firstExternalAccount,
            taxYear,
          });
          return;
        }

        if (_depositType === DepositTypes.RECURRING_DEPOSIT) {
          await createRecurringDeposit({
            amount,
            recurringInterval: interval,
            selectedDate: selectedStartDate,
            account: firstExternalAccount,
          });

          return;
        }
      } catch (error) {
        fLogger.error(`Error creating ${_depositType} transfer`, error);
        setHasError(true);
      } finally {
        goTo(2);
        setShowBackLink(false);
      }
    },
    [createTransfer, createRecurringDeposit, firstExternalAccount, setShowBackLink, goTo],
  );

  if (hasError) {
    return <ErrorSidePanel onBack={() => setHasError(false)} />;
  }

  return (
    <FormProvider {...form}>
      <form>
        {currentFlowStep === 0 && (
          <TransferSidePanelForm
            isLoading={isLoading}
            availableInstantDepositLimit={availableInstantDepositLimit}
            account={firstExternalAccount}
            onContinue={handleSummaryStep}
            handleToggleFormState={handleToggleFormState}
            setFormTransferType={setFormTransferType}
          />
        )}

        {currentFlowStep === 1 && (
          <TransferSidePanelConfirmation
            summary={summary}
            isSummaryLoading={isSummaryLoading}
            account={firstExternalAccount}
            onContinue={form.handleSubmit(handleConfirmTransfer)}
            onBack={goBack}
          />
        )}

        {currentFlowStep === 2 && <TransferSidePanelSuccess formTransferType={formTransferType} />}
      </form>
    </FormProvider>
  );
};
