import { NetworkStatus, useQuery } from '@apollo/client';
import {
  GET_ACH_TRANSFER_HISTORY,
  GET_ALL_TRANSACTION_HISTORY,
  GET_TRADE_HISTORY,
  GetAchTransferHistoryQuery,
  GetAchTransferHistoryQueryVariables,
  GetAllHistoryQuery,
  GetAllHistoryQueryVariables,
  GetTradeHistoryQuery,
  GetTradeHistoryQueryVariables,
} from '@fintronners/react-api/src';
import GqlConfig from '@fintronners/react-api/src/graphql/rest/GqlConfig';
import { getBeginningOfToday } from '@fintronners/react-utils/src/dateUtils';
import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useScrollThreshold } from '~hooks/utils/useScrollThreshold';

export type UseActivityHistory = {
  /**
   * The portfolio that contains the activity items that will be
   * fetched.
   */
  portfolioId?: string;
  /**
   * The ref of the object that can be scrolled to trigger the
   * fetchMore operation
   */
  scrollableRef: RefObject<HTMLDivElement> | null;
  /**
   * Variable used variable in case you wanna disable the query
   */
  skip?: boolean;

  /**
   * The filters that will be applied to the query
   * transfer and trade queries will be executed
   * respectively
   */
  filters?: ActivityHistoryFilters;
};

export type GetActivityHistoryQuery = GetAllHistoryQuery &
  GetAchTransferHistoryQuery &
  GetTradeHistoryQuery;

export type GetActivityHistoryQueryVariables = GetTradeHistoryQueryVariables &
  GetAchTransferHistoryQueryVariables &
  GetAllHistoryQueryVariables;

export type ActivityHistoryFilters = Partial<
  Pick<
    GetActivityHistoryQueryVariables,
    'achType' | 'startDate' | 'endDate' | 'orderType' | 'tradeType' | 'allFilters'
  >
>;

/**
 * Hook used to get activity history items from a portfolio.
 * It triggers a fetch more data callback function when the user has
 * scrolled 80% of the scrollable div (ref param).
 * It doesn't execute the fetchMore operation when already loading
 * to avoid unecessary calls to the backend.
 *
 */
export const useActivityHistory = ({
  portfolioId,
  scrollableRef,
  skip,
  filters,
}: UseActivityHistory) => {
  const [nextCursor, setNextCursor] = useState<string | null>();

  const shouldSkip = useMemo(() => skip || portfolioId === undefined, [portfolioId, skip]);

  const { query, variables } = useMemo(() => {
    /* When no filters are provided */
    if (!filters) {
      return {
        query: GET_ALL_TRANSACTION_HISTORY,
        variables: {
          recentPages: true,
          todayStart: getBeginningOfToday(),
          endDate: getBeginningOfToday(),
        },
      };
    }

    const commonFilters = {
      startDate: filters.startDate,
      endDate: filters.endDate,
      recentPages: false,
    };

    /* When selecting Deposits/Withdrawal */
    if (filters?.achType) {
      return {
        query: GET_ACH_TRANSFER_HISTORY,
        variables: {
          ...commonFilters,
          achType: filters.achType,
        },
      };
    }

    /* When selecting Buy/Sell/Automated investments/Security */
    if (filters?.tradeType || filters?.allFilters) {
      return {
        query: GET_TRADE_HISTORY,
        variables: {
          ...commonFilters,
          tradeType: filters.tradeType,
          allFilters: filters.allFilters,
          today: getBeginningOfToday(),
        },
      };
    }

    /* When any of the other filters are provided */
    return {
      query: GET_ALL_TRANSACTION_HISTORY,
      variables: {
        ...commonFilters,
        orderType: filters.orderType ? [filters.orderType] : undefined,
        todayStart: getBeginningOfToday(),
      },
    };
  }, [filters]);

  const { data, loading, error, networkStatus, fetchMore } = useQuery<GetActivityHistoryQuery>(
    query,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      skip: shouldSkip,
      variables: {
        portfolioId,
        first: GqlConfig.page_size,
        ...variables,
      },
    },
  );

  useEffect(() => {
    if (data) {
      const hasNextPage = data.pages?.pageInfo?.hasNextPage;
      setNextCursor(hasNextPage ? data?.pages?.pageInfo?.endCursor : undefined);
    }
  }, [data]);

  const fetchNextPage = useCallback(async () => {
    if (!nextCursor || !data?.pages?.pageInfo?.hasNextPage || networkStatus !== NetworkStatus.ready)
      return;

    await fetchMore({
      variables: {
        after: nextCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return mergeData(prev, fetchMoreResult);
      },
    });
  }, [nextCursor, fetchMore, networkStatus]);

  /**
   * Use browser-only api to get the scroll amount
   */
  useScrollThreshold({ ref: scrollableRef, threshold: 0.8, onThresholdReached: fetchNextPage });

  return { data, loading, error };
};

const mergeData = (
  currentData: GetActivityHistoryQuery,
  newData: GetActivityHistoryQuery,
): GetActivityHistoryQuery => {
  return {
    __typename: newData.__typename,
    recentPages: currentData.recentPages,
    pages: {
      __typename: newData.pages?.__typename,
      pageInfo: newData.pages?.pageInfo,
      edges: [...(currentData.pages?.edges ?? []), ...(newData.pages?.edges ?? [])],
    },
  };
};
