import { gql } from '@apollo/client';

export const GET_ADMIN_ACH_TRANSFERS = gql`
  query GetAdminACHTransfers(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $startDate: Time
    $endDate: Time
    $criteria: String
    $allFilters: [ACHTransferWhereInput!]
    $orderDirection: OrderDirection!
  ) {
    response: achTransfers(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        and: $allFilters
        hasUserWith: {
          hasProfileWith: {
            or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
          }
        }
        unsettledTimeGTE: $startDate
        unsettledTimeLTE: $endDate
      }
      orderBy: { direction: $orderDirection, field: create_time }
    ) {
      edges {
        cursor
        node {
          id
          type
          amount
          status
          orderType
          transferFee
          instantDepositAmount
          orderStatus
          isReturned
          reason
          createTime
          user {
            profile {
              firstName
              lastName
            }
          }
          externalAccount {
            institution {
              id
              name
            }
            accountNumber
            id
          }
          portfolio {
            account {
              externalID
              type
            }
          }
          recurringRuleInstance {
            id
            rule {
              id
            }
          }
          statusChangeLog {
            status
            eventTime
            note
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_ACH_TRANSFER_DETAILS_BY_ID = gql`
  query GetAchTransferDetailsById($id: ID!) {
    response: achTransfers(where: { id: $id }) {
      edges {
        node {
          id
          type
          amount
          status
          orderStatus
          orderType
          transferFee
          note
          instantDepositAmount
          orderStatus
          isReturned
          reason
          externalID
          user {
            id
          }
          requestDetails {
            requestUUID
            requestor
            admin {
              id
              email
              createTime
              updateTime
              externalID
              firstName
              lastName
            }
          }
          externalAccount {
            institution {
              id
              name
            }
            accountNumber
            id
          }
          portfolio {
            account {
              externalID
              type
            }
            name
            user {
              username
              profile {
                firstName
                lastName
              }
            }
          }
          recurringRuleInstance {
            id
            rule {
              id
            }
          }
          statusChangeLog {
            status
            eventTime
            note
          }
        }
      }
    }
  }
`;
