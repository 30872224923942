'use client';

import React, { useMemo } from 'react';
import { ThemeProvider as ThemeProviderStyled } from 'styled-components';
export { useTheme } from 'styled-components';
import { AppTheme } from '@fintronners/react-utils/types/styled-components';
import { scale } from '@fintronners/react-utils/src/dimensions';
import { getRandomHexColor } from '@fintronners/react-utils/src/utilFunctions';
import localFont from 'next/font/local';
import { regenerateAllStyles } from '@fintronners/react-ui/src/GlobalStyling';
import { NextFont } from 'next/dist/compiled/@next/font';
import { NextApiRequest } from 'next';

const fsElliotProRegular = localFont({ src: '../fonts/FS-Elliot-ProRegular.otf' });
const myriadProRegular = localFont({ src: '../fonts/MyriadPro-Regular.otf' });
const openSansExtraBold = localFont({ src: '../fonts/OpenSans-ExtraBold.ttf' });
const oswaldLight = localFont({ src: '../fonts/Oswald-Light.ttf' });
const robotoRegular = localFont({ src: '../fonts/Roboto-Regular.ttf' });
const robotoBold = localFont({ src: '../fonts/Roboto-Bold.ttf' });
const robotoThin = localFont({ src: '../fonts/Roboto-Thin.ttf' });
const sourceSerifProRegular = localFont({ src: '../fonts/SourceSerifPro-Regular.otf' });

export const FontEnum: Record<string, NextFont> = {
  'FS-Elliot-ProRegular': fsElliotProRegular,
  'MyriadPro-Regular': myriadProRegular,
  'OpenSans-ExtraBold': openSansExtraBold,
  'Oswald-Light': oswaldLight,
  'Roboto-Regular': robotoRegular,
  'Roboto-Bold': robotoBold,
  'Roboto-Thin': robotoThin,
  'SourceSerifPro-Regular': sourceSerifProRegular,
};

const getRandomFontValue = () => {
  const fontKeys = Object.keys(FontEnum);
  const randomKey = fontKeys[Math.floor(Math.random() * fontKeys.length)];
  return FontEnum[randomKey];
};

/**
 * Gets a random theme.
 *
 * @returns
 */
export const getRandomTheme = (): AppTheme => {
  const randomHex = getRandomHexColor();
  const randomFont = getRandomFontValue();

  return {
    text: {
      fontFamily: `"${randomFont.style.fontFamily}"`, // must quote due to multiple fonts - https://github.com/styled-components/styled-components/issues/2403#issuecomment-466570387
    },
    colors: {
      background: '#ffffff',
      primary: randomHex,
      primaryDark: randomHex,
    },
    ui: {
      buttonBorderRadius: scale(Math.floor(Math.random() * 100)),
    },
    images: {
      lightBrandLogoL: {
        logoRatio: 1.5,
        uri: `https://picsum.photos/150/100?${randomHex}`,
      },
      darkBrandLogoL: {
        logoRatio: 1.5,
        uri: `https://picsum.photos/150/100?${randomHex}`,
      },
      colorBrandLogoL: {
        logoRatio: 1.5,
        uri: `https://picsum.photos/150/100?${randomHex}`,
      },
      darkBrandLogoS: {
        logoRatio: 1.5,
        uri: `https://picsum.photos/150/100?${randomHex}`,
      },
    },
  };
};
/**
 * Gets the FinTron theme.
 *
 * @returns
 */
export const getFinTronTheme = (): AppTheme => {
  const font = FontEnum['Roboto-Regular'];

  return {
    text: {
      fontFamily: `"${font.style.fontFamily}"`,
    },
    colors: {
      background: '#ffffff',
      primary: '#52b9e5',
      primaryDark: '#0000ff',
    },
    ui: {
      buttonBorderRadius: 5,
    },
    images: {
      lightBrandLogoL: {
        logoRatio: 1,
        uri: ``,
      },
      darkBrandLogoL: {
        logoRatio: 1,
        uri: `/images/login-logo-fintron.svg`,
      },
      colorBrandLogoL: {
        logoRatio: 1,
        uri: ``,
      },
      darkBrandLogoS: {
        logoRatio: 1,
        uri: `/images/logo-fintron.svg`,
      },
    },
  };
};

/**
 * Gets the Alight theme.
 *
 * @returns
 */
export const getAlightTheme = (): AppTheme => {
  const font = FontEnum['FS-Elliot-ProRegular'];

  return {
    text: {
      fontFamily: `"${font.style.fontFamily}"`,
    },
    colors: {
      background: '#ffffff',
      primary: '#266de2',
      primaryDark: '#0000ff',
    },
    ui: {
      buttonBorderRadius: 20,
    },
    images: {
      lightBrandLogoL: {
        logoRatio: 1,
        uri: ``,
      },
      darkBrandLogoL: {
        logoRatio: 1,
        uri: `/images/login-logo-alight.svg`,
      },
      colorBrandLogoL: {
        logoRatio: 1,
        uri: ``,
      },
      darkBrandLogoS: {
        logoRatio: 2,
        uri: `/images/logo-alight.svg`,
      },
      mobileLogo: {
        logoRatio: 1,
        uri: `/images/mobile-logo-alight.svg`,
      },
    },
  };
};

/**
 * Gets the Trabian "Thrive" theme.
 *
 * @returns
 */
export const getTrabianTheme = (): AppTheme => {
  const font = FontEnum['FS-Elliot-ProRegular'];

  return {
    text: {
      fontFamily: `"${font.style.fontFamily}"`,
    },
    colors: {
      background: '#ffffff',
      primary: '#5c3d6f',
      primaryDark: '#0000ff',
    },
    ui: {
      buttonBorderRadius: 20,
    },
    images: {
      lightBrandLogoL: {
        logoRatio: 1,
        uri: ``,
      },
      darkBrandLogoL: {
        logoRatio: 1,
        uri: `/images/login-logo-trabian.png`,
      },
      colorBrandLogoL: {
        logoRatio: 1,
        uri: ``,
      },
      darkBrandLogoS: {
        logoRatio: 1,
        uri: `/images/logo-trabian.png`,
      },
    },
  };
};

/**
 * Gets a custom theme using the specified request values.
 *
 * @returns
 */
export const getCustomTheme = (req: NextApiRequest): AppTheme => {
  const font = FontEnum[req.body.fontFamily];

  return {
    text: {
      fontFamily: `"${font.style.fontFamily}"`,
    },
    colors: {
      background: '#ffffff',
      primary: `#${req.body.primaryColor}`,
      primaryDark: '#0000ff',
    },
    ui: {
      buttonBorderRadius: req.body.buttonBorderRadius,
    },
    images: {
      lightBrandLogoL: {
        logoRatio: 1,
        uri: ``,
      },
      darkBrandLogoL: {
        logoRatio: 1,
        uri: req.body.largeLogoUri,
      },
      colorBrandLogoL: {
        logoRatio: 1,
        uri: ``,
      },
      darkBrandLogoS: {
        logoRatio: 1,
        uri: req.body.smallLogoUri,
      },
    },
  };
};

/**
 * Gets the error theme.
 *
 * @returns
 */
export const errorTheme: AppTheme = {
  text: {
    fontFamily: 'monospace',
  },
  colors: {
    background: '#ffffff',
    primary: '#ff0000',
    primaryDark: '#ff0000',
  },
  ui: {
    buttonBorderRadius: 0,
  },
  images: {
    lightBrandLogoL: {
      logoRatio: 1.5,
      uri: 'https://picsum.photos/150/100?ff0000',
    },
    darkBrandLogoL: {
      logoRatio: 1.5,
      uri: 'https://picsum.photos/150/100?ff0000',
    },
    colorBrandLogoL: {
      logoRatio: 1.5,
      uri: 'https://picsum.photos/150/100?ff0000',
    },
    darkBrandLogoS: {
      logoRatio: 1.5,
      uri: 'https://picsum.photos/150/100?ff0000',
    },
  },
} satisfies AppTheme;

export const ThemeProvider: React.FC<{ theme?: AppTheme; children: React.ReactNode }> = ({
  theme: providedTheme,
  children,
}) => {
  if (!providedTheme) {
    throw new Error('ThemeProvider requires a theme prop.');
  }
  const theme = useMemo(() => {
    // Apply font changes to typography imports.
    const fonts = providedTheme?.fonts?.classNames.join(' ');
    regenerateAllStyles({ fontFamily: fonts });
    return providedTheme;
  }, [providedTheme]);

  return <ThemeProviderStyled theme={theme}>{children}</ThemeProviderStyled>;
};
