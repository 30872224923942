import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import cn from '@/utils/tailwind';

const alertVariants = cva(
  'bg-grey7 relative w-full rounded-md p-2.5 pl-4 overflow-hidden [&>img]:float-left [&>img]:mr-1.5 [&>img]:mt-0.5 [&>h5~*]:mt-1.5 before:absolute before:content-[""] before:w-1.5 before:inset-0 before:right-auto ',
  {
    variants: {
      variant: {
        default: 'text-foreground',
        info: 'before:bg-primary [&>h5]:text-darkestBlue',
        warning: 'before:bg-orange [&>h5]:text-darkestBlue',
        danger: 'before:bg-red [&>h5]:text-darkestBlue',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
  <div ref={ref} role="alert" className={cn(alertVariants({ variant }), className)} {...props} />
));
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <h5 ref={ref} className={cn('leading-5 tracking-tight', className)} {...props} />
  ),
);
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('text-grey72 leading-4 w-full', className)} {...props} />
));
AlertDescription.displayName = 'AlertDescription';

export { Alert, AlertTitle, AlertDescription };
