import React from 'react';
import { useNProgress } from '@tanem/react-nprogress';
import cn from 'clsx';

const Loading: React.FC<{ isRouteChanging: boolean }> = ({ isRouteChanging }) => {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: isRouteChanging,
  });

  return (
    <>
      <div
        className={cn(
          {
            'opacity-0': isFinished,
            'opacity-90': !isFinished,
          },
          'linear pointer-events-none fixed left-0 top-0 z-50 w-full transition-opacity',
          `duration-${animationDuration}`,
        )}
      >
        <div
          className="bg-primary fixed left-0 top-0 h-1 w-full"
          style={{
            marginLeft: `${(-1 + progress) * 100}%`,
            transition: `margin-left ${animationDuration}ms linear`,
          }}
        >
          <div className="absolute right-0 block h-full w-24 translate-y-[-4px] rotate-3 transform" />
        </div>
      </div>
    </>
  );
};

export default Loading;
