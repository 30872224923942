import { useMemo } from 'react';
import { useAppStore } from '@/stores/app-store';
import { getPrivacyPolicyLink } from '@/config';

type TenantExternalResources = {
  privacyPolicyLink: string;
};

/**
 * Returns Tenant external resources such as web page links, statics links, etc.
 */
const useTenantExternalResources = (): TenantExternalResources => {
  const tenant = useAppStore((state) => state.tenant) ?? '';

  const externalResources = useMemo<TenantExternalResources>(
    () => ({
      privacyPolicyLink: getPrivacyPolicyLink(tenant),
      // NOTE: more to add
    }),
    [tenant],
  );

  return externalResources;
};

export default useTenantExternalResources;
