import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';

export default {
  sorry: 'Sorry',
  oops: 'Oops!',
  existingUser:
    'It looks like you already have some information on file. For security reasons, a person can only have one username.',
  existingAccount:
    'It looks like some of your information is already on file. Try logging in to continue.',
  createUser: `We are unable to create your profile at this time. Please confirm all information is entered correctly. If this issue persists, please contact support at ${FinTronConfig.supportEmail}.`,
  createAccount: `We are unable to create your account at this time. Please confirm all information is entered correctly. If this issue persists, please contact support at ${FinTronConfig.supportEmail}.`,
  enrollPreferences:
    'Something went wrong saving your enrollment preferences. Please check your settings and try again. If the issue persists, please contact support.',
  required: 'This field is required',
  minLength: 'At least {{minLength}} characters',
  maxLength: 'At most {{maxLength}} characters',
  minAmount: 'At least ${{minAmount}}',
  maxAmount: 'At most ${{maxAmount}}',
  minPercentAmount: 'Percentage must be at least {{minAmount}}%',
  maxPercentAmount: 'Percentage must be less than {{maxAmount}}%',
  invalidUsername: 'Username cannot contain special characters',
  invalidSSN: 'SSN must be exactly 9 digits',
  duplicateSSN: 'SSN already exists',
  duplicateEmail: 'Email already exists',
  duplicatePhoneNumber: 'Phone number already exists',
  passwordDoNotMatch: 'The passwords do not match',
  invalidPassword: 'Password must contain at least 1 special character (! @ # $…)',
  invalidPasswordUppercase: 'Password must contain at least one uppercase letter',
  invalidPasswordLowercase: 'Password must contain at least one lowercase letter',
  invalidPasswordNumber: 'Password must contain at least one number',
  invalidPasswordSpecialCharacter: 'Password must contain at least one special character',
  invalidPasswordLeadingTrailingSpace: 'Password cannot have leading or trailing whitespace',
  invalidName: '{{field}} cannot contain special characters and spaces',
  invalidCode: 'Code must be exactly 6 digits',
  invalidEmail: 'Invalid email format',
  invalidPhoneNumber: 'Invalid phone number',
  invalidPhoneNumberAdmin: 'Phone number must be 10 digits and include +1 ',
  birthCountryError: 'While on visa, cannot set birth country to United States',
  citizenshipCountryError: 'While on visa, cannot set citizenship country to United States',
  invalidDate: 'Invalid date',
  duplicatedField: '{{type}} is already in use. Please use another one.',
  phoneNumberMismatch: 'The phone number does not match the selected country',
  phoneNumberFormatBR: 'The phone number must follow the format +55 (AA) 9NNNN-NNNN',
  areaCodeInvalid: 'The area code is not valid for the selected country',
  phoneNumberFormatInvalid: 'Invalid phone number format',
  phoneNumberFormatUS: 'The phone number must follow the format +1 (NNN) NNN-NNNN',
  genericError:
    'We couldn’t process your request at this time. Please check your internet connection and try again. If this issue persists, please contact support at {{supportEmail}}.',
  wrongCode: 'The verification code you entered is incorrect.',
  noAddressProvided: 'Please provide an address',
};
