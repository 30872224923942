import React from 'react';
import Image from 'next/image';

import { WebBodyText18 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { Button } from '@/components/common/Button';
import useResponsiveDialog from '@/hooks/utils/useResponsiveDialog';
import { InfoPrimaryIcon } from '@/assets/icons';

const displayStrings: Record<string, string> = {
  title: 'Re-link bank account',
  description:
    'Your bank account connection requires you to log in again. Please log in to re-establish your bank link.',
  relink: 'Re-Link Account',
  close: 'Close',
};

interface RefreshItemDialogProps {
  open: boolean;
  setModalOpen: (open: boolean) => void;
  onRelinkItem?: () => void;
}

export const RefreshItemDialog = ({ open, setModalOpen, onRelinkItem }: RefreshItemDialogProps) => {
  const { ResponsiveDialog } = useResponsiveDialog();

  return (
    <ResponsiveDialog.Root open={open} onOpenChange={(isOpen) => setModalOpen(isOpen)}>
      <ResponsiveDialog.Content className="flex flex-col items-center">
        <Image src={InfoPrimaryIcon.src} height={48} width={48} alt="Information icon" />
        <ResponsiveDialog.Header>
          <ResponsiveDialog.Title>{displayStrings.title}</ResponsiveDialog.Title>
        </ResponsiveDialog.Header>

        <WebBodyText18.Regular className="text-grey72 text-center">
          {displayStrings.description}
        </WebBodyText18.Regular>

        <ResponsiveDialog.Footer className="w-full">
          <ResponsiveDialog.Close asChild>
            <div className="flex w-full flex-col gap-5">
              <Button className="w-full" onClick={onRelinkItem}>
                {displayStrings.relink}
              </Button>
              <Button variant="secondary" className="w-full">
                {displayStrings.close}
              </Button>
            </div>
          </ResponsiveDialog.Close>
        </ResponsiveDialog.Footer>
      </ResponsiveDialog.Content>
    </ResponsiveDialog.Root>
  );
};
