import { useQuery, QueryHookOptions } from '@apollo/client';
import { SECURITY_ASSET_DETAILS, SecurityAssetDetailsQuery } from '@fintronners/react-api/src';

type UseSecurityAssetDetailsProps = {
  securityId: string;
} & QueryHookOptions;

export const useSecurityAssetDetails = ({
  securityId,
  ...options
}: UseSecurityAssetDetailsProps) => {
  return useQuery<SecurityAssetDetailsQuery>(SECURITY_ASSET_DETAILS, {
    skip: !securityId,
    fetchPolicy: 'no-cache',
    variables: {
      securityId,
    },
    ...options,
  });
};
