export const errorMessages = {
  required: 'This field is required',
  minLength: (minLength: string | number) => `At least ${minLength} characters`,
  maxLength: (maxLength: string | number) => `At most ${maxLength} characters`,
  passwordDoNotMatch: 'The passwords do not match',
  invalidPassword: 'Password must contain at least 1 special character (! @ # $…)',
  invalidPasswordUppercase: 'Password must contain at least one uppercase letter',
  invalidPasswordLowercase: 'Password must contain at least one lowercase letter',
  invalidPasswordNumber: 'Password must contain at least one number',
  invalidPasswordSpecialCharacter: 'Password must contain at least one special character',
  invalidPasswordLeadingTrailingSpace: 'Password cannot have leading or trailing whitespace',
  invalidName: (field: string) => `${field} cannot contain special characters and spaces`,
  invalidEmail: 'Invalid email format',
  invalidPhoneNumber: 'Phone number must be 10 digits',
};
