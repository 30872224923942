import React from 'react';
import Image from 'next/image';
import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';

import { CalendarIcon } from '@/assets/icons';

import { Button } from '../Button';
import { DatepickerRootProps, DatepickerTrigger } from './base';
import { useDatepicker } from './base/Datepicker.context';
import { GenericDatepicker } from './GenericDatepicker';
import cn from '@/utils/tailwind';

export type SingleDatepickerProps = Omit<DatepickerRootProps, 'children'> & {
  fullWidth?: boolean;
  isPending?: boolean;
  showMonthAndYear?: boolean;
};

type SingleDatepickerTriggerProps = Pick<SingleDatepickerProps, 'fullWidth' | 'isPending'>;

const SingleDatepickerTrigger = ({ fullWidth, isPending }: SingleDatepickerTriggerProps) => {
  const { realValue, displayValue } = useDatepicker();

  return (
    <DatepickerTrigger>
      <Button
        variant="secondary"
        className={cn(
          'flex h-10 justify-start text-left font-normal gap-2',
          fullWidth ? 'w-full' : 'sm:w-[178px] w-[128px]',
          isPending ? 'bg-primary/20 text-primary' : '',
        )}
      >
        <Image alt="" width={15} height={15} src={CalendarIcon.src} />

        {realValue ? (
          <WebBodyText14.Medium
            className={cn('text-sm', isPending ? 'text-primary' : 'text-grey55')}
          >
            {displayValue}
          </WebBodyText14.Medium>
        ) : (
          <div className="text-grey41 font-regular text-sm tracking-wide">{displayValue}</div>
        )}
      </Button>
    </DatepickerTrigger>
  );
};

const SingleDatepicker: React.FC<SingleDatepickerProps> = ({
  fullWidth,
  isPending,
  showMonthAndYear,
  ...props
}) => {
  return (
    <GenericDatepicker
      showMonthAndYear={showMonthAndYear}
      trigger={<SingleDatepickerTrigger fullWidth={fullWidth} isPending={isPending} />}
      {...props}
    />
  );
};

SingleDatepicker.displayName = 'SingleDatepicker';

export { SingleDatepicker };
