import numbro from 'numbro';
import fLogger from './fLogger';

/**
 * Opposite of thousandFormatNumbro. Sometimes we need to display a
 * value to a user from a more precise number. This function is so that we
 * can get the exact value presented.
 *
 * @param inputValue the same number we put into thousandFormatNumbro
 */
export const rawThousandFormatNumbro = (inputValue: string | number | undefined) => {
  return NumberNoNaN(thousandFormatNumbro(inputValue, { thousandSeparated: false }));
};

export const thousandFormatNumbro = (
  value: string | number | undefined | null,
  formatOverride?: numbro.Format | undefined,
  fallback: string = '-',
) => {
  if (
    (Number.isNaN(value) || value === undefined || value === null || value === Infinity) &&
    fallback
  )
    return fallback;
  return numbro(value ?? 0).format({
    thousandSeparated: true,
    mantissa: 2,
    ...formatOverride,
  });
};

export const thousandFormatNumbroCurrency = (
  value: string | number | undefined,
  formatOverride?: numbro.Format | undefined,
  fallback: string = '-',
) => {
  if (
    (Number.isNaN(value) || value === undefined || value === null || value === Infinity) &&
    fallback
  )
    return fallback;
  return numbro(value ?? 0).format({
    output: 'currency',
    thousandSeparated: true,
    mantissa: 2,
    ...formatOverride,
  });
};

export const formatPercent = (
  value?: number | string | null,
  formatOverride?: numbro.Format | undefined,
) => {
  if (typeof value === 'string') {
    value = Number(value);
  }

  if (value === null || value === undefined || Number.isNaN(value)) {
    return '-%';
  }

  return numbro(value).format({
    output: 'percent',
    thousandSeparated: true,
    mantissa: 2,
    ...formatOverride,
  });
};

/**
 * Calculates the percent `part` represents in `total`.
 *
 * @returns A number between 0 and 1.
 */
export const calculatePercent = (part: number, total: number) => {
  // Preventing division by zero
  if (total === 0) {
    return 0;
  }

  return part / total;
};

/**
 * Helper function to parse a string as a number. This is useful in case you don't want
 * to handle NaN
 */
export const NumberNoNaN = (inputNumber?: string | number | undefined | null, fallback = 0) => {
  if (inputNumber === null || inputNumber === undefined) return fallback;
  if (Number.isNaN(Number(inputNumber))) {
    return fallback;
  }
  return Number(inputNumber);
};

export const CURRENCY_FORMATTER = new Intl.NumberFormat('en', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
  style: 'currency',
  useGrouping: true,
});

const APEX_DECIMAL_PRECISION = 5;

/**
 * Util function for displaying shares from APEX which will be
 * at most 5 units of precision.
 *
 * More convo:
 * https://fintron-workspace.slack.com/archives/C048SJZ6C9X/p1670537129337419
 *
 * @param shares Number of user shares, could be a decimal
 */
export const apexSharesShortFormat = (shares: number | null | undefined) => {
  // Intentional juggling check
  if (shares === null || shares === undefined) return '-';

  let extraFormat = {};
  switch (true) {
    case shares < 10:
      extraFormat = {
        mantissa: APEX_DECIMAL_PRECISION,
        totalLength: 6,
      };
      break;
    case shares < 1000:
      extraFormat = {
        mantissa: 2,
      };
      break;
    case shares < 100000:
      extraFormat = {
        mantissa: 0,
      };
      break;
    case shares < 1000000:
      extraFormat = {
        mantissa: 2,
        forceAverage: 'thousand',
      };
      break;
    default:
      extraFormat = {
        mantissa: 2,
        forceAverage: 'million',
      };
  }

  return numbro(shares).format({
    trimMantissa: true,
    thousandSeparated: true,
    roundingFunction: Math.floor,
    ...extraFormat,
  });
};

/**
 * Util function for displaying the reward points on the reward card.
 *
 * More convo:
 * https://fintron-workspace.slack.com/archives/C03CMSZSUKY/p1671030109826889
 *
 * @param points Number of reward points. We expect a whole number.
 */
export const rewardPointsFormat = (points: number | null) => {
  // Intentional juggling check
  if (points == null) return '-';

  let extraFormat = {};
  switch (true) {
    case points < 10000:
      extraFormat = {
        mantissa: 0,
      };
      break;
    default:
      extraFormat = {
        mantissa: 1,
        totalLength: 3,
      };
      break;
  }
  return numbro(points).format({
    // average: true,
    thousandSeparated: true,
    roundingFunction: Math.floor,
    ...extraFormat,
  });
};

export const thousandFormatIntNumbro = (value: string | number) => {
  try {
    if (!value) return '0';
    return numbro(value).format({ thousandSeparated: true, mantissa: 0 });
  } catch (e) {
    fLogger.error(e);
    return value;
  }
};

export const getDailyGainString = (isGain: boolean, value: number, percent: number) => {
  return `${thousandFormatNumbroCurrency(isGain ? value : -value, {
    forceSign: true,
  })} (${getDailyGainPercent(isGain, percent)})`;
};

export const getDailyGainPercent = (isGain: boolean, percent: number) => {
  return formatPercent((isGain ? percent : -percent) / 100, { mantissa: 2, forceSign: true });
};

export const deThousandFormatNumbro = (value: string) => {
  return numbro.unformat(value);
};

export const shortenNumberNumbro = (value: number | string, defaultFraction = true) => {
  if (!value) return defaultFraction ? '0.00' : '0';
  return numbro(value)
    .format({ average: true, mantissa: defaultFraction ? 2 : 0 })
    .toUpperCase();
};

export const trimToThreeDecimalPlaces = (value: string): string => {
  const match = value.match(/^\d+(\.\d{0,3})?/);
  return match ? match[0] : value;
};

export const decimalRoundUp = (value: number, exponent = APEX_DECIMAL_PRECISION): number => {
  const power = Math.pow(10, exponent);
  return Math.ceil(value * power) / power;
};

/**
 * Util function that returns whether the target number is between min and max.
 * The default is to make it inclusive. If you want to make only one side inclusive
 * then it's better to not use this function.
 * @param number target number to check
 * @param min the minimum number
 * @param max the maximum number
 * @param inclusive whether the range is inclusive or not
 * min < number < max vs min <= number <= max
 */
const isInRange = (number?: number, min?: number, max?: number, inclusive = true) => {
  if (min === undefined || max === undefined || number === undefined) return false;
  if (min === null || max === null || number === null) return false;

  if (inclusive) {
    return number >= min && number <= max;
  } else {
    return number > min && number < max;
  }
};

export default {
  isInRange,
};
