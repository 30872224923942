import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import { Button } from '@/components/common/Button';
import { YES_OR_NO } from '@fintronners/react-api/src/utils/formUtils/schemas/types';
import { getSimpleEnumKeysAsArray } from '@fintronners/react-utils/src';
import { useSidePanel } from '@/context/SidePanelContext';
import { ErrorSidePanel } from '@/components/common/SidePanels/ErrorSidePanel/ErrorSidePanel';
import { V1UpdateProfileRequest } from '@fintronners/react-api/src';
import { FOR_PERSONAL_USE } from '@fintronners/react-api/src/utils/constants';
import { getUserKycDisplayString } from '@fintronners/react-experience/src/Helpers/GraphQLMapUtilsTS';
import useUserDetails from '@fintronners/react-widgets/src/hooks/useUserDetails';
import useProfileService from '@/hooks/api/useProfileService';
import { securitiesProfessionalSchema, SecuritiesProfessionalFormData } from './schema';
import { InvestmentQuestionsRadio } from '../InvestmentQuestionsRadio';
import useCanUpdateProfile, { ApexRequestType } from '@/hooks/api/useCanUpdateProfile';
import PendingChangesNotice from '@/components/notices/PendingChangesNotice';

const displayStrings = {
  title: 'Securities professional',
  formOptions: {
    yes: 'Yes',
    no: 'No',
  },
  answer: 'Are you a securities professional?',
  labels: {
    isForPersonalUse: 'I am going to use market data for more than just personal use.',
    isAdvisorOrConsultant:
      'I receive stock market data from another provider at your place of work or elsewhere.',
    isRegisteredWithSecOrCftc: 'I am registered or qualified with the SEC or the CFTC.',
    requiresSecOrCftcRegistration:
      'I perform functions that would require me to register with the SEC, and or the CFTC or similar regulatory bodies.',
    investForOther: 'I invest with someone else’s money.',
    investForTheBenefitOfOther: 'I invest for the benefit of another person/entity. ',
    receivesDataFromExternalSource:
      'I am an investment advisor, asset manager, or financial consultant.',
    sharesProfit:
      'I have entered into an agreement to share the profit of my investing activities and or receive compensation for trading.',
    isRegisteredWithEntity:
      'I am currently registered with a security agency, exchange, or commodity/future entity.',
  },
  dialog: {
    isForPersonalUse: {
      descriptionTitle: 'Personal Use',
      description: `The question is asking if you plan on soley using the stock market data provided on this application for investing and research on the FinTron Invest Platform only.`,
    },
    requiresSecOrCftcRegistration: {
      descriptionTitle: 'Regulatory Body Registration',
      description: `Are you involved with any securities (stocks) related activities? If not, select no for this question.`,
    },
    receivesDataFromExternalSource: {
      descriptionTitle: 'Market Data',
      description: `If you trade for your own benefit, select no for this question.`,
    },
  },
  markedDataQuestions: 'Market data questions',
  marketDataDesc: 'Select all that apply to you. If you aren’t sure, it likely doesn’t apply.',
  isSecuritiesProfessionalDescriptionTitle: 'Securities Professionals',
  isSecuritiesProfessionalDescription:
    'A securities professional would be one of the following.\n\n' +
    'Is registered or qualified with: the Securities and Exchange Commission, the Commodities futures Trading Commission, any state securities agency, any exchange association, or any commodities of futures market or association.\n\n' +
    'Is engaged as an “investment advisor” as that term is defined in section 201 (11) of the investment Advisor’s Act of 1940 (whether or not registered or qualified under that Act).\n\n' +
    'Is employed by a bank or another organization that is exempt from registration under Federal and/or state securities laws to preform functions or qualified if he or she were to preform such functions for an organization not so exempt. ',
  save: 'Save',
  back: 'Back',
};

export const SecuritiesProfessionalForm = () => {
  const userApiService = useProfileService();
  const { userDetails } = useUserDetails();

  const { goBack } = useSidePanel();
  const [hasError, setHasError] = useState(false);

  const marketData = userDetails?.profile?.marketDataAnswers;

  const isSecuritiesProfessional =
    marketData?.advisorOrConsultant ||
    marketData?.investForOther ||
    marketData?.personalUse === false ||
    marketData?.registeredWithSecOrCftc ||
    marketData?.requiresSecOrCftcRegistration ||
    marketData?.investForTheBenefitOfOther ||
    marketData?.gettingDataFromExternalSource ||
    marketData?.sharesProfit ||
    marketData?.registeredWithEntity
      ? YES_OR_NO.YES
      : YES_OR_NO.NO;

  const {
    control,
    formState: { isValid, isDirty },
    watch,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(securitiesProfessionalSchema),
    values: {
      isSecuritiesProfessional: isSecuritiesProfessional,
      isForPersonalUse: getUserKycDisplayString(
        !marketData?.personalUse,
      ).toUpperCase() as YES_OR_NO,
      isAdvisorOrConsultant: getUserKycDisplayString(
        marketData?.advisorOrConsultant,
      ).toUpperCase() as YES_OR_NO,
      isRegisteredWithSecOrCftc: getUserKycDisplayString(
        marketData?.registeredWithSecOrCftc,
      ).toUpperCase() as YES_OR_NO,
      requiresSecOrCftcRegistration: getUserKycDisplayString(
        marketData?.requiresSecOrCftcRegistration,
      ).toUpperCase() as YES_OR_NO,
      investForOther: getUserKycDisplayString(
        marketData?.investForOther,
      ).toUpperCase() as YES_OR_NO,
      investForTheBenefitOfOther: getUserKycDisplayString(
        marketData?.investForTheBenefitOfOther,
      ).toUpperCase() as YES_OR_NO,
      receivesDataFromExternalSource: getUserKycDisplayString(
        marketData?.gettingDataFromExternalSource,
      ).toUpperCase() as YES_OR_NO,
      sharesProfit: getUserKycDisplayString(marketData?.sharesProfit).toUpperCase() as YES_OR_NO,
      isRegisteredWithEntity: getUserKycDisplayString(
        marketData?.registeredWithEntity,
      ).toUpperCase() as YES_OR_NO,
    },
  });

  const isSecuritiesProfessionalValue = watch('isSecuritiesProfessional');
  const formValues = watch();

  const { isProfilePending, refetchUpdateProfileStatus } = useCanUpdateProfile(
    ApexRequestType.STOCK_PROFILE,
  );

  const saveDisabled = useMemo(() => {
    return (
      !isDirty ||
      !isValid ||
      (isSecuritiesProfessionalValue === YES_OR_NO.YES &&
        formValues.isForPersonalUse === YES_OR_NO.NO &&
        formValues.isAdvisorOrConsultant === YES_OR_NO.NO &&
        formValues.isRegisteredWithSecOrCftc === YES_OR_NO.NO &&
        formValues.requiresSecOrCftcRegistration === YES_OR_NO.NO &&
        formValues.investForOther === YES_OR_NO.NO &&
        formValues.investForTheBenefitOfOther === YES_OR_NO.NO &&
        formValues.receivesDataFromExternalSource === YES_OR_NO.NO &&
        formValues.sharesProfit === YES_OR_NO.NO &&
        formValues.isRegisteredWithEntity === YES_OR_NO.NO)
    );
  }, [formValues, isDirty, isValid, isSecuritiesProfessionalValue]);

  const onUpdateControlPerson = async (data: SecuritiesProfessionalFormData) => {
    const request: V1UpdateProfileRequest = {
      profileParameters: {
        marketDataQuestionsAnswers:
          data.isSecuritiesProfessional === YES_OR_NO.YES
            ? {
                isForPersonalUse: data.isForPersonalUse !== YES_OR_NO.YES,
                receivesDataFromExternalSource:
                  data.receivesDataFromExternalSource === YES_OR_NO.YES,
                isRegisteredWithSecOrCftc: data.isRegisteredWithSecOrCftc === YES_OR_NO.YES,
                requiresSecOrCftcRegistration: data.requiresSecOrCftcRegistration === YES_OR_NO.YES,
                investForOther: data.investForOther === YES_OR_NO.YES,
                investForTheBenefitOfOther: data.investForTheBenefitOfOther === YES_OR_NO.YES,
                isAdvisorOrConsultant: data.isAdvisorOrConsultant === YES_OR_NO.YES,
                sharesProfit: data.sharesProfit === YES_OR_NO.YES,
                isRegisteredWithEntity: data.isRegisteredWithEntity === YES_OR_NO.YES,
              }
            : FOR_PERSONAL_USE,
      },
    };

    try {
      await userApiService.userServiceUpdateProfile(request);

      refetchUpdateProfileStatus();
      goBack();
    } catch (error) {
      setHasError(true);
    }
  };

  if (hasError) return <ErrorSidePanel onBack={() => setHasError(false)} />;

  return (
    <SheetContent className="overflow-hidden">
      <form className="flex h-full flex-col" onSubmit={handleSubmit(onUpdateControlPerson)}>
        <SheetHeader>
          <SheetTitle>{displayStrings.title}</SheetTitle>
        </SheetHeader>

        <div className="flex h-full flex-col gap-7 overflow-auto">
          <div>{isProfilePending && <PendingChangesNotice />}</div>

          <InvestmentQuestionsRadio
            title={displayStrings.answer}
            name="isSecuritiesProfessional"
            dialogTitle={displayStrings.isSecuritiesProfessionalDescriptionTitle}
            dialogContent={displayStrings.isSecuritiesProfessionalDescription}
            items={getSimpleEnumKeysAsArray(
              YES_OR_NO,
              displayStrings.formOptions as Record<string, string>,
            )}
            control={control}
          />

          {isSecuritiesProfessionalValue === YES_OR_NO.YES && (
            <div className="flex flex-col gap-2">
              <ul className="flex flex-col gap-4">
                {Object.keys(securitiesProfessionalSchema.fields).map((field, index) => (
                  <React.Fragment key={index}>
                    {field !== 'isSecuritiesProfessional' && (
                      <li>
                        <div className="flex justify-between gap-2">
                          <div className="w-full">
                            <InvestmentQuestionsRadio
                              title={
                                displayStrings.labels[field as keyof typeof displayStrings.labels]
                              }
                              dialogTitle={
                                displayStrings.dialog[field as keyof typeof displayStrings.dialog]
                                  ?.descriptionTitle
                              }
                              dialogContent={
                                displayStrings.dialog[field as keyof typeof displayStrings.dialog]
                                  ?.description
                              }
                              name={field}
                              items={getSimpleEnumKeysAsArray(
                                YES_OR_NO,
                                displayStrings.formOptions as Record<string, string>,
                              )}
                              control={control}
                            />
                          </div>
                        </div>
                      </li>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          )}
        </div>

        <SheetFooter>
          <div className="mt-8 flex w-full flex-col">
            <Button variant="primary" type="submit" className="mb-4" disabled={saveDisabled}>
              {displayStrings.save}
            </Button>
            <Button variant="secondary" type="button" onClick={goBack}>
              {displayStrings.back}
            </Button>
          </div>
        </SheetFooter>
      </form>
    </SheetContent>
  );
};
