import { gql } from '@apollo/client';

export const TRADE_FRAGMENT = gql`
  fragment TradeFragment on Trade {
    id
    base {
      id
      symbol
      name
    }
    orderStatus
    side
    tradeType: type
    limit
    isAll
    requestAmount
    requestInQuote
    stop
    reason
    errorCode
    recurringRuleInstance {
      id
      rule {
        asset {
          symbol
        }
        sector {
          name
        }
        id
      }
    }
    liquidation {
      id
    }
    expiration
    expirationType
    tradeStatus: status
    tradeStatusChangeLog: statusChangeLog(status: TRADE_STATUS_SETTLED) {
      status
      eventTime
    }
    baseAmount
    quoteAmount
    createTime
    updateTime
  }
`;

export const TRADE_EDGE_FRAGMENT = gql`
  fragment TradeEdgeFragment on TradeEdge {
    node {
      ...TradeFragment
    }
    cursor
  }
  ${TRADE_FRAGMENT}
`;
