import {
  WebBodyText14,
  WebBodyText20,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { SheetHeader, SheetTitle } from '../Sheet';
import Skeleton from '../Skeleton/Skeleton';

const displayStrings = {
  title: 'Deposit summary',
  dollarValue: 'Dollar value',
};

const SkeletonTransferConfirmation = () => {
  return (
    <div className="space-y-5">
      <SheetHeader>
        <SheetTitle>
          <WebBodyText20.Bold className="text-darkestBlue">
            {displayStrings.title}
          </WebBodyText20.Bold>
        </SheetTitle>
      </SheetHeader>
      {Array.from({ length: 3 }).map((_, index) => (
        <Skeleton key={index} className="w-full h-10" />
      ))}
      <WebBodyText14.Bold className="text-grey72 justify-self-end">
        {displayStrings.dollarValue}
      </WebBodyText14.Bold>
      <Skeleton className="w-full h-10" />
      <hr className="border-grey55" />
      <Skeleton className="w-full h-4" />
      <Skeleton className="w-full h-24" />
    </div>
  );
};

export default SkeletonTransferConfirmation;
