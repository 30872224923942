import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { thousandFormatNumbroCurrency } from '@fintronners/react-utils/src/numberUtilsTSX';
import { TradeCurrency } from './TradeCurrency';
import { TradeSide } from '../schema';

const displayStrings = {
  available: 'Available',
};

interface TradeMarketBuyFormProps {
  tradableInCash: number;
}

export const TradeMarketBuyForm = ({ tradableInCash }: TradeMarketBuyFormProps) => {
  return (
    <div>
      <TradeCurrency side={TradeSide.BUY} />
      <WebBodyText14.Regular className="text-primary">
        {thousandFormatNumbroCurrency(tradableInCash)} {displayStrings.available}
      </WebBodyText14.Regular>
    </div>
  );
};
