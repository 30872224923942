export type PlaceResult = google.maps.places.PlaceResult;

export type PlaceInfo = {
  location: string;
  city: string;
  state: string;
  zipCode: string;
};

export const getPlaceInfo = (place: PlaceResult | null): PlaceInfo => {
  const cityComponent = place?.address_components?.find((component) =>
    component.types.includes('locality'),
  );

  const stateComponent = place?.address_components?.find((component) =>
    component.types.includes('administrative_area_level_1'),
  );

  const zipComponent = place?.address_components?.find((component) =>
    component.types.includes('postal_code'),
  );

  const location = getLocation(place);

  return {
    location: location ?? '',
    city: cityComponent?.long_name ?? 'N/A',
    state: stateComponent?.short_name ?? 'N/A',
    zipCode: zipComponent?.long_name ?? 'N/A',
  };
};

export const getLocation = (place: PlaceResult | null): string | null => {
  const lat = place?.geometry?.location?.lat();
  const lng = place?.geometry?.location?.lng();

  if (!lat || !lng) return null;
  return `${lat},${lng}`;
};
