import React, { useState } from 'react';
import Menu from '@/components/common/Layout/Menu';
import MobileMenu from '@/components/common/Layout/MobileMenu';
import Loading from '@/components/common/Loading/Loading';
import useIsMobile from 'hooks/utils/useIsMobile';
import { useUserStore } from '@/stores/user-store';
import useUserDetails from '@fintronners/react-widgets/src/hooks/useUserDetails';
import useLoadingBar from '@/hooks/utils/useLoadingBar';
import cn from '@/utils/tailwind';
import type { JwtIdToken } from '@/middleware';
import type { ConfigType, ThemeType } from '@/config/utils';

type LayoutProps = {
  idToken: JwtIdToken | null;
  children: React.ReactNode;
  theme: ThemeType;
  config: ConfigType;
  className?: string | undefined;
};

const Layout: React.FC<LayoutProps> = ({ idToken, theme, config, className, children }) => {
  const setUserData = useUserStore((state) => state.setUserData);
  const isMobile = useIsMobile();
  const loadingBar = useLoadingBar();

  useUserDetails('cache-and-network', (userData) => {
    const { firstName, lastName, phoneNumber, streetAddress, city, state, zipCode, email } =
      userData.users.edges?.at(0)?.node?.profile ?? {};

    setUserData({
      firstName,
      lastName,
      phoneNumber,
      streetAddress,
      city,
      state,
      zipCode,
      email,
    });
  });

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <div
      className={cn('flex h-screen flex-col flex-nowrap overflow-hidden md:flex-row', className)}
    >
      {/* Loading bar */}
      <Loading isRouteChanging={loadingBar.isRouteChanging} key={loadingBar.loadingKey} />

      {/* Desktop Menu */}
      <Menu
        idToken={idToken}
        isMobileBreakpoint={isMobile}
        isMobileMenuOpen={isMobileMenuOpen}
        closeHandler={() => {
          setIsMobileMenuOpen(false);
        }}
      />

      <div className="stable-scrollbar-gutter w-full overflow-y-auto border-l border-gray-100">
        {/* Mobile Menu */}
        <MobileMenu
          config={config}
          theme={theme}
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
        />

        {/* Page Content */}
        <div className="p-4 lg:px-6 lg:py-8" data-page-layout>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
