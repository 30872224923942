import Image, { ImageProps } from 'next/image';
import React, { useEffect, useState } from 'react';

interface ImageWithFallbackProps extends Omit<ImageProps, 'src'> {
  src: string;
  fallbackSrc: string;
  alt: string;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  src,
  alt,
  fallbackSrc,
  ...props
}) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [src]);

  return (
    <Image src={error ? fallbackSrc : src} alt={alt} onError={() => setError(true)} {...props} />
  );
};

export default ImageWithFallback;
