import { gql } from '@apollo/client';

export const GET_ADMIN_USER_ROBOGOAL_FRAGMENT = gql`
  fragment AdminUserRoboGoalFragment on RoboGoal {
    id
    targetPortfolioID
    accountPortfolioID
    name
    isActive
    accountPortfolio {
      totalValue
      account {
        id
        externalID
      }
    }
    user {
      id
    }
    targetPortfolio {
      externalReferenceID
    }
    isAutoInfusionPaused
    allocation {
      fiatPct
      stockPct
      bondPct
      totalValue
      drift
    }
    parameters {
      infusionAmount
      infusionFreq
      endDate
      targetAmount
      riskProfile
      goalPriority
      infusionAmount
      infusionFreq
      initialInvestment
      maxDrift
    }
    accountPortfolio {
      id
      name
      totalValue
    }
    projection {
      goalProbability
    }
    fundingAccount {
      accountNumber
    }
    createTime
    updateTime
    actions(where: { type: ROBO_ACTIVITY_TYPE_INFUSION, status: ROBO_ACTION_STATUS_DONE }) {
      edges {
        node {
          details {
            ... on RoboTransferDetails {
              amount
            }
          }
        }
      }
    }
  }
`;

export const GET_ADMIN_USER_ROBOGOALS = gql`
  query GetAdminUserRoboGoals($id: ID) {
    roboGoals(where: { hasUserWith: { id: $id } }) {
      edges {
        node {
          ...AdminUserRoboGoalFragment
        }
      }
    }
  }
  ${GET_ADMIN_USER_ROBOGOAL_FRAGMENT}
`;

export const GET_ADMIN_USER_ROBO_GOALS_BY_USERID = gql`
  query GetAdminUserRoboGoalsByUserId($id: ID) {
    users(where: { id: $id }) {
      edges {
        node {
          roboGoals {
            edges {
              node {
                accountPortfolio {
                  account {
                    id
                  }
                }
                id
                isActive
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ADMIN_USER_ROBO_GOAL_BY_ID = gql`
  query GetAdminUserRoboGoalById($id: ID) {
    roboGoals(where: { id: $id }) {
      edges {
        node {
          ...AdminUserRoboGoalFragment
        }
      }
    }
  }
  ${GET_ADMIN_USER_ROBOGOAL_FRAGMENT}
`;

export const GET_ADMIN_USER_ROBOGOAL_BY_GOALID = gql`
  query GetAdminUserRoboGoalByGoalId($id: ID) {
    roboGoals(where: { id: $id }) {
      edges {
        node {
          ...AdminUserRoboGoalFragment
        }
      }
    }
  }
  ${GET_ADMIN_USER_ROBOGOAL_FRAGMENT}
`;

export const GET_ADMIN_ROBO_ALLOCATION_CARD = gql`
  query GetAdminRoboAllocationCard($roboId: ID) {
    roboGoals(where: { id: $roboId }) {
      edges {
        node {
          id
          targetPortfolio {
            id
            externalReferenceID
          }
          allocation {
            stockPct
            bondPct
            fiatPct
            drift
          }
          parameters {
            maxDrift
          }
        }
      }
    }
    roboGeneralInvestments(where: { id: $roboId }) {
      edges {
        node {
          id
          targetPortfolio {
            id
            externalReferenceID
          }
          allocation {
            stockPct
            bondPct
            fiatPct
            drift
          }
        }
      }
    }
  }
`;

export const GET_ADMIN_ROBO_AUTOMATED_INVESTMENTS_CARD = gql`
  query GetAdminRoboAutomatedInvestmentsCard($roboId: ID) {
    roboGoals(where: { id: $roboId }) {
      edges {
        node {
          id
          isAutoInfusionPaused
          parameters {
            endDate
            targetAmount
            infusionAmount
            infusionFreq
            riskProfile
            goalPriority
          }
          user {
            id
          }
        }
      }
    }
    roboGeneralInvestments(where: { id: $roboId }) {
      edges {
        node {
          id
          isAutoInfusionPaused
          recurringInfusion
          infusionFrequency
          user {
            id
          }
        }
      }
    }
  }
`;

export const GET_ADMIN_ROBO_GOALS_BY_ACCOUNT_ID = gql`
  query GetAdminGoalsByAccountId($id: ID) {
    roboGoals(where: { hasAccountPortfolioWith: { hasAccountWith: { id: $id } } }) {
      edges {
        node {
          id
          name
          accountPortfolioID
        }
      }
    }
  }
`;

export const GET_ADMIN_ROBO_DRIFT_POPOUT = gql`
  query GetAdminRoboDriftPopout($id: ID) {
    roboGoals(where: { id: $id }) {
      edges {
        node {
          id
          allocation {
            drift
          }
          parameters {
            maxDrift
          }
        }
      }
    }
    roboGeneralInvestments(where: { id: $id }) {
      edges {
        node {
          id
          allocation {
            drift
          }
        }
      }
    }
  }
`;
