import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  WebBodyText12,
  WebBodyText16,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { Button } from '@/components/common/Button';
import FormTextField from '@/components/common/Forms/fields/FormTextField';
import { SheetFooter, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import { useSidePanel } from '@/context/SidePanelContext';
import { NewPasswordData, newPasswordSchema } from '../schema';
import useAuthService from '@/hooks/api/useAuthService';

const displayStrings = {
  updatePasswordSidePanelContinue: 'Continue',
  updatePasswordSidePanelCancel: 'Cancel',

  updatePasswordSidePanelResetCodeLabel: 'Code',
  updatePasswordSidePanelResetCodeDescripton:
    'We’ve sent your password reset code via email. Please enter it above to continue with your password reset.',
  updatePasswordSidePanelResetCodePlaceholder: 'Enter password code',

  updatePasswordSidePanelNewPasswordTitle: 'New password',
  updatePasswordSidePanelNewPasswordDescripton: 'Please reset your password below.',
  updatePasswordSidePanelNewPasswordPlaceholder: 'Enter new password',
  updatePasswordSidePanelNewPasswordInputDescription:
    'Your password must be a minimum of 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character for enhanced security.',
  updatePasswordSidePanelNewPasswordConfirmLabel: 'Confirm new password',
  updatePasswordSidePanelNewPasswordConfirmPlaceholder: 'Enter new password',
};

export const ResetPasswordForm = () => {
  const authService = useAuthService();

  const {
    formState: { errors, isValid, isSubmitting },
    register,
    handleSubmit,
  } = useForm<NewPasswordData>({
    mode: 'onChange',
    resolver: yupResolver(newPasswordSchema),
    defaultValues: {
      code: '',
      password: '',
      confirmPassword: '',
    },
  });

  const { closeSidePanel, goTo, sidePanelProps } = useSidePanel();

  const onSubmit = async ({ code, password }: NewPasswordData) => {
    if (!isValid) {
      return;
    }

    try {
      await authService.authServiceConfirmForgotPassword({
        confirmationCode: code,
        password,
        username: sidePanelProps?.username,
      });

      goTo(2);
    } catch (error) {
      goTo(3);
    }
  };

  return (
    <form
      className="flex flex-col gap-5 h-full"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <SheetHeader>
        <SheetTitle>{displayStrings.updatePasswordSidePanelNewPasswordTitle}</SheetTitle>
      </SheetHeader>
      <div className="flex flex-col h-full gap-5 justify-between">
        <div className="flex flex-col gap-[20px]">
          <WebBodyText16.Regular className="text-grey72 text-left w-full">
            {displayStrings.updatePasswordSidePanelNewPasswordDescripton}
          </WebBodyText16.Regular>
          <div className="flex flex-col">
            <FormTextField
              label={displayStrings.updatePasswordSidePanelResetCodeLabel}
              placeholder={displayStrings.updatePasswordSidePanelResetCodePlaceholder}
              fieldError={errors.code}
              {...register('code')}
            />
            {!errors.code && (
              <WebBodyText12.Regular className="text-grey72 text-left w-full">
                {displayStrings.updatePasswordSidePanelResetCodeDescripton}
              </WebBodyText12.Regular>
            )}
          </div>
          <div className="flex flex-col">
            <FormTextField
              label={displayStrings.updatePasswordSidePanelNewPasswordTitle}
              placeholder={displayStrings.updatePasswordSidePanelNewPasswordPlaceholder}
              fieldError={errors.password}
              type="password"
              {...register('password')}
            />
            {!errors.password && (
              <WebBodyText12.Regular className="text-grey72 text-left w-full">
                {displayStrings.updatePasswordSidePanelNewPasswordInputDescription}
              </WebBodyText12.Regular>
            )}
          </div>
          <div className="flex flex-col">
            <FormTextField
              label={displayStrings.updatePasswordSidePanelNewPasswordConfirmLabel}
              placeholder={displayStrings.updatePasswordSidePanelNewPasswordConfirmPlaceholder}
              fieldError={errors.confirmPassword}
              type="password"
              {...register('confirmPassword')}
            />
          </div>
        </div>
        <SheetFooter>
          <div className="flex flex-col justify-center items-center w-full gap-5">
            <Button
              className="w-full"
              variant="primary"
              disabled={!isValid || isSubmitting}
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              {displayStrings.updatePasswordSidePanelContinue}
            </Button>
            <Button onClick={closeSidePanel} className="w-full" variant="secondary">
              {displayStrings.updatePasswordSidePanelCancel}
            </Button>
          </div>
        </SheetFooter>
      </div>
    </form>
  );
};
