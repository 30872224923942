import { useQuery } from '@apollo/client';
import { GET_SECURITY_TRADING_DATA, GetSecurityTradingDataQuery } from '@fintronners/react-api/src';

type UseSecurityTradingDataProps = {
  securityId: string;
  portfolioId: string;
  fiatId: string;
};

export const useSecurityTradingData = ({
  securityId,
  portfolioId,
  fiatId,
}: UseSecurityTradingDataProps) => {
  const tradingData = useQuery<GetSecurityTradingDataQuery>(GET_SECURITY_TRADING_DATA, {
    skip: !securityId || !portfolioId || !fiatId,
    fetchPolicy: 'no-cache',
    variables: {
      securityId: securityId,
      balanceIds: [fiatId, securityId],
      portfolioId: portfolioId ?? '',
    },
  });

  return {
    ...tradingData,
    fiatId,
  };
};
