import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';

export default {
  loader: {
    title: 'Working on your plan.',
    subtitle:
      'Our investment algorithms are processing your information to create an investment plan just for you.',
  },
  landing: {
    title: 'How it works.',
    yourRiskTolerance: 'Your risk tolerance',
    riskToleranceOptions: [
      {
        title: 'Conservative',
        description:
          'Designed for individuals who prioritize stability over growth and have a low tolerance for risk. It typically contains low-risk bonds.',
      },
      {
        title: 'Moderately Conservative',
        description:
          'Is a good choice for those who want some growth but are still cautious. It balances bonds with a moderate amount of stocks.',
      },
      {
        title: 'Moderate',
        description:
          'Is aimed at investors who have a balanced risk tolerance and a desire for moderate growth. This portfolio contains a mix of stocks and bonds.',
      },
      {
        title: 'Moderately Aggressive',
        description:
          'Is for investors who are willing to take on more risk for the potential of higher returns. This portfolio contains a higher proportion of stocks.',
      },
      {
        title: 'Aggressive',
        description:
          'Is for investors who have a high tolerance for risk and a strong desire for growth. It is heavily weighted towards stocks.',
      },
    ],
  },
  rtqConfirmationTrigger: {
    label: 'Select here to make edits to your investor questions.',
  },
  rtqConfirmation: {
    title: 'Risk tolerance questionnaire',
    subtitle: `Changing the answers to these questions may impact your target portfolio.`,
    changeAnswer: 'Change answer',
    thankYouTitle: 'Thank you',
    thankYouDescription:
      'The updates you are making to your risk tolerance questions will not currently impact your recommended portfolio.',
    thankYouButtonText: 'Save Answers',
    warningTitle: 'Warning',
    warningDescription:
      'To save your answers, you are required to review the recommended portfolio.',
    warningButtonText: 'Review Recommended Portfolio',
  },
  rtqAgreement: {
    title: 'Create General Investing.',
    description: `By submitting this general investing account, ${FinTronConfig.robo.name} will initiate your initial deposit and your automated additional investments. You can always delete your general investing account in the app settings.`,
    createdTitle: 'General Investing Created',
    createdDescription: 'Congratulations! Your General Investing account has been created.',
  },
};
