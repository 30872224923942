import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Image from 'next/image';
import { Button } from '~components/common/Button';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~components/common/Select';
import { Textarea } from '~components/common/Textarea/Textarea';
import LabelText from '~components/shims/LabelText';
import { contactFormSchema, FormValues } from './validationSchema';
import { TICKET_TYPES, PRODUCT_TYPES, CATEGORY_MAP } from './constants';
import { WebCaption10 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { useSidePanel } from '~context/SidePanelContext';
import { useUserStore } from '~stores/user-store';
import { useContactForm } from '~hooks/utils/useContactForm';
import LoadingIcon from '@/assets/icons/loading.svg';

const displayStrings = {
  contactFormTicketType: 'Ticket type',
  contactFormProductType: 'Product type',
  contactFormCategory: 'Category',
  contactFormMessage: 'Your message',
  contactFormTicketTypePlaceholder: 'Select ticket type',
  contactFormProductTypePlaceholder: 'Select product type',
  contactFormCategoryPlaceholder: 'Select category',
  contactFormMessagePlaceholder: 'At least 30 characters',
  contactFormSubmitButton: 'Send Message',
  contactFormCloseButton: 'Close',
};

type ContactFormProps = {
  onSuccess: () => void;
  onError: () => void;
};

const ContactForm: React.FC<ContactFormProps> = ({ onSuccess, onError }) => {
  const [availableCategories, setAvailableCategories] = useState<string[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(contactFormSchema),
    defaultValues: {
      ticketType: undefined,
      productType: undefined,
      category: undefined,
      message: '',
    },
  });

  const { closeSidePanel } = useSidePanel();

  const selectedProductType = watch('productType');

  const userData = useUserStore((state) => state.userData);

  const { onSubmit, isLoading } = useContactForm(onSuccess, onError, userData);

  useEffect(() => {
    if (selectedProductType && selectedProductType in CATEGORY_MAP) {
      setAvailableCategories(CATEGORY_MAP[selectedProductType as keyof typeof CATEGORY_MAP]);
      setValue('category', '');
    } else {
      setAvailableCategories([]);
    }
  }, [selectedProductType, setValue]);

  return (
    <div className="h-full">
      <form className="h-full flex flex-col justify-between" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5">
          {/* Ticket Type */}
          <div>
            <LabelText htmlFor="ticketType">{displayStrings.contactFormTicketType}</LabelText>
            <Select
              name="ticketType"
              value={watch('ticketType') || ''}
              onValueChange={(value) => setValue('ticketType', value)}
            >
              <SelectTrigger className="mt-[5px]">
                <SelectValue placeholder={displayStrings.contactFormTicketTypePlaceholder} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {TICKET_TYPES.map((type) => (
                    <SelectItem key={type} value={type}>
                      {type}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            {errors.ticketType && (
              <WebCaption10.Regular className="text-red">
                {errors.ticketType.message}
              </WebCaption10.Regular>
            )}
          </div>

          {/* Product Type */}
          <div>
            <LabelText htmlFor="productType">{displayStrings.contactFormProductType}</LabelText>
            <Select
              name="productType"
              value={watch('productType') || ''}
              onValueChange={(value) => setValue('productType', value)}
            >
              <SelectTrigger className="mt-[5px]">
                <SelectValue placeholder={displayStrings.contactFormProductTypePlaceholder} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {PRODUCT_TYPES.map((type) => (
                    <SelectItem key={type} value={type}>
                      {type}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            {errors.productType && (
              <WebCaption10.Regular className="text-red">
                {errors.productType.message}
              </WebCaption10.Regular>
            )}
          </div>

          {/* Category */}
          <div>
            <LabelText htmlFor="category">{displayStrings.contactFormCategory}</LabelText>
            <Select
              name="category"
              value={watch('category') || ''}
              onValueChange={(value) => setValue('category', value)}
              disabled={!selectedProductType}
            >
              <SelectTrigger className="mt-[5px]">
                <SelectValue placeholder={displayStrings.contactFormCategoryPlaceholder} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {availableCategories.map((category) => (
                    <SelectItem key={category} value={category}>
                      {category}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            {errors.category && (
              <WebCaption10.Regular className="text-red">
                {errors.category.message}
              </WebCaption10.Regular>
            )}
          </div>

          {/* Message */}
          <div>
            <LabelText htmlFor="message">{displayStrings.contactFormMessage}</LabelText>
            <Textarea
              className="mt-[5px] h-[220px]"
              placeholder={displayStrings.contactFormMessagePlaceholder}
              {...register('message')}
            />
            {errors.message && (
              <WebCaption10.Regular className="text-red">
                {errors.message.message}
              </WebCaption10.Regular>
            )}
          </div>
        </div>

        <div className="flex flex-col justify-center items-center w-full gap-[10px]">
          <Button className="w-full" type="submit" disabled={!isValid || isLoading}>
            {isLoading && (
              <Image src={LoadingIcon} className="h-5 w-5 animate-spin mr-2" alt="loading" />
            )}
            {displayStrings.contactFormSubmitButton}
          </Button>
          <Button onClick={closeSidePanel} className="w-full" variant="secondary" type="button">
            {displayStrings.contactFormCloseButton}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
