import { useMemo, useState } from 'react';
import { Matcher } from 'react-day-picker';
import { SingleDatepickerProps } from '../SingleDatepicker';

type useRangeDatepickerProps = {
  startProps?: SingleDatepickerProps;
  endProps?: SingleDatepickerProps;
};

export const useRangeDatepicker = ({ endProps, startProps }: useRangeDatepickerProps) => {
  const [innerStartValue, setInnerStartValue] = useState(startProps?.defaultValue);
  const [innerEndValue, setInnerEndValue] = useState(endProps?.defaultValue);

  /**
   * Hold the controlled start value in case it has been passed
   * Else hold the uncontrolled start value
   */
  const realStartValue = startProps?.value !== undefined ? startProps?.value : innerStartValue;

  /**
   * Hold the controlled end value in case it has been passed
   * Else hold the uncontrolled end value
   */
  const realEndValue = endProps?.value !== undefined ? endProps?.value : innerEndValue;
  /**
   * Callback used when you want to apply changes to the start value.
   * Set the real start value to the selected date in the calendar
   */
  const handleStartChange = (newDate?: Date | null) => {
    if (startProps?.value === undefined) {
      setInnerStartValue(newDate);
    }

    if (startProps?.onChange) {
      startProps.onChange(newDate);
    }
  };

  /**
   * Callback used when you want to apply changes to the end value.
   * Set the real end value to the selected date in the calendar
   */
  const handleEndChange = (newDate?: Date | null) => {
    if (endProps?.value === undefined) {
      setInnerEndValue(newDate);
    }

    if (endProps?.onChange) {
      endProps.onChange(newDate);
    }
  };

  /**
   * Callback used when you want to clear the end and start dates
   */
  const handleClearDates = () => {
    if (startProps?.value === undefined) {
      setInnerStartValue(null);
    }

    if (endProps?.value === undefined) {
      setInnerEndValue(null);
    }

    if (startProps?.onChange) {
      startProps.onChange(null);
    }

    if (endProps?.onChange) {
      endProps.onChange(null);
    }
  };

  /**
   * Used to disable every day that is before the controlled or uncontrolled
   * start value in the end date picker. Else, disable no days.
   */
  const disabledEndDates: Matcher[] = useMemo(() => {
    const defaultMatcher = realStartValue ? { before: realStartValue } : false;

    if (endProps?.disableDates) {
      if (Array.isArray(endProps.disableDates)) {
        return [...endProps.disableDates, defaultMatcher];
      } else {
        return [endProps.disableDates, defaultMatcher];
      }
    }

    return [defaultMatcher];
  }, [realStartValue, endProps?.disableDates]);

  /**
   * Disable every day that is after the controlled or uncontrolled end value
   * in the start date picker. Else, disable no days
   */
  const disabledStartDates: Matcher[] = useMemo(() => {
    const defaultMatcher = realEndValue ? { after: realEndValue } : false;

    if (startProps?.disableDates) {
      if (Array.isArray(startProps.disableDates)) {
        return [...startProps.disableDates, defaultMatcher];
      } else {
        return [startProps.disableDates, defaultMatcher];
      }
    }

    return [defaultMatcher];
  }, [realEndValue, startProps?.disableDates]);

  return {
    startProps: {
      ...startProps,
      value: realStartValue,
      onChange: handleStartChange,
      disableDates: disabledStartDates,
    },
    endProps: {
      ...endProps,
      value: realEndValue,
      onChange: handleEndChange,
      disableDates: disabledEndDates,
    },
    handleClearDates,
  };
};
