import React, { CSSProperties, ReactNode } from 'react';

// FinTron
import { BodyText40 } from '@fintronners/react-ui/src/GlobalStyling';

const BodyText: React.FC<{ style?: CSSProperties; className?: string; children: ReactNode }> = (
  props,
) => {
  return (
    <BodyText40.Regular>
      <span style={props.style} className={props.className}>
        {props.children}
      </span>
    </BodyText40.Regular>
  );
};

export default BodyText;
