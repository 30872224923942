import { gql } from '@apollo/client';
import { ICT_TRANSFERS_FRAGMENT } from './IctTransfers';
import { ACH_TRANSFER } from './achTransfers';

export const GET_TRANSFERS_BY_ID = gql`
  query GetTransfersById($id: ID!) {
    node(id: $id) {
      ...AchTransfer
      ...IctTransfer
    }
  }
  ${ACH_TRANSFER}
  ${ICT_TRANSFERS_FRAGMENT}
`;
