import { gql } from '@apollo/client';

export const PING_SERVER_QUERY = gql`
  query PingServer {
    ping {
      version
      time
    }
  }
`;
