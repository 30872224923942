import React from 'react';

import { VariantProps, cva } from 'class-variance-authority';
import cn from '@/utils/tailwind';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-custom cursor-pointer text-base font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none',
  {
    variants: {
      variant: {
        primary:
          'bg-primary border-2 border-primary text-white hover:bg-primary/80 focus:bg-primary/90 disabled:border-2 disabled:border-grey6 disabled:bg-white disabled:text-grey41',
        secondary:
          'bg-white border-2 border-grey6 text-primary hover:bg-white/80 hover:border-primary/50 disabled:text-grey41',
        ghost:
          'bg-background text-primary disabled:text-grey41 border border-transparent hover:border-grey6',
      },
      size: {
        default: 'h-7 px-4 py-2',
        lg: 'h-8 text-lg px-8',
        icon: 'h-8 w-8 rounded-full',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <button className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
    );
  },
);

export default Button;
export { buttonVariants };
