import { Button } from '@/components/common/Button';
import React from 'react';
import { useRangeDatepicker } from './range/useRangeDatepicker';
import { SingleDatepicker, SingleDatepickerProps } from './SingleDatepicker';

export type RangeDatepickerProps = {
  startProps?: SingleDatepickerProps;
  endProps?: SingleDatepickerProps;
};

export const RangeDatepicker: React.FC<RangeDatepickerProps> = (props: RangeDatepickerProps) => {
  const { startProps, endProps, handleClearDates } = useRangeDatepicker(props);

  return (
    <div className="flex gap-2 items-center">
      <SingleDatepicker showMonthAndYear placeholder="Start" {...startProps} />
      to
      <SingleDatepicker
        showMonthAndYear
        placeholder="End"
        className="sm:w-[178px] w-[128px]"
        {...endProps}
      />
      <Button variant="ghost" className="text-grey41 h-full" onClick={handleClearDates}>
        Clear
      </Button>
    </div>
  );
};

RangeDatepicker.displayName = 'RangeDatepicker';
