import { gql } from '@apollo/client';

export const SECTOR_FIELDS = gql`
  fragment SectorFields on Query {
    securitySectors {
      edges {
        node {
          description
          id
          imagePrimary
          imageSector
          name
          portfolioAvgReturn
          portfolioExpRetLb
          portfolioExpRetUb
          portfolioRealTimePerf
          portfolioStdDev
          portfolioVariance
          portfolioYearlyPerf
          securities {
            symbol
          }
        }
      }
    }
  }
`;

export const MARKET_FIELDS = gql`
  fragment MarketFields on Query {
    fiatAssets {
      edges {
        node {
          id
          type
          name
          symbol
        }
      }
    }
  }
`;

export const GET_MARKET_DATA = gql`
  query GetMarketData {
    ...MarketFields
    ...SectorFields
  }
  ${MARKET_FIELDS}
  ${SECTOR_FIELDS}
`;

export const GET_MARKET_STATUS = gql`
  query GetMarketStatus {
    marketHours {
      status(calendar: MARKET_TYPE_SECURITIES) {
        date
        status
        open
        close
        calendar
      }
      previous(calendar: MARKET_TYPE_SECURITIES, count: 1) {
        date
        status
        open
        close
        calendar
      }
    }
  }
`;
