import { gql } from '@apollo/client';
import { GET_USER_ACCOUNT_DETAIL_FRAGMENT, GET_USER_ROBO_GOAL_DETAIL_FRAGMENT } from '../queries';

export const GET_ADMIN_USER_ALL_ACCOUNTS = gql`
  query GetAdminUserAllAccounts(
    $id: ID!
    $accountFilter: UserAccountWhereInput
    $discretionaryEnabled: Boolean = true
  ) {
    users(where: { id: $id }) {
      edges {
        cursor
        node {
          ...UserAccountDetail
          ...UserRoboGoalDetail @include(if: $discretionaryEnabled)
          username
          restrictionStatus
          profile {
            firstName
            lastName
            email
            dateOfBirth
            phoneNumber
            ssn
            streetAddress
            additionalStreetAddress
            city
            state
            zipCode
            country
          }
        }
      }
    }
  }
  ${GET_USER_ACCOUNT_DETAIL_FRAGMENT}
  ${GET_USER_ROBO_GOAL_DETAIL_FRAGMENT}
`;

export const GET_ADMIN_ALL_ACCOUNTS = gql`
  query GetAdminAllAccounts(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $startDate: Time
    $endDate: Time
    $criteria: String = ""
    $allFilters: [UserWhereInput!]
    $accountFilter: UserAccountWhereInput
    $orderDirection: OrderDirection = DESC
  ) {
    response: users(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        hasAccounts: true
        and: $allFilters
        createTimeGTE: $startDate
        createTimeLTE: $endDate
        hasProfileWith: {
          or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
        }
      }
      orderBy: { direction: $orderDirection, field: create_time }
    ) {
      edges {
        cursor
        node {
          id
          createTime
          accounts(where: $accountFilter) {
            edges {
              node {
                type
                status
                portfolios {
                  edges {
                    node {
                      id
                      totalValue
                      fiat {
                        total
                      }
                      security {
                        total
                      }
                    }
                  }
                }
              }
            }
          }
          profile {
            firstName
            lastName
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_ADMIN_USER_DISCRETIONARY_ACCOUNT = gql`
  query GetAdminUserDiscretionaryAccount($id: ID!) {
    userAccounts(where: { type: ACCOUNT_TYPE_BROKERAGE_DISCRETIONARY, hasUserWith: { id: $id } }) {
      edges {
        node {
          id
          externalID
          type
          status
        }
      }
    }
  }
`;

export const GET_ADMIN_USER_STOCKS_ACCOUNT = gql`
  query GetAdminUserStocksAccount($id: ID!) {
    userAccounts(where: { type: ACCOUNT_TYPE_BROKERAGE, hasUserWith: { id: $id } }) {
      edges {
        node {
          id
          externalID
          type
          status
        }
      }
    }
  }
`;
