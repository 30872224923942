import { useMemo, useState } from 'react';
import Image from 'next/image';
import { V1TradeExpirationType } from '@fintronners/react-api';
import { WebBodyText14, WebCaption10 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import CurrencyInput from 'react-currency-input-field';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Radio } from '@/components/common/Radio/Radio';
import { GoodForDialog } from '@/components/features/trade/GoodForDialog/GoodForDialog';
import {
  rawThousandFormatNumbro,
  thousandFormatNumbroCurrency,
} from '@fintronners/react-utils/src/numberUtilsTSX';
import { TradeCurrency } from './TradeCurrency';
import { Button } from '@/components/common/Button';
import { InfoIcon } from '@/assets/icons';
import { StopLossDialog } from '@/components/features/trade/StopLossPriceDialog/StopLossPriceDialog';
import { getStopLossTradeableShares } from '../utils';

const displayStrings = {
  [V1TradeExpirationType.Day]: '1 Day',
  [V1TradeExpirationType.GoodTilCancel]: 'Good Till Cancel',
  stopPrice: 'Enter stop price',
  availableStopLossPrice: 'Available at stop loss price:',
  sharesToBeSold: 'Shares to be sold:',
  holdings: (amount?: string) => `Current holdings: ${amount ?? '--'}`,
  sellAll: 'Sell All',
  stopLossAmountTooHigh:
    'It looks like you have entered a stop loss that is over the current market price. In order to place a stop loss order, please enter a stop loss that is lower than the displayed market price.',
};

interface TradeStopLossFormProps {
  securitySymbol: string;
  tradableInCash: number;
  tradableUnits: number;
  currentPrice: number;
}

export const TradeStopLossForm = ({
  securitySymbol,
  tradableInCash,
  tradableUnits,
  currentPrice,
}: TradeStopLossFormProps) => {
  const { control, setValue } = useFormContext();
  const [showAvailableAtStopLossPrice, setShowAvailableAtStopLossPrice] = useState(false);

  const [amount, stopLoss] = useWatch({
    control,
    name: ['amount', 'stopLoss'],
  });

  const maximumThreshold = rawThousandFormatNumbro(tradableUnits * parseFloat(stopLoss));

  const sharesToBeSold = useMemo(() => {
    if (amount && parseFloat(stopLoss)) {
      return getStopLossTradeableShares(amount, tradableUnits, stopLoss);
    } else {
      return '-';
    }
  }, [amount, stopLoss]);

  const availableAtStopLossPrice = useMemo(() => {
    if (tradableUnits && parseFloat(stopLoss)) {
      return thousandFormatNumbroCurrency(maximumThreshold);
    } else {
      return '-';
    }
  }, [tradableUnits, stopLoss]);

  return (
    <>
      <StopLossDialog
        sharesToBeSold={sharesToBeSold}
        securitySymbol={securitySymbol}
        availableAtStopLossPrice={availableAtStopLossPrice}
        stopLoss={thousandFormatNumbroCurrency(stopLoss)}
        open={showAvailableAtStopLossPrice}
        onOpenChange={setShowAvailableAtStopLossPrice}
      />
      <div>
        <WebBodyText14.Regular className="text-grey72">
          {displayStrings.stopPrice}
        </WebBodyText14.Regular>
        <Controller
          control={control}
          name="stopLoss"
          render={({ field: { value, onChange } }) => (
            <CurrencyInput
              className="mt-2 w-full border rounded h-[35px] px-3 py-2 text-sm text-darkestBlue focus-visible:outline-primary"
              prefix="$"
              value={value}
              placeholder="$0.00"
              decimalsLimit={2}
              intlConfig={{ locale: 'en-US' }}
              onValueChange={(_value) => onChange(_value)}
            />
          )}
        />
        {parseFloat(stopLoss) > currentPrice && (
          <div className="flex mt-2 -mb-4">
            <WebCaption10.Regular className="text-red">
              {displayStrings.stopLossAmountTooHigh}
            </WebCaption10.Regular>
          </div>
        )}
      </div>
      {parseFloat(stopLoss) > 0 && (
        <>
          <div>
            <TradeCurrency />

            <WebBodyText14.Regular className="text-primary">
              {displayStrings.holdings(thousandFormatNumbroCurrency(tradableInCash))}
            </WebBodyText14.Regular>
            <Button
              variant="secondary"
              className="mt-2"
              onClick={() =>
                setValue(
                  'amount',
                  rawThousandFormatNumbro(tradableUnits * parseFloat(stopLoss)).toString(),
                )
              }
            >
              {displayStrings.sellAll}
            </Button>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex justify-between">
              <div className="flex gap-1">
                <Image
                  alt="Info icon"
                  className="cursor-pointer"
                  width={14}
                  height={14}
                  src={InfoIcon.src}
                  onClick={() => setShowAvailableAtStopLossPrice(true)}
                />
                <WebBodyText14.Regular className="text-grey72">
                  {displayStrings.availableStopLossPrice}
                </WebBodyText14.Regular>
              </div>
              <WebBodyText14.Regular className="text-grey72">
                {availableAtStopLossPrice}
              </WebBodyText14.Regular>
            </div>
            <div className="flex justify-between">
              <WebBodyText14.Regular className="text-grey72">
                {displayStrings.sharesToBeSold}
              </WebBodyText14.Regular>
              <WebBodyText14.Regular className="text-grey72">
                {sharesToBeSold}
              </WebBodyText14.Regular>
            </div>
          </div>
          <div>
            <GoodForDialog />
            <ul className="flex gap-4 mt-2">
              {[V1TradeExpirationType.Day, V1TradeExpirationType.GoodTilCancel].map(
                (expirationType) => (
                  <li key={expirationType} className="w-full">
                    <Controller
                      name="duration"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <>
                          <Radio.Input
                            id={expirationType}
                            value={expirationType}
                            onChange={() => onChange?.(expirationType as V1TradeExpirationType)}
                            checked={expirationType === value}
                          />
                          <Radio.Label variant="secondary" htmlFor={expirationType.toString()}>
                            {displayStrings[expirationType]}
                          </Radio.Label>
                        </>
                      )}
                    />
                  </li>
                ),
              )}
            </ul>
          </div>
        </>
      )}
    </>
  );
};
