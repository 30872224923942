import { gql } from '@apollo/client';

export const GET_ACCOUNT_VALUES = gql`
  query GetAccountValues($start: Time!, $end: Time, $period: TimePeriod!, $portfolioId: ID) {
    totalValue: userAccountPortfolios(where: { id: $portfolioId }) {
      edges {
        node {
          totalValue
        }
      }
    }
    userAccountPortfolios(where: { id: $portfolioId }) {
      edges {
        node {
          valueHistory(range: { period: $period, start: $start, end: $end }) {
            period
            start
            end
            limit
            values
            timestamps
            gainLosses
            returnRates
          }
        }
      }
    }
  }
`;

export const GET_STOCK_ACCOUNT_DETAIL = gql`
  query GetStockAccountDetail {
    userAccounts(where: { type: ACCOUNT_TYPE_BROKERAGE }) {
      edges {
        node {
          id
          externalID
          type
          status
          fpslEnrolled
          cashSweepEnrolled
          programEnrollmentHistory {
            id
            electionPreference
            approvalStatus
            programType
            reviewedAt
            reason
            submissionStatus
            submittedAt
            createTime
          }
          portfolios {
            edges {
              node {
                assetBalances(where: { hasAssetWith: { symbol: "USD" } }) {
                  edges {
                    node {
                      balances {
                        withdrawable
                        total
                        pending
                        tradeable
                        activeBalance
                        frozen
                      }
                    }
                  }
                }
                holdings: assetBalances(where: { hasAssetWith: { not: { symbol: "USD" } } }) {
                  edges {
                    node {
                      balances {
                        activeBalance
                      }
                      asset {
                        id
                        name
                        symbol
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    users {
      edges {
        node {
          user_id: id
          recurringRules(where: { isActive: true }) {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`;
