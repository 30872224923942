import {
  CommonUserInvestorInformationNonDiscretionaryDetailsFragment,
  CommonUserInvestorInformationNonDiscretionaryRequestParametersDetailsFragment,
  EmploymentDetails,
  NonDiscretionaryUpdateRequestUpdateRequestStatus,
} from '@fintronners/react-api/src';
import { getYesterday } from '../../../dateUtils';
import { FieldUpdateRequests } from '../types';

export type InvestorInformationNonDiscretionaryUpdateRequests =
  CommonUserInvestorInformationNonDiscretionaryDetailsFragment['updateRequests'];

export type InvestorInformationNonDiscretionaryFieldUpdateRequests = FieldUpdateRequests<
  CommonUserInvestorInformationNonDiscretionaryRequestParametersDetailsFragment | undefined | null
>;

export const getInvestorInformationNonDiscretionaryUpdateRequests = (
  updateRequests: InvestorInformationNonDiscretionaryUpdateRequests,
): InvestorInformationNonDiscretionaryFieldUpdateRequests | [] => {
  if (updateRequests === null || !updateRequests) return [];

  const yesterday = getYesterday();
  const sorted = updateRequests.sort((a, b) => {
    const timeA = new Date(a.createTime);
    const timeB = new Date(b.createTime);
    return timeA === timeB ? 0 : timeA < timeB ? 1 : -1;
  });
  const filtered = sorted
    .filter((request) =>
      [
        NonDiscretionaryUpdateRequestUpdateRequestStatus.UpdateRequestStatusPending,
        NonDiscretionaryUpdateRequestUpdateRequestStatus.UpdateRequestStatusApproved,
        NonDiscretionaryUpdateRequestUpdateRequestStatus.UpdateRequestStatusSubmitted,
      ].includes(request.status),
    )
    .filter((request) => new Date(request.createTime) >= yesterday);

  return filtered.map((request) => ({
    requestParameters: request.requestParameters,
    isPending:
      request.status ===
        NonDiscretionaryUpdateRequestUpdateRequestStatus.UpdateRequestStatusPending ||
      request.status ===
        NonDiscretionaryUpdateRequestUpdateRequestStatus.UpdateRequestStatusSubmitted,
  }));
};

export const getInvestorInformationNonDiscretionaryFormFieldPending = (
  kycFieldUpdateRequests: InvestorInformationNonDiscretionaryFieldUpdateRequests | [],
  propLookup:
    | keyof CommonUserInvestorInformationNonDiscretionaryRequestParametersDetailsFragment
    | keyof EmploymentDetails,
) => {
  for (let i = 0; i < kycFieldUpdateRequests.length; i++) {
    const kycRequestParameters = kycFieldUpdateRequests[i].requestParameters; // makes ts happy
    if (kycRequestParameters !== undefined && kycRequestParameters !== null) {
      let currRequest;
      switch (propLookup) {
        case 'Position':
          currRequest = kycRequestParameters.employmentDetails?.Position;
          break;
        case 'EmployerName':
          currRequest = kycRequestParameters.employmentDetails?.EmployerName;
          break;
        default:
          currRequest = kycRequestParameters[propLookup];
          break;
      }

      if (currRequest !== null && currRequest !== undefined) {
        return kycFieldUpdateRequests[i].isPending;
      }
    }
  }
  return false;
};

export const getInvestorInformationNonDiscretionaryFormFieldValue = (
  kycFieldUpdateRequests: InvestorInformationNonDiscretionaryFieldUpdateRequests | [],
  propLookup:
    | keyof CommonUserInvestorInformationNonDiscretionaryRequestParametersDetailsFragment
    | keyof EmploymentDetails,
  dbValue: any,
) => {
  if (propLookup === 'employmentDetails') return dbValue ?? '';

  for (let i = 0; i < kycFieldUpdateRequests.length; i++) {
    const kycRequestParameters = kycFieldUpdateRequests[i].requestParameters; // makes ts happy
    if (kycRequestParameters !== undefined && kycRequestParameters !== null) {
      let currRequest;
      switch (propLookup) {
        case 'Position':
          currRequest = kycRequestParameters.employmentDetails?.Position;
          break;
        case 'EmployerName':
          currRequest = kycRequestParameters.employmentDetails?.EmployerName;
          break;
        default:
          currRequest = kycRequestParameters[propLookup];
          break;
      }

      if (currRequest !== null && currRequest !== undefined) {
        return kycFieldUpdateRequests[i].isPending ? currRequest : dbValue ?? '';
      }
    }
  }
  return dbValue ?? '';
};
