import { useSidePanel } from '@/context/SidePanelContext';
import { useState } from 'react';
import { ErrorSidePanel } from '../ErrorSidePanel/ErrorSidePanel';
import { RiskProfileSidePanelForm } from './RiskProfileSidePanelForm';
import { RiskProfileSidePanelSuccess } from './RiskProfileSidePanelSuccess';

export const RiskProfileSidePanel = () => {
  const { currentFlowStep } = useSidePanel();

  const [hasError, setHasError] = useState(false);

  if (hasError) return <ErrorSidePanel onBack={() => setHasError(false)} />;

  return (
    <>
      {currentFlowStep === 0 && <RiskProfileSidePanelForm />}
      {currentFlowStep === 1 && <RiskProfileSidePanelSuccess />}
    </>
  );
};
