import { TimePeriod } from '@fintronners/react-api';
import { ChartDuration } from '@fintronners/react-utils/src/durationUtils';
import { getBeginningOfToday } from '@fintronners/react-utils/src/dateUtils';
import {
  getLatestTradingDayStartEndTime,
  MINUTES_IN_A_TRADING_DAY,
} from '@fintronners/react-utils/src/chartDateUtils/chartDateUtils';
import { width } from '@fintronners/react-utils/src/dimensions';

const isWeb = () => typeof window !== 'undefined' && !('ReactNative' in window);

const getWidth = (containerRef?: React.RefObject<HTMLElement>) => {
  if (isWeb() && containerRef?.current) {
    return containerRef.current.getBoundingClientRect().width;
  }
  return width;
};

export const TimePeriodMap: Record<ChartDuration, TimePeriod> = {
  [ChartDuration.DAY]: TimePeriod.TimePeriodMinute,
  [ChartDuration.TRADING_DAY]: TimePeriod.TimePeriodMinute,
  [ChartDuration.EXTENDED_TRADING_DAY]: TimePeriod.TimePeriodMinute,
  [ChartDuration.WEEK]: TimePeriod.TimePeriodMinute_5,
  [ChartDuration.MONTH]: TimePeriod.TimePeriodHour,
  [ChartDuration.THREE_MONTHS]: TimePeriod.TimePeriodDay,
  [ChartDuration.YEAR]: TimePeriod.TimePeriodDay,
  [ChartDuration.FIVE_YEARS]: TimePeriod.TimePeriodDay,
  [ChartDuration.ALL]: TimePeriod.TimePeriodDay,
};

export const StartDatePeriodMap: Record<ChartDuration, () => string> = {
  [ChartDuration.TRADING_DAY]: () => getLatestTradingDayStartEndTime(false).start,
  [ChartDuration.EXTENDED_TRADING_DAY]: () => getLatestTradingDayStartEndTime(true).start,
  [ChartDuration.DAY]: () => {
    const t = getBeginningOfToday();
    t.setDate(t.getDate());
    return t.toISOString();
  },
  [ChartDuration.WEEK]: () => {
    const t = getBeginningOfToday();
    t.setDate(t.getDate() - 7);
    return t.toISOString();
  },
  [ChartDuration.MONTH]: () => {
    const t = getBeginningOfToday();
    t.setMonth(t.getMonth() - 1);
    return t.toISOString();
  },
  [ChartDuration.THREE_MONTHS]: () => {
    const t = getBeginningOfToday();
    t.setMonth(t.getMonth() - 3);
    return t.toISOString();
  },
  [ChartDuration.YEAR]: () => {
    const t = getBeginningOfToday();
    t.setFullYear(t.getFullYear() - 1);
    return t.toISOString();
  },
  [ChartDuration.FIVE_YEARS]: () => {
    const t = getBeginningOfToday();
    t.setFullYear(t.getFullYear() - 5);
    return t.toISOString();
  },
  [ChartDuration.ALL]: () => {
    const t = getBeginningOfToday();
    t.setFullYear(t.getFullYear() - 10);
    return t.toISOString();
  },
};

export const calculateChartWidthForTradingDay = (
  endTimeIsoString: string | undefined,
  lastTimeIsoString: string | undefined,
  containerRef?: React.RefObject<HTMLElement>,
  minutesInTradingDay = MINUTES_IN_A_TRADING_DAY,
) => {
  const currentWidth = getWidth(containerRef);
  if (endTimeIsoString && lastTimeIsoString) {
    const startTimestamp = new Date(lastTimeIsoString);
    const endTimestamp = new Date(endTimeIsoString);
    if (startTimestamp < endTimestamp) {
      const timeDifferenceMs = endTimestamp.getTime() - startTimestamp.getTime();
      const minutesDifference = Math.floor(timeDifferenceMs / (1000 * 60));
      return (
        Math.sqrt(Math.abs(minutesInTradingDay - minutesDifference) / minutesInTradingDay) *
        currentWidth
      );
    }
  }
  return currentWidth;
};
