export const TICKET_TYPES = [
  'I want to learn more about a FinTron product or feature',
  `I'm having trouble using the FinTron app`,
  'I need help with my FinTron Invest account',
];

export const PRODUCT_TYPES = [
  'Transfers',
  'FinTron App Access',
  'Investing Application',
  'Research Tools',
  'Technical Support',
];

interface CategoryMap {
  [key: string]: string[];
}

export const CATEGORY_MAP: CategoryMap = {
  Transfers: ['Incoming to FinTron', 'Outgoing from FinTron'],
  'FinTron App Access': ['Registration', 'Login', 'Other'],
  'Investing Application': [
    'Account Opening',
    'Funding My Account',
    'Trading',
    'Withdrawals',
    'Other',
  ],
  'Research Tools': ['Risk Assessor', 'Quotes / Security Search', 'Watchlist', 'FinTronU', 'Other'],
  'Technical Support': [
    'Report an Issue',
    'Help Using a Feature',
    'General Product Feedback',
    'Other',
  ],
};
