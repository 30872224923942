import * as React from 'react';
import cn from '@/utils/tailwind';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex h-[35px] w-full rounded border px-3 py-2 text-sm text-darkestBlue file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-grey41 focus-visible:outline-primary disabled:cursor-not-allowed disabled:bg-grey7',
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';

export { Input };
