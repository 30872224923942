import { Configuration, PlaidServiceApi } from '@fintronners/react-api/src';
import RuntimeEnvGRPCAPI from '@/utils/RuntimeEnvGRPCAPI';
import { useGrpcApiV1Config } from '@/hooks/api/useGrpcApiV1Config';

const usePlaidApiService = () => {
  const { basePath, accessToken } = useGrpcApiV1Config();

  const plaidApiService = RuntimeEnvGRPCAPI.getV1Service(
    PlaidServiceApi,
    new Configuration({
      basePath,
      accessToken,
      baseOptions: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    }),
  );

  return plaidApiService;
};

export default usePlaidApiService;
