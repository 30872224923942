import React from 'react';
import { SheetContent, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import { WebBodyText16 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import useUserDetails from '@fintronners/react-widgets/src/hooks/useUserDetails';
import { getUserKycDisplayString } from '@fintronners/react-experience/src/Helpers/GraphQLMapUtilsTS';
import {
  getInvestorInformationNonDiscretionaryFormFieldPending,
  getInvestorInformationNonDiscretionaryFormFieldValue,
} from '@fintronners/react-api/src/utils/formUtils/schemas/pending/investorInformationNonDiscretionary';
import useNonDiscretionaryUpdateRequests from '@fintronners/react-widgets/src/hooks/useNonDiscretionaryUpdateRequests';
import { ListItemButton } from '@/components/common/ListItemButton';
import { InvestmentValuesList } from './parts/InvestmentValuesList';
import { InvestorFormKey } from './InvestorProfileSidePanel';

const displayStrings = {
  investorProfileInformation: 'Investor profile information',
  investing: 'Investing',
  personal: 'Personal',
  exposure: 'Exposure',
  investingExperience: 'Investment experience',
  investmentObjective: 'Investment objective',
  investmentTimeHorizon: 'Investment time horizon',
  importanceOfCashOnHand: 'Importance of cash on hand',
  marriageStatus: 'Marriage status',
  employmentStatus: 'Employment status',
  annualIncome: 'Annual income',
  netWorth: 'Net worth',
  controlPerson: 'Control person',
  politicallyExposed: 'Politically exposed',
  finraStockExchangeMember: 'FINRA/Stock exchange member',
  securitiesProfessional: 'Securities professional',
};

interface InvestorProfileSummaryProps {
  onEdit: (formToEdit: InvestorFormKey) => void;
}

export const InvestorProfileSummary = ({ onEdit }: InvestorProfileSummaryProps) => {
  const { userDetails, nonDiscretionaryAccountKycs } = useUserDetails();
  const { nonDiscretionaryUpdateRequests } = useNonDiscretionaryUpdateRequests();

  const investingDetails = [
    {
      label: displayStrings.investingExperience,
      value: getUserKycDisplayString(
        getInvestorInformationNonDiscretionaryFormFieldValue(
          nonDiscretionaryUpdateRequests,
          'investmentExperience',
          nonDiscretionaryAccountKycs?.investmentExperience,
        ),
      ),
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        nonDiscretionaryUpdateRequests,
        'investmentExperience',
      ),
    },
    {
      label: displayStrings.investmentObjective,
      value: getUserKycDisplayString(
        getInvestorInformationNonDiscretionaryFormFieldValue(
          nonDiscretionaryUpdateRequests,
          'investmentObjective',
          nonDiscretionaryAccountKycs?.investmentObjective,
        ),
      ),
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        nonDiscretionaryUpdateRequests,
        'investmentObjective',
      ),
    },
    {
      label: displayStrings.investmentTimeHorizon,
      value: getUserKycDisplayString(
        getInvestorInformationNonDiscretionaryFormFieldValue(
          nonDiscretionaryUpdateRequests,
          'timeHorizon',
          nonDiscretionaryAccountKycs?.timeHorizon,
        ),
      ),
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        nonDiscretionaryUpdateRequests,
        'timeHorizon',
      ),
    },
    {
      label: displayStrings.importanceOfCashOnHand,
      value: getUserKycDisplayString(
        getInvestorInformationNonDiscretionaryFormFieldValue(
          nonDiscretionaryUpdateRequests,
          'liquidityNeeds',
          nonDiscretionaryAccountKycs?.liquidityNeeds,
        ),
      ),
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        nonDiscretionaryUpdateRequests,
        'liquidityNeeds',
      ),
    },
  ];

  const personalDetails = [
    {
      label: displayStrings.marriageStatus,
      value: getUserKycDisplayString(
        getInvestorInformationNonDiscretionaryFormFieldValue(
          nonDiscretionaryUpdateRequests,
          'maritalStatus',
          nonDiscretionaryAccountKycs?.maritalStatus,
        ),
      ),
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        nonDiscretionaryUpdateRequests,
        'maritalStatus',
      ),
    },
    {
      label: displayStrings.employmentStatus,
      value: getUserKycDisplayString(
        getInvestorInformationNonDiscretionaryFormFieldValue(
          nonDiscretionaryUpdateRequests,
          'employmentStatus',
          nonDiscretionaryAccountKycs?.employmentStatus,
        ),
      ),
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        nonDiscretionaryUpdateRequests,
        'employmentStatus',
      ),
    },
    {
      label: displayStrings.annualIncome,
      value: getUserKycDisplayString(
        getInvestorInformationNonDiscretionaryFormFieldValue(
          nonDiscretionaryUpdateRequests,
          'annualIncome',
          nonDiscretionaryAccountKycs?.annualIncome,
        ),
      ),
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        nonDiscretionaryUpdateRequests,
        'annualIncome',
      ),
    },
    {
      label: displayStrings.netWorth,
      value: getUserKycDisplayString(
        getInvestorInformationNonDiscretionaryFormFieldValue(
          nonDiscretionaryUpdateRequests,
          'netWorth',
          nonDiscretionaryAccountKycs?.netWorth,
        ),
      ),
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        nonDiscretionaryUpdateRequests,
        'netWorth',
      ),
    },
  ];

  const marketData = userDetails?.profile?.marketDataAnswers;

  const isSecuritiesProfessional =
    marketData?.advisorOrConsultant ||
    marketData?.investForOther ||
    marketData?.personalUse === false ||
    marketData?.registeredWithSecOrCftc ||
    marketData?.requiresSecOrCftcRegistration;

  const exposureMenuItems = [
    {
      title: displayStrings.controlPerson,
      subtitle: getUserKycDisplayString(
        Boolean(userDetails?.profile?.controlPersonDetails?.isControlPerson),
      ),
      form: InvestorFormKey.CONTROL_PERSON,
    },
    {
      title: displayStrings.politicallyExposed,
      subtitle: getUserKycDisplayString(
        Boolean(userDetails?.profile?.politicallyExposedDetails?.isPoliticallyExposed),
      ),
      form: InvestorFormKey.POLITICALLY_EXPOSED,
    },
    {
      title: displayStrings.finraStockExchangeMember,
      subtitle: getUserKycDisplayString(
        Boolean(userDetails?.profile?.affiliatedExchangeOrFinraDetails?.isAffiliated),
      ),
      form: InvestorFormKey.FINRA_STOCK_EXCHANGE_MEMBER,
    },
    {
      title: displayStrings.securitiesProfessional,
      subtitle: getUserKycDisplayString(isSecuritiesProfessional ?? false),
      form: InvestorFormKey.SECURITIES_PROFESSIONAL,
    },
  ];

  const displayItems = [
    {
      title: displayStrings.investing,
      items: investingDetails,
      form: InvestorFormKey.INVESTING,
    },
    {
      title: displayStrings.personal,
      items: personalDetails,
      form: InvestorFormKey.PERSONAL,
    },
  ];

  return (
    <SheetContent>
      <SheetHeader>
        <SheetTitle>{displayStrings.investorProfileInformation}</SheetTitle>
      </SheetHeader>
      <div className="flex flex-col h-full justify-between">
        <div className="flex flex-col gap-8 pb-5">
          {displayItems.map((details, index) => (
            <InvestmentValuesList key={index} {...details} onEdit={(form) => onEdit(form)} />
          ))}
          <div>
            <WebBodyText16.Bold className="text-darkestBlue">
              {displayStrings.exposure}
            </WebBodyText16.Bold>

            {exposureMenuItems.map((details, index) => (
              <ListItemButton
                key={index}
                className="!py-4"
                {...details}
                onClick={() => onEdit(details.form)}
              />
            ))}
          </div>
        </div>
      </div>
    </SheetContent>
  );
};
