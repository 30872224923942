import { gql } from '@apollo/client';

export const GET_ADMIN_RECURRING_RULES = gql`
  query GetAdminRecurringRules(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $criteria: String
    $startDate: Time
    $endDate: Time
    $allFilters: [RecurringRuleWhereInput!]
  ) {
    response: recurringRules(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        hasUserWith: {
          hasProfileWith: {
            or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
          }
        }
        createTimeGTE: $startDate
        createTimeLTE: $endDate
        and: $allFilters
      }
      orderBy: { direction: DESC, field: create_time }
    ) {
      edges {
        node {
          id
          user {
            profile {
              firstName
              lastName
            }
          }
          amount
          type
          portfolio {
            account {
              type
            }
          }
          asset {
            symbol
          }
          interval
          isActive
          onHold
          createTime
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_ADMIN_RECURRING_RULE_DETAILS_BY_ID = gql`
  query GetAdminRecurringRuleDetailById($id: ID!) {
    recurringRules(where: { id: $id }) {
      edges {
        node {
          id
          isActive
          user {
            id
            username
            profile {
              firstName
              lastName
            }
          }
          portfolio {
            account {
              externalID
              type
            }
            name
          }
          asset {
            symbol
          }
          instances {
            achTransfer {
              orderStatus
            }
            amount
            tradeDate
            transferDate
            orderStatus
            createTime
            trade {
              quoteAmount
              baseAmount
            }
            failureStage
          }
          requestor
          interval
          amount
          createTime
          start
          updateTime
          updates {
            updateTime
            requestor
            diff {
              field
              oldValue
              newValue
            }
          }
        }
      }
    }
  }
`;
