import {
  V1BrokerageAccountParameters,
  Accountpbv1EmploymentInfo,
} from '@fintronners/react-api/src/tsoai';
import { getFieldDifference } from '../commonUtils';

export const getFieldDifferenceKycNonDiscretionary = (
  ...args: Parameters<typeof getFieldDifference<keyof V1BrokerageAccountParameters>>
) => {
  return getFieldDifference<keyof V1BrokerageAccountParameters>(...args);
};

export const getFieldDifferenceKycNonDiscretionaryEmploymentInfo = (
  ...args: Parameters<typeof getFieldDifference<keyof Accountpbv1EmploymentInfo>>
) => {
  return getFieldDifference<keyof Accountpbv1EmploymentInfo>(...args);
};
