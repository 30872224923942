import { Dimensions, PixelRatio, Platform, StatusBar } from 'react-native';

const { width, height } = Dimensions.get('window');

const iphoneX =
  Platform.OS === 'ios' && (height === 812 || width === 812 || height === 896 || width === 896);
const isAndroid = Platform.OS === 'android';
const isWeb = Platform.OS === 'web';
// Guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 1125;
const guidelineBaseHeight = 2436;

const scaler = width > 500 ? 0.7 : 1;
const webScaler = 0.4;

const normalize = (size: number) => Math.round(PixelRatio.roundToNearestPixel(size * scaler));

const scale = (size: number) =>
  isWeb ? Math.round(size * webScaler) : normalize((width / guidelineBaseWidth) * size);
const hScale = (size: number) =>
  isWeb ? Math.round(size * webScaler) : normalize((height / guidelineBaseHeight) * size);
// scale components based on component width or height, for use in Storybook components within FinTronUI, fixes web server scaling issues
const componentScale = (size: number, componentWidth: number) =>
  isWeb ? normalize((componentWidth / guidelineBaseWidth) * size) : scale(size);
const componenthScale = (size: number, componentHeight: number) =>
  isWeb ? normalize((componentHeight / guidelineBaseWidth) * size) : scale(size);

const modalBodyMaxHeight = height * 0.75 - 160;

const statusBarHeight = StatusBar.currentHeight;

const spacing = [0, 4, 8, 12, 16, 24, 32, 48, 64];

export {
  width,
  height,
  scale,
  hScale,
  componentScale,
  componenthScale,
  iphoneX,
  isAndroid,
  isWeb,
  modalBodyMaxHeight,
  statusBarHeight,
  spacing,
};
