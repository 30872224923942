import { gql } from '@apollo/client';
import { GET_SECTOR_FRAGMENT } from '@fintronners/react-api/src/graphql/queries/modelPortfolios';

// fragments

export const COMMON_RTQ_PORTFOLIO_LEVEL_FRAGMENT = gql`
  fragment CommonRTQPortfolioLevel on RTQPortfolioLevelSet {
    levels {
      id
      index
      name
      description
      assignments {
        modelPortfolio {
          id
          stockPct
          bondPct
          fiatPct
          sectors {
            id
            targetPct
            sector {
              ...GetSectorDetails
            }
          }
        }
      }
    }
  }
  ${GET_SECTOR_FRAGMENT}
`;

// queries

export const GET_RTQ_PORTFOLIOS = gql`
  query GetRTQPortfolios {
    rtqPortfolioLevelSets {
      edges {
        node {
          id
          ...CommonRTQPortfolioLevel
        }
      }
    }
  }
  ${COMMON_RTQ_PORTFOLIO_LEVEL_FRAGMENT}
`;

const RTQ_QUESTIONS_FRAGMENT = gql`
  fragment RTQQuestions on RTQQuestion {
    label
    type
    category
    id
    options {
      id
      label
      score
    }
  }
`;

const RTQ_PORTFOLIO_LEVEL_SCORES_FRAGMENT = gql`
  fragment RTQPortfolioLevelScores on RTQPortfolioLevelScore {
    riskTolerance {
      min
      max
    }
    riskCapacity {
      min
      max
    }
    level {
      id
      name
    }
  }
`;

export const GET_RTQ_QUESTIONNAIRE = gql`
  query GetRTQQuestionnaire {
    rtqQuestionnaires {
      edges {
        node {
          id
          questions {
            ...RTQQuestions
          }
          portfolioLevelScores {
            ...RTQPortfolioLevelScores
          }
        }
      }
    }
  }
  ${RTQ_QUESTIONS_FRAGMENT}
  ${RTQ_PORTFOLIO_LEVEL_SCORES_FRAGMENT}
`;

export const GET_ACTIVE_RTQ_SUBMISSION_BY_USER_ID = gql`
  query GetActiveRtqSubmissionByUserId($userId: ID!) {
    rtqSubmissions(where: { isActive: true, hasUserWith: [{ id: $userId }] }) {
      edges {
        node {
          responses {
            question {
              id
            }
            option {
              id
            }
          }
        }
      }
    }
  }
`;
