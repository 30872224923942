import React, { useEffect, useState } from 'react';
import { SheetContent, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import { ConfigStore, useConfigStore } from '@/stores/config-store';
import { tenantConfigs } from '@/config/tenant';
import { Button } from '@/components/common/Button';
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectValue,
  SelectItem,
} from '@/components/common/Select';
import { Input } from '@/components/common/Input/Input';

import {
  robotoBold,
  robotoMedium,
  robotoRegular,
  fsElliotProRegular,
  fsElliotProBold,
  openSansExtraBold,
} from '@/config/fonts';

type LogoKey = 'lightBrandLogoL' | 'darkBrandLogoL' | 'colorBrandLogoL' | 'darkBrandLogoS';

const ThemeCustomizerSidePanel: React.FC = () => {
  const [preset, setPreset] = useState('Fintron');
  const [customTheme, setCustomTheme] = useState({
    colors: { primary: '#000', primaryDark: '#000', background: '#000' },
    fonts: { regular: '', medium: '', bold: '' },
    borderRadius: '10',
  });

  const [customConfig, setCustomConfig] = useState({
    images: {
      lightBrandLogoL: { logoRatio: 1, uri: '' },
      darkBrandLogoL: { logoRatio: 1, uri: '/images/login-logo-fintron.svg' },
      colorBrandLogoL: { logoRatio: 1, uri: '' },
      darkBrandLogoS: { logoRatio: 1, uri: '/images/logo-fintron.svg' },
    },
  });

  const [isCustom, setIsCustom] = useState(false);

  const { setConfig, config: conf } = useConfigStore();

  useEffect(() => {
    const storedConfig = loadThemeFromSessionStorage();
    if (storedConfig) {
      setPreset(storedConfig.preset);
      if (storedConfig.preset === 'Custom') {
        setIsCustom(true);
        setCustomTheme(storedConfig.theme);
        setCustomConfig(storedConfig.config);
      }
    }

    const matchedTenant = Object.keys(tenantConfigs).find((key) => {
      const tenantConfig = tenantConfigs[key as keyof typeof tenantConfigs];
      return JSON.stringify(tenantConfig.config) === JSON.stringify(conf);
    });

    if (!storedConfig) {
      setPreset(matchedTenant === 'default' ? 'Fintron' : matchedTenant ?? 'Fintron');
    }
  }, [conf]);

  const saveToSessionStorage = (
    currentPreset: string,
    theme: ConfigStore['theme'],
    config: ConfigStore['config'],
  ) => {
    const data = { preset: currentPreset, theme, config };
    sessionStorage.setItem('tenantThemeConfig', JSON.stringify(data));
  };

  const loadThemeFromSessionStorage = () => {
    const themeConfig = sessionStorage.getItem('tenantThemeConfig');
    return themeConfig ? JSON.parse(themeConfig) : null;
  };

  const onSubmit = async (event: React.FormEvent) => {
    event?.preventDefault();

    const tenantConfig =
      tenantConfigs[
        preset === 'Fintron' || preset === 'Custom'
          ? 'default'
          : (preset as keyof typeof tenantConfigs)
      ];

    let newTheme = tenantConfig.theme;
    let newConfig = tenantConfig.config;

    if (isCustom) {
      newTheme = {
        ...newTheme,
        colors: customTheme.colors,
        fonts: {
          regular: customTheme.fonts.regular ?? '',
          medium: customTheme.fonts.medium ?? '',
          bold: customTheme.fonts.bold ?? '',
        },
        borderRadius: customTheme.borderRadius,
      };
      newConfig = {
        ...newConfig,
        images: customConfig.images,
      };
    }

    saveToSessionStorage(preset, newTheme, newConfig);
    const savedConfig = loadThemeFromSessionStorage();
    setConfig(preset, savedConfig);
  };

  const handlePresetChange = (value: string) => {
    setPreset(value);
    setIsCustom(value === 'Custom');

    if (value === 'Custom') {
      const storedConfig = loadThemeFromSessionStorage();
      if (storedConfig) {
        setCustomTheme(storedConfig.theme);
        setCustomConfig(storedConfig.config);
      }
    }
  };

  const handleCustomThemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCustomTheme((prev) => ({
      ...prev,
      colors: { ...prev.colors, [name]: value },
    }));
  };

  const handleCustomConfigChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const logoName = name as LogoKey;
    setCustomConfig((prev) => ({
      ...prev,
      images: {
        ...prev.images,
        [logoName]: { ...prev.images[logoName], uri: value },
      },
    }));
  };

  const handleFontChange = (value: string, type: 'regular' | 'medium' | 'bold') => {
    setCustomTheme((prev) => ({
      ...prev,
      fonts: { ...prev.fonts, [type]: value },
    }));
  };

  const handleBorderRadiusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomTheme((prev) => ({
      ...prev,
      borderRadius: e.target.value,
    }));
  };

  return (
    <SheetContent>
      <div className="flex flex-col gap-5 h-full">
        <SheetHeader>
          <SheetTitle>Theme customizer</SheetTitle>
        </SheetHeader>
        <form onSubmit={onSubmit} className="w-full">
          <Select value={preset} onValueChange={handlePresetChange}>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select a theme" />
            </SelectTrigger>
            <SelectContent className="text-grey55">
              <SelectItem value="Fintron">FinTron</SelectItem>
              <SelectItem value="Alight">Alight</SelectItem>
              <SelectItem value="Custom">Custom</SelectItem>
            </SelectContent>
          </Select>

          {isCustom && (
            <>
              {/* Color Inputs */}
              <div className="my-4">
                <label htmlFor="primary-color">Primary Color</label>
                <Input
                  type="color"
                  id="primary-color"
                  name="primary"
                  value={customTheme.colors.primary}
                  onChange={handleCustomThemeChange}
                />
              </div>

              <div className="my-4">
                <label htmlFor="primary-dark-color">Primary Dark Color</label>
                <Input
                  type="color"
                  id="primary-dark-color"
                  name="primaryDark"
                  value={customTheme.colors.primaryDark}
                  onChange={handleCustomThemeChange}
                />
              </div>

              <div className="my-4">
                <label htmlFor="background-color">Background Color</label>
                <Input
                  type="color"
                  id="background-color"
                  name="background"
                  value={customTheme.colors.background}
                  onChange={handleCustomThemeChange}
                />
              </div>

              {/* Logo Customization Inputs */}
              <div className="my-4">
                <label htmlFor="lightBrandLogoL">Light Brand Logo (Large)</label>
                <Input
                  type="text"
                  id="lightBrandLogoL"
                  name="lightBrandLogoL"
                  placeholder="Enter URI"
                  value={customConfig.images.lightBrandLogoL.uri}
                  onChange={handleCustomConfigChange}
                />
              </div>

              <div className="my-4">
                <label htmlFor="darkBrandLogoL">Dark Brand Logo (Large)</label>
                <Input
                  type="text"
                  id="darkBrandLogoL"
                  name="darkBrandLogoL"
                  placeholder="Enter URI"
                  value={customConfig.images.darkBrandLogoL.uri}
                  onChange={handleCustomConfigChange}
                />
              </div>

              <div className="my-4">
                <label htmlFor="colorBrandLogoL">Color Brand Logo (Large)</label>
                <Input
                  type="text"
                  id="colorBrandLogoL"
                  name="colorBrandLogoL"
                  placeholder="Enter URI"
                  value={customConfig.images.colorBrandLogoL.uri}
                  onChange={handleCustomConfigChange}
                />
              </div>

              <div className="my-4">
                <label htmlFor="darkBrandLogoS">Dark Brand Logo (Small)</label>
                <Input
                  type="text"
                  id="darkBrandLogoS"
                  name="darkBrandLogoS"
                  placeholder="Enter URI"
                  value={customConfig.images.darkBrandLogoS.uri}
                  onChange={handleCustomConfigChange}
                />
              </div>

              {/* Border Radius and Font */}
              <div className="my-4">
                <label htmlFor="border-radius">Border Radius (px)</label>
                <Input
                  id="border-radius"
                  type="number"
                  value={customTheme.borderRadius}
                  placeholder="10"
                  onChange={handleBorderRadiusChange}
                />
              </div>

              <div className="my-4">
                <label htmlFor="regularFont">Regular Font</label>
                <Select
                  value={customTheme.fonts.regular}
                  onValueChange={(e) => handleFontChange(e, 'regular')}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select a regular font" />
                  </SelectTrigger>
                  <SelectContent className="text-grey55">
                    <SelectItem value={robotoRegular.style.fontFamily}>Roboto Regular</SelectItem>
                    <SelectItem value={fsElliotProRegular.style.fontFamily}>
                      FS Elliot Pro Regular
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div className="my-4">
                <label htmlFor="mediumFont">Medium Font</label>
                <Select
                  value={customTheme.fonts.medium}
                  onValueChange={(e) => handleFontChange(e, 'medium')}
                >
                  <SelectTrigger id="mediumFont" className="w-full">
                    <SelectValue placeholder="Select a medium font" />
                  </SelectTrigger>
                  <SelectContent className="text-grey55">
                    <SelectItem value={robotoMedium.style.fontFamily}>Roboto Medium</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div className="my-4">
                <label htmlFor="boldFont">Bold Font</label>
                <Select
                  value={customTheme.fonts.bold}
                  onValueChange={(e) => handleFontChange(e, 'bold')}
                >
                  <SelectTrigger id="boldFont" className="w-full">
                    <SelectValue placeholder="Select a bold font" />
                  </SelectTrigger>
                  <SelectContent className="text-grey55">
                    <SelectItem value={robotoBold.style.fontFamily}>Roboto Bold</SelectItem>
                    <SelectItem value={fsElliotProBold.style.fontFamily}>
                      FS Elliot Pro Bold
                    </SelectItem>
                    <SelectItem value={openSansExtraBold.style.fontFamily}>
                      Open Sans Extra Bold
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </>
          )}

          <Button type="submit" className="w-full mt-4">
            Apply Changes
          </Button>
        </form>
      </div>
    </SheetContent>
  );
};

export default ThemeCustomizerSidePanel;
