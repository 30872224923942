import { gql } from '@apollo/client';

export const INTEREST_DISTRIBUTION_FRAGMENT = gql`
  fragment InterestDistributionFragment on InterestDistribution {
    id
    orderType
    orderStatus
    amount
    type
    processDate
    effectiveDate
    recordDate
    createTime
  }
`;

export const INTEREST_DISTRIBUTION_EDGE = gql`
  fragment InterestDistributionEdge on InterestDistributionEdge {
    node {
      ...InterestDistributionFragment
    }
  }
  ${INTEREST_DISTRIBUTION_FRAGMENT}
`;
