import { gql } from '@apollo/client';

export const GET_SUPPORT_FAQS = gql`
  query GetSupportFaqs {
    supportFaqCategories {
      id
      name
      order
      faqs {
        id
        type
        title
        image
        description
      }
    }
  }
`;
