import { WebBodyText18 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import Image from 'next/image';
import React, { useState } from 'react';
import { InfoIcon, InfoPrimaryIcon } from '~assets/icons';
import { Button } from '~components/common/Button';
import useBreakpoint from '@/hooks/utils/useBreakpoint';
import useResponsiveDialog from '@/hooks/utils/useResponsiveDialog';

const displayStrings = {
  title: 'Withholding State:',
  description:
    'If the state in which you reside has changed since you opened up your account you can update this information in your settings. This information is important because it may impact the withholdings on your IRA distributions.',
  close: 'Close',
};

interface WithholdingStateDialogProps {
  state: string;
}

export const WithholdingStateDialog = ({ state }: WithholdingStateDialogProps) => {
  const { isBreakpoint } = useBreakpoint();
  const { ResponsiveDialog } = useResponsiveDialog();

  const [showModal, setShowModal] = useState(false);

  return (
    <ResponsiveDialog.Root open={showModal} onOpenChange={(isOpen) => setShowModal(isOpen)}>
      <ResponsiveDialog.Trigger>
        <Image alt="Info icon" width={14} height={14} src={InfoIcon.src} />
      </ResponsiveDialog.Trigger>
      <ResponsiveDialog.Content>
        <ResponsiveDialog.Header>
          <Image alt="Info icon" width={50} height={50} src={InfoPrimaryIcon.src} />
          <ResponsiveDialog.Title>
            {displayStrings.title} {state}
          </ResponsiveDialog.Title>
        </ResponsiveDialog.Header>

        <WebBodyText18.Regular className="text-grey72 text-center">
          {displayStrings.description}
        </WebBodyText18.Regular>

        <ResponsiveDialog.Footer>
          <ResponsiveDialog.Close asChild>
            <Button className="w-full" variant={isBreakpoint('xs') ? 'ghost' : 'primary'}>
              {displayStrings.close}
            </Button>
          </ResponsiveDialog.Close>
        </ResponsiveDialog.Footer>
      </ResponsiveDialog.Content>
    </ResponsiveDialog.Root>
  );
};
