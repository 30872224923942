import { GET_MARKET_STATUS, GetMarketStatusQuery, MarketStatus } from '@fintronners/react-api';
import { useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';

/**
 * This hook is used to fetch the market hours from the BE and cache it. The
 * date requested is the latest trading day.
 */
const useCachedMarketStatus = () => {
  const [singleState, setSingleState] = useState<{
    latestMarketStatus?: GetMarketStatusQuery['marketHours']['status'];
    closedOnWeekdayDate?: Date;
  }>({
    latestMarketStatus: undefined,
    closedOnWeekdayDate: undefined,
  });

  const { data, loading } = useQuery(GET_MARKET_STATUS, {
    fetchPolicy: 'cache-first',
  });

  useMemo(() => {
    const marketHours = data?.marketHours;
    if (!marketHours || loading) return;
    if (marketHours.status.status === MarketStatus.MarketStatusClosed) {
      const now = new Date();
      const closedOnWeekdayDate = now.getDay() >= 1 && now.getDay() <= 5 ? now : undefined;
      setSingleState({
        latestMarketStatus: marketHours.previous,
        closedOnWeekdayDate,
      });
      return;
    }
    if (new Date(marketHours.status.open) < new Date()) {
      setSingleState({
        latestMarketStatus: marketHours.status,
      });
      return;
    } else {
      setSingleState({
        latestMarketStatus: marketHours.previous,
      });
      return;
    }
  }, [data, loading]);

  return singleState;
};

export default useCachedMarketStatus;
