import {
  WebBodyText14,
  WebBodyText16,
  WebCaption10,
  WebCaption12,
  WebBodyText12,
  WebBodyText10,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import Image from 'next/image';
import React, { useEffect, useMemo, useState } from 'react';
import { InfoIcon, InfoPrimaryIcon } from '~assets/icons';
import { Button } from '~components/common/Button';
import { Skeleton } from '@/components/common/Skeleton';
import useBreakpoint from '@/hooks/utils/useBreakpoint';
import useResponsiveDialog from '@/hooks/utils/useResponsiveDialog';
import {
  thousandFormatIntNumbro,
  thousandFormatNumbroCurrency,
} from '@fintronners/react-utils/src/numberUtilsTSX';
import { useNbboQuoteData } from '~hooks/api/useNbboQuoteData';

interface NationalBestBidOfferDialogProps {
  securityId: string;
  isBuy: boolean;
}

interface NbboInfoDialogProps {
  title: string;
  description: string;
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

/**
 * The BE doesn't provide us with the short name so we map it here.
 *
 * https://fintron-workspace.slack.com/archives/C0562BKBXB5/p1720618818417769?thread_ts=1720545316.527389&cid=C0562BKBXB5
 * https://fintron-workspace.slack.com/archives/C04860PGH8V/p1720626446869169
 */
const EXCHANGE_NAME_MAP = [
  { name: 'NASDAQ BX Options/ETF', shortName: 'NASDAQ' },
  { name: 'CME S&P Complete Indices', shortName: 'CME' },
  { name: 'Russell Tick Indices', shortName: 'RUSSELL' },
  { name: 'CSMI Indices Exchange', shortName: 'CSMI' },
  { name: 'CME S&P Base Indices', shortName: 'CME' },
  { name: 'Dow Jones Indexes', shortName: 'DOWJ' },
  { name: 'NYSE American (AMEX)', shortName: 'NYSEAMER' },
  { name: 'NASDAQ OMX BX', shortName: 'NASDAQ' },
  { name: 'Cboe EDGA', shortName: 'CBOEEDGA' },
  { name: 'Cboe EDGX', shortName: 'CBOEEDGX' },
  { name: 'Chicago Stock Exchange, Inc', shortName: 'CHX' },
  { name: 'New York Stock Exchange', shortName: 'NYSE' },
  { name: 'NYSE Arca', shortName: 'ARCX' },
  { name: 'Nasdaq', shortName: 'NASDAQ' },
  { name: 'Long-Term Stock Exchange', shortName: 'LTSE' },
  { name: 'IEX', shortName: 'IEX' },
  { name: 'Nasdaq PSX', shortName: 'PSX' },
  { name: 'Cboe BYX', shortName: 'CBOEBYX' },
  { name: 'Cboe BZX', shortName: 'CBOEBZX' },
  { name: 'MIAX Pearl', shortName: 'MIAX' },
  { name: 'Members Exchange', shortName: 'MEMX' },
];

const displayString = {
  nbboQuoteBestBidAskDesc:
    'The National Best Bid and Offer (NBBO) is a Securities Exchange Commission (SEC) rule requiring brokers to trade at the lowest ask price and the highest bid price when buying and selling securities for customers (Submitting your orders). The National Best Bid and Offer is the bid or ask price that the average customer will see. The Securities and Exchange Commission’s Rule NMS requires that brokers guarantee their customers this price.',
  nbboQuoteBidSizeDesc:
    'The bid size represents the quantity of a security that investors are willing to purchase (Bid) at the current market price. The bid size helps determine "liquidity" which is a fancy word for one\'s ability to buy a security quickly and at a favorable price. If there are no bids, there is nobody buying! Bid size and bid price are quoted by exchanges, exchanges facilitate the sales of securities and provide public quotations of prices, volumes and other various metrics. Below each bid size you will see the exchange that the bid quote originated from.',
  nbboQuoteAskSizeDesc:
    'The ask size represents the quantity of a security that investors are currently offering (Asking) to sell at the current market price. The ask size helps determine "liquidity" which is a fancy word for one\'s ability to sell a security quickly and for a good price. If there are no asks, there is nobody selling! Ask size and ask price are quoted by exchanges, exchanges facilitate the sales of securities and provide public quotations of prices, volumes and other various metrics. Below each ask size you will see the exchange that the ask quote originated from.',
  lastSale: 'Last sale:',
  refreshedAt: 'Refreshed at:',
  refresh: 'Refresh',
};

const NbboInfoDialog = ({ title, description, open, onOpenChange }: NbboInfoDialogProps) => {
  const { ResponsiveDialog } = useResponsiveDialog();

  return (
    <ResponsiveDialog.Root open={open} onOpenChange={onOpenChange}>
      <ResponsiveDialog.Content>
        <ResponsiveDialog.Header>
          <Image alt="Info icon" width={50} height={50} src={InfoPrimaryIcon.src} />
          <ResponsiveDialog.Title>{title}</ResponsiveDialog.Title>
        </ResponsiveDialog.Header>
        <div className="flex flex-col items-center gap-2">{description}</div>
      </ResponsiveDialog.Content>
    </ResponsiveDialog.Root>
  );
};

export const NationalBestBidOfferDialog = ({
  securityId,
  isBuy,
}: NationalBestBidOfferDialogProps) => {
  const { isBreakpoint } = useBreakpoint();
  const { ResponsiveDialog } = useResponsiveDialog();

  const [showModal, setShowModal] = useState(false);
  const [showNbboInfoDialog, setShowNbboInfoDialog] = useState(false);
  const [nbboInfoDialogState, setNbboInfoDialogState] = useState({ title: '', description: '' });

  const { data, loading, refetch } = useNbboQuoteData({
    securityId,
    isBuy,
    // IMPORTANT: The request MUST be made only when the modal is explicity open by the user
    skip: !showModal,
  });
  const nbboQuote = data?.securityAssets?.edges?.[0]?.node?.nbboQuote;

  const lastFetchTime = useMemo(() => {
    const timestamp = nbboQuote?.lastTrade?.timestamp;

    if (!timestamp) {
      return '';
    }

    return new Intl.DateTimeFormat(undefined, {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(new Date(timestamp));
  }, [nbboQuote]);

  const refreshLastSaleData = () => {
    if (!showModal || loading) {
      return;
    }

    refetch();
  };

  useEffect(refreshLastSaleData, []);

  const getCard = (title: string, description: string) => {
    let displayValue = '-';

    switch (title) {
      case 'Best Bid':
        displayValue = thousandFormatNumbroCurrency(nbboQuote?.BidPrice);
        break;
      case 'Best Ask':
        displayValue = thousandFormatNumbroCurrency(nbboQuote?.AskPrice);
        break;
      case 'Bid Size':
        displayValue = nbboQuote?.BidSize ? `${nbboQuote?.BidSize}x` : '-';
        break;
      case 'Ask Size':
        displayValue = nbboQuote?.AskSize ? `${nbboQuote?.AskSize}x` : '-';
        break;
    }

    return (
      <div className="flex w-[145px] h-[50px]">
        {loading ? (
          <Skeleton className="flex flex-1" />
        ) : (
          <div className="flex flex-1 justify-between bg-grey6 p-2 rounded-md ">
            <div className="flex flex-col ">
              <WebCaption10.Regular className="text-grey72">{title}</WebCaption10.Regular>
              <WebBodyText16.Regular className="text-darkestBlue">
                {displayValue}
              </WebBodyText16.Regular>
            </div>
            <Image
              className="cursor-pointer"
              alt="Info icon"
              width={14}
              height={14}
              src={InfoIcon.src}
              onClick={() => {
                setShowNbboInfoDialog(true);
                setNbboInfoDialogState({ title, description });
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const getShortName = (name: string) => {
    const exchange = EXCHANGE_NAME_MAP.find((_exchange) => _exchange.name === name);
    return exchange ? exchange.shortName : '';
  };

  return (
    <ResponsiveDialog.Root open={showModal} onOpenChange={(isOpen) => setShowModal(isOpen)}>
      <ResponsiveDialog.Trigger>
        <WebCaption12.Regular className="text-grey55 underline">
          Best bid/offer
        </WebCaption12.Regular>
      </ResponsiveDialog.Trigger>
      <ResponsiveDialog.Content
        className={showNbboInfoDialog && !isBreakpoint('xs') ? 'hidden' : ''}
      >
        <ResponsiveDialog.Header>
          <Image alt="Info icon" width={50} height={50} src={InfoPrimaryIcon.src} />
          <ResponsiveDialog.Title>National best bid & offer</ResponsiveDialog.Title>
        </ResponsiveDialog.Header>
        <div className="flex flex-col items-center gap-2 w-full max-w-[300px] mx-auto">
          <div className="flex gap-2">
            {getCard('Best Bid', displayString.nbboQuoteBestBidAskDesc)}
            {getCard('Best Ask', displayString.nbboQuoteBestBidAskDesc)}
          </div>
          <div className="flex gap-2">
            {getCard('Bid Size', displayString.nbboQuoteBidSizeDesc)}
            {getCard('Ask Size', displayString.nbboQuoteAskSizeDesc)}
          </div>
        </div>
        <div className="flex justify-center gap-4">
          <WebCaption12.Regular className="text-grey55">
            Bid provided by:{' '}
            <span className="text-darkestBlue font-bold">
              {getShortName(nbboQuote?.BidExchange?.name || '')}
            </span>
          </WebCaption12.Regular>
          <WebCaption12.Regular className="text-grey55">
            Ask provided by:{' '}
            <span className="text-darkestBlue font-bold">
              {getShortName(nbboQuote?.AskExchange?.name || '')}
            </span>
          </WebCaption12.Regular>
        </div>
        <div className="bg-grey7 rounded-[6px] flex flex-col gap-2.5 p-2.5 w-full max-w-[300px] mx-auto">
          <div className="flex justify-between space-x-1.5">
            <WebBodyText14.Regular className="text-grey72 whitespace-nowrap">
              {displayString.lastSale}
            </WebBodyText14.Regular>

            {loading ? (
              <div className="inline-flex gap-2.5 w-1/2">
                <Skeleton className="flex flex-1" />

                <Skeleton className="w-6" />
              </div>
            ) : (
              <div className="inline-flex gap-2.5">
                <WebBodyText14.Bold className="text-darkestBlue">
                  {thousandFormatNumbroCurrency(nbboQuote?.lastTrade?.price)}
                  {' x '}
                  {thousandFormatIntNumbro(nbboQuote?.lastTrade?.volume)}
                </WebBodyText14.Bold>

                <span className="bg-grey41 text-white flex justify-center items-center px-[7px] py-[3px] rounded-[3px]">
                  <WebBodyText10.Regular>
                    {nbboQuote?.lastTrade?.securityExchange?.tape}
                  </WebBodyText10.Regular>
                </span>
              </div>
            )}
          </div>
          <div className="flex justify-between space-x-1.5">
            <WebBodyText14.Regular className="text-grey72 whitespace-nowrap">
              {displayString.refreshedAt}
            </WebBodyText14.Regular>

            {loading ? (
              <Skeleton className="w-1/3" />
            ) : (
              <WebBodyText14.Bold className="text-darkestBlue">{lastFetchTime}</WebBodyText14.Bold>
            )}
          </div>
          <div className="flex justify-center">
            <button className="text-grey55 underline" type="button" onClick={refreshLastSaleData}>
              <WebBodyText12.Regular>{displayString.refresh}</WebBodyText12.Regular>
            </button>
          </div>
        </div>
        <NbboInfoDialog
          title={nbboInfoDialogState.title}
          description={nbboInfoDialogState.description}
          open={showNbboInfoDialog}
          onOpenChange={setShowNbboInfoDialog}
        />
        <ResponsiveDialog.Footer>
          <ResponsiveDialog.Close asChild>
            <Button className="w-full" variant={isBreakpoint('xs') ? 'ghost' : 'primary'}>
              Close
            </Button>
          </ResponsiveDialog.Close>
        </ResponsiveDialog.Footer>
      </ResponsiveDialog.Content>
    </ResponsiveDialog.Root>
  );
};
