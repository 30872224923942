export const REG_PHONE_NUMBER_WITH_MASK = /^\+\d{1,3} \(\d{3}\) [2-9]\d{2}-\d{4}$/;
export const REG_PHONE_NUMBER_WITH_MASK_BR = /^\+55 \(\d{2}\) 9\d{4}-\d{4}$/;
export const REG_UPPERCASE_LETTERS = /[A-Z]/;
export const REG_LOWERCASE_LETTERS = /[a-z]/;
export const REG_NUMBERS = /[0-9]/;
export const REG_SPECIAL_CHARACTERS = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
export const REG_LETTER_NUMBER = /^[A-Za-z0-9]+$/;
export const REG_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REG_FIRST_LAST_NAME = /^[a-zA-Z'’0-]+$/;
export const REG_ONLY_ASCII = /[\x21-\x7E‘“’”]/;
export const REG_POST_BOX =
  /^ *((#\d+)|((box|bin)[-. /\\]?\d+)|(.*p[ .]? ?(o|0)[-. /\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i;
export const REG_POSTAL_CODE = /^[0-9]{5}$/;
export const REG_US_STATE =
  /^(?:(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY]))$/;
export const REG_NO_FIRST_SPACE = /^[^\s].*/;
export const REG_SSN = /(?:\d{3})-(?:\d{2})-(\d{4})/;
