import React from 'react';
import Image from 'next/image';
import { WebBodyText18, WebHeading28 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';

import { useSidePanel } from '@/context/SidePanelContext';

import { SuccessCircleIcon } from '@/assets/icons';

import { Button } from '@/components/common/Button';
import { SheetContent, SheetFooter, SheetTitle } from '@/components/common/Sheet';

const displayStrings = {
  congratulations: 'Success!',
  checkActivityHistory: 'Your bank account has been linked successfully.',
  continue: 'Continue',
};

export const AddACHAccountSidePanelSuccess = () => {
  const { closeSidePanel } = useSidePanel();

  const handleClose = () => closeSidePanel();

  return (
    <SheetContent>
      <div className="flex flex-col items-center gap-7 text-center mt-20">
        <Image alt="Success icon" width={50} height={50} src={SuccessCircleIcon.src} />
        <SheetTitle>
          <WebHeading28.Bold className="text-darkestBlue">
            {displayStrings.congratulations}
          </WebHeading28.Bold>
        </SheetTitle>
        <WebBodyText18.Regular className="text-grey72">
          {displayStrings.checkActivityHistory}
        </WebBodyText18.Regular>
      </div>

      <SheetFooter className="my-5 text-center">
        <div className="w-full flex flex-col gap-2">
          <Button variant="primary" onClick={handleClose}>
            {displayStrings.continue}
          </Button>
        </div>
      </SheetFooter>
    </SheetContent>
  );
};
