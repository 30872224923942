import {
  V1UserEmploymentStatus,
  V1UserMaritalStatus,
  V1UserRiskTolerance,
} from '@fintronners/react-api/src/tsoai';
import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';

import robo from '@fintronners/react-language/src/Lang/en.robo';
import errors from '@fintronners/react-language/src/Lang/en.errors';
import settings from '@fintronners/react-language/src/Lang/en.settings';
import widgets from './en.widgets';
import gi from './en.gi';

export default {
  common: {
    back: 'Back',
    continue: 'Continue',
    loading: 'Loading',
    getStarted: 'GET STARTED',
    submit: 'SUBMIT',
    submitAndContinue: 'SUBMIT AND CONTINUE',
    home: 'HOME',
    login: 'LOG IN',
    signUp: 'SIGN UP',
    privacyPolicyText: `For more information, see our [Privacy Policy.](${FinTronConfig.privacyPolicyUrl})`,
    exit: 'Exit setup',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password?',
    done: 'Done',
    exitTitle: 'Are you sure you want to exit?',
    exitDescription:
      'You’re almost there! You can always come back and finish your application later.',
    pendingChangesDesc:
      '*New values have been submitted but aren’t yet confirmed, you may still continue to submit additional changes.',
    yes: 'Yes',
    no: 'No',
    amount: 'Amount',
    totalAmount: 'Total amount',
    updating: 'Updating...',
    saveChanges: 'Save Changes',
    cancel: 'Cancel',
    warning: 'Warning',
    yesCancel: 'Yes, cancel',
    save: 'Save',
    apply: 'Apply',
    processing: 'Processing',
    delete: 'Delete',
    deleteAll: 'Delete all',
    deleteSure: 'Are you sure you want to delete this',
    noSuggestionsFound: 'No suggestions found',
    other: 'Other',
    dummyScreen: 'DUMMY SCREEN',
    congratulations: 'Congratulations',
    document: 'Document',
    date: 'Date',
    deposit: 'Deposit',
    instantDeposit: 'Instant deposit',
    withdrawal: 'Withdrawal',
    trade: 'Trade',
    depositAmount: 'Deposit amount',
    withdrawAmount: 'Withdrawal amount',
    submitBtnText: 'Submit',
    enterAmount: 'Enter amount (minimum ${{amount}})',
    ask: 'Ask: ',
    bid: 'Bid: ',
    notAvailable: 'N/A',
    by: 'By',
    myHolding: 'My holding',
    info: 'Info',
    data: 'Data',
    news: 'News',
    distributions: 'Distributions',
    pastDistributions: 'Past distributions',
    expensesAndFees: 'Expenses & fees',
    minimums: 'Minimums',
    top10Holdings: 'Top 10 Holdings',
    settings: 'Settings',
    documents: 'Documents',
    accountStatement: 'Account statement',
    accountNumber: 'Account number',
    taxForm: 'Tax form',
    tradeConfirm: 'Trade confirm',
    statements: 'Statements',
    tradeConfirms: 'Trade confirms',
    profileInformation: 'Profile information',
    personalInformation: 'Personal information',
    profileSettings: 'Profile settings',
    success: 'Success!',
    support: 'Support',
    search: 'Search',
    to: 'to',
    symbolOfCompany: 'Symbol of company',
    selectDate: 'Select date',
    open: 'Open',
    cancelled: 'Cancelled',
    cancelling: 'Cancelling',
    pending: 'Pending',
    completed: 'Completed',
    returned: 'Returned',
    received: 'Received',
    marketBuy: 'Market buy',
    marketSell: 'Market sell',
    shares: 'Shares',
    costBasis: 'Cost basis',
    perShare: 'per share',
    dividend: 'Dividend',
    rollover: 'Roll-over',
    stock: 'Stock',
    automatedInvestment: 'Automated investment',
    recurring: 'Automated transfers',
    searchSecurity: 'Search security',
    status: 'Status',
    type: 'Type',
    symbol: 'Symbol',
    custom: 'Custom',
    gainLoss: 'Gain/Loss:  ',
    asOf: 'As of {{time}}',
    expand: 'Expand',
    showMore: 'Show more',
    showLess: 'Show less',
    frequency: {
      weekly: 'Weekly',
      biWeekly: 'Bi-Weekly',
      monthly: 'Monthly',
    },
    duration: {
      day: '1D',
      tradingDay: '1D',
      extendedTradingDay: '1D',
      week: '1W',
      month: '1M',
      threeMonths: '3M',
      year: '1Y',
      fiveYears: '5Y',
      all: 'All',
    },
  },
  selfDirected: {
    single: {
      depositAmount: 'Deposit amount',
      enterAmount: 'Enter amount (minimum ${{minimum}})',
    },
  },
  onboardingWelcome: {
    items: [
      {
        uri: require('@fintronners/react-ui/src/Themes/carouselImages/image1.png'),
        title: 'Lorem Ipsum',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      },
      {
        uri: require('@fintronners/react-ui/src/Themes/carouselImages/image2.png'),
        title: 'Lorem Ipsum',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      },
      {
        uri: require('@fintronners/react-ui/src/Themes/carouselImages/image3.png'),
        title: 'Lorem Ipsum',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      },
    ],
    termsAndPrivacyPolicy: `By clicking “Get Started” you will begin the process of creating a profile with ${FinTronConfig.parentName}, and agree to our [Terms of Use](${FinTronConfig.termsAndConditionsUrl}) and [Privacy Policy.](${FinTronConfig.privacyPolicyUrl})`,
    extraFooterElement: '',
    accessibilityStatementTitle: '',
    accessibilityStatement: '',
  },
  onboardingAccountVerification: {
    title: 'Confirm your email and mobile number.',
    subtitle:
      'Please confirm your email and phone number to expedite the process of opening your account.',
    profileNotFound: 'Profile not found',
    profileNotFoundDescription: `The information you provided is not associated with a ${FinTronConfig.parentName} account. If you continue to have a problem, please contact ${FinTronConfig.supportEmail}.`,
    profileFound: 'Profile found',
    profileFoundDescription: `Great news! The information you provided is associated with a ${FinTronConfig.parentName} account. You are minutes away from opening your investment account.`,
  },
  onboardingPrefill: {
    title: 'Pre-filled answers',
    description: `*I acknowledge the pre-filled information below was provided by me during the account opening process for ${FinTronConfig.parentName}, and understand that this information will be used to open my account in ${FinTronConfig.selfDirected.name}.\n\nBy continuing the account opening process for ${FinTronConfig.selfDirected.name}., I certify the information below is accurate and correct. If the information below is no longer accurate I will not continue with the onboarding process and will update my information by sending an email to ${FinTronConfig.supportEmail}`,
  },
  onboardingUsernamePassword: {
    title: 'Let’s keep your account secure.',
    subtitle: 'For security reasons, do not re-use passwords for multiple websites or profiles.',
    confirmPasswordFieldHelpText:
      'Your password must be a minimum of 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character for enhanced security.',
    username: 'Username',
    password: 'Password',
    confirmPassword: 'Confirm Password',
  },
  onboardingBasicInfo: {
    title: 'Hi there! Let’s start with some basic info.',
    subtitle: `${FinTronConfig.parentName} is required to collect this information to create your profile.`,
    firstName: 'First Legal Name',
    firstNameHelpText: '',
    lastName: 'Last Legal Name',
    email: 'Email',
    dateOfBirth: 'Date of Birth',
    phoneNumber: 'Phone Number (10 Digits)',
    checkbox: '',
  },
  onboardingVerification: {
    title: 'Please verify your phone number and email.',
    subtitle:
      'Thank you for providing your profile information.\nWe need you to verify your phone number, which will be used for two-factor authentication, and your email, which will be used to recover your password.',
    email: 'Email',
    phoneNumber: 'Phone Number',
    verify: 'Verify',
  },
  onboardingAddress: {
    title: 'What’s your residential address?',
    subtitle: `${FinTronConfig.parentName} is required to collect this information to create your profile.`,
    streetAddress: 'Address',
    additionalStreetAddress: 'Apartment, suite, unit, building, floor, etc.',
    city: 'City',
    state: 'State',
    postalCode: 'Zip Code',
    country: 'Country',
    footer: '',
  },
  onboardingCitizenship: {
    title: 'Citizenship status.',
    subtitle: 'At the moment, we can only open accounts for U.S. Citizens and some visa holders.',
    notCitizenTitle: "We're very sorry.",
    notCitizenSubtitle:
      'This program is only available to US Citizens, green card holders, and holders of certain types of visas.',
    usCitizen: 'U.S. Citizen',
    greenCard: 'Green Card',
    visa: 'Visa',
    noneApplicable: 'None Applicable',
    nonResident: 'Non-resident',
    yourStatus: 'Your status{{pendingMark}}:',
  },
  onboardingPlaceOfBirth: {
    title: 'Place of birth.',
    subtitle: 'Select your place of birth:',
    label: 'Place of birth',
  },
  onboardingVisa: {
    title: 'Visa type.',
    subtitle: 'Select your visa type:',
    visaExpirationTitle: 'Visa expiration date.',
    visaExpirationSubtitle: 'Enter your visa’s expiration date:',
    placeholder: 'Month / Day / Year',
  },
  onboardingSocial: {
    title: 'Verify your identity',
    subtitle: `${FinTronConfig.parentName} is required to collect this information to open your brokerage account.`,
    checklist: [
      {
        title: 'We will protect your SSN',
        name: 'We will protect your SSN like it’s our own. We will never share your SSN without your permission, unless required by the law. Don’t worry this does not effect your credit score.',
      },
      {
        title: '256-bit encryption',
        name: '256-bit encryption is a data/file encryption technique that uses a 256 bit key to encrypt and decrypt data or files. It is used in most modern encryption algorithms, protocols, and technologies.',
      },
    ],
    personalIdNumber: 'Social Security Number',
  },
  onboardingLicense: {
    title: 'Verify your identity with your driver’s license or passport.',
    subtitle: `${FinTronConfig.parentName} is required to collect this information to create your profile. `,
    maxSizeTitle: 'Upload Failed!',
    maxSizeDescription: 'Your file size is too large, please try and upload a smaller one!',
    driverLicense: 'Driver’s License',
    passport: 'Passport',
    uploadFront: 'Upload Front',
    uploadBack: 'Upload Back',
  },
  onboardingOtherSourceOfWealth: {
    title: 'Other source of wealth',
    subtitle:
      'Please describe your other source of wealth. This is separate from the wealth received from your job.',
    label: 'Other source of wealth',
  },
  onboardingCompliance: {
    title: 'Select all that apply to you. If you aren’t sure, it likely doesn’t apply.',
    questions: {
      isControlPerson: 'I am a control person',
      isControlPersonDescriptionTitle: 'What’s A Control Person',
      isControlPersonDescription:
        'A control person could be defined as a direct, officer, or other affiliate of a publicly traded company, who has control over management or policies. It could also be a stockholder who owns 10% or more of any class of a corporation’s voting securities.',
      isPoliticallyExposed: 'I am politically exposed',
      isPoliticallyExposedDescriptionTitle: 'Political Exposure',
      isPoliticallyExposedDescription:
        'A politically exposed person (PEP) is an individual with a high profile political role, or who has been entrusted with a prominent public function. PEPs represent a higher risk for financial institutions and Designed Non-Financial Businesses and professionals (DNFBS).',
      isAffiliatedExchangeOrFINRA:
        'I, or someone in my family, work at a FINRA member or Stock Exchange',
      isAffiliatedExchangeOrFINRADescriptionTitle: 'Working for FINRA or a stock exchange',
      isAffiliatedExchangeOrFINRADescription:
        'Are you, your spouse, any member of your immediate household or anyone who contributes 25% or more towards your annual income a FINRA employee (or a registered exchange employee) with a financial interest in the account or that will control trading in the account?',
      isSecuritiesProfessional: 'I am a securities professional',
      isSecuritiesProfessionalDescriptionTitle: 'Securities Professionals',
      isSecuritiesProfessionalDescription:
        'A securities professional would be one of the following.\n\n' +
        'Is registered or qualified with: the Securities and Exchange Commission, the Commodities futures Trading Commission, any state securities agency, any exchange association, or any commodities of futures market or association.\n\n' +
        'Is engaged as an “investment advisor” as that term is defined in section 201 (11) of the investment Advisor’s Act of 1940 (whether or not registered or qualified under that Act).\n\n' +
        'Is employed by a bank or another organization that is exempt from registration under Federal and/or state securities laws to preform functions or qualified if he or she were to preform such functions for an organization not so exempt. ',
    },
  },
  onboardingIsControlPerson: {
    title: 'Control person information.',
    description:
      'Please enter the ticker symbol(s) of the company/companies for which you are a control person.',
    addACompany: '+ Add a company',
    symbolOfCompany: 'Symbol of company',
    editTitle: 'Are you a control person?',
    editDescription: 'If you aren’t sure, it likely doesn’t apply.',
  },
  onboardingIsPoliticallyExposed: {
    title: 'Political exposure information.',
    description: "If you aren't sure, it likely doesn't apply.",
    politicalOrganizationNameTitle:
      'Please enter the political organization with which you are affiliated.',
    familyMembersFullNameTitle: 'Please enter the names of your immediate family members.',
    addFamilyMember: '+ Add A Family Member',
    settingsTitle: 'Are you politically exposed?',
    isPoliticallyExposed: 'I am politically exposed',
    namePoliticalOrganization: 'Name of political organization',
    familyMemberName: 'Full name of family member',
    politicalOrganizationName: 'Political Organization Name',
    familyMembersFullName: 'Family Members Full Name',
  },
  onboardingIsFinra: {
    title: 'Employing firm information.',
    question: 'Do you, or someone in your family, work at a FINRA member or Stock Exchange?',
    employingFirmTitle: 'Enter the name of your employing firm.',
    firmNamePlaceholder: 'Name of firm',
    uploadLetterTitle: 'Upload 3210 letter',
    uploadLetterButton: 'Upload 3210 letter',
    uploadLetterDescription:
      'Upload a picture of your 3210 letter from your FINRA affiliated employer permitting you to create a brokerage account with ${FinTronConfig.parentName}.',
    maxSizeTitle: 'Upload failed!',
    maxSizeDescription: 'Your image file size is too large, please try and upload a smaller image!',
    form: {
      title: 'Enter the name of your employing firm.',
      label: 'Name of firm',
      letterTitle: 'Upload 3210 letter',
      letterButtonText: 'Upload 3210 Letter',
      letterDescription: `Upload a picture of your 3210 letter from your FINRA affiliated employer permitting you to create a brokerage account with ${FinTronConfig.parentName}.`,
      maxSizeTitle: 'Upload failed!',
      maxSizeDescription:
        'Your image file size is too large, please try and upload a smaller image!',
      companyEmployeerTitle: `I work at ${FinTronConfig.parentName}.`,
    },
  },
  onboardingSecuritiesProfessional: {
    title: 'Market Data Questions',
    subtitle: 'Select all that apply to you. If you aren’t sure, it likely doesn’t apply.',
    settingsTitle: 'Securities professional',
    personalUse: 'Personal Use',
    isForPersonalUseQuestion: 'I am going to use market data for more than just personal use.',
    isForPersonalUseDescription: `The question is asking if you plan on soley using the stock market data provided on this application for investing and research on the FinTron Invest Platform only.`,
    isAdvisorOrConsultantQuestion:
      'I receive stock market data from another provider for professional reasons via my employer.',
    isRegisteredWithSecOrCftcQuestion: 'I am registered or qualified with the SEC or the CFTC.',
    requiresSecOrCftcRegistrationQuestion:
      'I perform functions that would require me to register with the SEC, and or the CFTC or similar regulatory bodies.',
    requiresSecOrCftcRegistrationTitle: 'Regulatory Body Registration',
    requiresSecOrCftcRegistrationDescription: `Are you involved with any securities (stocks) related activities? If not, select no for this question.`,
    investForOtherQuestion: 'I invest with someone else’s money.',
    investForTheBenefitOfOtherQuestion: 'I invest for the benefit of another person/entity.',
    receivesDataFromExternalSourceQuestion:
      'I am an investment advisor, asset manager, or financial consultant.',
    receivesDataFromExternalSourceTitle: 'Market Data',
    receivesDataFromExternalSourceDescription: `If you trade for your own benefit, select no for this question.`,
    sharesProfitQuestion:
      'I have entered into an agreement to share the profit of my investing activities and or receive compensation for trading',
    isRegisteredWithEntityQuestion:
      'I am currently registered with a security agency, exchange, or commodity/future entity.',
    questions: {
      firstQuestionTitle: 'Do you intend to use market data for purposes beyond personal use?',
      firstQuestionInfoTitle: 'Personal Use',
      firstQuestionInfoDescription:
        'This question asks if you plan to use the stock market data provided in this application exclusively for investment and research on the Bradesco Investments platform.',
      secondQuestionTitle:
        'Do you receive stock market data from another provider at your workplace or elsewhere?',
      thirdQuestionTitle: 'Are you registered or qualified with the SEC or the CFTC?',
      fourthQuestionTitle:
        'Do you perform functions that would require you to register with the SEC and/or the CFTC or similar regulatory bodies?',
      fourthQuestionInfoTitle: 'Regulatory Body Registration',
      fourthQuestionInfoDescription:
        'Are you involved in any activities related to securities (stocks)? If not, select no for this question.',
      fifthQuestionTitle: 'Do you invest with someone else’s money?',
      sixthQuestionTitle: 'Do you invest for the benefit of another person/entity?',
      seventhQuestionTitle:
        'Are you an investment advisor, asset manager, or financial consultant?',
      seventhQuestionInfoTitle: 'Investment Advisor, Asset Manager, or Financial Consultant',
      seventhQuestionInfoDescription:
        'If you trade for your own benefit, select no for this question.',
      eighthQuestionTitle:
        'Have you entered into an agreement to share the profit of your investing activities and/or receive compensation for trading?',
      ninthQuestionTitle:
        'Are you currently registered with a securities agency, exchange, or commodity/future entity?',
    },
  },
  onboardingSelectAccountType: {
    title: 'Choose the type of account you’d like to open',
    stockAccountTypeTitle: 'Build your own portfolio',
    stockAccountTypeDescription: `Open a ${FinTronConfig.selfDirected.name} brokerage account with ${FinTronConfig.selfDirected.name} and pick your own securities.`,
    stockAccountTypeCardTitle: FinTronConfig.selfDirected.name,
    stockAccountTypeCardDescription: 'Choose stocks that fit your lifestyle.',
    generalInvestingAccountTypeCardTitle: 'General Investing',
    generalInvestingAccountTypeCardDescription: 'Don’t have a goal in mind, start here.',
    roboAccountTypeTitle: 'Open a Robo account',
    roboAccountTypeDescription: `Whether you want to invest for later or create a specific goal, let ${FinTronConfig.robo.name} help you get there.`,
    roboAccountTypeCardTitle: 'Create A Goal',
    roboAccountTypeCardDescription: 'Invest for a specific goal.',
    retirementAccountTypeCardTitle: 'Retirement',
    retirementAccountTypeCardDescription: 'Invest for retirement.',
  },
  onboardingStocksLanding: {
    title: 'Stocks',
    subtitle: `Open your ${FinTronConfig.selfDirected.name} brokerage account and buy stocks that fit your lifestyle.`,
    legal: `By clicking “Get Started” you will begin the process of opening an individual cash Brokerage Account with ${FinTronConfig.selfDirected.name}, and agree to our [Terms of use](${FinTronConfig.termsAndConditionsUrl}) and [Privacy Policy](${FinTronConfig.privacyPolicyUrl})`,
    checklist: [{ name: 'Choose from 2,000+ fractional stocks', bold: false }],
    bodyText: '',
    footer: '',
  },
  onboardingStocks: {
    firstSurvey: {
      title: 'Let’s get started with the basics.',
      subtitle: 'We need you to answer a few regulatory questions in order to open your account.',
      experienceLevelQuestion: 'Your investing level of experience is...',
      experienceLevelAnswers: {
        none: 'None',
        limited: 'Limited',
        good: 'Good',
        extensive: 'Extensive',
      },
      investmentObjectiveQuestion: 'What’s your investment objective?',
      investmentObjectiveAnswers: {
        growth: 'Growth',
        income: 'Income',
        speculation: 'Speculation',
        capitalpreservation: 'Capital Preservation',
        other: 'Other',
      },
      investmentObjectiveInfoTitle: 'Investment Objectives',
      investmentObjectiveInfoDescription:
        'Preservation: This objective focuses on the safe keeping of your money. Investments should be tailored to stocks with the least amount of risk as possible.\n\n' +
        'Income: This objective focuses on investing in securities that generate higher than average dividends and long term capital appreciation. (Dividends are quarterly payouts of profits to shareholders.)\n\n' +
        'Growth: This objective focuses on investing in securities that experience short-term capital appreciation (stock price goes up in a short amount of time!). This investment objective is inherently riskier than the preservation and income objectives. \n\n' +
        'Speculation: This objective refers to the act of buying a security that has substantial risk of losing value but also carries the expectation of increasing in value significantly. This is by far the most risky objective. \n',
      investmentHorizonQuestion: 'What is your investment time horizon?',
      investmentHorizonAnswers: {
        short: 'Short',
        average: 'Average',
        longest: 'Longest',
      },
      investmentHorizonInfoTitle: 'Your Investing Time Horizon',
      investmentHorizonInfoDescription:
        'Short: A short term investment time horizon implies you plan on buying and selling securities (financial asset, etc) frequently for a quick return on your investment (less than a year). \n\n' +
        'This could have tax implication. Short term gains are taxed at a higher rate. We recommend meeting with a tax professional to learn more. \n\n' +
        'Medium: A medium term investment time horizon means you have some securities in your portfolio that you plan on holding for longer than 1 year . However, you may also have investment that you intend on flipping for short term gains. \n\n' +
        'Long: A long term investment time horizon implies you are investing for the future and not immediate income needs. Long term capital gains are taxed at a much lower rate!',
      liquidityNeedsQuestion: 'How important is having cash on hand?',
      liquidityNeedsAnswers: {
        veryimportant: 'Very Important',
        somewhatimportant: 'Somewhat Important',
        notimportant: 'Not Important',
      },
      liquidityNeedsInfoTitle: 'Liquidity Needs',
      liquidityNeedsInfoDescription:
        'The ability to quickly and easily convert all or a portion of the account assets into cash without experiencing significant loss.',
    },
    secondSurvey: {
      title: 'Personal questions',
      maritalStatusQuestion: 'Are you married?',
      maritalStatusAnswers: {
        [V1UserMaritalStatus.Married]: 'Married',
        [V1UserMaritalStatus.Single]: 'Single',
        [V1UserMaritalStatus.Divorced]: 'Divorced',
        [V1UserMaritalStatus.Widowed]: 'Widowed',
      },
      employmentStatusQuestion: "What's your current employment status?",
      employmentStatusAnswers: {
        [V1UserEmploymentStatus.Employed]: 'Employed',
        [V1UserEmploymentStatus.Unemployed]: 'Unemployed',
        [V1UserEmploymentStatus.Retired]: 'Retired',
        [V1UserEmploymentStatus.Student]: 'Student',
      },
      employerNameTitle: 'What is the name of your employer?',
      employerName: "Employer's name",
      positionTitle: 'What is your position?',
      position: 'Your position',
      annualIncomeRangeQuestion: 'What is your Annual Income?',
      annualIncomeRangeFirstAnswer: 'Less than or equal to $25,000',
      annualIncomeRangeSecondAnswer: '$25,001-$50,000',
      annualIncomeRangeThirdAnswer: '$50,001-$100,000',
      annualIncomeRangeFourthAnswer: '$100,001-$200,000',
      annualIncomeRangeFifthAnswer: '$200,001-$300,000',
      annualIncomeRangeSixthAnswer: '$300,001-$500,000',
      annualIncomeRangeSeventhAnswer: '$500,001-$1,200,000',
      annualIncomeRangeEighthAnswer: '$1,200,001-$9,999,999',
      netWorthRangeQuestion: 'What is your Net Worth?',
      netWorthRangeFirstAnswer: 'Less Than $50,000',
      netWorthRangeSecondAnswer: '$50,001-$100,000',
      netWorthRangeThirdAnswer: '$100,001-$200,000',
      netWorthRangeFourthAnswer: '$200,001-$500,000',
      netWorthRangeFifthAnswer: '$500,001-$1,000,000',
      netWorthRangeSixthAnswer: '$1,000,001-$5,000,000',
      netWorthRangeSeventhAnswer: '$5,000,001-$9,999,999',
      netWorthRangeEighthAnswer: 'Less Than $25,000',
      netWorthRangeNinthAnswer: '$25,001-$50,000',
      netWorthRangeTenthAnswer: '$200,001-$300,000',
      netWorthRangeEleventhAnswer: '$300,001-$500,000',
      netWorthRangeInfoDescription:
        'Total net worth is the dollar amount of your assets minus all your debts. In this case, exclude the value of your residence in your answer (if you own your home).',
    },
  },
  onboardingGoals: {
    title: 'Please select the level of importance for this goal',
    subtitle:
      'This step indicates the importance of your goal and helps decide the investment strategy.',
  },
  commonRiskTolerance: {
    title: 'Choose your risk tolerance.',
    change: 'Change your risk tolerance.',
    riskTolerance: 'Risk tolerance',
    subtitleStocks:
      'Choosing an accurate risk tolerance allows us to understand your personal investing objective.',
    goalSubtitle:
      'Choosing an accurate risk tolerance allows us to understand your personal investing objective. This risk tolerance will be applied to any additional future goals.',
    subtitleGoals:
      'The three possible risk profiles determine the maximum amount of equity exposure in your portfolio at any time. Changing this setting will change the maximum amount of equity allowed as described below. Generally, choosing an aggressive profile will result in an asset allocation with a higher equity exposure to meet the highest probability of reaching your goal. This can potentially create higher levels of both risk and reward.',
    stocksData: {
      [V1UserRiskTolerance.Low]:
        'Conservative Risk Tolerance investors may prioritize the safety of their investment principal over the potential of higher returns.',
      [V1UserRiskTolerance.Medium]:
        'Moderate Risk Tolerance investors may be comfortable with moderate market fluctuations and prefer investments with a moderate level of risk. ',
      [V1UserRiskTolerance.High]:
        'Aggressive Risk Tolerance investors may prioritize the potential for high returns over the safety of their investment principal.',
    },
    roboData: {
      [V1UserRiskTolerance.Low]: `A conservative risk profile allows equity exposure up to ${
        (FinTronConfig.robo.goals?.lowRiskPercentage ?? 0) * 100
      }%.`,
      [V1UserRiskTolerance.Medium]: `A moderate risk profile allows equity exposure up to ${
        (FinTronConfig.robo.goals?.mediumRiskPercentage ?? 0) * 100
      }%.`,
      [V1UserRiskTolerance.High]: `An aggressive risk profile allows equity exposure up to ${
        (FinTronConfig.robo?.goals?.highRiskPercentage ?? 0) * 100
      }%.`,
    },
    blurb: {
      title: 'Risk Profile',
      [V1UserRiskTolerance.Low]:
        'A **conservative investor** values stability more than higher return and prefers less risk and downside potential.',
      [V1UserRiskTolerance.Medium]:
        'A **moderate investor** values a balanced approach to growth of capital with preserving capital and accepting of some risk.',
      [V1UserRiskTolerance.High]:
        'An **aggressive investor** values higher return more than stability and is willing to accept greater risk and greater downside potential.',
      footer:
        'You will receive investment recommendations that align with your chosen risk profile in order to progress toward the full amount of your goal within the desired timeframe.',
    },
    surveyTitle: 'What is your risk profile?',
  },
  onboardingProfileConfirmation: {
    title: 'We have saved your profile information.',
    subtitle:
      'Next, you will select the type of account you’d like to create. Opening an account takes less than 10 minutes.',
    confirmButton: 'Continue To Select Account',
  },
  onboardingAgreement: {
    title: 'Congrats you made it to the end! You’re only one swipe away. ',
    stockSubtitle: 'Please read and sign {{ account }} customer agreement below.',
    roboSubtitle: 'Please read and sign {{ account }} customer agreement below.',
    stocksFirstButton: 'View Customer Agreement',
    stocksSecondButton: 'View Customer Relationship Summary',
    roboFirstButton: 'View Customer Agreement',
    roboSecondButton: 'View Customer Relationship Summary',
    agreeBsmButton: 'Accept',
    agreeBsmFooter: 'You must scroll all the way to the bottom of the contract in order to agree.',
    stocksCheckboxes: [
      {
        questionKey: 'apex_paid_security_lending',
        question: `Enroll in Apex’s Fully Paid Security Lending Program (optional). See the [Apex Master Securities Lending Agreement](https://apexfintechsolutions.com/wp-content/uploads/2023/10/FPSL-Master-Securities-Lending-Agreement_June-2023.pdf) and [Securities Lending Disclosures](https://apexfintechsolutions.com/wp-content/uploads/2023/10/FPSL-Risk-Disclosure_April-2023.pdf).`,
      },
      {
        questionKey: 'apex_cash_sweep_service',
        question:
          'Enroll in Apex’s Cash Sweep Program (optional). See the [Apex Cash Sweep Program](https://apexfintechsolutions.com/wp-content/uploads/2022/05/Apex-FDIC-Sweep-Program-Terms-and-Conditions-1.pdf) Terms for more information.',
      },
    ],
    roboCheckboxes: [
      {
        questionKey: '',
        question: '',
      },
    ],
    roboCheckboxesTitles: [
      {
        checkboxKey: '',
        title: '',
      },
    ],
  },
  onboardingYouAreAllSet: {
    title: `You're all set!`,
    roboSubtitle: `Congratulations you submitted your ${FinTronConfig.robo.name}  Robo account application.\n\nOnce your account is approved, your initial investment will be automatically transferred for your goal and we will place the investments.`,
    stockSubtitle: `Congratulations you submitted your ${FinTronConfig.selfDirected.name} brokerage account application.`,
    checkListTitle: 'Now that you completed your application you’ll have access to the following!',
    goalCreatedTitle: 'Goal Created',
    goalCreatedDescription:
      'Congratulations! You have successfully created a new goal. View your goal details and history on this page.',
    stocksChecklist: [
      { name: 'Commission free fractional trading' },
      { name: 'Automated Repeat Investments' },
    ],
  },
  forgotPassword: {
    title: 'Forgot Password',
    changePassword: 'Change Password',
    description:
      'Please enter the username associated with your account and we will send you a password reset code via email.',
    label: 'Username',
  },
  onboardingApexExtendedQuestions: {
    title: 'Personal Information',
    footer: '',
  },
  resetPassword: {
    title: 'New Password',
    description: 'Please reset your password below.',
    codeLabel: 'Password Code',
    newPasswordLabel: 'Enter New Password',
    confirmPasswordLabel: 'Confirm New Password',
    helpText:
      'Password must contain 8 characters. We highly recommend creating a password with a combination of letters, numbers, and special characters (! @ # $…).',
    codeHelpText:
      "We've sent you a code via email. Please enter it above to continue with your password reset.",
    passwordSuccessSummary: 'Your password has been reset.',
  },

  settingsVerification: {
    title: 'Profile Verification',
    subtitle: 'Please verify your email and phone number.',
  },
  riskTolerance: {
    [V1UserRiskTolerance.High]: 'Aggressive',
    [V1UserRiskTolerance.Low]: 'Conservative',
    [V1UserRiskTolerance.Medium]: 'Moderate',
  },
  errors,
  settingsApexClearingPrograms: {
    title: 'Apex Clearing Programs',
    fpslProgram: 'Fully Paid Lending Program (FPSL)',
    fpslDesc: `Do you wish to be enrolled in Apex’s Fully Paid Security Lending Program. See the [Apex Master Securities Lending Agreement](${FinTronConfig.apexUrls.apexMasterSecuritiesLendingAgreement}) and [Securities Lending Disclosures](${FinTronConfig.apexUrls.securitiesLendingDisclosure}).`,
    cashSweepProgram: 'Cash Sweep Program',
    cashSweepDesc: `Do you wish to be enrolled in Apex’s Cash Sweep program? See the [Apex Cash Sweep Program Terms](${FinTronConfig.apexUrls.apexCashSweep}) for more information.`,
    contactSupport: 'Contact Support',
    contactSupportDesc: `To opt into the FPSL program, please contact [${FinTronConfig.selfDirected.supportEmail}](${FinTronConfig.selfDirected.supportEmail}) stating so.`,
    pendingChanges: 'Pending changes',
    pendingChangesDesc:
      '*New values have been submitted but aren’t yet confirmed, you may still continue to submit additional changes.',
    optOut: 'Opt out',
    optOutDesc: `You are opting out of the FPSL program. You will be required to contact support in order to opt-in again.\n\nAre you sure you want to opt out?`,
  },
  support: {
    account: 'Account',
  },
  accounts: {
    active: {
      accountHistory: 'Account history',
      trustedContact: 'Trusted contact',
      documents: 'Documents',
      investmentQuestions: 'Investment questions',
      apexClearingPrograms: 'Apex Clearing Programs',
      closeAccount: 'Close account',
    },
    settings: {
      title: 'Account Settings',
      profileSettings: 'Profile settings',
      profileInformation: 'Profile information',
      support: 'Support',
      security: 'Security',
      bankLink: 'Bank link',
      logout: 'Log out',
    },
    settingsTitle: 'Account settings',
    open: 'Open a {{ account }} account',
    pending: 'Pending {{ account }} account',
    accountPendingTitle: 'Account status in review',
    accountPendingDescription: `We are committed to maintaining the highest standards of security and compliance. While we monitor the status of your account, you may have limited access to some features.\n\nPlease contact support at [${FinTronConfig.supportEmail}](mailto:${FinTronConfig.supportEmail}) for more information.`,
    accountCanOpenTitle: 'Open An Account',
    accountCanOpenDescription: `We see that you’ve created a profile. You may create an account to access ${FinTronConfig.parentName}’s brokerage features.\n\nIf you have any issues, contact [${FinTronConfig.supportEmail}](mailto:${FinTronConfig.supportEmail}).`,
    accountCanOpenButton: 'Open An Account',
    accountClosedTitle: 'Your account has been closed',
    accountClosedDescription: `Your account has been closed. You can no longer take actions associated with your account, but you may view your documents such as monthly statements and tax forms.\n\nTo reopen this account, contact [${FinTronConfig.supportEmail}](mailto:${FinTronConfig.supportEmail}).`,
    stocks: {
      providedBy: `By ${FinTronConfig.selfDirected.name}`,
      pendingTitle: `${FinTronConfig.selfDirected.name} account application pending`,
      pendingDescription: `Your application has been submitted and is currently being processed. Please check your email for additional updates.\n\nContact [${FinTronConfig.selfDirected.supportEmail}](mailto:${FinTronConfig.selfDirected.supportEmail}) for more information or if you have additional questions concerning your application.`,
    },
    robo: {
      providedBy: `By ${FinTronConfig.robo.name}`,
      pendingTitle: `${FinTronConfig.robo.name}  application pending`,
      pendingDescription: `Your application has been submitted and is currently being processed. Please check your email for additional updates.\n\nContact [${FinTronConfig.robo.supportEmail}](mailto:${FinTronConfig.robo.supportEmail}) for more information or if you have additional questions concerning your application.`,
      closeAccountTitle: 'Are you sure you want to delete your account',
      closeAccountDescription:
        'In order to close your Robo Account ({{ accountNumber }}), you must first make sure to:\n1. Deactivate your existing Goals.\n\nWhen this action is completed, you will be able to close this account.',
      closeAccountExtraDescription: '',
      confirm: 'Yes, close account',
      goal: {
        deleteGoalTitle: 'Are you sure you want to deactivate goal?',
        deleteGoalBody:
          'In order to deactivate your “{{goalName}}” in your Robo Account ({{accountNumber}}), you must first make sure to:' +
          '\n1. Pause your automated investments in “{{goalName}}”' +
          '\n2. Withdraw all funds from “{{goalName}}”' +
          '\nWhen both of these actions are completed, you will be able to deactivate the goal.' +
          '\nDeactivating your goal will remove the tile from the My Accounts Home page',
        confirm: 'Deactivate goal',
        currentProbability: 'Current probability:',
        afterChange: 'After change:',
      },
    },
  },
  trustedContact: {
    title: 'Want to add a trusted contact to your account?',
    subtitle:
      'By choosing to provide information about a trusted contact person, you authorize us to contact the trusted contact person listed below and disclose information about your account to that person in the following circumstances: to address possible financial exploitation, to confirm the specifics of your current contact information, health status, or the identity of any legal guardian, executor, trustee or holder of a power of attorney, or as otherwise permitted by FINRA Rule 2165 (Financial Exploitation of Specified Adults).',
    learnMore: 'Learn more about trusted contacts',
    question: 'Do you want to add a trusted contact to your account?',
    learnMoreTitle: 'Trusted contact',
    learnMoreDescription: `Under FINRA Rule 4512, {{ key }} is required to disclose to you (the customer) that {{ key }} or an associated person of {{ key }} is authorized to contact the trusted contact person and disclose information about the customer’s account to address possible financial exploitation to confirm the specifics of the customer’s current contact information, health guardian, executor, trustee, or holder of a power of attorney, or as otherwise permitted by FINRA Rule 2165.`,
    legal: '',
    confirmEmailDescription: 'Did you mean to enter:',
    yes: 'Yes, that is correct',
    no: 'No, that is not correct',
  },
  settingsAddress: {
    title: 'Residential Address',
    footer: '',
  },
  profileInformation: {
    updateInfo: {
      title: 'Update info',
      description: 'The information update will be applied to all opened accounts.',
      confirm: 'Yes, update',
    },
  },

  roboEtf: {
    stockDetail: {
      seeMore: 'See more',
      seeLess: 'See less',
      marketPrice: 'Market price:',
      day30SecYield: '30-Day SEC Yield:',
      navPrice: 'NAV Price:',
      expenseRatio: 'Expense ratio:',
      yourPosition: 'Your position',
      marketValue: 'Market value:',
      shares: 'Shares:',
      secBsmTitle: 'SEC yield',
      secBsmMessage:
        "A non-money market fund's SEC yield is based on a formula developed by the SEC. The method calculates a fund's hypothetical annualized income as a percentage of its assets.\n\nA security's income, for the purposes of this calculation, is based on the current market yield to maturity (for bonds) or projected dividend yield (for stocks) of the fund's holdings over a trailing 30-day period. This hypothetical income will differ (at times, significantly) from the fund's actual experience. As a result, income distributions from the fund may be higher or lower than implied by the SEC yield.\n\nThe SEC yield for a money market fund is calculated by annualizing its daily income distributions for the previous 7 days.",
      expenseBsmTitle: 'Expense ratio',
      expenseBsmMessage: `Expressed as a percentage, the expense ratios are fees charged to investors to cover a fund or ETF's operating costs. They're deducted from dividend and capital gains distributions, not the principal.`,
      navBsmTitle: 'Net asset value “NAV”',
      navBsmMessage: `NAV is the value of each share in an investment fund, calculated based on the fund's assets and liabilities. It helps investors understand the worth of their investment. However, the market value of fund shares can differ from the NAV due to factors like supply and demand.`,
    },
  },
  allocation: {
    subtitle: 'Initial investment',
    subtitle2: 'Account value',
    initialAllocation: 'Initial allocation',
    initialAllocationDesc:
      'This is your proposed allocation of your initial investment. Our advice engine uses the inputs you provide to design a portfolio to help you achieve your goal.',
    title: 'Allocation',
    allocationDesc: `The recommended asset allocation is the most appropriate based on the information provided by you. Your asset allocation will be adjusted periodically, based on market movements, annual reassessments or when you provide updated information, to improve the probability of achieving your stated goal.\n\nOnce you have reached your goal within your specified timeframe, your assets will be invested in the most conservative asset allocation until you provide additional direction.`,
  },
  connectBank: {
    title: 'Connect your bank and fund your new account!',
    subtitle: `${FinTronConfig.parentName} uses a secure third party service to connect your account. `,
    connectBank: 'Connect bank',
    checkbox: '',
  },
  recurringRuleSlider: {
    recurringRules: 'RECURRING RULES',
    createRepeatInvestment: 'Create a repeat investment',
    automateInvestments: 'Automate your investments in thousands of stocks & ETFs',
  },
  commonKycConfirmation: {
    title: 'Please confirm account information.',
    subtitle: `Below are the details you provided for your existing investment account custodied at ${FinTronConfig.clearingFirm} To make it easier for you, we have re-used the information you provided to get started with your investment account application. You can always update your account information in Settings. Learn more about how we protect your personal data by reading the [FinTron Invest Privacy Policy](${FinTronConfig.selfDirected.privacyPolicyUrl}).`,
    investingTitle: 'Investing',
    investmentObjectives: 'Investment objective',
    personalTitle: 'Personal',
    employmentStatus: 'Employment status',
    netWorth: 'Net worth',
    annualIncome: 'Annual income',
    exposureTitle: 'Exposure',
    isControlPerson: 'Control person',
    isPoliticallyExposed: 'Politically exposed',
    isAffiliated: 'FINRA/Stock exchange member',
    isSecuritiesProfessional: 'Securities professional',
  },
  transfer: {
    reachedDailyLimitTitle: 'Cannot submit transfer',
    reachedDailyLimitDescription:
      'Your transfer has not been submitted because you have reached your ACH 24 hour velocity limit. This limit exists for your safety. Your remaining limit is {{ remainingLimit }}. If you would like to raise (or lower) your limit, please contact support.',
    cancelUpcoming: {
      title: 'Hold up!',
      body: 'You are canceling your next scheduled transfer! Are you sure you want to break your streak of awesome savings?',
    },
    cancelAll: {
      title: 'Whoa!',
      body: 'You are canceling your automated transfer schedule! Are you sure you want to break your streak of awesome savings?',
    },
    assetTypeSecurity: 'Recurring Investment',
    assetTypeFiat: 'Recurring Deposit',
    error: 'Your request to cancel your repeat transfer was not approved, please try again.',
  },
  securities: {
    searchPlaceholder: 'Search by name or symbol',
    totalSectors: 'Total Sectors',
    top10Holdings: 'Top 10\nHoldings',
  },
  twoFactorAuth: {
    title: 'Two-factor authentication',
    titleEmail: 'Email verification',
    titlePhone: 'Phone verification',
    security: 'Security',
    password: 'Password',
    method: 'Two-factor authentication method',
    sendText: 'Send text',
    sendEmail: 'Send email',
    descLogin:
      'How would you like to receive your two-factor authentication code for a secure login?',
    descToggle:
      'How would you like to receive your two-factor authentication code to toggle verification?',
    descCloseStockAccount:
      'How would you like to receive your two-factor authentication code to close your Stocks account?',
    descCloseRoboAccount:
      'How would you like to receive your two-factor authentication code to close your Robo account?',
    descVerification: {
      email: 'We will send you an email with the two-factor authentication code.',
      text: 'We will send you a text with the two-factor authentication code.',
    },
    enterCode: 'Enter code',
    submit: 'Submit',
    loginTitle: 'Login',
    variant: {
      login:
        'We just sent you a one-time two-factor authentication code to your mobile phone number. Enter the code below.',
      toggle: 'We just sent you a one-time two-factor authentication code. Enter the code below.',
      default:
        'We just sent you a one-time two-factor authentication code. Enter the code below to confirm your',
      email: 'email',
      phoneNumber: 'mobile phone number',
    },
    helpText: `If you can't find it, check your spam/junk folder. If you need assistance, contact our support team: ${FinTronConfig.supportEmail}`,
    didntReceiveCode: 'Didn’t receive the code?',
    resendCode: 'Tap to resend',
    mfaSuccessDescription: 'Two-factor authentication setting has been changed successfully.',
    loginDescription:
      'We will send you a text with the two-factor authentication code for each login.',
  },
  shortcutSlider: {
    header: 'Shortcuts',
    description: 'Search Stocks, Transfer, and More',
    buttonText: 'View',
    shortcuts: {
      search: 'Search Securities',
      withdraw: 'Withdraw',
      history: 'Activity History',
      deposit: 'Deposit',
      investments: 'Automate Investments',
      acat: 'ACAT',
    },
  },
  login: {
    loggedOut: 'Logged out',
    loggedOutBody: 'You have been logged out due to inactivity',
    accountDisabledTitle: 'Account disabled!',
    accountDisabledBody: `Your account has been disabled. \nPlease contact [${FinTronConfig.supportEmail}](${FinTronConfig.contactUrl}) for assistance!`,
    updateAvailableTitle: 'App update available!',
    updateAvailableBody: {
      main:
        "We're very happy to announce that the latest version of the app is available in the " +
        'App Store! Click the button below to download the app, and get access to the latest ' +
        `feature updates and enhancements.`,
      devOnly: 'Dev only note: this dialog is not skippable in the production app.',
    },
    noConnection: 'No internet connection',
    noConnectionBody:
      "It looks like you're having issues with your internet connection! Check your Wifi or cell service and try logging in again.",
    loseSomething: 'Lose something?',
    loseSomethingBody: "We've got your back.",
  },
  forceUpdate: {
    updateAvailableTitle: 'App update available!',
    updateAvailableBody: {
      title:
        "We're very happy to announce that the latest version of the app ({{remote}}) is available in the App Store! Click the button below to download the app, and get access to the latest feature updates and enhancements.",
      remote: 'Current Version:',
      devOnlyNote: 'Dev only note: this dialog is not skippable in the production app.',
    },
    updateButton: 'Update',
    buttonDevOnly: 'DEV ONLY: SKIP',
  },
  retirementBeneficiary: {
    title: 'Want to add a beneficiary to your account?',
    learnMore: 'Learn more',
    addBeneficiaryButton: '+ Add a beneficiary',
    beneficiariesHeaderTitle: 'Primary beneficiaries',
    beneficiariesHeaderSubtile: 'At least one Primary Beneficiary is required.',
  },
  allAccountsScreen: {
    welcomeString: `Hi {{username}}`,
    myAccountsTitle: 'My accounts',
    openStocksAccount: 'Open a stocks account',
    createAGoalTitle: `Create a {{roboName}} goal`,
    openARetirementAccountTitle: 'Open a retirement account',
    goalsTitle: 'Goals',
    cashCalendarTitle: 'Cash calendar',
  },
  initialInvestments: {
    initialInvestmentTitle: 'Initial Investment',
    initialInvestmentDescription: 'How much do you plan to invest today towards your goal?',
    targetAmountTitle: 'Target Amount',
    targetAmountDescription:
      'Set an amount that you believe will be achievable. Setting an amount that is too high may lower your probability of achieving your goal.',
    investmentAmountTitle: 'Additional Investments',
    investmentAmountDescription:
      'Increasing your additional investments can greatly improve the probability of achieving your savings goal.',
    recurringInfusionTitle: 'Automated investments',
    recurringInfusionDescription:
      'Increasing your automated investments can greatly improve the probability of achieving your savings goal.',
  },
  miscScreens: {
    common: {
      companyName: 'Company name',
      ceo: 'CEO',
      sector: 'Sector',
    },
    contactUs: {
      title: 'Contact us',
      submit: 'Submit',
      successTitle: 'Form submitted',
      successTxt: 'Thanks for submitting the form.',
    },
    goals: {
      edit: {
        title: 'Edit your goal details',
        investmentFrequencyTitle: 'Automated Investment\nFrequency',
        investmentFrequencyDescription:
          'Set up automated investments and choose your investment frequency.',
        pauseInvestmentTitle: 'Pause recurring investments',
        pauseInvestmentDescription:
          'Are you sure you want to pause your automated investments?\n\nPausing recurring investments for long periods of time may negatively impact the probability of achieving the full amount of your goal within the desired timeframe.',
        pauseInvestmentBottomText:
          'The potential reallocation triggered by this change may have tax implications.\n\nResume your investments at any time.',
        yesPause: 'Yes, Pause Investments',
        yesResume: 'Yes, Resume Investments',
        noCancel: 'No, cancel',
        resumeInvestmentTitle: 'Resume recurring investments',
        resumeInvestmentDescription:
          'Are you sure you want to resume your recurring investments?\n\nPausing recurring investments for long periods of time may negatively impact the probability of achieving the full amount of your goal within the desired timeframe.',
        resumeInvestmentBottomText: `The potential reallocation triggered by this change may have tax implications.\n\nBy resuming your investments, the next recurring investment will occur on {{date}}.`,
        targetDate: 'Target date',
      },
      close: {
        closeAccountTitle: 'Your goal has been deactivated',
        closeAccountDescription:
          'This goal will no longer appear on your My Accounts page. You can always create a new goal when you want.',
        home: 'Home',
      },
    },
    stocks: {
      accountHistory: 'Account history',
      documents: 'Documents',
      investmentQuestions: 'investment questions',
      closeAccountTitle: 'Your account has been closed',
      closeAccountDescription: `If you ever want to re-open this account, contact ${FinTronConfig.selfDirected.supportEmail}.`,
      home: 'Home',
    },
    myAccounts: {
      title:
        'Holdings is just another word for things that you own. Under this section you will see:\n',
      checklist: [
        { name: 'Securities that you own' },
        { name: 'The cash that you have available to buy securities with (Available cash)' },
        {
          name: 'The cash that is being processed from selling a security or pending deposits (Pending cash)',
        },
      ],
      footer:
        'After the sale of a security, it will take 2 days for the cash to settle in your account and become available for trading or withdrawal.',
      viewData: 'View data',
      footerLegalText: `Brokerage services for registered securities are offered to self-directed customers by ${FinTronConfig.selfDirected.name}. Cash and securities are custodied at ${FinTronConfig.clearingFirm}.`,
      marketClosedTitle: `Stock Market Closed {{date}}`,
      marketClosedDescription: `The stock market is taking a day off to rest. If you place any trades, ${FinTronConfig.parentName} will submit them the next day the markets are trading. Please note, the stock prices displayed are from the last day the markets were open.`,
      repeatInvestments: 'Repeat investments',
    },
    plaid: {
      linkAccountButton: 'Select a bank account',
      createLinkErrorBody:
        'We are really sorry for the inconvenience, but our secure bank linking partner Plaid has failed to initiate. Please exit the page and try again, we will get you on your way in no time!',
      createAccountErrorBody:
        'Our third party was a little sleepy and timed out when linking your bank account. ' +
        'Please try to link your bank account again. We sorry for the inconvenience!',
    },
    bankLink: {
      removeBankTitle: 'Linked actions',
      removeBankStep3Title: 'Account unlinked',
      removeBankCancelAllAndContinue: 'Cancel all & continue',
      removeBankSuccessTitle: 'Success!',
      removeBankSuccessMessage: 'Your bank account has been unlinked successfully.',
      removeBodyMessage:
        'You have the following automated functions linked to this bank account. In order to remove this bank account, you must either (1) update these automated functions from within the app to pull funds from a different bank account or (2) you must cancel the functions altogether. Please note, **we will cancel any pending transfers associated to this bank account** on your behalf.',
    },
    tradeStopLoss: {
      tradeStopLossEnterText: 'Enter stop price',
      tradeStopLossTooLowError:
        'It looks like you have entered a stop price that is over the current market price. In order to place a stop loss  order, please enter a stop price that is lower than the displayed market price.',
      tradeStopLossOrderType: 'Stop loss',
      tradeStopLossSummaryLimitPriceLabel: 'Stop price',
      tradeStopLossSummaryLimitPriceTooHigh:
        'It looks like you have entered a stop loss that is over the current market price. In order to place a stop loss order, please enter a stop loss that is lower than the displayed market price.',
    },
    tradeOptions: {
      tradeOptionsBuy: 'Buy',
      tradeOptionsBuySubtitle: 'Add to your holdings',
      tradeOptionsSell: 'Sell',
      tradeOptionsSellSubtitle: 'Remove part or all of this security from your holdings.',
      tradeOptionsRepeatInvestment: 'New repeat investment',
      tradeOptionsRepeatInvestmentSubtitle: 'Automated scheduled investments.',
      tradeOptionsEditRepeatInvestment: 'Edit repeat investment',
      tradeOptionsEditRepeatInvestmentSubtitle: 'Automated scheduled investments.',
      tradeOptionsRepeatInvestmentActive: 'Active',
      durationOptionsGoodFor: 'Good for:',
      durationOptionsGoodForOneDay: 'Good for 1 day',
      durationOptionsPopup:
        "This determines how long your order will last for if it doesn't get executed right away.",
      tradeMarketBuySellLessThanADollarTitle: 'Caution',
      tradeMarketBuySellLessThanADollarDesc: `After this sell order, you may be left with **{{amount}}** worth of **{{symbol}}**, which is less than the $1 sell order minimum.\n\nWould you like to include the remaining **{{amount}}** of **{{symbol}}** in this order?`,
      tradeMarketBuySellLessThanADollarSellAllButton: 'Yes',
      tradeMarketBuySellLessThanADollarKeepButton: `Keep {{amount}} of {{symbol}}`,
      stopLossPreviewInfo: `This value is what your current holdings (minus any pending sell orders) would be worth at the stop price you specified.\n\nIn this instance, your [{{sharesToBeSold}}] shares in [{{securitySymbol}}] would be worth [{{availableAtStopLossPrice}}] if [{{securitySymbol}}] traded at [{{stopLoss}}], your specified stop price.`,
      stopLossPreviewSharesToBeSold: 'Shares to be sold:',
      stopLossPreviewAvailableAtStopLossPrice: 'Available at Stop Loss price:',
      tradeLimitBuyOrderType: 'Limit buy',
      tradeLimitBuyOrderLimit: 'Enter limit price',
      tradeLimitBuyOrderLimitDesc: 'Buy when the share price falls below:',
      tradeLimitBuySummaryLimitPriceLabel: 'Limit price',
      tradeLimitBuySummaryLimitPriceTooHigh:
        'It looks like you have entered a limit price that is over the current market price. In order to place a limit order, please enter a limit price that is lower than the displayed market price.',
      tradeOptionsSelector: {
        tradeOptionsBuySubtitle: 'Add to your holdings.',
        tradeOptionsLimitBuySubtitle: 'Buy at the price you set.',
        tradeOptionsSellSubtitle: 'Buy at the price you set.',
        tradeOptionsStopLossSubtitle: 'Sell at the price you set.',
        tradeOptionsRepeatInvestment: 'Repeat investment',
        tradeOptionsRepeatInvestmentSubtitle: 'Automated scheduled investments.',
      },
    },
    securities: {
      youOwnTitle: 'You own:',
      youOwnFooter: 'Shares',
      yourValueTitle: 'Your value:',
      yourValueFooter: 'Today',
      portfolioPercentTitle: 'Making up:',
      portfolioPercentFooter: 'Of Your Portfolio',
      costPerShareTitle: 'Avg. cost per share:',
      gainLostTitle: 'Your gain/loss:',
      dividendRate: 'Dividend rate',
      dividendAmount: 'Dividend amount',
      totalDividendsReceived: 'Total dividends received:',
      previousDividendReceived: 'Previous dividend received:',
      securityNotificationRiskQuestion:
        'Statistically speaking, 99% of the time, a set of set of data (or in our case the performance of a security) will fall within 3 standard deviations of the average returns of that set of data. The 10 Yr. Historical Range upside you see here is calculated by adding 3 standard deviations to the average 10 year return of this security. The downside is calculated by subtracting 3 standard deviations from the average 10 year return. Thus, the 10 Yr. Historical Range presented here will display a securities performance range with a 99% accuracy (Statistically speaking)!',
      securityNotificationRiskQuestion1:
        "The risk tolerance metric is the outcome of our proprietary risk assessment. We provide this Risk Range to you as a benchmark to consider, the range is based on the answers you've given us when filling out your Risk Assessment. We believe taking on excessive risk leads to poor investment decisions, so in essence, we've provided this range for you to check yoself before you wreck yoself! If you take on too much risk, maybe you'll sell too early when you should have otherwise held on. Maybe you just don't have the financial stability to risk too much on high risk stocks... In any case, please Note, this is NOT a recommendation, just an extra layer of consideration that we've provided to you as a courtesy (: ",
      tradingVolume: 'Trading volume',
      tenDay: '10 day avg. volume',
      bidProvidedBy: 'Bid provided by: ',
      askProvidedBy: 'Ask provided by: ',
      mutualFund: {
        transactionFee: 'Transaction fee',
        noFee: 'No fee',
        nav: 'NAV',
        navChangeDoD: 'NAV Change (DoD)',
        ytdReturns: 'YTD returns',
        yield30Day: '30 day SEC yield',
        fundCategory: 'Fund category',
        fundInception: 'Fund inception',
        loadType: 'Load type',
        load: 'Load',
        minimumToInvest: 'Minimum to invest',
        minimumSubsequentInvestment: 'Minimum subsequent investment',
        turnoverRate: 'Turnover rate',
        portfolioNetAssets: 'Portfolio net assets',
        shareClassNetAssets: 'Share class net assets',
        twelveMonthLowHigh: '12 minth low-high',
        beta: 'Beta',
        rSquared: 'R2',
        sharpeRatio: 'Sharpe ratio',
        standardDeviation: 'Standard deviation',
        initialInvestment: 'Initial investment',
        additionalInvestment: 'Additional investment',
        initialIraInvestment: 'Initial IRA investment',
        additionalIraInvestment: 'Additional IRA investment',
        initialGroupRetirementInvestment: 'Initial group retirement invstment',
        additionalGroupRetirementInvestment: 'Additional group retirement invstment',
        expenseRatioGross: 'Exp ratio (gross)',
        expenseRatioNet: 'Exp ratio (net)',
        contractualExpenseWaiver: 'Contractual exp waiver (dated)',
        distributionServiceFee: 'Distribution/service fee (12b-1)',
      },
    },
    tradeDisplay: {
      tradeOrderSell: 'Sell',
      tradeOrderBuy: 'Buy',
      tradeOrderSubmitButton: 'Submit',
      tradeOrderContinueButton: 'Continue',
      tradeOrderSummaryTitle: 'Order summary',
      tradeOrderTypeLabel: 'Order type',
      tradeOrderFrequencyLabel: 'Frequency',
      tradeOrderAmountLabel: 'Dollar amount',
      tradeOrderEstimatedSharesLabel: 'Estimated # of shares',
      tradeOrderOrderDuration: 'Order duration',
      tradeOrderTransactionFee: 'Transaction fee',
      tradeOrderTransactionFeeNoFee: 'No fee',
      tradeOrderEstimatedValue: 'Estimated dollar value',
      tradeOrderEnterAmount: 'Enter amount:',
      tradeOrderEnterMinimumAmount: 'Enter amount (greater than or equal to ${{minimum}}):',
      sellAll:
        'Sell orders of {{thresholdAmount}} or greater will automatically Sell All.\n\nIf your sell is {{thresholdPercent}} or greater of your mutual fund holding, then we will automatically Sell All of the holding.',
      tradeOrderCashAvailable: `{{amount}} available`,
      tradeOrderEstimatedCashHoldings: `Current holdings: {{amount}}`,
      tradeOrderLimitOneDay: '1 day',
      tradeOrderLimitTillCancelled: 'Good till cancelled',
      tradeOrderBuySellOrderType: `Market {{side}}`,
      tradeOrderMarketRepeatSummary: `Your **{{amount}}** recurring **{{frequency}}** order to buy **{{symbol}}** was submitted.`,
      tradeOrderMarketBuySellSummary: `Your **{{amount}} {{orderType}}** to {{side}} **{{symbol}}** was submitted. The order duration is set as **{{duration}}.**`,
      tradeOrderMarketLimitBuyStopLossSummary: `Your **{{amount}} {{orderType}}** to {{side}} **{{symbol}}** was submitted. If the price of the security falls below **{{limit}}**, we will submit a market {{side}} order. The order duration is set as **{{duration}}.**`,
      tradeOrderSellAll: 'Sell all',
      tradeOrderStopLoss: 'Stop loss',
      tradeOrderStopLimit: 'Stop limit',
      tradeOrderStopPrice: 'Stop price',
      tradeOrderLimitBuy: 'Limit buy',
      tradeOrderLimitPrice: 'Limit price',
      tradeOrderLimitOrder: 'Limit order',
      tradeOrderRepeatInvestment: 'Repeat investment',
      tradeScreenOrderTypesInfoTitle: 'Order types',
      tradeScreenOrderStatus: 'Order status',
      tradeScreenOrderTypesInfoBuySide:
        '**Market:** This order type is often used if you are looking to buy a stock immediately at the current market price.\n' +
        '\n' +
        '**Limit:** This order type is often used if you would like to buy a security, but think the current price is too high. You can set a limit price at your desired price level, and if the stock price comes down to your set level, we submit a market buy order.',
      tradeScreenOrderTypesInfoSellSide:
        '**Market:** This order type is often used if you are looking to sell a stock immediately at the current market price. Market orders are generally filled immediately.\n' +
        '\n' +
        '**Stop Loss:** This order type is often used if you are looking to sell a security around a certain price that is below the current market price. If the security drops below your stop price, a market sell order will be placed.',
      tradeOrderHighlyVolatile: `{{securitySymbol}} is a highly volatile security! In most cases, the higher a security’s volatility, the higher risk of losing some or all of your investment. Please consider your investment objectives and risk tolerance prior to making an investment.`,
      tradeOrderComplex: `{{securitySymbol}} is a contra, levered or complex security. You must have “Good” to “Extensive” investment experience in order to purchase this security. You may change your investment experience by navigating to your investment profile in the settings page.`,
      tradeScreenMutualFundOrderTimeDisclosure:
        "Mutual Fund orders entered before 3:45 EST will execute after market hours at the Mutual Fund's NAV. Orders entered after 3:45 EST will execute the next trading day, after market hours at the Mutual Fund's NAV.",
      tradeOrderRepeatInvestmentSummary: `You are submitting a repeat investment, which means you are instructing ${FinTronConfig.selfDirected.name} to transfer the requested dollar amount from your connected bank account at your requested frequency. Repeat investments will be submitted to ${FinTronConfig.clearingFirm} for execution as a Market Buy order with a One Day time in force instruction at market open on the third banking day following the successful processing of the transfer of funds.\nSecurities are offered via ${FinTronConfig.selfDirected.name} in a fractional capacity and custodied by ${FinTronConfig.clearingFirm}. Investors should be aware that fractional shares represent partial ownership of a security. Investments may lose value and there is a risk of losing money. Brokerage services are offered through ${FinTronConfig.selfDirected.name}, a registered broker dealer (member FINRA & SIPC).`,
      tradeOrderSummaryLegal: `Securities are offered via ${FinTronConfig.selfDirected.name} in a fractional capacity and custodied by ${FinTronConfig.clearingFirm}. Investors should be aware that fractional shares represent partial ownership of a security. Investments may lose value and there is a risk of losing money. Brokerage services are offered through ${FinTronConfig.selfDirected.name}, a registered broker dealer (member FINRA & SIPC).`,
      tradeOrderMFDetailGrossRatio: 'Exp ratio (gross):',
      tradeOrderMFDetailNetRatio: 'Exp ratio (net):',
      tradeOrderMFDetailDistributionFee: 'Distribution/service fee (12b-1):',
      tradeOrderMFDetailMinimumInvestment: 'Minimum initial investment:',
      tradeOrderMFDetailSubsequentInvestment: 'Minimum subsequent investment:',
      tradeOrderMFDetailTransactionFee: 'Transaction fee:',
      tradeOrderMFDetailLoadType: 'Load type:',
      tradeOrderMFDetailLoad: 'Load:',
      tradeOrderMFNoFee: 'No Fee',
      bestBidOffer: 'Best bid/offer',
      tradeScreenContinue: 'Continue',
      nbbo: {
        nbboQuoteBSMTitle: 'National best bid & offer',
        nbboQuoteBestBid: 'Best Bid',
        nbboQuoteBestAsk: 'Best Ask',
        nbboQuoteBestBidAskDesc:
          'The National Best Bid and Offer (NBBO) is a Securities Exchange Commission (SEC) rule requiring brokers to trade at the lowest ask price and the highest bid price when buying and selling securities for customers (Submitting your orders). The National Best Bid and Offer is the bid or ask price that the average customer will see. The Securities and Exchange Commission’s Rule NMS requires that brokers guarantee their customers this price.',
        nbboQuoteBidSize: 'Bid Size',
        nbboQuoteBidSizeDesc:
          'The bid size represents the quantity of a security that investors are willing to purchase (Bid) at the current market price. The bid size helps determine "liquidity" which is a fancy word for one\'s ability to buy a security quickly and at a favorable price. If there are no bids, there is nobody buying! Bid size and bid price are quoted by exchanges, exchanges facilitate the sales of securities and provide public quotations of prices, volumes and other various metrics. Below each bid size you will see the exchange that the bid quote originated from.',
        nbboQuoteAskSize: 'Ask Size',
        nbboQuoteAskSizeDesc:
          'The ask size represents the quantity of a security that investors are currently offering (Asking) to sell at the current market price. The ask size helps determine "liquidity" which is a fancy word for one\'s ability to sell a security quickly and for a good price. If there are no asks, there is nobody selling! Ask size and ask price are quoted by exchanges, exchanges facilitate the sales of securities and provide public quotations of prices, volumes and other various metrics. Below each ask size you will see the exchange that the ask quote originated from.',
        lastTrade: 'Last sale:',
        refreshedAt: 'Refreshed at:',
        refresh: 'Refresh',
      },
      pennyStock: {
        pennyStockDisclosureBSMTitle: 'Penny stock disclosure',
        importantInformation: 'Important information on penny stocks',
        statement: `This statement is required by the U.S. Securities and Exchange Commission (SEC) and contains important information on penny stocks. You are urged to read it before making a purchase or sale.`,
        pennyStocksCanBeRisky: 'Penny stocks can be very risky',
        pennyStockAreLowPriced: `\u2022 Penny stocks are low-priced shares of small companies not traded on an exchange or quoted on NASDAQ. Prices often are not available. Investors in penny stocks often are unable to sell stock back to the dealer that sold them the stock. Thus, you may lose your investment. Be cautious of newly issued penny stock.`,
        pennyStockSalesPerson: `\u2022 Your sales person is not an impartial advisor but is paid to sell you the stock. Do not rely only on the salesperson, but seek outside advice before you buy any stock. If you have problems with a salesperson, contact the firm’s compliance officer or the regulators listed below.`,
      },
    },
    deposits: {
      recurringTitle: 'Make this a recurring deposit?',
      recurringDeposit: 'Recurring deposit',
      depositLimit: 'Instant deposit limit:',
      depositStatus: 'Deposit status',
      singleDepositSummary: 'Check the transfer history for updates to your funds availability.',
      depositPendingTitle: 'Pending...',
      depositPendingSummary:
        'Your order is still pending, check the transaction history for updates to your funds availability.',
      getNamedFreqDisplayString: `Your **{{amount}}** recurring {{intervalKind}} deposit has been submitted.`,
      depositLimits: 'Deposit limits',
      depositSummary: 'Deposit summary',
      makeAnotherDeposit: 'Make another deposit',
      infuseSummarySuccess: `Your **{{amount}}** deposit will be invested into your portfolio. See your activity history for details.`,
      threeDayDepositAmount: '3 day deposit amount',
      limits: {
        instantDepositLimit: 'Instant deposit limit: ',
        instantDepositLimitDesc:
          'We calculate this limit by using a portion of your portfolio minus any recent transfers that have not settled into your account.',
        dailyLimit: 'Daily limit: ',
        dailyLimitDescription: `There is a daily {{limit}} deposit limit.`,
        dailyLimitDescription2: `Transfers amounts submitted within the Same Day Instant Deposit Window (7:00 am - 4:00 pm EST) will be accessible immediately to trade in market hours.
      Transfers amounts submitted after the Same Day Instant Deposit Window will not be available for trading until the next trading day.`,
      },
      fundingSource: 'Funding Source',
    },
    withdrawals: {
      title: 'Are you sure you want to withdraw all funds?',
      description:
        `Withdrawing all funds will require ${FinTronConfig.robo.name} to sell all securities in your account` +
        '\n It is not guaranteed that the dollar amount displayed now on the screen will be equal to the dollar amount in the account after all securities are sold and withdrawn to your bank account.' +
        '\n\n *Withdrawals have tax consequences. Please consult a tax professional or www.irs.gov for more information.',
      withdrawFunds: 'Withdraw funds',
      amounts: {
        available: 'Available:',
        withdrawButton: 'Withdraw all',
        selectBank: 'Select bank account',
        withdrawalDelay: 'Withdrawal delay',
        withdrawalDelayText1:
          'To keep money launderers and fraudsters at bay, we place a brief hold on all deposits from leaving. This means that once the cash from a deposit is available for trading, a brief hold is placed on the funds from leaving.',
        withdrawalDelayText2: `If you have any questions about our fraud and anti-money laundering procedures, please feel free to contact [${FinTronConfig.supportEmail}](mailto:${FinTronConfig.supportEmail}) with any questions that you may have!`,
        withdrawAllText: `Withdrawal inputs of **{{value}}** or greater will automatically Withdraw All. \n\nIf your withdrawal is 90% or greater of your portfolio value, then we will automatically Withdraw All funds. \n\nBy submitting this transaction, you are authorizing ${FinTronConfig.clearingFirm} to sell the stated amount from your goal and deposit into your linked bank account. Please allow 3-4 business days for this transaction to be completed.`,
        withdrawStatus: 'Withdrawal status',
        withdrawSummary: `Your request to withdraw **{{value}}** has been received. Funds will generally settle in 3-4 business days. See your activity history for details.`,
        withdrawalSummaryTitle: 'Withdrawal summary',
        withdrawalPendingTitle: 'Pending...',
        withdrawalPendingSummary:
          'Your order is still pending, check the transaction history for updates to your funds availability.',
      },
    },
    transfers: {
      transferTypeTitle: 'Transfer type',
      bankNameTitle: 'Bank name',
      dollarValueTitle: 'Dollar value',
      estimatedDollarValueTitle: 'Estimated dollar value',
      recurringSummaryDescription: `We will initiate a deposit {{recurringInterval}}, starting on {{userSelectedDate}}. Deposits will settle within 3 business days after it has been initiated.`,
      instantTitle: 'Instant',
      regularTitle: 'Regular',
      estimatedSettlementDescription: `Estimated settlement {{settlementDate}}`,
      totalAmountTitle: 'Total amount',
      depositSummaryLegalText: `Cash deposits are custodied by ${FinTronConfig.clearingFirm}. If you elect to participate in the Apex FDIC-Insured Sweep Program, Apex will automatically place your cash deposits in this program. If you do not elect to participate in the Apex FDIC-Insured Sweep Program, your deposits are not FDIC insured. You may un-enroll from the Apex FDIC-Insured Sweep Program at any time. Brokerage services are offered through ${FinTronConfig.selfDirected.name}, a registered broker dealer (member FINRA & SIPC).`,
      returnedTransferFee: 'Returned transfer fee',
    },
    activityHistory: {
      recentActivity: 'Recent Activity',
      myHistory: 'My History',
      filter: {
        title: 'Filter by category',
        selectDate: 'Select date',
        to: 'to',
        orders: 'Orders',
        transfers: 'Transfers',
        deposits: 'Deposit',
        withdrawal: 'Withdrawal',
        dividends: 'Dividends',
        interests: 'Interests',
        acat: 'ACAT',
        buy: 'Buy',
        sell: 'Sell',
        all: 'All',
        filterButton: 'Filter',
        clearButton: 'Clear all',
      },
      popups: {
        transferringFrom: 'Transferring from',
        transferringTo: 'Transferring to',
        dividendReceived: 'Dividend received',
      },
    },
  },
  generalInvestments: {
    allocation: 'Allocation',
    choosePortfolio: 'Choose Portfolio',
    changePortfolio: 'Change Portfolio',
    currentPortfolio: 'This is Your Current Portfolio',
    saveAndUpdatePortfolio: 'Save And Update Portfolio',
    editQuestionsText: 'Select here to make edits to your investor questions.',
    recommended: 'Recommended',
    recommendedDescription:
      'This risk level is the most recommended based on your responses in your investor profile.',
    notRecommended: 'Not Recommended',
    notRecommendedDescription:
      'This risk level is not recommended based on your responses in your investor profile.',
    notRecommendedSubDescription: 'Select here to go to recommended portfolio.',
    cantRecommendDescription:
      'This risk level is not selectable based on your answers in your investor profile.',
    bsm: {
      recommended: 'Confirm Selection',
      notRecommended: 'Portfolio not recommended',
      recommendedText:
        'You are about to choose the recommended portfolio. The chosen portfolio can always be changed later from your Settings page.',
      notRecommendedText:
        'This portfolio is not recommended based on your investment profile. Are you sure that you want to choose this portfolio?',
      continueBtnText: 'Continue With This Portfolio',
    },
    stockScreen: {
      holdings: 'Holdings',
      topTen: 'Top 10 Holdings',
      viewAll: 'View all',
      other: 'Other',
      availableCash: 'Available cash:',
      pendingCash: 'Pending cash:',
      costPerShare: 'Avg. cost\nper share:',
      value: 'Value:',
      gainLoss: 'Gain/Loss:',
      shares: 'Shares',
    },
    allHoldingsScreen: {
      title: `{{etfName}} holdings`,
    },
    editDetailsScreen: {
      title: 'Edit your automated\ninvestment values',
      pauseInvestments: 'Pause investments',
      resumeInvestments: 'Resume investments',
      saveButtonText: 'Save',
      cancelButtonText: 'Cancel',
      recurringInvestments: 'Recurring investments',
      investmentAmount: 'Amount',
      investmentFrequency: 'Frequency',
      fundingSource: 'Funding source',
      active: 'Active',
      paused: 'Paused',
      targetAmount: 'Target amount',
      target: 'Target',
      initialInvestment: 'Initial investment',
      cancelNext: 'Cancel next',
      canceledUntil: `Canceled until {{date}}`,
    },
  },
  robo,
  settings,
  widgets,
  gi,
};
