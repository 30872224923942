import { Button } from '@/components/common/Button';
import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import Link from 'next/link';

// TODO: Move this contact info to the config file
const contactInfo = {
  supportEmail: 'AlightFScustomerservice@alight.com',
  contactUsPage: 'https://alightfs.com/contact',
  supportPhone: '1-866-415-3383',
};

const displayStrings = {
  supportEmail: 'Support e-mail',
  contactUsPage: 'Contact us page',
  supportPhone: 'Support phone',
  back: 'Back',
};

const removeUrlProtocol = (url: string) => url.replace(/^https:\/\//, '');

type ContactsProps = {
  onBackClick: () => void;
};

export const Contacts = ({ onBackClick }: ContactsProps) => {
  return (
    <div className="h-full flex flex-col justify-between">
      <ul className="list-none space-y-4">
        <li className="flex justify-between">
          <WebBodyText14.Regular className="text-grey72">
            {displayStrings.supportEmail}
          </WebBodyText14.Regular>
          <Link href={`mailto:${contactInfo.supportEmail}`}>
            <WebBodyText14.Regular className="underline text-primaryDarkBlue">
              {contactInfo.supportEmail}
            </WebBodyText14.Regular>
          </Link>
        </li>
        <li className="flex justify-between">
          <WebBodyText14.Regular className="text-grey72">
            {displayStrings.contactUsPage}
          </WebBodyText14.Regular>
          <Link href={contactInfo.contactUsPage} target="_blank" rel="noopener noreferrer">
            <WebBodyText14.Regular className="underline text-primaryDarkBlue">
              {removeUrlProtocol(contactInfo.contactUsPage)}
            </WebBodyText14.Regular>
          </Link>
        </li>
        <li className="flex justify-between">
          <WebBodyText14.Regular className="text-grey72">
            {displayStrings.supportPhone}
          </WebBodyText14.Regular>
          <WebBodyText14.Bold className="text-grey84">
            {contactInfo.supportPhone}
          </WebBodyText14.Bold>
        </li>
      </ul>
      <Button onClick={onBackClick} className="w-full" variant="secondary" type="button">
        {displayStrings.back}
      </Button>
    </div>
  );
};
