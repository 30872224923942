//TODO: migrate this to en.errors.ts
export const FORM_ERROR_MESSAGES = {
  required: 'This field is required',
  minLength: (minLength: string | number) => `At least ${minLength} characters`,
  maxLength: (maxLength: string | number) => `At most ${maxLength} characters`,
  minAmount: (minAmount: string | number) => `At least $${minAmount}`,
  maxAmount: (maxAmount: string | number) => `At most $${maxAmount}`,
  minPercentAmount: (minAmount: string | number) => `Percentage must be at least ${minAmount}%`,
  maxPercentAmount: (maxAmount: string | number) => `Percentage must be less than ${maxAmount}%`,
  invalidUsername: 'Username cannot contain special characters',
  invalidSSN: 'SSN must be exactly 9 digits',
  duplicateSSN: 'SSN already exists',
  duplicateEmail: 'Email already exists',
  duplicatePhoneNumber: 'Phone number already exists',
  passwordDoNotMatch: 'The passwords do not match',
  invalidPassword: 'Password must contain at least 1 special character (! @ # $…)',
  invalidPasswordUppercase: 'Password must contain at least one uppercase letter',
  invalidPasswordLowercase: 'Password must contain at least one lowercase letter',
  invalidPasswordNumber: 'Password must contain at least one number',
  invalidPasswordSpecialCharacter: 'Password must contain at least one special character',
  invalidPasswordLeadingTrailingSpace: 'Password cannot have leading or trailing whitespace',
  invalidName: (field: string) => `${field} cannot contain special characters and spaces`,
  invalidCode: 'Code must be exactly 6 digits',
  invalidEmail: 'Invalid email format',
  invalidPhoneNumber: 'Invalid phone number',
  invalidPhoneNumberAdmin: 'Phone number must be 10 digits and include +1 ',
  birthCountryError: 'While on visa, cannot set birth country to United States',
  citizenshipCountryError: 'While on visa, cannot set citizenship country to United States',
  invalidDate: 'Invalid date',
  duplicatedField: (type: string) => `${type} is already in use. Please use another one.`,
};
