import { AuthServiceApi } from '@fintronners/react-api/src';
import RuntimeEnvGRPCAPI from '@/utils/RuntimeEnvGRPCAPI';
import { useGrpcApiV1Config } from '@/hooks/api/useGrpcApiV1Config';

const useAuthService = () => {
  const { authApiBaseUrl } = useGrpcApiV1Config();

  const authApiService = RuntimeEnvGRPCAPI.getAuthService(AuthServiceApi, authApiBaseUrl!);

  return authApiService;
};

export default useAuthService;
