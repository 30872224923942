import {
  WebBodyText14,
  WebBodyText18,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import Image from 'next/image';
import React, { useState } from 'react';
import { InfoIcon, InfoPrimaryIcon } from '~assets/icons';
import { Button } from '~components/common/Button';
import useBreakpoint from '@/hooks/utils/useBreakpoint';
import useResponsiveDialog from '@/hooks/utils/useResponsiveDialog';

const displayStrings = {
  durationOptionsGoodFor: 'Good For:',
  durationOptionsPopup:
    "This determines how long your order will last for if it doesn't get executed right away.",
  close: 'Close',
};

export const GoodForDialog = () => {
  const { isBreakpoint } = useBreakpoint();
  const { ResponsiveDialog } = useResponsiveDialog();

  const [showModal, setShowModal] = useState(false);

  return (
    <ResponsiveDialog.Root open={showModal} onOpenChange={(isOpen) => setShowModal(isOpen)}>
      <ResponsiveDialog.Trigger asChild>
        <div className="flex gap-1 cursor-pointer w-24">
          <Image alt="Info icon" width={14} height={14} src={InfoIcon.src} />
          <WebBodyText14.Regular className="text-grey41">
            {displayStrings.durationOptionsGoodFor}
          </WebBodyText14.Regular>
        </div>
      </ResponsiveDialog.Trigger>
      <ResponsiveDialog.Content>
        <ResponsiveDialog.Header>
          <Image alt="Info icon" width={50} height={50} src={InfoPrimaryIcon.src} />
        </ResponsiveDialog.Header>

        <WebBodyText18.Regular className="text-grey72">
          {displayStrings.durationOptionsPopup}
        </WebBodyText18.Regular>
        <ResponsiveDialog.Footer>
          <ResponsiveDialog.Close asChild>
            <Button className="w-full" variant={isBreakpoint('xs') ? 'ghost' : 'primary'}>
              {displayStrings.close}
            </Button>
          </ResponsiveDialog.Close>
        </ResponsiveDialog.Footer>
      </ResponsiveDialog.Content>
    </ResponsiveDialog.Root>
  );
};
