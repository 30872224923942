import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Generates the final class list dynamically and prevents conflicts between
 * Tailwind classes.
 */
export default function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getComputedStyleValue = (variable: string): string => {
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    // If we're on the server (SSR), return null or a fallback value
    return '';
  }

  // Proceed if we are in the browser environment
  return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
};
