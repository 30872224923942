import React, { useEffect } from 'react';
import {
  WebBodyText12,
  WebBodyText14,
  WebBodyText20,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { Button } from '@/components/common/Button';
import { Checkbox } from '@/components/common/Checkbox/Checkbox';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { WithholdingInfoFormData, withholdingInfoSchema } from './schema';
import { WaiveWithholdingDialog } from '@/components/features/settings/withholdingElections/WaiveWithholdingDialog';
import { WithholdingStateDialog } from '@/components/features/settings/withholdingElections/WithholdingStateDialog';
import PercentageTextField from '@/components/common/Forms/fields/PercentageTextField';
import useUserSettingsService from '@/hooks/api/useUserSettingsService';
import { useGetUserSettings } from '@fintronners/react-api/src/hooks/useGetUserSettings';
import useUserDetails from '@fintronners/react-widgets/src/hooks/useUserDetails';
import cn from '@/utils/tailwind';
import { useSidePanel } from '@/context/SidePanelContext';
import {
  StateWithholdingClass,
  StateWithholdingRateType,
  useWithholdings,
} from '@/hooks/api/useWithholdings';
import { showToast } from '@/components/common/Toaster/Toaster';

const displayStrings = {
  headingText: 'Settings - Traditional IRA self-directed',
  headingSubText: 'Withholding elections',
  withholdingState: 'Withholding state:',
  enterFederalTax: 'Enter a federal tax withholding percentage.',
  federalWithholding: 'Federal withholding',
  stateWithholding: 'State withholding',
  sidePanelFooterDescription:
    'Any changes to your withholding elections will become the default for future withdrawals. You can change these at any time from the Settings page.',
  waive: 'Waive',
  percentage: 'Percentage',
  stateHoldingsLabel:
    'Your state of residence does not require information to be submitted related to state withholdings. You will only be submitting information related to federal withholdings.',
  mandatoryStateLabel:
    'Enter a federal tax withholding percentage. If you enter your federal withholding amount, then you cannot waive your state withholdings and must enter a state withholding amount.',
  mandatoryOptOutStateLabel:
    'You may waive your state withholdings, but this requires the submission of a tax form from your state.',
  saveChanges: 'Save changes',
  contactLabel:
    ' Please contact support@email.com for the withdrawal form and instructions related to opting out of your state’s withholdings.',
  contactSupport: 'Contact Support',
  enforceWaiveValue: (amount: number) => `Your state requires a minimum withholding of ${amount}%.`,
  successToast: 'Success! Your withholding elections have been saved.',
  errorToast: 'An error occurred. Please try again.',
};

type WithholdingElectionFormProps = {
  isSidePanel?: boolean;
};

export const WithholdingElectionForm = ({ isSidePanel = false }: WithholdingElectionFormProps) => {
  const { closeSidePanel } = useSidePanel();
  const userSettingsServiceApi = useUserSettingsService();
  const { userDetails } = useUserDetails();
  const { userSettings, userSettingsDataLoading } = useGetUserSettings();
  const userState = userDetails?.profile?.state;

  const { state, federalWithholdingRate, stateWithholdingRate } = useWithholdings();

  const { control, formState, handleSubmit, setError, setValue, resetField } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      withholdingInfoSchema(
        Number(state.rate),
        state.class as StateWithholdingClass,
        state.rateType as StateWithholdingRateType,
      ),
    ),
    values: {
      federalWithholding: federalWithholdingRate,
      stateWithholding:
        state.rateType === StateWithholdingRateType.PCT_OF_FEDERAL
          ? Math.ceil(stateWithholdingRate * federalWithholdingRate) / 100
          : stateWithholdingRate,
      isWaivingFederalWithholding: !federalWithholdingRate,
      isWaivingStateWithholding: !parseFloat(stateWithholdingRate),
    },
  });

  const onSubmitWithholdingInformation = async (data: WithholdingInfoFormData) => {
    const stateValue = userSettings?.retirement?.defaultWithholding.state || userState;

    if (!stateValue) return;

    try {
      await userSettingsServiceApi.userSettingsServiceUpdateUserSettings({
        retirement: {
          defaultWithholding: {
            federalTaxWithholding: {
              waive: data.isWaivingFederalWithholding,
              percentage: data.federalWithholding?.toString() || '0',
            },
            stateTaxWithholding: {
              waive:
                state.class !== StateWithholdingClass.MNBE ? data.isWaivingStateWithholding : true,
              percentage: data.stateWithholding?.toString() || '0',
            },
            state: stateValue,
          },
        },
      });

      isSidePanel && closeSidePanel();

      showToast({ message: displayStrings.successToast, variant: 'success' });
    } catch (error) {
      console.error(error);
      showToast({ message: displayStrings.errorToast, variant: 'error' });
    }
  };

  const { isWaivingFederalWithholding, isWaivingStateWithholding, federalWithholding } = useWatch({
    control,
  });

  useEffect(() => {
    if (isWaivingFederalWithholding) {
      setValue('federalWithholding', 0);
      setError('federalWithholding', {});
    } else {
      resetField('federalWithholding', {
        defaultValue: !federalWithholdingRate ? 1 : federalWithholdingRate,
      });
    }
  }, [isWaivingFederalWithholding]);

  useEffect(() => {
    if (isWaivingStateWithholding) {
      setValue('stateWithholding', 0);
      setError('stateWithholding', {});
    } else {
      resetField('stateWithholding', {
        defaultValue:
          state.rateType === StateWithholdingRateType.PCT_OF_FEDERAL
            ? Math.ceil(stateWithholdingRate * (federalWithholding ?? 10)) / 100
            : parseFloat(state.rate) === 0
              ? 1
              : stateWithholdingRate,
      });
    }
  }, [isWaivingStateWithholding]);

  useEffect(() => {
    if (federalWithholding === 0) {
      setValue('isWaivingFederalWithholding', true);
    } else {
      if (
        federalWithholding &&
        !isWaivingStateWithholding &&
        state.rateType === StateWithholdingRateType.PCT_OF_FEDERAL
      ) {
        resetField('stateWithholding', {
          defaultValue: Math.ceil(stateWithholdingRate * federalWithholding) / 100,
        });
      }
    }
  }, [federalWithholding]);

  const showStateWithholding = state.class !== StateWithholdingClass.MNBE;
  const showEnforceWaiveValue =
    !isWaivingStateWithholding &&
    ((parseFloat(state.rate) && state.rateType === StateWithholdingRateType.FIXED) ||
      (state.rateType === StateWithholdingRateType.PCT_OF_FEDERAL && !isWaivingFederalWithholding));

  if (userSettingsDataLoading) return null;

  return (
    <form
      onSubmit={handleSubmit(onSubmitWithholdingInformation)}
      className={cn({
        'flex flex-col justify-between h-full': isSidePanel,
      })}
    >
      <div className="flex flex-col gap-5 mt-5">
        <WebBodyText20.Bold
          className={cn({
            'text-darkestBlue': isSidePanel,
            'text-grey55': !isSidePanel,
          })}
        >
          {displayStrings.headingSubText}
        </WebBodyText20.Bold>
        <div>
          {state.name && (
            <>
              <div className="flex gap-1">
                <WithholdingStateDialog state={state.name} />
                <WebBodyText14.Bold className="text-grey72">
                  {displayStrings.withholdingState}
                </WebBodyText14.Bold>
              </div>
              <Button className="text-primary bg-primary/20 border-none mt-1 pointer-events-none">
                {state.name}
              </Button>
            </>
          )}
        </div>
        {state.class === StateWithholdingClass.MANDATORY ? (
          <WebBodyText14.Regular className="text-grey72">
            {displayStrings.mandatoryStateLabel}
          </WebBodyText14.Regular>
        ) : (
          <WebBodyText14.Regular className="text-grey72">
            {displayStrings.enterFederalTax}
          </WebBodyText14.Regular>
        )}
        {state.class === StateWithholdingClass.MANDATORY_OPT_OUT && (
          <WebBodyText14.Regular className="text-grey72">
            {displayStrings.mandatoryOptOutStateLabel}
          </WebBodyText14.Regular>
        )}
        <div>
          <div className="flex items-center justify-between">
            <WebBodyText14.Bold className="text-darkestBlue">
              {displayStrings.federalWithholding}
            </WebBodyText14.Bold>
            <div className="flex items-center gap-1">
              <WaiveWithholdingDialog />
              <WebBodyText12.Bold className="text-grey55">
                {displayStrings.waive}
              </WebBodyText12.Bold>
              <Controller
                control={control}
                name="isWaivingFederalWithholding"
                render={({ field: { value, onChange } }) => (
                  <Checkbox onCheckedChange={onChange} checked={value} />
                )}
              />
            </div>
          </div>
          <div className="mt-2 flex flex-col gap-2">
            <WebBodyText12.Medium className="text-grey72">
              {displayStrings.percentage}
            </WebBodyText12.Medium>
            <Controller
              control={control}
              name="federalWithholding"
              disabled={isWaivingFederalWithholding}
              render={({ field: { name, onChange, ref, value, disabled }, fieldState }) => (
                <PercentageTextField
                  ref={ref}
                  name={name}
                  disabled={disabled}
                  value={value}
                  onValueChange={onChange}
                  fieldError={fieldState.error}
                />
              )}
            />
          </div>
        </div>
        {showStateWithholding && (
          <div>
            <div className="flex items-center justify-between">
              <WebBodyText14.Bold className="text-darkestBlue">
                {displayStrings.stateWithholding}
              </WebBodyText14.Bold>
              {(state.class !== StateWithholdingClass.MANDATORY || isWaivingFederalWithholding) && (
                <div className="flex items-center gap-1">
                  <WaiveWithholdingDialog />
                  <WebBodyText12.Bold className="text-grey55">
                    {displayStrings.waive}
                  </WebBodyText12.Bold>
                  <Controller
                    control={control}
                    name="isWaivingStateWithholding"
                    render={({ field: { value, onChange } }) => (
                      <Checkbox onCheckedChange={onChange} checked={value} />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="mt-2 flex flex-col gap-2">
              <WebBodyText12.Medium className="text-grey72">
                {displayStrings.percentage}
              </WebBodyText12.Medium>
              <Controller
                control={control}
                name="stateWithholding"
                disabled={isWaivingStateWithholding}
                render={({ field: { name, onChange, ref, value, disabled }, fieldState }) => (
                  <>
                    <PercentageTextField
                      ref={ref}
                      name={name}
                      disabled={disabled}
                      value={value}
                      onValueChange={onChange}
                      fieldError={!showEnforceWaiveValue ? fieldState.error : undefined}
                    />
                    {showEnforceWaiveValue && (
                      <WebBodyText12.Regular
                        className={`${fieldState.error ? 'text-red' : 'text-grey55'}`}
                      >
                        {displayStrings.enforceWaiveValue(
                          state.rateType === StateWithholdingRateType.PCT_OF_FEDERAL &&
                            federalWithholding
                            ? Math.ceil(stateWithholdingRate * federalWithholding) / 100
                            : stateWithholdingRate,
                        )}
                      </WebBodyText12.Regular>
                    )}
                  </>
                )}
              />
            </div>
          </div>
        )}
        {state.class === StateWithholdingClass.MNBE && (
          <WebBodyText14.Regular className="text-grey55">
            {displayStrings.stateHoldingsLabel}
          </WebBodyText14.Regular>
        )}
        {state.class === StateWithholdingClass.MANDATORY_OPT_OUT && isWaivingStateWithholding && (
          <div className="flex flex-col justify-center items-center gap-6">
            <WebBodyText14.Regular className="text-grey55">
              {displayStrings.contactLabel}
            </WebBodyText14.Regular>
            <Button variant="secondary" type="button">
              {displayStrings.contactSupport}
            </Button>
          </div>
        )}
      </div>
      <div>
        {isSidePanel && (
          <WebBodyText14.Regular className="text-grey41 font-normal mb-7 text-center">
            {displayStrings.sidePanelFooterDescription}
          </WebBodyText14.Regular>
        )}

        <Button
          className={cn('w-full', {
            'mt-7': !isSidePanel,
            'mb-12': isSidePanel,
          })}
          variant="primary"
          type="submit"
          disabled={!formState.isValid || !formState.isDirty || !userState}
        >
          {displayStrings.saveChanges}
        </Button>
      </div>
    </form>
  );
};
