import React, { useEffect, useState } from 'react';
import { SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  NonDiscretionaryAccountKycUserLiquidityNeeds,
  NonDiscretionaryAccountKycUserTimeHorizon,
  V1UserInvestmentExperience,
  V1UserInvestmentObjective,
} from '@fintronners/react-api';
import { InvestmentQuestionsRadio } from '../InvestmentQuestionsRadio';
import { Button } from '@/components/common/Button';
import useUserDetails from '@fintronners/react-widgets/src/hooks/useUserDetails';
import { useSidePanel } from '@/context/SidePanelContext';
import { InvestingFormData, investingSchema } from './schema';
import { getFieldDifferenceKycNonDiscretionary } from '@fintronners/react-api/src/utils/formUtils/schemas/investorInformationNonDiscretionary/utils';
import { ErrorSidePanel } from '@/components/common/SidePanels/ErrorSidePanel/ErrorSidePanel';
import useNonDiscretionaryUpdateRequests from '@fintronners/react-widgets/src/hooks/useNonDiscretionaryUpdateRequests';
import {
  getInvestorInformationNonDiscretionaryFormFieldPending,
  getInvestorInformationNonDiscretionaryFormFieldValue,
} from '@fintronners/react-api/src/utils/formUtils/schemas/pending/investorInformationNonDiscretionary';
import { displayStrings, formOptions } from './data';
import PendingChangesNotice from '@/components/notices/PendingChangesNotice';
import { V1UpdateKYCRequest } from '@fintronners/react-api/src';
import useProfileService from '@/hooks/api/useProfileService';

export const InvestingQuestionsForm = () => {
  const userApiService = useProfileService();
  const { nonDiscretionaryAccountKycs: kycs } = useUserDetails();
  const { nonDiscretionaryUpdateRequests: updateRequests } = useNonDiscretionaryUpdateRequests();

  const { goBack } = useSidePanel();

  const [hasError, setHasError] = useState(false);

  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(investingSchema),
    values: {
      investmentExperience: kycs?.investmentExperience ?? V1UserInvestmentExperience.None,
      investmentObjective: kycs?.investmentObjective ?? V1UserInvestmentObjective.Other,
      timeHorizon:
        kycs?.timeHorizon ?? NonDiscretionaryAccountKycUserTimeHorizon.UserTimeHorizonShort,
      liquidityNeeds:
        kycs?.liquidityNeeds ??
        NonDiscretionaryAccountKycUserLiquidityNeeds.UserLiquidityNeedsNotImportant,
    },
  });

  const isPending = formOptions
    .map((formOption) =>
      getInvestorInformationNonDiscretionaryFormFieldPending(updateRequests, formOption.name),
    )
    .some((formOptionIsPending) => formOptionIsPending === true);

  const items = formOptions.map((option) => {
    return {
      ...option,
      isPending: getInvestorInformationNonDiscretionaryFormFieldPending(
        updateRequests,
        option.name,
      ),
    };
  });

  useEffect(() => {
    reset({
      investmentExperience: getInvestorInformationNonDiscretionaryFormFieldValue(
        updateRequests,
        'investmentExperience',
        kycs?.investmentExperience,
      ),
      investmentObjective: getInvestorInformationNonDiscretionaryFormFieldValue(
        updateRequests,
        'investmentObjective',
        kycs?.investmentObjective,
      ),
      timeHorizon: getInvestorInformationNonDiscretionaryFormFieldValue(
        updateRequests,
        'timeHorizon',
        kycs?.timeHorizon,
      ),
      liquidityNeeds: getInvestorInformationNonDiscretionaryFormFieldValue(
        updateRequests,
        'liquidityNeeds',
        kycs?.liquidityNeeds,
      ),
    });
  }, [kycs, updateRequests]);

  const onUpdateInvestorQuestions = async (data: InvestingFormData) => {
    const request: V1UpdateKYCRequest = {
      brokerage: {
        ...getFieldDifferenceKycNonDiscretionary(
          kycs?.investmentExperience,
          data?.investmentExperience,
          'experienceLevel',
        ),
        ...getFieldDifferenceKycNonDiscretionary(
          kycs?.investmentObjective,
          data?.investmentObjective,
          'investmentObjective',
        ),
        ...getFieldDifferenceKycNonDiscretionary(
          kycs?.timeHorizon,
          data?.timeHorizon,
          'investmentHorizon',
        ),
        ...getFieldDifferenceKycNonDiscretionary(
          kycs?.liquidityNeeds,
          data?.liquidityNeeds,
          'liquidityNeeds',
        ),
      },
    };

    try {
      await userApiService.userServiceUpdateKYC(request);

      goBack();
    } catch (error) {
      setHasError(true);
    }
  };

  if (hasError) return <ErrorSidePanel onBack={() => setHasError(false)} />;

  return (
    <SheetContent>
      <form className="flex flex-col h-full" onSubmit={handleSubmit(onUpdateInvestorQuestions)}>
        <div>
          <SheetHeader>
            <SheetTitle className="mb-4">{displayStrings.title}</SheetTitle>
          </SheetHeader>
          {isPending && <PendingChangesNotice />}
          <div className="flex flex-col h-full gap-7 mt-4">
            {items.map((option, index) => (
              <InvestmentQuestionsRadio key={index} {...option} control={control} />
            ))}
          </div>

          <SheetFooter>
            <div className="w-full flex flex-col mt-8">
              <Button
                variant="primary"
                type="submit"
                className="mb-4"
                disabled={!isDirty || !isValid}
              >
                {displayStrings.save}
              </Button>
              <Button variant="secondary" type="button" onClick={goBack}>
                {displayStrings.back}
              </Button>
            </div>
          </SheetFooter>
        </div>
      </form>
    </SheetContent>
  );
};
