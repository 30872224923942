import Image from 'next/image';
import { InfoPrimaryIcon } from '@/assets/icons';
import { Button } from '@/components/common/Button';
import useBreakpoint from '@/hooks/utils/useBreakpoint';
import useResponsiveDialog from '@/hooks/utils/useResponsiveDialog';

interface HelpInfoDialogProps {
  open: boolean;
  setModalOpen: (open: boolean) => void;
  title: string;
  content: string;
}

const displayStrings = {
  close: 'Close',
};

export const HelpInfoDialog = ({ title, content, open, setModalOpen }: HelpInfoDialogProps) => {
  const { isBreakpoint } = useBreakpoint();
  const { ResponsiveDialog } = useResponsiveDialog();

  return (
    <ResponsiveDialog.Root open={open} onOpenChange={(isOpen) => setModalOpen(isOpen)}>
      <ResponsiveDialog.Content>
        <ResponsiveDialog.Header>
          <Image alt="Info icon" width={50} height={50} src={InfoPrimaryIcon.src} />
          <ResponsiveDialog.Title>{title}</ResponsiveDialog.Title>
        </ResponsiveDialog.Header>
        <div className="whitespace-pre-line text-justify text-grey72">{content}</div>
        <ResponsiveDialog.Footer>
          <ResponsiveDialog.Close asChild>
            <Button className="w-full" variant={isBreakpoint('xs') ? 'ghost' : 'primary'}>
              {displayStrings.close}
            </Button>
          </ResponsiveDialog.Close>
        </ResponsiveDialog.Footer>
      </ResponsiveDialog.Content>
    </ResponsiveDialog.Root>
  );
};
