import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import LoadingIcon from '@/assets/icons/loading.svg';
import { useAppStore } from '@/stores/app-store';
import { getCurrentTenant } from '@/config';
import { useConfigStore } from '@/stores/config-store';
import { getTenantSupportEmail } from '@/config';

import { AppProps } from 'next/app';
import { getServerSideProps } from '@/ssr/common';
import type { InferGetServerSidePropsType } from 'next';

type ServerSideProps = InferGetServerSidePropsType<typeof getServerSideProps>;
type ConfigProviderProps = React.PropsWithChildren & AppProps<ServerSideProps>['pageProps'];

const loadThemeFromSessionStorage = () => {
  const themeConfig = sessionStorage.getItem('tenantThemeConfig');
  return themeConfig ? JSON.parse(themeConfig) : null;
};

const ConfigProvider: React.FC<ConfigProviderProps> = ({ newApiUrl, tenant, children }) => {
  const { setConfig } = useConfigStore();

  const setNewApiUrl = useAppStore((state) => state.setNewApiUrl);
  const setTenant = useAppStore((state) => state.setTenant);
  const setTenantSupportEmail = useAppStore((state) => state.setTenantSupportEmail);

  const [isConfigLoaded, setIsConfigLoaded] = useState(false);

  /**
   * Set base config
   */
  useEffect(() => {
    const savedConfig = loadThemeFromSessionStorage();

    if (savedConfig) {
      setConfig(tenant, savedConfig);
    } else {
      setConfig(tenant);
    }

    setIsConfigLoaded(true);
  }, [tenant]);

  /**
   * Set basic App data
   */
  useEffect(() => {
    if (newApiUrl) {
      setNewApiUrl(newApiUrl);
      setTenant(getCurrentTenant(newApiUrl));
    }
  }, [newApiUrl]);

  /**
   * Support Email based on tenant
   */
  useEffect(() => {
    if (!tenant) return;
    const supportEmail = getTenantSupportEmail(tenant);

    if (!supportEmail) return;
    setTenantSupportEmail(supportEmail);
  }, [tenant]);

  if (!isConfigLoaded) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Image src={LoadingIcon} className="mr-2 h-10 w-10 animate-spin" alt="loading" />
      </div>
    );
  }

  return children;
};

export default ConfigProvider;
