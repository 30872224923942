import { gql } from '@apollo/client';

export const GET_LIQUIDATION_DEBTS_FRAGMENT = gql`
  fragment GetLiquidationDebtsFragment on Liability {
    id
    account {
      id
      adminActions {
        adminFirstName
        adminLastName
      }
      externalID
      user {
        id
        firstName
        lastName
      }
    }
    createTime
    owedSince
    amountOwed
    amountPaid
    amountRemaining
  }
`;

export const GET_USER_ACCOUNT_PORTFOLIO_EDGE_FRAGMENT = gql`
  fragment GetUserAccountPortfolioEdgeFragment on UserAccountPortfolioEdge {
    node {
      id
      account {
        externalID
      }
      assetBalances(where: { hasAssetWith: { not: { symbol: "USD" } } }) {
        edges {
          node {
            asset {
              id
              symbol
              name
              createTime
              ... on SecurityAsset {
                market {
                  last
                }
              }
            }
            balances {
              value
              activeBalance
            }
          }
        }
      }
    }
  }
`;

export const GET_LIQUIDATION_DEBTS = gql`
  query GetLiquidationDebts(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $criteria: String
    $startDate: Time
    $endDate: Time
    $allFilters: [LiabilityWhereInput!]
    $orderDirection: OrderDirection!
  ) {
    response: liabilities(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        and: $allFilters
        owedSinceGT: $startDate
        owedSinceLT: $endDate
        hasAccountWith: {
          or: [
            { externalIDContainsFold: $criteria }
            {
              hasUserWith: {
                or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
              }
            }
          ]
        }
      }
      orderBy: { direction: $orderDirection, field: owedSince }
    ) {
      edges {
        cursor
        node {
          ...GetLiquidationDebtsFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
  ${GET_LIQUIDATION_DEBTS_FRAGMENT}
`;

export const GET_LIQUIDATION_DEBTS_BY_ID = gql`
  query GetLiquidationDebtsById($id: ID) {
    liabilities(where: { id: $id }) {
      edges {
        node {
          holdings: account {
            portfolios {
              edges {
                ...GetUserAccountPortfolioEdgeFragment
              }
            }
          }
          ...GetLiquidationDebtsFragment
        }
      }
    }
  }
  ${GET_LIQUIDATION_DEBTS_FRAGMENT}
  ${GET_USER_ACCOUNT_PORTFOLIO_EDGE_FRAGMENT}
`;
