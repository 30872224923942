import { useQuery } from '@apollo/client';
import {
  GET_RECURRING_RULES_V2,
  GetRecurringRulesV2Query,
  RecurringRuleType,
} from '@fintronners/react-api';

export enum RuleTargetType {
  NONE = 'NONE',
  RULE_ID = 'RULE_ID',
  ASSET_ID = 'ASSET_ID',
}

/**
 * Hook for fetching recurring rules and providing utils for managing them
 *
 * @param targetType Type of target to filter recurring rules by
 * @param targetValue Vsalue of the target to filter recurring rules by
 */
const useRecurringRules = (targetType = RuleTargetType.NONE, targetValue?: string) => {
  const { data, refetch, loading } = useQuery<GetRecurringRulesV2Query>(GET_RECURRING_RULES_V2, {
    fetchPolicy: 'cache-first',
    variables: {
      where: {
        isActive: true,
        type: RecurringRuleType.User,
      },
    },
  });

  const recurringRules = data?.recurringRules?.edges ?? [];
  let targetRecurringRule;
  switch (targetType) {
    case RuleTargetType.ASSET_ID:
      targetRecurringRule = recurringRules?.find(
        (rule) => rule?.node?.asset?.id === targetValue,
      )?.node;
      break;
    case RuleTargetType.RULE_ID:
      targetRecurringRule = recurringRules?.find((rule) => rule?.node?.id === targetValue)?.node;
      break;
    default:
      break;
  }

  return { recurringRules, targetRecurringRule, loading, refetch };
};

export default useRecurringRules;
