import { gql } from '@apollo/client';
import {
  GetModelPortfolioByExternalReferenceIdQuery,
  GetModelPortfoliosScreenQuery,
  GetTargetPortfolioQuery,
} from '../types/graphql';

export type QueryModelPortfolios = NonNullable<
  NonNullable<GetModelPortfoliosScreenQuery['modelPortfolios']['edges']>[number]
>['node'];

export type QueryModelPortfolioSectorComposition = NonNullable<
  NonNullable<QueryModelPortfolios>['sectors']
>[number];

export type QueryModelPortfolioSectorAssetComposition = NonNullable<
  NonNullable<QueryModelPortfolios>['sectors']
>[number]['sector']['assets'][number];

export type QueryTargetedProfile = NonNullable<
  NonNullable<GetModelPortfolioByExternalReferenceIdQuery['modelPortfolios']['edges']>[0]
>['node'];

export const GET_SECTOR_FRAGMENT = gql`
  fragment GetSectorDetails on ModelPortfolioSector {
    id
    name
    description
    category
    assets {
      targetPct
      asset {
        id
        symbol
        name
        ... on CryptoAsset {
          imageLogo
        }
        ... on FiatAsset {
          imageLogo
        }
        ... on SecurityAsset {
          imageLogo
        }
      }
    }
  }
`;

export const GET_GOE_MAPPINGS = gql`
  query GetGOEMappings {
    goePortfolioMappings {
      id
      goePortfolioID
      modelPortfolio {
        id
        externalReferenceID
        fiatPct
        bondPct
        stockPct
      }
    }
  }
`;

export const GET_MODEL_PORTFOLIO_BY_EXTERNAL_REFERENCE_ID = gql`
  query GetModelPortfolioByExternalReferenceID($id: ID) {
    modelPortfolios(where: { id: $id }) {
      edges {
        node {
          bondPct
          fiatPct
          stockPct
          externalReferenceID
          id
          sectors {
            id
            targetPct
            sector {
              ...GetSectorDetails
            }
          }
        }
      }
    }
  }
  ${GET_SECTOR_FRAGMENT}
`;

export const GET_ALL_MODEL_PORTFOLIO_ALLOCATIONS = gql`
  query GetAllModelPortfolioAllocations {
    modelPortfolios {
      edges {
        node {
          bondPct
          fiatPct
          stockPct
          externalReferenceID
          id
        }
      }
    }
  }
`;

export const GET_MODEL_PORTFOLIOS = gql`
  query GetModelPortfoliosScreen($id: ID!) {
    modelPortfolios(where: { id: $id }) {
      edges {
        node {
          bondPct
          fiatPct
          stockPct
          id
          sectors {
            id
            targetPct
            sector {
              ...GetSectorDetails
            }
          }
        }
      }
    }
  }
  ${GET_SECTOR_FRAGMENT}
`;

export type QueryTargetPortfolio = NonNullable<
  NonNullable<NonNullable<GetTargetPortfolioQuery['roboGoals']['edges']>[number]>['node']
>['targetPortfolio'];

export type QueryAllocationBySector = NonNullable<
  NonNullable<NonNullable<GetTargetPortfolioQuery['roboGoals']['edges']>[number]>['node']
>['allocation']['allocationBySector'][number];

export const GET_TARGET_PORTFOLIO = gql`
  query GetTargetPortfolio($id: ID!) {
    roboGoals(where: { id: $id }) {
      edges {
        node {
          allocation {
            fiatPct
            stockPct
            bondPct
            totalValue
            drift
            allocationBySector {
              sector {
                id
                name
                category
                assets {
                  targetPct
                  asset {
                    id
                    symbol
                  }
                }
              }
              percentage
            }
          }
          targetPortfolio {
            bondPct
            fiatPct
            stockPct
            id
            sectors {
              id
              targetPct
              sector {
                ...GetSectorDetails
              }
            }
          }
        }
      }
    }
    roboGeneralInvestments(where: { id: $id }) {
      edges {
        node {
          allocation {
            fiatPct
            stockPct
            bondPct
            totalValue
            drift
            allocationBySector {
              sector {
                id
                name
                category
                assets {
                  targetPct
                  asset {
                    id
                    symbol
                  }
                }
              }
              percentage
            }
          }
          targetPortfolio {
            bondPct
            fiatPct
            stockPct
            id
            sectors {
              id
              targetPct
              sector {
                ...GetSectorDetails
              }
            }
          }
        }
      }
    }
  }
  ${GET_SECTOR_FRAGMENT}
`;
