import { gql } from '@apollo/client';

export const GET_SECURITY_LIQUIDATION_FRAGMENT = gql`
  fragment GetSecurityLiquidationFragment on BaseOrder {
    id
    orderType
    orderStatus
    ... on FeeAndCreditOrder {
      amount
      note
      portfolio {
        user {
          id
          firstName
          lastName
        }
        account {
          externalID
        }
      }
    }
    ... on Liquidation {
      user {
        id
        firstName
        lastName
      }
      totalLiquidatedAmount
      liquidationPortfolio {
        account {
          externalID
        }
      }
      note
    }
    createTime
    requestDetails {
      requestor
      admin {
        id
        createTime
        updateTime
        externalID
        firstName
        lastName
      }
    }
  }
`;

export const GET_ADMIN_SECURITY_LIQUIDATIONS = gql`
  query GetAdminSecurityLiquidations(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $criteria: String
    $startDate: Time
    $endDate: Time
    $allFilters: [OrderWhereInput!]
    $orderDirection: OrderDirection!
  ) {
    response: orders(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        and: $allFilters
        createTimeGT: $startDate
        createTimeLT: $endDate
        typeIn: [ORDER_TYPE_FEE_CREDIT, ORDER_TYPE_LIQUIDATION]
        hasPortfolioWith: {
          or: [
            { hasAccountWith: { externalIDContainsFold: $criteria } }
            {
              hasUserWith: {
                or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
              }
            }
          ]
        }
      }
      orderBy: { direction: $orderDirection, field: create_time }
    ) {
      edges {
        cursor
        node {
          ...GetSecurityLiquidationFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
  ${GET_SECURITY_LIQUIDATION_FRAGMENT}
`;

export const GET_ADMIN_SECURITY_LIQUIDATION_BY_ID = gql`
  query GetAdminSecurityLiquidationById($id: ID) {
    orders(where: { id: $id }) {
      edges {
        node {
          id
          ... on Liquidation {
            trades {
              id
              type
              createTime
              side
              status
              orderStatus
              baseAmount
              quoteAmount
              requestAmount
              requestInQuote
              fromLiquidation
              base {
                symbol
                name
              }
              quote {
                symbol
                name
              }
            }
          }
          ...GetSecurityLiquidationFragment
        }
      }
    }
  }
  ${GET_SECURITY_LIQUIDATION_FRAGMENT}
`;
