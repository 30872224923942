import parsePhoneNumber from 'libphonenumber-js';
import { Country, supportedCountries } from './countries';

export const phoneMasks = {
  US: '+[0] ([000]) [000]-[0000]',
  BR: '+[00] ([00]) [00000]-[0000]',
};

export const getPhoneNumberDetails = (phoneNumber: string) => {
  if (!phoneNumber) {
    return {
      phoneNumber,
      isValid: false,
    };
  }

  const phoneNumberObj = parsePhoneNumber(phoneNumber);

  const country: Country | undefined = supportedCountries.find(
    (c) => c.alpha2Code === phoneNumberObj?.country,
  );

  return {
    phoneNumber,
    country,
    isValid: phoneNumberObj?.isValid(),
  };
};
