import { gql } from '@apollo/client';

export const GET_APEX_REJECT_REASONS = gql`
  query GetApexRejectReasons($userId: ID!) {
    response: apexAccountRequests(
      where: { hasUserAccountWith: { hasUserWith: { id: $userId } } }
      orderBy: { direction: DESC, field: create_time }
    ) {
      edges {
        node {
          id
          requestID
          externalRequestID
          createTime
          userAccount {
            id
            user {
              id
              firstName
            }
          }
          apexAccountInvestigations {
            id
            createTime
            investigationStatus
            detailsBase64
            apexInvestigationFiles {
              externalID
              documentType
              userDocument {
                url
                name
                mimeType
                documentType
              }
            }
            apexAccountMeta {
              id
              holderType
              applicationStatus
            }
          }
          request
          response
        }
      }
    }
  }
`;

export const GET_APEX_CLEARING_DETAILS = gql`
  query GetApexClearingDetails($id: ID!) {
    userAccounts(where: { id: $id }) {
      edges {
        node {
          id
          fpslEnrolled
          cashSweepEnrolled
          programEnrollmentHistory {
            id
            electionPreference
            approvalStatus
            programType
            reviewedAt
            reason
            submissionStatus
            submittedAt
            createTime
          }
        }
      }
    }
  }
`;
