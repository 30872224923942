import Skeleton from '../Skeleton/Skeleton';

const SkeletonBankLinkUnlink = () => {
  return (
    <div className="space-y-4">
      <Skeleton className="w-full h-6" />
      <Skeleton className="w-full h-40" />
      {Array.from({ length: 3 }).map((_, index) => (
        <Skeleton key={index} className="w-full h-10" />
      ))}
    </div>
  );
};

export default SkeletonBankLinkUnlink;
