import CurrencyInput, { type CurrencyInputProps } from 'react-currency-input-field';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { DepositTypes, TransferTypes } from '../schema';

type TransferCurrencyInputProps = CurrencyInputProps;

export const TransferCurrencyInput = ({ ...props }: TransferCurrencyInputProps) => {
  const { control } = useFormContext();
  const [depositType, transferType] = useWatch({ control, name: ['depositType', 'transferType'] });

  return (
    <Controller
      control={control}
      name="amount"
      render={({ field: { value, onChange, disabled } }) => (
        <CurrencyInput
          className="w-full text-[60px] text-primary outline-none disabled:text-grey25 bg-transparent"
          prefix="$"
          value={value}
          decimalsLimit={2}
          intlConfig={{ locale: 'en-US' }}
          onValueChange={(_value) => onChange(_value ?? '0')}
          allowNegativeValue={false}
          disabled={
            disabled &&
            depositType === DepositTypes.ONE_TIME &&
            transferType === TransferTypes.DEPOSIT
          }
          {...props}
        />
      )}
    />
  );
};
