import {
  NonDiscretionaryAccountKycUserAnnualIncome,
  NonDiscretionaryAccountKycUserEmploymentStatus,
  NonDiscretionaryAccountKycUserInvestmentExperience,
  NonDiscretionaryAccountKycUserInvestmentObjective,
  NonDiscretionaryAccountKycUserLiquidityNeeds,
  NonDiscretionaryAccountKycUserMaritalStatus,
  NonDiscretionaryAccountKycUserNetWorth,
  NonDiscretionaryAccountKycUserTimeHorizon,
  RoboGeneralInvestmentRoboInfusionFreq,
  UserProfileUserVisaType,
  UserRiskTolerance,
} from '@fintronners/react-api';
import { V1GoalPriority } from '@fintronners/react-api/src/tsoai';
import i18next from '@fintronners/react-language/src/Lang/lang';

export const UserKycEnum: Record<string, string> = {
  [NonDiscretionaryAccountKycUserInvestmentExperience.UserInvestmentExperienceExtensive]:
    'Extensive',
  [NonDiscretionaryAccountKycUserInvestmentExperience.UserInvestmentExperienceGood]: 'Good',
  [NonDiscretionaryAccountKycUserInvestmentExperience.UserInvestmentExperienceLimited]: 'Limited',
  [NonDiscretionaryAccountKycUserInvestmentExperience.UserInvestmentExperienceNone]: 'None',
  [UserRiskTolerance.UserRiskToleranceHigh]: i18next.t(
    `riskTolerance.${UserRiskTolerance.UserRiskToleranceHigh}`,
  ),
  [UserRiskTolerance.UserRiskToleranceLow]: i18next.t(
    `riskTolerance.${UserRiskTolerance.UserRiskToleranceLow}`,
  ),
  [UserRiskTolerance.UserRiskToleranceMedium]: i18next.t(
    `riskTolerance.${UserRiskTolerance.UserRiskToleranceMedium}`,
  ),
  [NonDiscretionaryAccountKycUserTimeHorizon.UserTimeHorizonAverage]: 'Average',
  [NonDiscretionaryAccountKycUserTimeHorizon.UserTimeHorizonLongest]: 'Long',
  [NonDiscretionaryAccountKycUserTimeHorizon.UserTimeHorizonShort]: 'Short',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_0_25000]: '$0 - $25,000',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_25001_50000]: '$25,001 - $50,000',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_50001_100000]: '$50,001 - $100,000',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_100001_200000]:
    '$100,001 - $200,000',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_200001_300000]:
    '$200,001 - $300,000',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_300001_500000]:
    '$300,001 - $500,000',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_500001_1200000]:
    '$500,001 - $1,200,000',
  [NonDiscretionaryAccountKycUserAnnualIncome.UserAnnualIncome_1200001_9999999]: '$1,200,001+',
  [NonDiscretionaryAccountKycUserEmploymentStatus.UserEmploymentStatusEmployed]: 'Employed',
  [NonDiscretionaryAccountKycUserEmploymentStatus.UserEmploymentStatusRetired]: 'Retired',
  [NonDiscretionaryAccountKycUserEmploymentStatus.UserEmploymentStatusStudent]: 'Student',
  [NonDiscretionaryAccountKycUserEmploymentStatus.UserEmploymentStatusSelfEmployed]:
    'Self-Employed',
  [NonDiscretionaryAccountKycUserEmploymentStatus.UserEmploymentStatusUnemployed]: 'Unemployed',
  [NonDiscretionaryAccountKycUserInvestmentObjective.UserInvestmentObjectiveCapitalPreservation]:
    'Capital Preservation',
  [NonDiscretionaryAccountKycUserInvestmentObjective.UserInvestmentObjectiveGrowth]: 'Growth',
  [NonDiscretionaryAccountKycUserInvestmentObjective.UserInvestmentObjectiveIncome]: 'Income',
  [NonDiscretionaryAccountKycUserInvestmentObjective.UserInvestmentObjectiveOther]: 'Other',
  [NonDiscretionaryAccountKycUserInvestmentObjective.UserInvestmentObjectiveSpeculation]:
    'Speculation',
  [NonDiscretionaryAccountKycUserLiquidityNeeds.UserLiquidityNeedsNotImportant]: 'Not Important',
  [NonDiscretionaryAccountKycUserLiquidityNeeds.UserLiquidityNeedsSomewhatImportant]:
    'Somewhat Important',
  [NonDiscretionaryAccountKycUserLiquidityNeeds.UserLiquidityNeedsVeryImportant]: 'Very Important',
  [NonDiscretionaryAccountKycUserMaritalStatus.UserMaritalStatusDivorced]: 'Divorced',
  [NonDiscretionaryAccountKycUserMaritalStatus.UserMaritalStatusMarried]: 'Married',
  [NonDiscretionaryAccountKycUserMaritalStatus.UserMaritalStatusSingle]: 'Single',
  [NonDiscretionaryAccountKycUserMaritalStatus.UserMaritalStatusWidowed]: 'Widowed',
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_0_50000]: '$0 - $50,000',
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_50001_100000]: '$50,001 - $100,000',
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_100001_200000]: '$100,001 - $200,000',
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_200001_500000]: '$200,001 - $500,000',
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_500001_1000000]: '$500,001 - $1,000,000',
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_1000001_5000000]: '$1,000,001 - $5,000,000',
  [NonDiscretionaryAccountKycUserNetWorth.UserNetWorth_5000001_9999999]: '$5,000,001+',
  [RoboGeneralInvestmentRoboInfusionFreq.RoboInfusionFreqWeekly]: 'Weekly',
  [RoboGeneralInvestmentRoboInfusionFreq.RoboInfusionFreqBiWeekly]: 'Bi-Weekly',
  [RoboGeneralInvestmentRoboInfusionFreq.RoboInfusionFreqSemiMonthly]: 'Semi-Monthly',
  [RoboGeneralInvestmentRoboInfusionFreq.RoboInfusionFreqMonthly]: 'Monthly',
  [RoboGeneralInvestmentRoboInfusionFreq.RoboInfusionFreqSemiAnnually]: 'Semi-Annually',
  [RoboGeneralInvestmentRoboInfusionFreq.RoboInfusionFreqYearly]: 'Yearly',
  [V1GoalPriority.Dream]: i18next.t(`robo.goalImportance.${V1GoalPriority.Dream}`),
  [V1GoalPriority.Need]: i18next.t(`robo.goalImportance.${V1GoalPriority.Need}`),
  [V1GoalPriority.Want]: i18next.t(`robo.goalImportance.${V1GoalPriority.Want}`),
  [V1GoalPriority.Wish]: i18next.t(`robo.goalImportance.${V1GoalPriority.Wish}`),
  [V1GoalPriority.Unspecified]: 'Unspecified',
  [UserProfileUserVisaType.UserVisaTypeE1]: 'E1',
  [UserProfileUserVisaType.UserVisaTypeE2]: 'E2',
  [UserProfileUserVisaType.UserVisaTypeE3]: 'E3',
  [UserProfileUserVisaType.UserVisaTypeF1]: 'F1',
  [UserProfileUserVisaType.UserVisaTypeG4]: 'G4',
  [UserProfileUserVisaType.UserVisaTypeH1B]: 'H1B',
  [UserProfileUserVisaType.UserVisaTypeL1]: 'L1',
  [UserProfileUserVisaType.UserVisaTypeO1]: 'O1',
  [UserProfileUserVisaType.UserVisaTypeTn1]: 'Tn1',
  [UserProfileUserVisaType.UserVisaTypeOther]: 'Other',
};

export const getUserKycDisplayString = (
  input: keyof typeof UserKycEnum | boolean | undefined | null,
) => {
  if (input === undefined || input === null) {
    return '';
  }
  if (input === true) {
    return i18next.t('common.yes');
  }
  if (input === false) {
    return i18next.t('common.no');
  }
  return UserKycEnum[input] || '';
};
