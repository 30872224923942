import { useQuery, QueryFunctionOptions } from '@apollo/client';
import { GET_USER_DETAILS_V2, GetUserDetailsV2Query } from '@fintronners/react-api/src';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';

/**
 * This hook is used to fetch user details.
 */
const useUserDetails = (
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
  onCompleted?: QueryFunctionOptions<GetUserDetailsV2Query>['onCompleted'],
) => {
  const { data, loading, refetch } = useQuery<GetUserDetailsV2Query>(GET_USER_DETAILS_V2, {
    fetchPolicy,
    onCompleted,
  });

  const userDetails = data?.users.edges?.at(0)?.node;
  const discretionaryAccountKycs = data?.discretionaryAccountKycs?.edges?.at(0)?.node;
  const nonDiscretionaryAccountKycs = data?.nonDiscretionaryAccountKycs?.edges?.at(0)?.node;

  return { userDetails, discretionaryAccountKycs, nonDiscretionaryAccountKycs, loading, refetch };
};

export default useUserDetails;
