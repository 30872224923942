import { QueryHookOptions, useQuery } from '@apollo/client';
import { GetRetirementInfoQuery, UserProfileUserFilingStatus } from '@fintronners/react-api/src';
import { GET_RETIREMENT_INFO } from '@fintronners/react-api/src/graphql/queries/retirement';

type useGetRetirementInfoProps = {
  state: string;
  annualGrossIncome: number;
  age: number;
  filingStatus: UserProfileUserFilingStatus;
} & QueryHookOptions;

export const useGetRetirementInfo = ({
  state,
  annualGrossIncome,
  age,
  filingStatus,
  ...options
}: useGetRetirementInfoProps) => {
  const { data: userRetirementInfo, loading: userRetirementInfoLoading } =
    useQuery<GetRetirementInfoQuery>(GET_RETIREMENT_INFO, {
      skip: !state || options.skip,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      variables: {
        state,
        annualGrossIncome,
        age,
        filingStatus,
      },
      ...options,
    });

  return {
    ...userRetirementInfo,
    userRetirementInfoLoading,
  };
};
