import React from 'react';

import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config.js';

import {
  WebBodyText14,
  WebCaption10,
  WebCaption12,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import {
  formatPercent,
  thousandFormatNumbroCurrency,
} from '@fintronners/react-utils/src/numberUtilsTSX';
import cn from '@/utils/tailwind';
import { useComputedStyle } from '@/hooks/utils/useComputedStyle';

import { SvgProps } from 'react-native-svg';

type AccountsMenuItemVariant = 'primary' | 'default' | 'selected';

type AccountsMenuItemProps = {
  SvgIcon: React.FC<SvgProps>;
  label: string;
  value: string;
  percentageString: string;
  variant?: AccountsMenuItemVariant;
  onClick?: () => void;
};

const fullConfig = resolveConfig(tailwindConfig);

const AccountsMenuItem: React.FC<AccountsMenuItemProps> = ({
  SvgIcon,
  label,
  value,
  percentageString,
  variant = 'default',
  onClick,
}) => {
  const selectedColor = useComputedStyle('--primary-color');
  const defaultColor = fullConfig.theme.colors.grey41;
  const dayGainLossContainerStyle =
    parseFloat(percentageString) >= 0
      ? 'border-green border-[1px] bg-green10'
      : 'border-red border-[1px] bg-red10';
  const dayGainLossTextStyle = parseFloat(percentageString) >= 0 ? 'text-green' : 'text-red';

  return (
    <button
      onClick={onClick}
      className={cn(
        'w-full flex flex-col justify-center items-start rounded-[5px] px-[15px] pt-[7px] pb-[19px]',
        {
          'bg-grey7': variant === 'primary',
          'bg-blue10': variant === 'selected',
          'bg-transparent': variant === 'default',
        },
      )}
    >
      <div className="flex justify-start items-center gap-[10px] mb-[5px]">
        <SvgIcon
          color={variant === 'selected' ? selectedColor : defaultColor}
          width={14}
          height={14}
        />
        <WebBodyText14.Medium
          className={cn('text-left', {
            'text-grey72': variant !== 'selected',
            'text-primary': variant === 'selected',
          })}
        >
          {label}
        </WebBodyText14.Medium>
      </div>
      <div className="w-full flex justify-between items-center">
        <WebCaption12.Bold className="text-grey55">
          {thousandFormatNumbroCurrency(value)}
        </WebCaption12.Bold>
        <div
          className={cn(
            dayGainLossContainerStyle,
            'flex justify-center items-center px-[7px] py-[3px] rounded-[3px]',
          )}
        >
          <WebCaption10.Bold className={dayGainLossTextStyle}>
            {formatPercent(percentageString)}
          </WebCaption10.Bold>
        </div>
      </div>
    </button>
  );
};

export default AccountsMenuItem;
