import { RefObject, useEffect } from 'react';
import { clamp } from 'lodash';

type UseScrollThresholdProps = {
  /**
   * The ref of the object that contains the scroll
   */
  ref: RefObject<HTMLDivElement> | null;
  /**
   * The threshold between 0 and 1 that needs to be reached
   * to execute the callback
   */
  threshold: number;
  /**
   * The callback function that will be called when the threshold
   * is hit
   */
  onThresholdReached?: (percentage?: number) => void;
  /**
   * Optional value used when you want to avoid the callback
   * to be called. E.g. When you want a specific condition
   * in the component to be met before executing an action
   */
  isDisabled?: boolean;

  disableReachOnNoScroll?: boolean;
};

/**
 * This hook can be used to run a callback function when
 * a threshold value between 0 and 1 is reached on scrolling a
 * component (ref).
 *
 * After full scrolling the component the percentage is 1
 * and before scrolling the component the percentage is 0.
 *
 * If a component doesn't have a scroll amount, it will be
 * considered as having the percentage 1 (will hit the threshold)
 *
 */
export const useScrollThreshold = ({
  ref,
  threshold,
  onThresholdReached,
  isDisabled,
  disableReachOnNoScroll,
}: UseScrollThresholdProps) => {
  useEffect(() => {
    const handleScroll = () => {
      if (ref?.current) {
        const { clientHeight, scrollTop, scrollHeight } = ref.current;

        let percentage = scrollTop / (scrollHeight - clientHeight);
        percentage = clamp(percentage, 0, 1);

        if (percentage >= threshold && onThresholdReached) {
          onThresholdReached(percentage);
        }
      }
    };

    if (ref?.current && !isDisabled && !disableReachOnNoScroll) {
      const { clientHeight, scrollHeight } = ref.current;

      if (clientHeight === scrollHeight && onThresholdReached) {
        onThresholdReached(1);
      }
    }

    if (!isDisabled) {
      ref?.current?.addEventListener('scroll', handleScroll);
    }

    return () => {
      ref?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [ref, onThresholdReached, threshold, isDisabled]);
};
