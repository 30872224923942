import { gql } from '@apollo/client';

export const GET_ADMIN_FEE_AND_CREDIT_HISTORY = gql`
  query GetAdminFeeAndCreditHistory(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $orderDirection: OrderDirection = DESC
    $startDate: Time
    $endDate: Time
    $criteria: String = ""
    $allFilters: [FeeAndCreditOrderWhereInput!]
  ) {
    response: feeAndCreditOrders(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        and: $allFilters
        createTimeGTE: $startDate
        createTimeLTE: $endDate
        hasPortfolioWith: {
          or: [
            { hasAccountWith: { externalIDContainsFold: $criteria } }
            {
              hasUserWith: {
                or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
              }
            }
          ]
        }
      }
      orderBy: { direction: $orderDirection, field: create_time }
    ) {
      edges {
        cursor
        node {
          id
          submissionID
          submission {
            externalID
          }
          type
          orderType
          amount
          status
          orderStatus
          createTime
          portfolio {
            user {
              id
              firstName
              lastName
            }
            account {
              externalID
              apexAccountMeta {
                externalRequestID
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;
