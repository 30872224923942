import { gql } from '@apollo/client';

export const GET_EDITABLE_VALUES_FOR_SINGLE_GOAL = gql`
  query GetEditableValuesForSingleGoal($goalUid: ID) {
    users {
      edges {
        node {
          roboGoals(where: { id: $goalUid }) {
            edges {
              node {
                isAutoInfusionPaused
                name
                parameters {
                  targetAmount
                  endDate
                  infusionAmount
                  infusionFreq
                  initialInvestment
                }
              }
            }
          }
        }
      }
    }
  }
`;
