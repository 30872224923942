import { useContext } from 'react';
import { GrpcApiV1ConfigContext } from '@/context/grpcApiV1ConfigContext';
import { JwtIdToken } from '@/middleware';

type ContextData = {
  basePath?: string;
  authApiBaseUrl?: string;
  accessToken?: string;
  idToken: JwtIdToken | null;
};

export const useGrpcApiV1Config = (): Partial<ContextData> => {
  const context = useContext(GrpcApiV1ConfigContext);

  if (!context) {
    throw new Error('useGrpcApiV1Config must be used within the provider');
  }

  return context as ContextData;
};
