import { cva, VariantProps } from 'class-variance-authority';
import { HTMLProps } from 'react';
import cn from '@/utils/tailwind';

const labelVariants = cva('cursor-pointer', {
  variants: {
    disabled: {
      true: '!text-grey41 !cursor-not-allowed peer-checked:!bg-grey41 peer-checked:!text-white',
    },
    variant: {
      primary:
        'px-4 py-1 bg-grey6 text-grey72 rounded-custom cursor-pointer peer-checked:bg-primary/10 peer-checked:text-primary hover:text-gray-600 hover:bg-gray-100',
      secondary:
        'border border-grey6 shadow rounded-custom py-1 flex items-center justify-center text-primary peer-checked:bg-primary peer-checked:text-white peer-checked:shadow-none',
    },
  },
});

type LabelProps = VariantProps<typeof labelVariants> & HTMLProps<HTMLLabelElement>;

export const Label = ({ variant, disabled, className, ...props }: LabelProps) => (
  <label className={cn(labelVariants({ variant, className, disabled }))} {...props}>
    {props.children}
  </label>
);
