import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { addBusinessDays } from 'date-fns';
import { useRouter } from 'next/router';

import {
  SecurityAssetSecurityType,
  V1CreateRepeatTradeRequest,
  V1RecurringRuleInterval,
} from '@fintronners/react-api/src';
import useExternalAccounts, {
  AccountType,
} from '@fintronners/react-widgets/src/hooks/useExternalAccounts';
import { convertISODateStringToObject } from '@fintronners/react-api/src/utils/dateUtils';
import {
  apexSharesShortFormat,
  decimalRoundUp,
  rawThousandFormatNumbro,
  trimToThreeDecimalPlaces,
} from '@fintronners/react-utils/src/numberUtilsTSX';
import { SecurityAsset } from '@fintronners/react-api/src';
import {
  V1CreateTradeRequest,
  V1MarketSide,
  V1TradeExpirationType,
} from '@fintronners/react-api/src/tsoai';
import useUserAccount from '@/hooks/api/useUserAccount';
import useCachedMarketData from '@fintronners/react-widgets/src/hooks/market/useCachedMarketData';

import useTradeApiService from '@/hooks/api/useTradeService';
import { useSidePanel } from '@/context/SidePanelContext';
import { useSecurityAssetDetails } from '@/hooks/api/useSecurityAssetDetails';
import { useSecurityTradingData } from '@/hooks/api/useSecurityTradingData';

import { TradeSidePanelForm } from './TradeSidePanelForm';
import { TradeSidePanelConfirmation } from './TradeSidePanelConfirmation';
import { TradeSidePanelSuccess } from './TradeSidePanelSuccess';
import { ErrorSidePanel } from '../ErrorSidePanel/ErrorSidePanel';
import { OrderType, TradeFormData, TradeSide, tradeSchema } from './schema';
import useCurrentAccountType from '@/hooks/utils/useCurrentAccountType';

const defaultFormValues = {
  orderType: OrderType.MARKET_BUY,
  amount: '0',
  duration: V1TradeExpirationType.Day,
  limitPrice: undefined,
  interval: V1RecurringRuleInterval.EveryWeek,
  selectedStartDate: addBusinessDays(new Date(), 1).toISOString(),
  stopLoss: '0',
};

const TradeSidePanel = () => {
  const router = useRouter();
  const [hasError, setHasError] = useState(false);
  const [side, setSide] = useState<TradeSide>(TradeSide.BUY);

  const accountType = useCurrentAccountType();
  const { userAccount } = useUserAccount(accountType);
  const { fiatAsset: fiat } = useCachedMarketData();
  const {
    currentFlowStep,
    sidePanelProps,
    goTo,
    goBack,
    setShowBackLink,
    setShowCloseButton,
    closeSidePanel,
  } = useSidePanel();

  useEffect(() => {
    if (sidePanelProps?.side) {
      setSide(sidePanelProps.side);
    }
  }, [sidePanelProps]);

  const tradeApiService = useTradeApiService();

  const securityId = router.query['security-id'] as string;
  const { data: securityDetails, loading: loadingSecurityDetails } = useSecurityAssetDetails({
    securityId,
  });

  const asset = securityDetails?.securityAssets.edges?.[0]?.node as SecurityAsset;
  const fiatId = fiat?.id ?? '';
  const isMutualFund = asset?.securityType === SecurityAssetSecurityType.MutualFund;

  const { data: tradingData } = useSecurityTradingData({
    fiatId,
    securityId,
    portfolioId: userAccount?.firstPortfolioId ?? '',
  });

  const { firstExternalAccount, fetchingAccounts } = useExternalAccounts(AccountType.BROKERAGE);

  const fiatBalances = tradingData?.userAccountPortfolioAssets?.edges?.find(
    (edge) => edge?.node?.asset?.id === fiatId,
  )?.node?.balances;

  const securityBalances = tradingData?.userAccountPortfolioAssets?.edges?.find(
    (edge) => edge?.node?.asset?.id === securityId,
  )?.node?.balances;

  const form = useForm({
    resolver: yupResolver(tradeSchema),
    defaultValues: defaultFormValues,
    values: {
      ...defaultFormValues,
      orderType: side === TradeSide.BUY ? OrderType.MARKET_BUY : OrderType.MARKET_SELL,
    },
  });

  const isLimitBuy = form.getValues().orderType === OrderType.LIMIT_BUY;
  const isStopLoss = form.getValues().orderType === OrderType.STOP_LOSS;

  const tradableUnits =
    side === TradeSide.BUY ? fiatBalances?.tradeable : securityBalances?.tradeable;

  const tradableInCash =
    side === TradeSide.BUY
      ? tradableUnits
      : rawThousandFormatNumbro(tradableUnits * asset?.market?.last);

  const performTrade = async (tradeFormData: TradeFormData) => {
    const { amount, orderType, duration, limitPrice, stopLoss } = tradeFormData;

    if (orderType === OrderType.REPEAT_INVESTMENT) {
      onPerformRecurringTrade(tradeFormData);
      return;
    }

    let base;
    let quote;
    let all;

    switch (side) {
      case TradeSide.BUY:
        quote = amount.toString();
        break;
      case TradeSide.SELL:
        if (isStopLoss) {
          const maximumThreshold = rawThousandFormatNumbro(tradableUnits * parseFloat(stopLoss));
          if (parseFloat(amount) === maximumThreshold) {
            all = {};
          } else {
            const baseRoundUp = decimalRoundUp(parseFloat(amount) / parseFloat(stopLoss));

            base = apexSharesShortFormat(baseRoundUp);
          }
        } else {
          if (parseFloat(amount) === tradableInCash) {
            all = {};
          } else {
            const currentPrice = asset?.market?.last;
            const baseRoundUp = decimalRoundUp(parseFloat(amount) / currentPrice);

            base = apexSharesShortFormat(baseRoundUp);
          }
        }
        if (isMutualFund && base) {
          const baseRoundUp = String(decimalRoundUp(Number(base), 3));

          base = trimToThreeDecimalPlaces(baseRoundUp);
        }
        break;
    }

    const tradeRequest: V1CreateTradeRequest = {
      portfolioUid: userAccount?.firstPortfolioId,
      tradeOrder: {
        side: side === TradeSide.BUY ? V1MarketSide.Buy : V1MarketSide.Sell,
        instrument: {
          base: asset.id,
          quote: fiatId,
        },
        base: base,
        quote: quote,
        all: all,
        expirationType: isLimitBuy || isStopLoss ? duration : V1TradeExpirationType.Day,
        mit: isLimitBuy ? { limit: limitPrice?.toString() } : undefined,
        stopLoss: isStopLoss ? { stop: stopLoss } : undefined,
      },
    };

    try {
      await tradeApiService.tradeServiceCreateTrade(tradeRequest);

      goTo(2);
    } catch (err) {
      setHasError(true);
    } finally {
      setShowBackLink(false);
      setShowCloseButton(true);
    }
  };

  const onPerformRecurringTrade = async ({
    amount,
    interval,
    selectedStartDate,
  }: TradeFormData) => {
    const tradeRequest: V1CreateRepeatTradeRequest = {
      portfolioUid: userAccount?.firstPortfolioId,
      amount: amount.toString(),
      assetUid: asset.id,
      fundingAccountUid: firstExternalAccount?.id,
      interval: interval,
      start: convertISODateStringToObject(selectedStartDate),
    };

    try {
      await tradeApiService.tradeServiceCreateRepeatTrade(tradeRequest);

      goTo(2);
    } catch (err) {
      setHasError(true);
    } finally {
      setShowBackLink(false);
      setShowCloseButton(true);
    }
  };

  return hasError ? (
    <ErrorSidePanel onBack={() => setHasError(false)} />
  ) : (
    <FormProvider {...form}>
      <form>
        {currentFlowStep === 0 && (
          <TradeSidePanelForm
            side={side}
            asset={asset}
            tradableInCash={tradableInCash}
            tradableUnits={tradableUnits}
            account={firstExternalAccount}
            loading={loadingSecurityDetails || fetchingAccounts}
            onContinue={() => goTo(1)}
            isMutualFund={isMutualFund}
          />
        )}
        {currentFlowStep === 1 && (
          <TradeSidePanelConfirmation
            asset={asset}
            tradableInCash={tradableInCash}
            tradableUnits={tradableUnits}
            onBack={goBack}
            onContinue={form.handleSubmit(performTrade)}
          />
        )}
        {currentFlowStep === 2 && <TradeSidePanelSuccess onClose={closeSidePanel} />}
      </form>
    </FormProvider>
  );
};

export default TradeSidePanel;
