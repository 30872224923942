import Image from 'next/image';
import { clsx, type ClassValue } from 'clsx';
import { WebBodyText14, WebCaption12 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { ChevronRightDarkIcon } from '@/assets/icons';
import tailwindConfig from '@/tailwind.config';
import resolveConfig from 'tailwindcss/resolveConfig';

const fullConfig = resolveConfig(tailwindConfig);

type ListItemButtonProps = {
  title: string;
  subtitle?: string;
  className?: ClassValue;
  onClick?: () => void;
};

const ListItemButton = ({ title, subtitle, className, onClick }: ListItemButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'flex w-full items-center justify-between border-b border-b-grey25 py-5',
        className,
      )}
    >
      <div className="flex flex-col items-start gap-[5px]">
        <WebBodyText14.Regular className="text-left text-darkestBlue">
          {title}
        </WebBodyText14.Regular>
        <WebCaption12.Regular className="text-left text-grey55">{subtitle}</WebCaption12.Regular>
      </div>

      <Image src={ChevronRightDarkIcon} width={8} color={fullConfig.theme.colors.grey55} alt="" />
    </button>
  );
};

export default ListItemButton;
export type { ListItemButtonProps };
