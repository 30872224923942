import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';
import { V1GoalPriority } from '@fintronners/react-api/src/tsoai';

export default {
  roboFundSleeve: {
    title: 'Distribuições',
    lastDistribution: 'Última distribuição',
    pastDistribution: 'Histórico de Distribuições',
    amount: 'Valor',
    date: 'Data',
    frequency: 'Frequência',
    viewMore: 'Ver Mais',
    numberOfHoldings: 'Número de Ativos: ',
    numberOfHoldingsTruncated: 'Número de Ativos',
    substitute: {
      description: 'Você gostaria de substituir esta ação por uma alternativa?',
      buttonText: 'Substituir por ETF',
    },
    screenTitle: `{{sleeveName}} ativos`,
    distributions: {
      lastDistribution: 'Última distribuição',
      amount: 'Valor',
      date: 'Data',
      frequency: 'Frequência',
      frequencyOneTime: 'Única vez',
      frequencyAnnually: 'Anualmente',
      frequencyBiAnnually: 'Semestralmente',
      frequencyQuarterly: 'Trimestralmente',
      frequencyMonthly: 'Mensalmente',
      pastDistributions: 'Histórico de Distribuições',
      viewMore: 'Ver Mais +',
      na: 'N/D',
    },
    generalData: {
      marketCap: 'Cap. de mercado',
      totalEnterpriseValue: 'Valor total da empresa',
      revenue: 'Receita',
      ebitda: 'EBITDA',
      ebitdaMargin: 'EBITDA margem',
      ebit: 'EBIT (lucro operacional)',
      profitMargin: 'Margem de lucro',
      peRatio: 'Índice PE',
      pegRatio: 'Índice PEG',
      earningsPerShare: 'Lucro por ação',
      bookValuePerShare: 'Valor contábil por ação',
      dividendTtm: 'Dividendo acumulado (12m)',
      dividendYield: 'Rendimento do dividendo',
      dividendExDate: 'Data-ex do dividendo',
      longTermDebt: 'Dívida a longo prazo',
      quickRatio: 'Proporção rápida',
      totalCash: 'Caixa total',
    },
  },
  goalLanding: {
    title: 'Criar Um Objetivo',
    subtitle: '',
    bodyText1: '',
    infoLineTitle: '',
    bodyText2: '',
    infoLineBsmTitle: '',
    infoLineBsmDescription: '',
    boxSectionTitle1: 'Por Que Criar Um Objetivo?',
    boxSectionBody1:
      'Você pode criar um objetivo personalizado para qualquer evento futuro ou compra que tenha em mente. Talvez você esteja planejando um casamento, indo para a pós-graduação ou queira fazer uma viagem - criar um Objetivo é a escolha perfeita.',
    boxSectionTitle2: 'Múltiplos Objetivos',
    boxSectionBody2:
      'Você pode configurar vários objetivos de Eventos Futuros. Se você tem alguns em mente, basta terminar de criar este objetivo e depois iniciar o processo novamente.',
    boxSectionTitle3: 'Média de Custo em Dólar',
    boxSectionBody3:
      'Para aumentar a probabilidade de alcançar seu objetivo, é importante começar com um investimento inicial e continuar fazendo investimentos adicionais regulares e automatizados ao longo do tempo (média de custo em dólar). As etapas a seguir permitirão que você defina sua estratégia de investimento.',
    footer: '',
  },
  nameGoalScreen: {
    goalName: 'Nome do objetivo',
    title: 'Escolha um nome memorável para o seu objetivo.',
    header: 'Até quando você precisará alcançar seu objetivo?',
    description:
      'Escolha uma data no futuro que possamos mirar. Você sempre pode alterá-la depois.',
    next: 'Próximo: conte-nos como você se sente sobre {{ screen }}.',
    risk: 'risco',
    importance: 'importância',
  },
  goalImportance: {
    title: 'Quão importante é este objetivo para você?',
    subtitle:
      'Clique em cada opção abaixo para aprender sobre a prioridade do objetivo e depois selecione qual opção se encaixa melhor.\n\nSeu objetivo é um:',
    [V1GoalPriority.Dream]: 'Sonho',
    [V1GoalPriority.Need]: 'Necessidade',
    [V1GoalPriority.Want]: 'Desejo',
    [V1GoalPriority.Wish]: 'Vontade',
    [V1GoalPriority.Unspecified]: '',
    priorityLevelDescription: {
      [V1GoalPriority.Dream]:
        'De acordo com seu perfil de risco, nosso Mecanismo de Aconselhamento visará pelo menos **50% de probabilidade** de alcançar o valor total do seu objetivo e fará recomendações de contribuição para ajudá-lo a atingir essa meta de probabilidade.',
      [V1GoalPriority.Need]:
        'De acordo com seu perfil de risco, nosso Mecanismo de Aconselhamento visará pelo menos **85% de probabilidade** de alcançar o valor total do seu objetivo e fará recomendações de contribuição para ajudá-lo a atingir essa meta de probabilidade.',
      [V1GoalPriority.Want]:
        'De acordo com seu perfil de risco, nosso Mecanismo de Aconselhamento visará pelo menos **70% de probabilidade** de alcançar o valor total do seu objetivo e fará recomendações de contribuição para ajudá-lo a atingir essa meta de probabilidade.',
      [V1GoalPriority.Wish]:
        'De acordo com seu perfil de risco, nosso Mecanismo de Aconselhamento visará pelo menos **60% de probabilidade** de alcançar o valor total do seu objetivo e fará recomendações de contribuição para ajudá-lo a atingir essa meta de probabilidade.',
    },
    selectPriorityLevel: 'Selecione seu nível de prioridade para este objetivo.',
    choosingGoalPriority: 'Escolhendo uma Prioridade de Objetivo',
    choosingGoalPriorityDescription:
      'Se você pretende depender muito deste investimento no futuro, considere uma **meta de probabilidade mais alta (como Necessidade)**.\n\nSe você planeja priorizar outros investimentos ou despesas antes de contribuir para este objetivo, considere uma **meta de probabilidade mais baixa (como Sonho)**.',
  },
  goalTarget: {
    title: 'Qual é a meta do seu objetivo?',
    subtitle:
      'Digite o valor em dólares que você gostaria de alcançar até o final do prazo do seu objetivo, ou selecione uma das opções abaixo.',
    almostThere:
      'Quase lá! Apenas mais alguns passos para calcular a probabilidade do seu objetivo.',
  },
  addNowInvestment: {
    title: 'Digite o valor do seu investimento inicial.',
    subTitle: 'Quanto você planeja investir hoje para o seu objetivo?',
    repeatHeader: 'Depósito Mínimo de {{ amount }}',
  },
  addRepeatInvestment: {
    title: 'Planeje suas contribuições contínuas.',
    subtitle:
      'Aumente a probabilidade de alcançar seu objetivo configurando investimentos automáticos na frequência desejada.',
    frequency: 'Frequência',
  },
  goalProbability: {
    probability: 'Probabilidade',
    probabilityHeader: 'Probabilidade do objetivo:',
    probabilityBsmDescription:
      'A porcentagem de probabilidade mostrada é uma projeção hipotética baseada em modelagem estatística sobre a probabilidade de um resultado de investimento. Não reflete resultados reais de investimento, nem tenta retratar o desempenho futuro dos investimentos em seu portfólio recomendado. Não é uma garantia de resultados futuros ou valor futuro do seu objetivo, nem que você alcançará o valor total do seu objetivo. Não há garantia da taxa de sucesso do seu resultado simulado.',
    popUpTitle: 'Aviso de baixa probabilidade',
    popUpDescription:
      'Com base em suas entradas atuais, você tem uma baixa probabilidade de alcançar o valor total do seu objetivo. Por favor, considere diminuir o valor alvo do seu objetivo, estender sua data alvo, ou aumentar o valor do seu investimento inicial ou o valor do investimento automatizado.',
    editValues: 'Editar valores',
    confirmRiskToleranceBsmTitle: 'Alterando seu perfil de risco de investimento',
    confirmRiskToleranceBsmDescription:
      'Tem certeza de que deseja atualizar seu\nperfil de risco?\n\nIsso pode alterar a probabilidade do seu objetivo e as alocações na sua próxima realocação programada.',
    confirmGoalPriorityBsmTitle: 'Atualizar os valores do seu objetivo',
    confirmGoalLowPriorityBsmTitle: 'Aviso de baixa probabilidade',
    confirmGoalPriorityBsmDescription:
      'Tem certeza de que deseja atualizar os valores do seu objetivo?\n\nIsso pode afetar a probabilidade do seu objetivo ou as alocações do seu portfólio, ou ambos.',
    confirmGoalLowPriorityBsmDescription:
      'Esta atualização dos valores do seu objetivo fará com que a Probabilidade do Objetivo seja baixa. Você só pode salvar as edições do valor do objetivo se a nova probabilidade estiver acima de {{ percentage }}.',
    bottomDesc: 'A possível realocação desencadeada por esta mudança pode ter implicações fiscais.',
    yesUpdate: 'Sim, continuar',
    cancel: 'Cancelar',
  },
  goalProjection: {
    heading: 'Projeção',
    subhead: 'Sua projeção hipotética de objetivo',
    bsmTitle: 'Informações importantes sobre a projeção do seu objetivo',
    bsmDescription:
      'O valor estimado da carteira gerado por esta ferramenta de análise de investimentos, incluindo projeções estimadas de aumento ou diminuição do valor da carteira, representa probabilidades e possíveis resultados, não uma garantia de desempenho futuro. Quaisquer previsões geradas sobre a probabilidade de vários resultados de investimento são hipotéticas por natureza, não refletem resultados reais de investimento e não são uma garantia de resultados futuros.\n\nEssas projeções hipotéticas não refletem negociações reais e, portanto, não levam em conta riscos financeiros reais, condições econômicas, impostos, taxas ou despesas.',
    goalEstimation:
      'Seu objetivo tem uma probabilidade estimada de {{ probability }} de atingir o valor alvo de {{ target }} em {{ targetYear }} anos.',
    riskLevelAndPriority: 'Nível de risco e prioridade do objetivo',
    riskLevel: 'Nível de risco',
    goalPriority: 'Prioridade do objetivo',
    editValuesLegal: 'Você pode editar esses valores nas configurações.',
    editValuesLegalNew:
      'Retorne às páginas anteriores no fluxo de criação de objetivos para editar esses valores',
    goalDetails: 'Detalhes do objetivo',
    targetDate: 'Data alvo',
    initialInvestment: 'Investimento inicial',
    targetAmount: 'Valor alvo',
    automatedInvestments: 'Investimentos automatizados',
    frequency: 'Frequência',
    editDetails: 'Editar detalhes',
    howToRead: 'Como ler este gráfico e entender o intervalo de probabilidade',
    targetDateEstimate: ' ({{ years }} anos e {{ months }} meses)',
    targetProbability: 'Probabilidade alvo',
    targetProbabilityBsm: `A probabilidade alvo é uma seleção feita por você ao criar seu objetivo. Você pode atualizar sua probabilidade alvo a qualquer momento nas Configurações.\n\n**Escolhendo uma Prioridade de Objetivo**\nSe você pretende depender muito deste investimento no futuro, considere uma **meta de probabilidade mais alta (como uma Necessidade)**.\n\nSe você planeja priorizar outros investimentos ou despesas antes de contribuir para este objetivo, considere uma **meta de probabilidade mais baixa (como um Sonho)**.\n\n**Necessidade**\nDe acordo com seu perfil de risco, nosso Mecanismo de Aconselhamento visará pelo menos **85% de probabilidade** de alcançar o valor total do seu objetivo e fará recomendações de contribuição para ajudá-lo a atingir essa meta de probabilidade.\n\n**Desejo**\nDe acordo com seu perfil de risco, nosso Mecanismo de Aconselhamento visará pelo menos **70% de probabilidade** de alcançar o valor total do seu objetivo e fará recomendações de contribuição para ajudá-lo a atingir essa meta de probabilidade.\n\n**Vontade**\nDe acordo com seu perfil de risco, nosso Mecanismo de Aconselhamento visará pelo menos **60% de probabilidade** de alcançar o valor total do seu objetivo e fará recomendações de contribuição para ajudá-lo a atingir essa meta de probabilidade.\n\n**Sonho**\nDe acordo com seu perfil de risco, nosso Mecanismo de Aconselhamento visará pelo menos **50% de probabilidade** de alcançar o valor total do seu objetivo e fará recomendações de contribuição para ajudá-lo a atingir essa meta de probabilidade.`,
    increaseInitialInvestment: 'Aumentar investimento inicial',
    increaseAutomatedInvestment: 'Aumentar investimentos automatizados',
    learnMore: 'Saiba mais sobre este produto',
    learnMoreTitle: 'Informações adicionais sobre esta ferramenta de análise de investimentos',
    learnMoreDescription: `O Mecanismo de Otimização de Objetivos ("GOE") usa um algoritmo de investimento proprietário projetado para melhor alcançar um objetivo de investimento declarado. O GOE seleciona ou recomenda um portfólio ("Portfólio GOE") dentre vários portfólios prospectivos (construídos pela Franklin Advisers ("FAV")) com base em dados inseridos relacionados à tolerância ao risco do cliente, valor inicial do investimento, valor alvo do objetivo, horizonte de tempo de investimento, prioridade do objetivo, nível aceitável de perdas de investimento na data de posse do objetivo e contribuições e retiradas futuras esperadas de investimento, entre outros fatores ("Entradas de Dados").

Um Portfólio GOE geralmente consistirá em Fundos Registrados nos EUA e, em alguns casos, favorecerá e poderá consistir inteiramente de Fundos Afiliados (como os Fundos Franklin Templeton). Para informações sobre Conflitos Relacionados ao Investimento em Fundos Afiliados e Contas Afiliadas, consulte o Folheto do Consultor FAV (Formulário ADV Parte 2A). Em relações consultivas discricionárias, o GOE reavalia o progresso dos Portfólios GOE (em conexão com a reavaliação das Expectativas dos Mercados de Capitais ("CMEs") da FAV, para o algoritmo GOE) de tempos em tempos, pelo menos anualmente ou se um cliente fizer uma mudança em seu perfil de risco ou configurações de objetivo, e o algoritmo GOE pode identificar e implementar ou recomendar ajustes nas participações do Portfólio GOE ou um Portfólio GOE diferente nesses momentos.

As recomendações do GOE são limitadas pelas entradas de dados relacionadas ao cliente e pelos portfólios construídos para recomendação. As recomendações do GOE - e a porcentagem de probabilidade de sucesso do objetivo exibida neste aplicativo - envolvem cálculos que incorporam certas CMEs (pressupostos-chave gerados pela FAV), geradas pela FAV, que estimam a probabilidade de retornos e a volatilidade de várias classes de ativos.

As recomendações do GOE geradas em momentos diferentes variarão. O GOE recomenda portfólios criados pela FAV; esses portfólios incluem fundos afiliados (como os Fundos Franklin Templeton) e fundos não afiliados. O GOE recomenda portfólios pré-construídos com base nas Entradas de Dados em um esforço para maximizar a probabilidade de alcançar um objetivo de investimento. Investimentos que não fazem parte dos portfólios podem ter características semelhantes ou superiores aos investimentos contidos nos portfólios."`,
    goeSuggestion:
      'Considere uma das seguintes modificações para aumentar a probabilidade de atingir o valor total do seu objetivo de {{ amount }} de **{{ probability }}** para **{{ targetProbability }}:**',
    applyGoeSuggestionTitle: 'Aplicar recomendação',
    applyGoeSuggestionDescription:
      "Selecione 'Continuar' para aplicar automaticamente a modificação recomendada às entradas do seu",
    currentGoalValue: 'Valor atual do objetivo',
    currentGoalValueDescription: 'O valor do seu objetivo até hoje.',
    recurringContributions: 'Contribuições recorrentes',
    recurringContributionsDescription:
      'A soma das suas contribuições contínuas restantes (investimentos automatizados), assumindo que você não retirará nenhum fundo até a data alvo do seu objetivo.',
    targetProbabilityDescription:
      'O valor estimado do seu objetivo associado à sua seleção de Prioridade do Objetivo. Leia mais sobre a Prioridade do Objetivo aqui.',
    probabilityRange: 'Intervalo de probabilidade',
    probabilityRangeDescription:
      'Uma projeção hipotética representando os valores ao longo do tempo que você tem 90% de probabilidade de alcançar (a linha de limite inferior) e 35% de probabilidade de alcançar (a linha de limite superior).',
    targetAmountDescription:
      'O valor do objetivo que você indicou precisar na data alvo do seu objetivo. Consulte a probabilidade real do seu objetivo para entender a probabilidade de alcançar o valor total do seu objetivo dentro do prazo desejado.',
    howToReadFooter:
      'Os dados mostrados neste gráfico são uma projeção hipotética; não é uma garantia de quaisquer resultados. Essas projeções mudarão ao longo do tempo e/ou com qualquer modificação no seu objetivo.',
  },
  goalAgreement: {
    createGoalTitle: 'Criar objetivo.',
    createGoalDescription: `Ao enviar este objetivo, ${FinTronConfig.robo.name} iniciará seu depósito inicial e seus investimentos adicionais automatizados. Você sempre pode excluir seu objetivo nas configurações do aplicativo.`,
    goalCreatedTitle: 'Objetivo Criado',
    goalCreatedDescription:
      'Parabéns! Você criou com sucesso um novo objetivo. Veja os detalhes e o histórico do seu objetivo nesta página.',
  },
  goalAnalyze: {
    title: 'Trabalhando no seu plano.',
    subtitle:
      'Nossos algoritmos de investimento estão processando os detalhes do seu objetivo para criar um plano de investimento exclusivo para você.',
  },
  substituteEtf: {
    title: 'Substituir ETF',
    description:
      'Você pode exercer sua capacidade de impor restrições razoáveis ao portfólio do seu objetivo substituindo um ETF por outro no mesmo setor.\n\nVocê pode substituir um ETF ou reverter a substituição a qualquer momento.',
    substituteMsg: 'A seleção atual:',
    alternativeMsg: 'Pode ser substituída por:',
    subDescription: 'A alocação alvo para este título é **{{ target }}%**.',
    yesSubstitute: 'Sim, substituir',
    pendingTitle: 'ETF substituto selecionado',
    pendingDescription: 'As negociações estão atualmente em andamento.',
    extraText: '',
    etfTab: {
      sectors: 'Setores',
      top10Holdings: 'Top 10 Ativos',
      footerDisclosure:
        'Antes de investir, considere cuidadosamente os objetivos de investimento, riscos, encargos e despesas de um fundo. Você pode encontrar essas e outras informações no [prospecto do fundo.]({{fundProspectusUrl}})',
    },
  },
  settings: {
    investorProfile: {
      title: `Perfil do investidor (${FinTronConfig.clearingFirm})`,
      information: 'Informações Pessoais',
      exposure: 'Exposição',
      footer:
        'As informações do perfil do investidor são exigidas pela Apex Clearing Co. para abrir e manter sua conta de investimento. Não está relacionado e pode conflitar com as informações que você fornece para seus Objetivos Franklin Templeton.',
    },
    investingQuestions: {
      title: 'Questões de Investimento',
      editInvestmentQuestions: 'Editar questionário de perfil do investidor.',
      investorProfileInformation: 'Informações do perfil de investimento',
      riskProfile: 'Perfil de risco',
      investing: 'Investimento',
      personal: 'Pessoais',
      exposure: 'Exposição',
      investmentExperience: 'Experiência com investimentos',
      investmentObjective: 'Objetivo de investimento',
      investmentTimeHorizon: 'Horizonte de investimento',
      liquidityNeeds: 'Importância de ter dinheiro em mãos',
      maritalStatus: 'Estado civil',
      employmentStatus: 'Status de emprego',
      annualIncome: 'Renda anual',
      netWorth: 'Patrimônio líquido',
      controlPerson: 'Pessoa Controladora',
      politicallyExposed: 'Politicamente Exposta',
      finraStockExchangeMember: 'Membro FINRA/Bolsa de Valores',
      securitiesProfessional: 'Profissional de Valores Mobiliários',
    },
    controlPerson: {
      title: 'Você é um controlador?',
      subtitle: 'Se não tiver certeza, provavelmente não se aplica.',
      tooltip: 'Eu sou uma pessoa controladora.',
    },
  },
  cancelWithdrawal: {
    title: 'Cancelar retirada',
    description:
      'Tem certeza de que deseja cancelar esta retirada?\n\nIsso pode afetar a probabilidade do seu objetivo ou as alocações do seu portfólio, ou ambos.',
    tax: 'A possível realocação desencadeada por esta mudança pode ter implicações fiscais.',
    yes: 'Sim, cancelar retirada',
    no: 'Não, voltar',
  },
  cancelInvestment: {
    title: 'Cancelar próximo investimento',
    description:
      'Tem certeza de que deseja cancelar seu próximo investimento automatizado?\n\nIsso pode afetar a probabilidade do seu objetivo ou as alocações do seu portfólio, ou ambos.',
    nextInfusion:
      'A possível realocação desencadeada por esta mudança pode ter implicações fiscais.\n\nIsso cancelará apenas seu próximo investimento programado. Todos os investimentos seguintes serão concluídos normalmente.\n\nSeus investimentos seriam retomados em {{ date }}.',
    yes: 'Sim, cancelar próximo investimento',
    no: 'Não, voltar',
  },
};
