import type { SupplementalQuestionsOnboardingData } from '@/api/getOnboardingData';

export type InitialAnswersData<
  T extends keyof SupplementalQuestionsOnboardingData['answers'],
  V = string,
> = Record<keyof NonNullable<SupplementalQuestionsOnboardingData['answers'][T]>, V | undefined>;

export type OutputAnswersData<T extends keyof SupplementalQuestionsOnboardingData['answers']> =
  Record<keyof NonNullable<SupplementalQuestionsOnboardingData['answers'][T]>, boolean>;

export const shouldDisplaySupplementalQuestions = (
  data: SupplementalQuestionsOnboardingData,
): boolean => {
  const {
    hasSecurityProfessionalDataStored,
    hasMarketDataStored,
    hasRothIRAAccount,
    hasFilingStatusAndAGIDataStored,
    answers,
  } = data;

  const { isSecuritiesProfessional } = answers.onboarding ?? {};

  if (!hasSecurityProfessionalDataStored) return true;
  if (isSecuritiesProfessional && !hasMarketDataStored) return true;
  if (!hasRothIRAAccount) return false;
  if (!hasFilingStatusAndAGIDataStored) return true;

  return false;
};

export const getInitialStep = ({
  hasSecurityProfessionalDataStored,
  hasMarketDataStored,
  answers,
}: SupplementalQuestionsOnboardingData): number => {
  const { isSecuritiesProfessional } = answers.onboarding ?? {};

  if (!hasSecurityProfessionalDataStored) return 0;
  if (isSecuritiesProfessional && !hasMarketDataStored) return 1;
  return 2;
};

export const parseAnswersData = <
  T extends keyof SupplementalQuestionsOnboardingData['answers'],
  V = string,
>(
  values: SupplementalQuestionsOnboardingData['answers'][T],
) => {
  const parsed = Object.fromEntries(
    Object.entries(values ?? {}).map(([k, v]) => [k, String(v)]),
  ) as InitialAnswersData<T, V>;

  return parsed;
};

export const parseFormAnswersData = <
  T extends keyof SupplementalQuestionsOnboardingData['answers'],
>(
  values: Record<keyof SupplementalQuestionsOnboardingData['answers'][T], string>,
) => {
  const parsed = Object.fromEntries(
    Object.entries(values ?? {}).map(([k, v]) => [k, v === 'true' ? true : false]),
  );

  return parsed as OutputAnswersData<T>;
};

export const parseSingleFormAnswerData = (value: string | undefined): boolean | undefined => {
  if (!value) return undefined;
  return value === 'true' ? true : false;
};
