import React, { useLayoutEffect, useCallback, useState, useRef } from 'react';
import Image from 'next/image';
import { useSidePanel } from '@/context/SidePanelContext';
import { SheetContent, SheetFooter } from '@/components/common/Sheet';
import { WebBodyText12 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import Button from '@/components/common/Button/Button';
import useProfileService from '@/hooks/api/useProfileService';

// steps
import SecuritiesProfessionalStep from './parts/SecuritiesProfessionalStep';
import MarketDataQuestionsStep from './parts/MarketDataQuestionsStep';
import FilingStatusAndAGIStep from './parts/FilingStatusAndAGIStep';
import SupplementalQuestionsSuccess from './parts/SupplementalQuestionsSuccess';
import useTenantExternalResources from '@/hooks/config/useTenantExternalResources';
import { showToast } from '@/components/common/Toaster/Toaster';

import { parseAnswersData, parseSingleFormAnswerData } from './utils';
import BackIcon from '@/assets/icons/arrowLeftDarkGrey.svg';

import type { SupplementalQuestionsOnboardingData } from '@/api/getOnboardingData';
import type { OptionValue as MarketDataQuestionOptionValue } from './parts/MarketDataQuestionsStep';
import type { OptionValue as SecuritiesProfessionalQuestionOptionValue } from './parts/SecuritiesProfessionalStep';
import type { FilingStatusAndAGIAnswers } from './parts/FilingStatusAndAGIStep';

export type SupplementalQuestionStepProps = {
  setBlockNextStep: React.Dispatch<boolean>;
};

export type SupplementalQuestionSubmitHandler = () => boolean;

type Props = {
  data: SupplementalQuestionsOnboardingData;
  initialStep: number;
};

const TOTAL_STEPS = 4;

const SupplementalQuestionsSidePanel: React.FC<Props> = ({ data, initialStep }) => {
  const { hasRothIRAAccount, hasFilingStatusAndAGIDataStored } = data;
  const userProfileService = useProfileService();

  const [blockNextStep, setBlockNextStep] = useState<boolean>(true);

  const onboardingAnswersRef = useRef<
    ReturnType<typeof parseAnswersData<'onboarding', SecuritiesProfessionalQuestionOptionValue>>
  >(parseAnswersData(data.answers.onboarding));

  const marketDataAnswersRef = useRef<
    ReturnType<typeof parseAnswersData<'marketDataAnswers', MarketDataQuestionOptionValue>>
  >(parseAnswersData(data.answers.marketDataAnswers));

  const filigStatusAndAGIAnswersRef = useRef<FilingStatusAndAGIAnswers>();
  const submitHandlerRef = useRef<SupplementalQuestionSubmitHandler>(null);

  const {
    currentFlowStep: step,
    goTo,
    setShowBackLink,
    setShowCloseButton,
    closeSidePanel,
  } = useSidePanel();

  const { privacyPolicyLink } = useTenantExternalResources();

  const stepDependencies = { ref: submitHandlerRef, setBlockNextStep };

  useLayoutEffect(() => goTo(initialStep), []);

  useLayoutEffect(() => {
    setShowCloseButton(false);
    setShowBackLink(false);
  }, [step]);

  const onContinueHandler = useCallback(async () => {
    const submitHandler = submitHandlerRef.current;

    if (typeof submitHandler === 'function') {
      const canContinue: boolean = submitHandler();
      if (!canContinue) return;
    }

    setBlockNextStep(true);

    if (step + 1 === TOTAL_STEPS) {
      return closeSidePanel();
    }

    const isSecuritiesProfessional = parseSingleFormAnswerData(
      onboardingAnswersRef.current.isSecuritiesProfessional,
    );

    // Cases
    if (step === 0 && isSecuritiesProfessional) return goTo(1);
    if (step === 0 && !isSecuritiesProfessional && !hasRothIRAAccount) return goTo(3);
    if (step === 1 && hasRothIRAAccount && !hasFilingStatusAndAGIDataStored) return goTo(2);
    if (step === 1 && !hasRothIRAAccount) return goTo(3);
    if (step === 0 && !hasFilingStatusAndAGIDataStored) return goTo(2);

    try {
      //TODO: For demo purposes, we are only updating the tax filing info. We should update all the data in the future.
      await userProfileService.userServiceUpdateProfile({
        profileParameters: {
          taxFilingInfo: {
            filingStatus: filigStatusAndAGIAnswersRef.current?.filingStatus,
            adjustGrossIncome: Number(filigStatusAndAGIAnswersRef.current?.annualAGI),
          },
        },
      });

      goTo(3);
    } catch (error) {
      showToast({
        variant: 'error',
        message: 'An error occurred while saving the data. Please try again later.',
        showCloseButton: true,
      });
    }
  }, [goTo, step, closeSidePanel]);

  const onGoBackHandler = useCallback(() => {
    const isSecuritiesProfessional = parseSingleFormAnswerData(
      onboardingAnswersRef.current.isSecuritiesProfessional,
    );

    // Cases
    if (step === 2 && hasRothIRAAccount && !isSecuritiesProfessional) return goTo(0);
    if (step === 2 && hasRothIRAAccount && isSecuritiesProfessional) return goTo(1);

    // Default case
    goTo(0);
  }, [goTo, step]);

  return (
    <SheetContent
      className="flex flex-col justify-between overflow-hidden px-0 pb-40 pt-10"
      onInteractOutside={(e) => e.preventDefault()}
      onEscapeKeyDown={(e) => e.preventDefault()}
    >
      <div className="flex-1 overflow-auto px-6 pb-2">
        {/* Steps */}
        {step === 0 && (
          <SecuritiesProfessionalStep
            initialAnswersRef={onboardingAnswersRef}
            {...stepDependencies}
          />
        )}

        {step === 1 && (
          <MarketDataQuestionsStep initialAnswersRef={marketDataAnswersRef} {...stepDependencies} />
        )}

        {step === 2 && (
          <FilingStatusAndAGIStep
            initialAnswersRef={filigStatusAndAGIAnswersRef}
            {...stepDependencies}
          />
        )}

        {step === 3 && <SupplementalQuestionsSuccess setBlockNextStep={setBlockNextStep} />}
      </div>

      {/* Footer */}
      <SheetFooter className="absolute bottom-0 w-full bg-white pb-0">
        <div className="flex w-full flex-col space-y-5 px-6 pb-14 pt-6">
          <WebBodyText12.Regular>
            For more information, see our{' '}
            <a
              href={privacyPolicyLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy.
            </a>
          </WebBodyText12.Regular>

          <div className="flex w-full select-none items-center justify-between">
            {/* Go back button */}
            {/* Replace 0 with initialStep to prevent reverting to the initially displayed step. */}
            {step > 0 && step + 1 < TOTAL_STEPS ? (
              <Button
                className="bg-grey7 h-9 w-9 rounded-full p-0"
                variant="secondary"
                size="lg"
                onClick={onGoBackHandler}
              >
                <Image src={BackIcon} className="h-4 w-4" alt="" />
              </Button>
            ) : (
              <div /* empty div to not mess justify-between */ />
            )}

            {/* Continue button */}
            <Button size="lg" onClick={onContinueHandler} disabled={blockNextStep}>
              Continue
            </Button>
          </div>
        </div>
      </SheetFooter>
    </SheetContent>
  );
};

export default SupplementalQuestionsSidePanel;
