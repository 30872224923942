import { IconButton } from '@mui/material';
import React, { MouseEventHandler } from 'react';

// Trabian
import { useConfigStore } from '@/stores/config-store';

// FinTron SVGs
import SvgButtonClose from '@fintronners/react-ui/src/Images/autoIndexedImages/tintableSvgs/ButtonClose';

const PopupHeader: React.FC<{ onCloseButtonClick?: MouseEventHandler }> = (props) => {
  const { theme } = useConfigStore();

  return (
    <div className="mb-2">
      <IconButton
        className="bg-transparent hover:bg-transparent p-0"
        onClick={props.onCloseButtonClick}
      >
        <SvgButtonClose width={20} height={20} color={theme.colors.primary} />
      </IconButton>
    </div>
  );
};

export default PopupHeader;
