import { gql } from '@apollo/client';
import {
  COMMON_TRUSTED_CONTACT_UPDATE_REQUESTS_FRAGMENT,
  COMMON_USER_INVESTOR_INFORMATION_DISCRETIONARY_REQUEST_PARAMETERS_FRAGMENT,
  COMMON_USER_INVESTOR_INFORMATION_NON_DISCRETIONARY_REQUEST_PARAMETERS_FRAGMENT,
} from '../admin/common/fragments';

export const GET_LATEST_NON_DISCRETIONARY_UPDATE_REQUESTS = gql`
  query GetLatestNonDiscretionaryUpdateRequests {
    nonDiscretionaryUpdateRequests(first: 1, orderBy: { direction: DESC, field: create_time }) {
      edges {
        node {
          status
          createTime
          updateTime
        }
      }
    }
  }
`;

export const GET_LATEST_DISCRETIONARY_UPDATE_REQUESTS = gql`
  query GetLatestDiscretionaryUpdateRequests {
    discretionaryUpdateRequests(first: 1, orderBy: { direction: DESC, field: create_time }) {
      edges {
        node {
          status
          createTime
          updateTime
        }
      }
    }
  }
`;

export const GET_LATEST_PROFILE_UPDATE_REQUESTS = gql`
  query GetLatestProfileUpdateRequests {
    userProfileUpdateRequests(first: 1, orderBy: { direction: DESC, field: create_time }) {
      edges {
        node {
          status
          createTime
          updateTime
        }
      }
    }
  }
`;

export const GET_LATEST_NON_DISCRETIONARY_UPDATE_REQUESTS_DETAILS = gql`
  query GetLatestNonDiscretionaryUpdateRequestsDetails {
    nonDiscretionaryAccountKycs {
      edges {
        node {
          updateRequests {
            createTime
            status
            requestParameters {
              ...CommonUserInvestorInformationNonDiscretionaryRequestParametersDetails
            }
          }
        }
      }
    }
  }
  ${COMMON_USER_INVESTOR_INFORMATION_NON_DISCRETIONARY_REQUEST_PARAMETERS_FRAGMENT}
`;

export const GET_LATEST_DISCRETIONARY_UPDATE_REQUESTS_DETAILS = gql`
  query GetLatestDiscretionaryUpdateRequestsDetails {
    discretionaryAccountKycs {
      edges {
        node {
          updateRequests {
            createTime
            status
            requestParameters {
              ...CommonUserInvestorInformationDiscretionaryRequestParametersDetails
            }
          }
        }
      }
    }
  }
  ${COMMON_USER_INVESTOR_INFORMATION_DISCRETIONARY_REQUEST_PARAMETERS_FRAGMENT}
`;

export const GET_LATEST_TRUSTED_CONTACT_UPDATE_REQUESTS_DETAILS = gql`
  query GetLatestTrustedContactUpdateRequestsDetails {
    trustedContacts {
      edges {
        node {
          id
          id
          firstName
          lastName
          dateOfBirth
          email
          phoneNumber
          isActive
          __typename
        }
      }
    }
    trustedContactUpdateRequests {
      edges {
        node {
          id
          ...CommonTrustedContactUpdateRequestsDetails
          __typename
        }
      }
    }
  }
  ${COMMON_TRUSTED_CONTACT_UPDATE_REQUESTS_FRAGMENT}
`;
