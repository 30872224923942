export default {
  loader: {
    title: 'Trabalhando no seu plano.',
    subtitle:
      'Nossos algoritmos de investimento estão processando suas informações para criar um plano de investimento exclusivo para você.',
  },
  rtqConfirmationTrigger: {
    label: 'Toque aqui para fazer modificações nas suas perguntas de investidor.',
  },
  rtqConfirmation: {
    title: 'Questionário de risco de tolerância',
    subtitle: `Mudar as respostas dessas perguntas pode impactar seu portfólio alvo.`,
  },
};
