import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import React from 'react';
import { ApolloProvider } from '@apollo/client';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        achTransfers: {
          merge: true,
        },
        trades: {
          merge: true,
        },
        securityAssets: {
          merge: true,
        },
      },
    },
    AssetMarketData: {
      merge: false,
    },
  },
});

// declared separately here to set a distinct link on the client
export const apolloClient = new ApolloClient({
  name: 'fintron-web',
  link: new HttpLink({ uri: '/api/graphql' }),
  cache,
  defaultOptions: {
    // Disabling caching for now until we start learning how to
    // gql properly and the need for optmization arises
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

export const FinTronGraphQlProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
