import {
  apexSharesShortFormat,
  rawThousandFormatNumbro,
} from '@fintronners/react-utils/src/numberUtilsTSX';

export const getStopLossTradeableShares = (
  amount: string,
  tradableUnits: number,
  stopLoss: string,
) => {
  if (amount === rawThousandFormatNumbro(tradableUnits * parseFloat(stopLoss)).toString()) {
    return apexSharesShortFormat(tradableUnits);
  } else {
    return apexSharesShortFormat(parseFloat(amount) / parseFloat(stopLoss));
  }
};
