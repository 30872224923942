import i18next from 'i18next';
import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';
import en from './en';
import pt from './pt';

const i18nextInstance = i18next.createInstance({
  compatibilityJSON: 'v3',
  fallbackLng: 'en',
  lng: FinTronConfig.language,
  debug: true,
  resources: {
    en: {
      translation: en,
    },
    pt: {
      translation: pt,
    },
  },
  interpolation: {
    escapeValue: false,
  },
  defaultNS: 'translation',
});

/**
 * Initialize i18next with the language set in FinTronConfig
 */
const initI18Next = () => {
  if (!i18nextInstance.isInitialized) {
    i18nextInstance.init();
  }
  return i18nextInstance;
};

const initializedI18Next = initI18Next();
export default initializedI18Next;
