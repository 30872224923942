export const commonDisplayStrings = {
  cancel: 'Cancel',
  warning: 'Warning',
  pendingTxt:
    '*New values have been submitted but aren’t yet confirmed, you may still continue to submit additional changes.',
  genericErrorMessage: (supportEmail: string) =>
    `We couldn’t process your request at this time. Please check your internet connection and try again. If this issue persists, please contact support at ${supportEmail}.`,
};

// todo when we have the language package separated
// export const commonDisplayStrings = {
//   cancel: i18n.t('common.cancel'),
//   warning: i18n.t('common.warning'),
//   pendingTxt: i18n.t('settingsApexClearingPrograms.pendingChangesDesc'),
//   genericErrorMessage: (supportEmail: string) => i18n.t('errors.genericError', { supportEmail }),
// };
