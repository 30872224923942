import { AxiosInstance } from 'axios';
import { Configuration } from './tsoai';
import { BaseAPI } from './tsoai/base';

/**
 * Singleton class for the gRPC client.
 *
 * It should only have one instance of axios and each service.
 * Services are created when invoked and should only have one instance
 * of each service.
 *
 * Usage example:
 *
 * GRPCApi.getService(PingServiceApi)
 *   .pingServicePing({})
 *   .then((response) => {
 *     console.log('response: ' + JSON.stringify(response));
 *   })
 *   .catch((error) => {
 *     console.log('error: ' + JSON.stringify(error));
 *   });
 */
class GRPCApi {
  axiosInstance: AxiosInstance | undefined;

  private services: Record<string, BaseAPI> = {};

  setAxiosInstance(axios: AxiosInstance) {
    this.axiosInstance = axios;
  }

  /**
   * Gets a service instance and stores it.
   */
  getService<T extends BaseAPI>(
    ServiceApi: new (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) => T,
  ): T {
    const key = ServiceApi.name;

    if (!this.services[key]) {
      this.services[key] = new ServiceApi(
        new Configuration(),
        process.env.NEW_API_URL,
        this.axiosInstance,
      );
    }

    return this.services[key] as T;
  }

  /**
   * Gets a auth service instance and stores it.
   */
  getAuthService<T extends BaseAPI>(
    ServiceApi: new (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) => T,
  ): T {
    const key = ServiceApi.name;

    if (!this.services[key]) {
      this.services[key] = new ServiceApi(
        new Configuration(),
        process.env.AUTH_API_URL,
        this.axiosInstance,
      );
    }

    return this.services[key] as T;
  }
}

export default new GRPCApi();
