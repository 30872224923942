import React, { useEffect } from 'react';
import Image from 'next/image';
import { WebHeading24, WebBodyText16 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import SuccessIcon from '@/assets/icons/successCircleDarkGrey.svg';
import type { SupplementalQuestionStepProps } from '../SupplementalQuestionsSidePanel';

type SupplementalQuestionsSuccessProps = SupplementalQuestionStepProps;

const displayStrings: Record<string, string> = {
  title: 'Success!',
  description:
    'Your answer(s) have been submitted. You may now continue to view your Retirement account.',
};

const SupplementalQuestionsSuccess: React.FC<SupplementalQuestionsSuccessProps> = ({
  setBlockNextStep,
}) => {
  // Automatically unblock next step
  useEffect(() => setBlockNextStep(false), []);

  return (
    <div className="mt-20 flex flex-col items-center space-y-6 text-center">
      <Image src={SuccessIcon} className="h-[50px] w-[50px]" alt="" />
      <WebHeading24.Bold className="text-grey84">{displayStrings.title}</WebHeading24.Bold>
      <WebBodyText16.Regular className="text-grey84">
        {displayStrings.description}
      </WebBodyText16.Regular>
    </div>
  );
};

export default SupplementalQuestionsSuccess;
