import { WebBodyText18, WebCaption12 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import Image from 'next/image';
import React, { useState } from 'react';
import { InfoIcon, InfoPrimaryIcon } from '~assets/icons';
import { Button } from '~components/common/Button';
import useBreakpoint from '@/hooks/utils/useBreakpoint';
import useResponsiveDialog from '@/hooks/utils/useResponsiveDialog';
import { TradeSide } from '~components/common/SidePanels/TradeSidePanel/schema';

interface OrderTypeDialogProps {
  side: TradeSide;
}

const displayStrings = {
  title: 'Order types',
  marketBuy: 'Market buy:',
  marketSell: 'Market sell:',
  stopLoss: 'Stop loss:',
  limitBuy: 'Limit buy:',
  marketBuyDescription:
    'This order type is often used if you are looking to buy a stock immediately at the current market price.',
  limitBuyDescription:
    'This order type is often used if you would like to buy a security, but think the current price is too high. You can set a limit price at your desired price level, and if the stock price comes down to your set level, we’ll submit a market buy order.',
  marketSellDescription:
    'This order type is often used if you are looking to sell a stock immediately at the current market price. Market orders are generally filled immediately.',
  stopLossDescription:
    'This order type is often used if you are looking to sell a security around a certain price that is below the current market price. If the security drops below your stop price, a market sell order will be placed.',
  close: 'Close',
};

export const OrderTypeDialog = ({ side }: OrderTypeDialogProps) => {
  const { isBreakpoint } = useBreakpoint();
  const { ResponsiveDialog } = useResponsiveDialog();

  const [showModal, setShowModal] = useState(false);

  return (
    <ResponsiveDialog.Root open={showModal} onOpenChange={(isOpen) => setShowModal(isOpen)}>
      <ResponsiveDialog.Trigger asChild>
        <div className="flex gap-1 cursor-pointer w-24">
          <Image alt="Info icon" width={14} height={14} src={InfoIcon.src} />
          <WebCaption12.Regular className="text-grey41">
            {displayStrings.title}
          </WebCaption12.Regular>
        </div>
      </ResponsiveDialog.Trigger>
      <ResponsiveDialog.Content>
        <ResponsiveDialog.Header>
          <Image alt="Info icon" width={50} height={50} src={InfoPrimaryIcon.src} />
          <ResponsiveDialog.Title>{displayStrings.title}</ResponsiveDialog.Title>
        </ResponsiveDialog.Header>
        {side === TradeSide.BUY ? (
          <>
            <WebBodyText18.Regular className="text-grey72">
              <strong>{displayStrings.marketBuy}</strong> {displayStrings.marketBuyDescription}
            </WebBodyText18.Regular>
            <WebBodyText18.Regular className="text-grey72">
              <strong>{displayStrings.limitBuy}</strong> {displayStrings.limitBuyDescription}
            </WebBodyText18.Regular>
          </>
        ) : (
          <>
            <WebBodyText18.Regular className="text-grey72">
              <strong>{displayStrings.marketSell}</strong> {displayStrings.marketSellDescription}
            </WebBodyText18.Regular>
            <WebBodyText18.Regular className="text-grey72">
              <strong>{displayStrings.stopLoss}</strong> {displayStrings.stopLossDescription}
            </WebBodyText18.Regular>
          </>
        )}

        <ResponsiveDialog.Footer>
          <ResponsiveDialog.Close asChild>
            <Button className="w-full" variant={isBreakpoint('xs') ? 'ghost' : 'primary'}>
              {displayStrings.close}
            </Button>
          </ResponsiveDialog.Close>
        </ResponsiveDialog.Footer>
      </ResponsiveDialog.Content>
    </ResponsiveDialog.Root>
  );
};
