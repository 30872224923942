import { useState, useRef, useCallback, useEffect } from 'react';
import { BaseAPI } from '@fintronners/react-api/src/tsoai/base';
import fLogger from '@fintronners/react-utils/src/fLogger';
import { AxiosError } from 'axios';

type ApiFunctionType<ApiFunction extends (...args: any) => any> = (
  ...args: [...Parameters<ApiFunction>, { signal: AbortSignal }]
) => Promise<Awaited<ReturnType<ApiFunction>>>;

/**
 * Custom hook to handle API calls.
 * When a component is unmounted, the fetch is aborted.
 * Only one call is allowed at a time.
 *
 * @param apiFunctionInstanceAndFunction - A tuple containing:
 *   - ServiceApi: The service API instance. This is needed because it is the active
 *   instance of the service API.
 *   - ApiFunction: The function to call on the service API.
 * @param errorHandler The error handler function to handle errors such as
 * the useNetworkErrorHandler hook
 *
 * @returns A tuple containing:
 * makeApiCall - The function to call the API
 * isFetchingApi - A boolean to indicate if the API is currently being fetched
 *
 * example usage:
 *
 * const [checkPlaidRefresh, isCheckingPlaid] = useGrpcApi(
 *   [
 *     // The service API instance
 *     GRPCApi.getService(PlaidServiceApi),
 *     // The function to call on the service API. This is the whole function, not just the name
 *     // so that the type can be inferred.
 *     GRPCApi.getService(PlaidServiceApi).plaidServiceShouldRefresh,
 *   ],
 *   // The error handler function to handle errors such as the useNetworkErrorHandler hook
 *   networkErrorHandler,
 * );
 *
 * checkPlaidRefresh().then((response) => {}).catch((error) => {}).finally(() => {});
 */
const useGrpcApi = <ServiceApi extends BaseAPI, ApiFunction extends ApiFunctionType<ApiFunction>>(
  apiFunctionInstanceAndFunction: [ServiceApi, ApiFunction],
  errorHandler?: (error: Error) => void,
  delayAfterUnlockMs = 250,
) => {
  const [isFetchingApi, setIsFetchingApi] = useState<boolean>(false);
  const isLocked = useRef<boolean>(false);
  const abortController = useRef<AbortController | null>(null);
  const unlockTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const makeApiCall = useCallback(
    (...args: Parameters<ApiFunction>) => {
      const [serviceInstance, serviceFunction] = apiFunctionInstanceAndFunction;

      if (isLocked.current) {
        fLogger.warn(
          `Concurrent function=${serviceFunction.name} call detected. Only one call will be allowed at a time within ${delayAfterUnlockMs}ms.`,
        );
        return;
      }
      abortController.current = new AbortController();
      const { signal } = abortController.current;
      const options = { signal };

      isLocked.current = true;
      setIsFetchingApi(true);

      const asyncFunction: ApiFunctionType<ApiFunction> =
        // Since the incoming function is a tuple, we need to bind the function to the service instance.
        // Couldn't figure out a way to infer typing since it's unknown whether the function
        // exists on the service instance.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        serviceInstance[serviceFunction.name].bind(serviceInstance);

      if ([...args].length === asyncFunction.length) {
        // TODO: Since we are passing in the abort signal as the last parameter
        // we to make sure that any callers are not passing it in. In the case
        // it is required, we need to update the function signature to include
        // merge it with the signal. Otherwise this should cover almost all cases.
        fLogger.error(
          `Received too many arguments for useApiHelper for function=${serviceFunction.name}`,
        );
      }
      return asyncFunction(...[...args, options])
        .then((response) => {
          return response;
        })
        .catch((error: AxiosError<{ message?: string }> | Error) => {
          if (error.name === 'CanceledError') {
            fLogger.log('Fetch aborted');
          } else {
            const apiErrorObject: Record<string, unknown> = {
              url: undefined,
              serverErrorMessage: undefined,
            };
            if (error instanceof AxiosError) {
              apiErrorObject.url = error.config?.url;
              apiErrorObject.serverErrorMessage = error.response?.data?.message;
            }
            // TODO: Use Sentry when it's available
            errorHandler?.(error);
          }
        })
        .finally(() => {
          setIsFetchingApi(false);
          abortController.current = null;
          if (unlockTimeout.current) clearTimeout(unlockTimeout.current);
          unlockTimeout.current = setTimeout(() => {
            isLocked.current = false;
          }, delayAfterUnlockMs);
        });
    },
    [apiFunctionInstanceAndFunction, errorHandler],
  );

  useEffect(() => {
    return () => {
      abortController.current?.abort();
      if (unlockTimeout.current) clearTimeout(unlockTimeout.current);
    };
  }, []);

  return [makeApiCall, isFetchingApi] as const;
};

export default useGrpcApi;
