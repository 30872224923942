import { useEffect, useMemo, useState } from 'react';
import * as Dialog from '@/components/common/Dialog';
import * as Drawer from '@/components/common/Drawer';

type WindowSize = {
  width: number | undefined;
  height: number | undefined;
};

const useResponsiveDialog = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const currentDialogComponent = useMemo(() => {
    const windowWidth = windowSize.width;

    if (windowWidth && windowWidth < 640) {
      return {
        Root: Drawer.Drawer,
        Trigger: Drawer.DrawerTrigger,
        Close: Drawer.DrawerClose,
        Content: Drawer.DrawerContent,
        Header: Drawer.DrawerHeader,
        Footer: Drawer.DrawerFooter,
        Title: Drawer.DrawerTitle,
      };
    } else {
      return {
        Root: Dialog.Dialog,
        Close: Dialog.DialogClose,
        Trigger: Dialog.DialogTrigger,
        Content: Dialog.DialogContent,
        Header: Dialog.DialogHeader,
        Footer: Dialog.DialogFooter,
        Title: Dialog.DialogTitle,
      };
    }
  }, [windowSize]);

  return { ResponsiveDialog: currentDialogComponent };
};

export default useResponsiveDialog;
