import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { WebCaption12 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import useExternalAccounts, {
  AccountType,
  type UseExternalAccount,
} from '@fintronners/react-widgets/src/hooks/useExternalAccounts';

import { AddIcon, DeleteIcon, ExclamationBubble, RefreshBlue } from '@/assets/icons';
import { useSidePanel } from '@/context/SidePanelContext';
import usePlaidLinkSession from '@/hooks/plaid/usePlaidLinkSession';
import Skeleton from '@/components/common/Skeleton/Skeleton';
import { RefreshItemDialog } from './RefreshItemDialog';
import { showToast } from '@/components/common/Toaster/Toaster';
import useFeatureFlags from '@/hooks/config/useFeatureFlags';

export type AddActionHandler = () => void;
export type DeleteActionHandler = (externalAccount?: UseExternalAccount) => void;

type ACHAccountWidgetProps = {
  accountType: AccountType;
  loading?: boolean;
  setIsAccountValid?: (isValid: boolean) => void;
};

const ACHAccountWidget: React.FC<ACHAccountWidgetProps> = ({
  accountType,
  loading,
  setIsAccountValid,
}) => {
  const [showRefreshItemDialog, setShowRefreshItemDialog] = useState(false);
  const { openSidePanel, enableInteractionWithModal, activeSidePanel } = useSidePanel();

  const { tenants } = useFeatureFlags();
  const { isAlight } = tenants;

  const {
    firstExternalAccount,
    fetchingAccounts,
    relationshipId,
    refetch: refetchExternalAccounts,
    //FIXME: Remove tenant check when we the filters on the backend side
  } = useExternalAccounts(accountType, undefined, false, isAlight);

  /**
   * Add account (Link flow)
   */
  const { openPlaidFlow, isPlaidReady, plaidData, isCheckingPlaid, needsRefresh } =
    usePlaidLinkSession({
      relationshipId,
      externalAccountUid: firstExternalAccount?.id,
    });

  const isLoading = loading || fetchingAccounts || isCheckingPlaid;
  const hasAccount = Boolean(firstExternalAccount);

  useEffect(() => {
    setIsAccountValid?.(!needsRefresh);
  }, [needsRefresh, setIsAccountValid]);

  /**
   * Checks when plaid data changes.
   * When the user finish the Plaid's popup flow, the "accounts" array is populated
   */
  useEffect(() => {
    if (plaidData && plaidData?.accounts.length > 0) {
      openSidePanel(
        'AddACHAccount',
        {
          accounts: plaidData.accounts,
          publicToken: plaidData.publicToken,
          relationshipId,
          refetchExternalAccounts,
        },
        activeSidePanel === 'Transfer' ? 'Transfer' : undefined,
      );
    }
  }, [plaidData]);

  const onStartFlow: AddActionHandler = () => {
    if (!isPlaidReady) {
      return showToast({
        variant: 'error',
        message: 'Plaid is not ready. Please try again later.',
        showCloseButton: true,
      });
    }
    enableInteractionWithModal();
    openPlaidFlow();
  };

  /**
   * Delete account (Unlink flow)
   */
  const onDeleteButtonClick: DeleteActionHandler = (account) => {
    openSidePanel(
      'RemoveACHAccount',
      {
        account,
        refetchExternalAccounts,
      },
      activeSidePanel === 'Transfer' ? 'Transfer' : undefined,
    );
  };

  const handleShowRefreshItemDialog = () => {
    setShowRefreshItemDialog(true);
  };

  return (
    <>
      {/* No accounts linked: Show Add Button */}
      {!isLoading && !hasAccount && (
        <div className="flex h-[46px] w-full items-center justify-between rounded-lg border pl-6 pr-3 shadow peer-checked:border-primary">
          <WebCaption12.Regular className="text-primary">Add Bank Account</WebCaption12.Regular>

          <button type="button" onClick={onStartFlow}>
            <Image alt="Retirement icon" width={16} height={16} src={AddIcon.src} />
          </button>
        </div>
      )}

      {/* Accounts Linked: List Linked Accounts */}
      {!isLoading && hasAccount && (
        <>
          <div
            id={firstExternalAccount?.id}
            className="flex h-[46px] w-full items-center justify-between rounded-lg border pl-6 pr-3 shadow peer-checked:border-primary"
          >
            <WebCaption12.Regular className="text-grey72">
              Plaid {firstExternalAccount?.displayName}
            </WebCaption12.Regular>

            {needsRefresh ? (
              <button type="button" onClick={handleShowRefreshItemDialog}>
                <Image alt="Retirement icon" width={16} height={16} src={RefreshBlue.src} />
              </button>
            ) : (
              <button type="button" onClick={() => onDeleteButtonClick(firstExternalAccount)}>
                <Image alt="Retirement icon" width={16} height={16} src={DeleteIcon.src} />
              </button>
            )}
          </div>
          {needsRefresh && (
            <div className="flex items-center mt-1 gap-1">
              <Image
                src={ExclamationBubble.src}
                alt="Needs refresh warning"
                width={14}
                height={14}
              />
              <WebCaption12.Regular className="text-red">
                Please select this bank account to re-link it.
              </WebCaption12.Regular>
            </div>
          )}
        </>
      )}

      <RefreshItemDialog
        open={showRefreshItemDialog}
        setModalOpen={setShowRefreshItemDialog}
        onRelinkItem={onStartFlow}
      />

      {/* Loading Skeleton */}
      {isLoading && <Skeleton height={45} className="rounded-f1" />}
    </>
  );
};

export default ACHAccountWidget;
