import { Controller, useFormContext } from 'react-hook-form';
import { WebButton16 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { FrequencyDisplayStringMap } from '@fintronners/react-api/src';

import { Radio } from '@/components/common/Radio/Radio';
import { intervals } from '../../schema';
import { InlineDatepicker } from '~components/common/Datepicker/InlineDatepicker';

export const RecurringDepositIntervalOptions = () => {
  const { control } = useFormContext();

  return (
    <div>
      <ul className="flex gap-4">
        {intervals.map((interval) => (
          <li key={interval} className="w-full">
            <Controller
              control={control}
              name="interval"
              render={({ field: { value, onChange } }) => (
                <>
                  <Radio.Input
                    id={interval}
                    value={value}
                    onChange={() => onChange(interval)}
                    checked={interval === value}
                  />
                  <Radio.Label variant="secondary" htmlFor={interval}>
                    <WebButton16.Bold>{FrequencyDisplayStringMap[interval]}</WebButton16.Bold>
                  </Radio.Label>
                </>
              )}
            />
          </li>
        ))}
      </ul>
      <Controller
        control={control}
        name="selectedStartDate"
        render={({ field: { value, onChange } }) => (
          <InlineDatepicker
            onChange={(date) => onChange(date?.toISOString())}
            defaultValue={new Date(value)}
          />
        )}
      />
    </div>
  );
};
