import { thousandFormatNumbroCurrency } from '@fintronners/react-utils/src/numberUtilsTSX';
import { WebCaption10 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { ErrorMessage } from '@/components/common/Forms/feedback/ErrorMessage';

import { TransferCurrencyInput } from '../TransferCurrencyInput';
import { RecurringDepositIntervalOptions } from './RecurringDepositIntervalOptions';
import { InfoWithIcon } from '../InfoWithIcon';
import { Skeleton } from '~components/common/Skeleton';

const displayStrings = {
  exceedsDailyLimitError: (limit: string) => `Amount exceeds daily limit ${limit}`,
  instantDepositLimit: (limit: string) => `Instant Deposit Limit: ${limit}`,
  instantDepositTimeWindow: 'Same Day Instant Deposit Window: 9:00am - 1:30pm EST',
};

export type RecurringDepositFormProps = {
  isAmountOverDailyLimit: boolean;
  availableInstantDepositLimit: string;
  isLoading: boolean;
};

export const RecurringDepositForm = ({
  isAmountOverDailyLimit,
  availableInstantDepositLimit,
  isLoading,
}: RecurringDepositFormProps) => {
  return (
    <>
      <TransferCurrencyInput />
      {isLoading ? (
        <Skeleton className="h-4 w-full" />
      ) : (
        <InfoWithIcon
          message={displayStrings.instantDepositLimit(
            thousandFormatNumbroCurrency(availableInstantDepositLimit),
          )}
        />
      )}

      <WebCaption10.Regular className="text-grey55">
        {displayStrings.instantDepositTimeWindow}
      </WebCaption10.Regular>

      <RecurringDepositIntervalOptions />

      {isAmountOverDailyLimit ? (
        <ErrorMessage hasIcon>
          {displayStrings.exceedsDailyLimitError(
            thousandFormatNumbroCurrency(availableInstantDepositLimit),
          )}
        </ErrorMessage>
      ) : null}
    </>
  );
};
