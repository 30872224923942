import { RiskTolerance } from '@/assets/icons';
import {
  WebBodyText14,
  WebBodyText16,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import Image from 'next/image';

const displayStrings = {
  title: 'Risk tolerance',
  conservative: {
    pre: 'A',
    bold: 'conservative investor',
    post: 'values stability more than higher return and prefers less risk and downside potential.',
  },
  moderate: {
    pre: 'A',
    bold: 'moderate investor',
    post: 'balanced approach to growth of capital with preserving capital and accepting of some risk.',
  },
  aggressive: {
    pre: 'An',
    bold: 'aggressive investor',
    post: 'values higher return more than stability and is willing to accept greater risk and greater downside potential.',
  },
  bottom:
    'You will receive investment recommendations that align with your chosen risk profile in order to progress toward the full amount of your goal within the desired timeframe.',
};

export const Infograph = () => {
  return (
    <div className="shadow-40 border rounded-f1 text-center w-full px-2 pt-3 pb-5 flex flex-col items-center gap-6">
      <div className="flex flex-col gap-2 items-center">
        <Image src={RiskTolerance} alt="" width={36} height={36} />
        <WebBodyText16.Bold className="text-darkestBlue">Risk tolerance</WebBodyText16.Bold>
      </div>

      <div className="flex flex-col gap-4">
        <WebBodyText14.Regular className="text-darkestBlue">
          {displayStrings.conservative.pre}{' '}
          <WebBodyText14.Bold Component="span">
            {displayStrings.conservative.bold}
          </WebBodyText14.Bold>{' '}
          {displayStrings.conservative.post}
        </WebBodyText14.Regular>

        <WebBodyText14.Regular className="text-darkestBlue">
          {displayStrings.moderate.pre}{' '}
          <WebBodyText14.Bold Component="span">{displayStrings.moderate.bold}</WebBodyText14.Bold>{' '}
          {displayStrings.moderate.post}
        </WebBodyText14.Regular>

        <WebBodyText14.Regular className="text-darkestBlue">
          {displayStrings.aggressive.pre}{' '}
          <WebBodyText14.Bold Component="span">{displayStrings.aggressive.bold}</WebBodyText14.Bold>{' '}
          {displayStrings.aggressive.post}
        </WebBodyText14.Regular>
      </div>

      <div className="w-full h-[1px] bg-grey55" />

      <WebBodyText14.Regular>{displayStrings.bottom}</WebBodyText14.Regular>
    </div>
  );
};
