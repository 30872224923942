import { WebBodyText18 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import Image from 'next/image';
import React from 'react';
import { InfoPrimaryIcon } from '@/assets/icons';
import { Button } from '@/components/common/Button';
import useResponsiveDialog from '@/hooks/utils/useResponsiveDialog';

const displayStrings = {
  title: 'Update info',
  description: 'This information update will be applied to all opened accounts.',
  confirm: 'Yes, Update',
  cancel: 'Cancel',
};

interface ConfirmProfileUpdateDialogProps {
  open: boolean;
  setModalOpen: (open: boolean) => void;
  onConfirm?: () => void;
}

export const ConfirmProfileUpdateDialog = ({
  open,
  setModalOpen,
  onConfirm,
}: ConfirmProfileUpdateDialogProps) => {
  const { ResponsiveDialog } = useResponsiveDialog();

  return (
    <ResponsiveDialog.Root open={open} onOpenChange={(isOpen) => setModalOpen(isOpen)}>
      <ResponsiveDialog.Content>
        <ResponsiveDialog.Header>
          <Image alt="Info icon" width={50} height={50} src={InfoPrimaryIcon.src} />
          <ResponsiveDialog.Title>{displayStrings.title}</ResponsiveDialog.Title>
        </ResponsiveDialog.Header>
        <WebBodyText18.Regular className="text-center text-grey72">
          {displayStrings.description}
        </WebBodyText18.Regular>
        <ResponsiveDialog.Footer>
          <ResponsiveDialog.Close asChild>
            <div className="flex flex-col gap-5 w-full">
              <Button className="w-full" onClick={onConfirm}>
                {displayStrings.confirm}
              </Button>
              <Button variant="secondary" className="w-full">
                {displayStrings.cancel}
              </Button>
            </div>
          </ResponsiveDialog.Close>
        </ResponsiveDialog.Footer>
      </ResponsiveDialog.Content>
    </ResponsiveDialog.Root>
  );
};
