import { getOneOfEnumSchema } from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';
import { YES_OR_NO } from '@fintronners/react-api/src/utils/formUtils/schemas/types';
import * as yup from 'yup';

export const securitiesProfessionalSchema = yup.object({
  isSecuritiesProfessional: getOneOfEnumSchema(YES_OR_NO),
  isForPersonalUse: getOneOfEnumSchema(YES_OR_NO),
  isAdvisorOrConsultant: getOneOfEnumSchema(YES_OR_NO),
  isRegisteredWithSecOrCftc: getOneOfEnumSchema(YES_OR_NO),
  requiresSecOrCftcRegistration: getOneOfEnumSchema(YES_OR_NO),
  investForOther: getOneOfEnumSchema(YES_OR_NO),
  investForTheBenefitOfOther: getOneOfEnumSchema(YES_OR_NO),
  receivesDataFromExternalSource: getOneOfEnumSchema(YES_OR_NO),
  sharesProfit: getOneOfEnumSchema(YES_OR_NO),
  isRegisteredWithEntity: getOneOfEnumSchema(YES_OR_NO),
});

export type SecuritiesProfessionalFormData = yup.InferType<typeof securitiesProfessionalSchema>;
