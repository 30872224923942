/**
 * Returns a SVG image with the initials of the stock symbol
 */
const getDynamicStockSymbolAsData = (symbol: string) => {
  const initials = symbol.toUpperCase();
  const fontSize = ['80', '70', '50', '35', '28'][initials.length - 1];

  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="48" fill="white" />
      <text x="50%" y="50%" font-size="${fontSize ?? '28'}" font-weight="bold" text-anchor="middle" fill="black" dy=".3em">${initials}</text>
    </svg>
  `;
  return encodeURIComponent(svg);
};

export default getDynamicStockSymbolAsData;
