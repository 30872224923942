import React from 'react';
import { ThemeType } from '@/config/utils';

interface GlobalStylesProps {
  theme: ThemeType;
}

const GlobalStyles: React.FC<GlobalStylesProps> = ({ theme }) => (
  <style jsx global>{`
    :root {
      --primary-color: ${theme.colors.primary};
      --background-color: ${theme.colors.background};
      --primary-dark-color: ${theme.colors.primaryDark};
      --border-radius: ${theme.borderRadius}px;
      --font-regular: ${theme.fonts.regular};
      --font-medium: ${theme.fonts.medium ?? theme.fonts.bold};
      --font-bold: ${theme.fonts.bold};
    }
  `}</style>
);

export default GlobalStyles;
