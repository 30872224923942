import { gql } from '@apollo/client';

export const DIVIDEND_DISTRIBUTION_FRAGMENT = gql`
  fragment DividendDistributionFragment on DividendDistribution {
    id
    orderStatus
    orderType
    payDate
    amount
    createTime
    distributionAsset {
      symbol
    }
  }
`;

export const DIVIDEND_DISTRIBUTION_EDGE = gql`
  fragment DividendDistributionEdge on DividendDistributionEdge {
    node {
      ...DividendDistributionFragment
    }
  }
  ${DIVIDEND_DISTRIBUTION_FRAGMENT}
`;
