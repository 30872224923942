import { gql } from '@apollo/client';

export const ICT_TRANSFERS_FRAGMENT = gql`
  fragment IctTransfer on ICTTransfer {
    id
    externalAccount {
      id
      mask
      institution {
        name
        __typename
      }
      user {
        firstName
        lastName
        __typename
      }
      __typename
    }
    recurringRuleInstance {
      id
      rule {
        asset {
          symbol
          __typename
        }
        sector {
          name
          __typename
        }
        id
        __typename
      }
      __typename
    }
    isReturned
    instantDepositAmount
    orderType
    orderStatus
    status
    amount
    ictReturnFee: returnFee
    ictType: type
    ictTransferErrorCode: errorCode
    createTime
  }
`;

export const ICT_TRANSFERS_EDGE = gql`
  fragment IctTransferEdge on ICTTransferEdge {
    node {
      ...IctTransfer
    }
    cursor
  }
  ${ICT_TRANSFERS_FRAGMENT}
`;
