import { useState } from 'react';
import { FormValues } from '~components/forms/ContactForm/validationSchema';
import { UserData } from '~stores/user-store';

export const useContactForm = (
  onSuccess: () => void,
  onError: () => void,
  userData: Partial<UserData> | null,
) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: FormValues) => {
    setIsLoading(true);
    try {
      const response = await fetch('/api/contact-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          formData: data,
          userData,
        }),
      });

      if (!response.ok) {
        throw new Error('Form submission failed');
      }

      onSuccess();
    } catch (err) {
      onError();
    } finally {
      setIsLoading(false);
    }
  };

  return {
    onSubmit,
    isLoading,
  };
};
