import { gql } from '@apollo/client';

export const ROBO_INVESTMENT_ACTION_FRAGMENT = gql`
  fragment RoboInvestmentActionFragment on RoboInvestmentAction {
    id
    type
    status
    requestTime
    requestor
    goal {
      fundingAccount {
        mask
        institution {
          name
        }
      }
    }
    details {
      __typename
      ... on RoboTransferDetails {
        amount
        expectedSettlementDate
        expectedTradeDate
      }
      ... on RoboFeeDetails {
        type
        amount
      }
      ... on RoboDividendDetails {
        amount
      }
      ... on RoboInterestDetails {
        amount
        source
      }
    }
  }
`;

export const ROBO_INVESTMENT_ACTION_EDGE_FRAGMENT = gql`
  fragment RoboInvestmentActionEdgeFragment on RoboInvestmentActionEdge {
    node {
      ...RoboInvestmentActionFragment
    }
    cursor
  }
  ${ROBO_INVESTMENT_ACTION_FRAGMENT}
`;

export const GET_ROBO_GOAL_ACTIVITY_HISTORY = gql`
  query GetRoboGoalActivityHistory(
    $id: ID!
    $types: [RoboInvestmentActionRoboActivityType!]
    $startDate: Time
    $endDate: Time
    $recentOnly: Boolean!
  ) {
    recentPages: roboInvestmentActions(
      where: { hasGoalWith: { id: $id } }
      orderBy: { direction: DESC, field: create_time }
      first: 3
    ) {
      edges {
        cursor
        ...RoboInvestmentActionEdgeFragment
      }
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
    pages: roboInvestmentActions(
      where: {
        hasGoalWith: { id: $id }
        typeIn: $types
        createTimeGTE: $startDate
        createTimeLTE: $endDate
      }
      orderBy: { direction: DESC, field: create_time }
    ) @skip(if: $recentOnly) {
      edges {
        cursor
        ...RoboInvestmentActionEdgeFragment
      }
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
  ${ROBO_INVESTMENT_ACTION_EDGE_FRAGMENT}
`;

export const GET_ROBO_GOAL_FEES_DETAIL = gql`
  query GetGoalFeesDetail($startDate: Time!, $limit: Int) {
    roboGoals {
      edges {
        node {
          id
          name
          actionsAggregation(
            range: { start: $startDate, period: TIME_PERIOD_MONTH, limit: $limit }
          ) {
            timestamps
            fees
          }
        }
      }
    }
  }
`;

export const GET_GOAL_SUMMARY_CARD_WIDGET = gql`
  query GetGoalSummaryCardWidget($id: ID!, $startDate: Time!) {
    roboGoals(where: { id: $id }) {
      edges {
        node {
          id
          name
          totalFees
          totalDividends
          parameters {
            initialInvestment
            targetAmount
            infusionFreq
            infusionAmount
          }
          allocation {
            totalValue
          }
          fundingAccount {
            mask
            institution {
              name
            }
          }
          actionsAggregation(range: { start: $startDate, period: TIME_PERIOD_MONTH }) {
            timestamps
            fees
          }
          infusionRecurringRule {
            lifetimeAmount
          }
        }
      }
    }
  }
`;
