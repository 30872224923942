import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { WebBodyText18, WebCaption10 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import Image from 'next/image';
import React, { useCallback, useEffect, useState } from 'react';
import { MailIcon, PhoneIcon } from '@/assets/icons';
import { Button } from '@/components/common/Button';
import useResponsiveDialog from '@/hooks/utils/useResponsiveDialog';
import { Input } from '@/components/common/Input/Input';
import { useForm } from 'react-hook-form';
import RuntimeEnvGRPCAPI from '@/utils/RuntimeEnvGRPCAPI';
import { AuthServiceApi } from '@fintronners/react-api/src/tsoai';
import { useGrpcApiV1Config } from '@/hooks/api/useGrpcApiV1Config';
import { getSpecificErrorMessage } from '@fintronners/react-utils/src/errorUtils';

export enum MFAMethodsTypes {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

const displayStrings = {
  title: 'Two-factor authentication',
  description: (type: MFAMethodsTypes) => {
    const isEmail = type === MFAMethodsTypes.EMAIL;

    return `We just sent you a one-time two-factor authentication code. Enter the code below to confirm your ${
      isEmail ? 'email' : 'mobile phone number'
    }.`;
  },
  enterCode: 'Enter code',
  submit: 'Submit',
};
interface MFAVerifyCodeDialogProps {
  open: boolean;
  type: MFAMethodsTypes;
  email: string;
  phoneNumber: string;
  setModalOpen: (open: boolean) => void;
  onConfirm?: () => void;
}

const mfaCodeSchema = yup.object().shape({
  code: yup.string().required('Code is required'),
});

export const MFAVerifyCodeDialog = ({
  open,
  type,
  email,
  phoneNumber,
  setModalOpen,
  onConfirm,
}: MFAVerifyCodeDialogProps) => {
  const { ResponsiveDialog } = useResponsiveDialog();
  const { accessToken, authApiBaseUrl } = useGrpcApiV1Config();
  const [error, setError] = useState<string>('');
  const [isCodeSent, setIsCodeSent] = useState(false);

  const {
    handleSubmit,
    watch,
    register,
    formState: { isSubmitting, isValid },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(mfaCodeSchema),
  });

  const codeValue = watch('code');
  const canAutoSubmit = codeValue?.length === 6;

  const onSubmit = useCallback(
    async ({ code }: yup.InferType<typeof mfaCodeSchema>) => {
      setError('');

      try {
        const headers = { Authorization: `Bearer ${accessToken}` };
        const path = authApiBaseUrl || '';
        switch (type) {
          case MFAMethodsTypes.EMAIL:
            await RuntimeEnvGRPCAPI.getAuthService(AuthServiceApi, path).authServiceVerifyEmail(
              {
                code,
              },
              { headers },
            );
            break;
          case MFAMethodsTypes.SMS:
            await RuntimeEnvGRPCAPI.getAuthService(AuthServiceApi, path).authServiceVerifyPhone(
              {
                code,
              },
              { headers },
            );
            break;
          default:
            break;
        }

        onConfirm?.();
      } catch (err: any) {
        const errorMessage =
          getSpecificErrorMessage(err?.response?.data?.message) || 'Error occurred';
        setError(errorMessage);
      }
    },
    [type, onConfirm],
  );

  const onSendCode = async () => {
    const headers = { Authorization: `Bearer ${accessToken}` };
    const path = authApiBaseUrl || '';
    try {
      switch (type) {
        case MFAMethodsTypes.EMAIL:
          await RuntimeEnvGRPCAPI.getAuthService(AuthServiceApi, path).authServiceSendVerifyEmail(
            {
              email,
            },
            { headers },
          );
          break;
        case MFAMethodsTypes.SMS:
          await RuntimeEnvGRPCAPI.getAuthService(AuthServiceApi, path).authServiceSendVerifyPhone(
            {
              phoneNumber,
            },
            { headers },
          );
          break;
        default:
          break;
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const onResendCode = () => {
    setIsCodeSent(false);
  };

  useEffect(() => {
    if (open && !isCodeSent) {
      // onSendCode();
      setIsCodeSent(true);
    }
  }, [open, onSendCode]);

  useEffect(() => {
    if (canAutoSubmit) {
      handleSubmit(onSubmit)();
    }
  }, [canAutoSubmit]);

  return (
    <ResponsiveDialog.Root open={open} onOpenChange={(isOpen) => setModalOpen(isOpen)}>
      <ResponsiveDialog.Content>
        <ResponsiveDialog.Header>
          <Image
            alt="Info icon"
            width={50}
            height={50}
            src={type === MFAMethodsTypes.EMAIL ? MailIcon.src : PhoneIcon.src}
          />
          <ResponsiveDialog.Title>{displayStrings.title}</ResponsiveDialog.Title>
        </ResponsiveDialog.Header>
        <WebBodyText18.Regular className="text-center text-grey72">
          {displayStrings.description(type)}
        </WebBodyText18.Regular>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-52">
            <Input className="bg-grey6 text-center" {...register('code')} maxLength={6} />
          </div>

          {error && (
            <div className="text-center mt-2">
              <WebCaption10.Medium className="text-red">{error}</WebCaption10.Medium>
            </div>
          )}

          <div className="text-center mt-4">
            <WebCaption10.Regular className="text-grey72">
              Didn't receive the code?{' '}
              <span className="text-primary cursor-pointer" onClick={onResendCode}>
                Resend
              </span>
            </WebCaption10.Regular>
          </div>
          <ResponsiveDialog.Footer>
            <Button className="w-full" disabled={isSubmitting || !isValid} type="submit">
              {displayStrings.submit}
            </Button>
          </ResponsiveDialog.Footer>
        </form>
      </ResponsiveDialog.Content>
    </ResponsiveDialog.Root>
  );
};
