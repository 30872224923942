import { gql } from '@apollo/client';

export const ACH_TRANSFER = gql`
  fragment AchTransfer on ACHTransfer {
    id
    orderStatus
    externalAccount {
      id
      mask
      institution {
        name
      }
      user {
        firstName
        lastName
      }
    }
    recurringRuleInstance {
      id
      rule {
        asset {
          symbol
        }
        sector {
          name
        }
        id
      }
    }
    amount
    instantDepositAmount
    returnFee
    isReturned
    transferErrorCode: errorCode
    transferType: type
    reason
    transferStatus: status
    transferChangeLog: statusChangeLog {
      status
      eventTime
    }
    expectedSettlementDate
    lockup {
      clearDate
    }
    createTime
    updateTime
  }
`;
export const ACH_TRANSFER_EDGE = gql`
  fragment AchTransferEdge on ACHTransferEdge {
    node {
      ...AchTransfer
    }
    cursor
  }
  ${ACH_TRANSFER}
`;

export const GET_ACH_TRANSFER_BY_ID = gql`
  query GetACHTransferById($id: ID!) {
    node(id: $id) {
      ...AchTransfer
    }
  }
  ${ACH_TRANSFER}
`;

export const GET_DEPOSIT_INFO = gql`
  query GetDepositInfo($portfolioID: ID!) {
    userAccountPortfolios(where: { id: $portfolioID }) {
      edges {
        node {
          availableInstantDeposit
          availableDailyDeposit
        }
      }
    }
  }
`;
