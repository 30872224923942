import { useQuery } from '@apollo/client';
import {
  GET_LATEST_NON_DISCRETIONARY_UPDATE_REQUESTS_DETAILS,
  GetLatestNonDiscretionaryUpdateRequestsDetailsQuery,
} from '@fintronners/react-api';
import {
  InvestorInformationNonDiscretionaryFieldUpdateRequests,
  getInvestorInformationNonDiscretionaryUpdateRequests,
} from '@fintronners/react-api/src/utils/formUtils/schemas/pending/investorInformationNonDiscretionary';
import { useEffect, useState } from 'react';

/**
 * This hook is used to fetch a users non discretionary account update requests
 */
const useNonDiscretionaryUpdateRequests = () => {
  const { data, loading, refetch } = useQuery<GetLatestNonDiscretionaryUpdateRequestsDetailsQuery>(
    GET_LATEST_NON_DISCRETIONARY_UPDATE_REQUESTS_DETAILS,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [singleState, setSingleState] = useState<{
    nonDiscretionaryUpdateRequests: InvestorInformationNonDiscretionaryFieldUpdateRequests | [];
    refetchNonDiscretionaryUpdateRequests: () => void;
  }>({
    nonDiscretionaryUpdateRequests: [],
    refetchNonDiscretionaryUpdateRequests: refetch,
  });

  useEffect(() => {
    if (loading || !data) return;

    const account = data?.nonDiscretionaryAccountKycs.edges?.[0]?.node;

    const nonDiscretionaryUpdateRequests = getInvestorInformationNonDiscretionaryUpdateRequests(
      account?.updateRequests,
    );

    setSingleState({
      nonDiscretionaryUpdateRequests,
      refetchNonDiscretionaryUpdateRequests: refetch,
    });
  }, [loading, data]);

  return singleState;
};

export default useNonDiscretionaryUpdateRequests;
