import type { MapConfig } from './GoogleStaticMap';

const GOOGLE_STATIC_MAP_URL = 'https://maps.googleapis.com/maps/api/staticmap';
const API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;

export const getGoogleMapStaticURI = (
  location: string | null | undefined,
  config: MapConfig,
): string => {
  if (!location || !API_KEY) return '';

  const params = new URLSearchParams({
    center: location,
    zoom: config.zoom.toString(),
    scale: config.scale.toString(),
    size: `${config.width}x${config.height}`,
    mapType: config.mapType,
    format: config.format,
    key: API_KEY,
  });

  const url = new URL(GOOGLE_STATIC_MAP_URL);
  url.search = params.toString();

  return url.toString();
};
