import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';
import { V1GoalPriority } from '@fintronners/react-api/src/tsoai';

export default {
  roboHistory: {
    rebalanceContent: 'A portfolio rebalance brings your portfolio back to its target allocation.',
    reallocationContent: 'A re-allocation shifts your portfolio to a new target allocation.',
    dividendReceivedContent:
      'You have received a dividend. This will be intelligently reinvested with your next scheduled automated investment or if you decide to make an additional deposit.',
    amount: 'Amount',
    transferType: 'Transfer type',
    transferringFrom: 'Transferring from',
    transferringTo: 'Transferring to',
    transferMoneyTitle: 'Transfer money',
    frequency: 'Frequency',
    expectedTradeDate: 'Expected trade date',
    cancelInvestment: 'Cancel investment',
    cancelWithdrawal: 'Cancel withdrawal',
    roboInvestment: 'Robo investment',
    automatedRoboInvestment: 'Automated robo investment',
    cancelRequested: 'Cancel requested',
    failedRequested: 'Failed',
    withdrawalContent:
      'To make a withdrawal, we are required to sell securities in your account. We will always make sure to intelligently sell securities to keep your portfolio at the target allocation.',
    advisoryFeeContent: `See ${FinTronConfig.parentName} Client Relationship Summary for a description of the firm’s products and services, fees, and other important information as it relates to your account. Additionally, for more information regarding the management fee and other fees associated with the program, please refer to the ${FinTronConfig.robo.name}, Adviser Brochure (Form ADV Part 2A). Pricing is subject to change.`,
    total: 'Total',
    feeInfoTitle: 'Robo fees',
    feeInfoDescription: `${FinTronConfig.robo.name} robo charges an annual fee of 0.50%, which is billed monthly to your account. This means that the 0.50% annual fee is divided into 12 parts, so you are charged a fraction of the fee each month.
    For example, if you have an account with an average daily value of $1,000 in a month, your monthly fee would be 0.50% divided by 12 months, which is 0.0417%. This translates to a monthly fee of $0.42.
    ${FinTronConfig.robo.name} is committed to providing low-cost, personalized investment advice and support to all of our clients, regardless of the size of their portfolio.`,
    receivingFrom: 'Receiving from',
  },
  roboHistoryFilter: {
    filter: 'Filter',
    cancel: 'Cancel',
    filterByCategory: 'Filter by category',
    portfolioRebalance: 'Portfolio rebalance',
    portfolioReallocation: 'Portfolio re-allocation',
    automatedInvestment: 'Automated investment',
    investment: 'Investment',
    advisoryFee: 'Advisory fee',
    dividendReceived: 'Dividend received',
    dividendReinvested: 'Dividend reinvested',
    withdrawal: 'Withdrawal',
    canceled: 'Canceled',
    pending: 'Pending',
    completed: 'Completed',
  },
  roboFundSleeve: {
    title: 'Distribution',
    lastDistribution: 'Last distribution',
    pastDistribution: 'Past distributions',
    amount: 'Amount',
    date: 'Date',
    frequency: 'Frequency',
    viewMore: 'View More',
    numberOfHoldings: 'Number of holdings: ',
    numberOfHoldingsTruncated: 'Number of holdings',
    substitute: {
      description: 'Would you like to substitute this stock for an alternative?',
      buttonText: 'Substitute ETF',
    },
    screenTitle: `{{sleeveName}} holdings`,
    distributions: {
      lastDistribution: 'Last distribution',
      amount: 'Amount',
      date: 'Date',
      frequency: 'Frequency',
      frequencyOneTime: 'One-Time',
      frequencyAnnually: 'Annually',
      frequencyBiAnnually: 'Bi-annually',
      frequencyQuarterly: 'Quarterly',
      frequencyMonthly: 'Monthly',
      pastDistributions: 'Past distributions',
      viewMore: 'View More +',
      na: 'N/A',
    },
    generalData: {
      marketCap: 'Market cap',
      totalEnterpriseValue: 'Total enterprise value',
      revenue: 'Revenue',
      ebitda: 'EBITDA',
      ebitdaMargin: 'EBITDA margin',
      ebit: 'EBIT (operating income)',
      profitMargin: 'Profit margin',
      peRatio: 'PE Ratio',
      pegRatio: 'PEG Ratio',
      earningsPerShare: 'Earnings per share',
      bookValuePerShare: 'Book value per share',
      dividendTtm: 'Dividend TTM',
      dividendYield: 'Dividend yield',
      dividendExDate: 'Dividend ex-date',
      longTermDebt: 'Long term debt',
      quickRatio: 'Quick ratio',
      totalCash: 'Total cash',
    },
  },
  goalLanding: {
    title: 'Create A Goal',
    subtitle: '',
    bodyText1: '',
    infoLineTitle: '',
    bodyText2: '',
    infoLineBsmTitle: '',
    infoLineBsmDescription: '',
    boxSectionTitle1: 'Why Create A Goal?',
    boxSectionBody1:
      'You can create a custom goal for whatever future event or purchase you have in mind. Maybe you are planning a wedding, going to graduate school, or want to go on vacation - creating a Goal is the perfect choice.',
    boxSectionTitle2: 'Multiple Goals',
    boxSectionBody2:
      'You can set up multiple Future Event goals. If you have a few in mind, just finish creating this goal, then start the process again.',
    boxSectionTitle3: 'Dollar-Cost Averaging',
    boxSectionBody3:
      'To increase the probability of achieving your goal, it’s important to start with an initial investment and continue making regular, automated additional investments over time (dollar-cost averaging). The following steps will allow you to set your investing strategy.',
    footer: '',
  },
  nameGoalScreen: {
    goalName: 'Goal name',
    title: 'Choose a memorable name for your goal.',
    header: 'By when will you need to achieve your goal?',
    description:
      'Choose a date in the future that we can target. You can always change this later.',
    next: 'Next: tell us how you feel about {{ screen }}.',
    risk: 'risk',
    importance: 'importance',
  },
  goalImportance: {
    title: 'How important is this goal to you?',
    subtitle:
      'Click each option below to learn about goal priority and then select which option fits best.\n\nIs your goal a:',
    [V1GoalPriority.Dream]: 'Dream',
    [V1GoalPriority.Need]: 'Need',
    [V1GoalPriority.Want]: 'Want',
    [V1GoalPriority.Wish]: 'Wish',
    [V1GoalPriority.Unspecified]: '',
    priorityLevelDescription: {
      [V1GoalPriority.Dream]:
        'In alignment with your risk profile, our Advice Engine will target at least **50% probability** of achieving the full amount of your goal and make contribution recommendations to help you meet this probability target.',
      [V1GoalPriority.Need]:
        'In alignment with your risk profile, our Advice Engine will target at least **85% probability** of achieving the full amount of your goal and make contribution recommendations to help you meet this probability target.',
      [V1GoalPriority.Want]:
        'In alignment with your risk profile, our Advice Engine will target at least **70% probability** of achieving the full amount of your goal and make contribution recommendations to help you meet this probability target.',
      [V1GoalPriority.Wish]:
        'In alignment with your risk profile, our Advice Engine will target at least **60% probability** of achieving the full amount of your goal and make contribution recommendations to help you meet this probability target.',
    },
    selectPriorityLevel: 'Select your priority level for this goal.',
    choosingGoalPriority: 'Choosing a Goal Priority',
    choosingGoalPriorityDescription:
      'If you intend to be heavily reliant on this investment in the future, consider a **higher probability target (such as Need)**.\n\nIf you plan to prioritize other investments or expenditures before contributing to this goal, consider a **lower probability target (such as Dream)**.',
  },
  goalTarget: {
    title: 'What is your goal target?',
    subtitle:
      'Enter the dollar amount you would like to achieve by the end of your goal’s timeframe, or select from one of the options below.',
    almostThere: 'Almost there! Only a few more steps to calculate your goal probability.',
  },
  addNowInvestment: {
    title: 'Enter your initial investment amount.',
    subTitle: 'How much do you plan to invest today towards your goal?',
    repeatHeader: '{{ amount }} Deposit Minimum',
  },
  addRepeatInvestment: {
    title: 'Plan your ongoing contributions.',
    subtitle:
      'Increase the likelihood of reaching your goal by setting up automatic investments at your desired frequency.',
    frequency: 'Frequency',
  },
  goalProbability: {
    probability: 'Probability',
    probabilityHeader: 'Goal probability:',
    probabilityBsmDescription:
      'The probability percentage shown is a hypothetical projection based upon statistical modeling regarding the likelihood of an investment outcome. It does not reflect actual investment results, nor does it attempt to portray the future performance of the investments in your recommended portfolio. It is not a guarantee of future results or future value of your goal, nor that you will achieve the full amount of your goal. There is no guarantee of the success rate of your simulated outcome.',
    popUpTitle: 'Low probability warning',
    popUpDescription:
      'Based on your current inputs, you have a low probability of achieving the full amount of your goal. Please consider decreasing your goal target amount, extending your target date, or increasing your initial investment amount or automated investment amount.',
    editValues: 'Edit values',
    confirmRiskToleranceBsmTitle: 'Changing your investment risk profile',
    confirmRiskToleranceBsmDescription:
      'Are you sure you want to update your\nrisk profile?\n\nThis may change your goal probability and allocations at your next scheduled re-allocation.',
    confirmGoalPriorityBsmTitle: 'Update your goal values',
    confirmGoalLowPriorityBsmTitle: 'Low probability warning',
    confirmGoalPriorityBsmDescription:
      'Are you sure you want to update your goal values?\n\nThis may affect your goal probability or your portfolio allocations, or both.',
    confirmGoalLowPriorityBsmDescription:
      'This update to your goal values will cause your Goal Probability to be low. You may only save your goal value edits if the new probability is above {{ percentage }}.',
    bottomDesc: 'The potential reallocation triggered by this change may have tax implications.',
    confirmGoalLowPriorityBsmFooter:
      'Based on your current inputs, you have a low probability of achieving the full amount within the desired timeframe. Please consider decreasing your goal target amount, extending your target date, or increasing your initial automated investment amount.\n\nA reallocation initiated by this change may have tax implications.',
    yesUpdate: 'Yes, continue',
    cancel: 'Cancel',
  },
  goalProjection: {
    heading: 'Projection',
    subhead: 'Your hypothetical goal projection',
    bsmTitle: 'Important information about your goal projection',
    bsmDescription:
      'The estimated value of the portfolio generated by this investment analysis tool, including an estimated increase or decrease in portfolio value projections represent probabilities and possible outcomes, not a guarantee of future performance. Any predictions generated regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual investment results, and are not a guarantee of future results.\n\nThese hypothetical projections do not reflect actual trading and therefore do not account for actual financial risks, economic conditions, taxes, fees or expenses.',
    goalEstimation:
      'Your goal has an estimated {{ probability }} probability of reaching the target amount of {{ target }} in {{ targetYear }} years.',
    riskLevelAndPriority: 'Risk level and goal priority',
    riskLevel: 'Risk level',
    goalPriority: 'Goal priority',
    editValuesLegal: 'You can edit these values in settings.',
    editValuesLegalNew: 'Return to previous pages in the goal creation flow to edit these values',
    goalDetails: 'Goal details',
    targetDate: 'Target date',
    initialInvestment: 'Initial investment',
    targetAmount: 'Target amount',
    automatedInvestments: 'Automated investments',
    frequency: 'Frequency',
    editDetails: 'Edit details',
    howToRead: 'How to read this chart and understand probability range',
    targetDateEstimate: ' ({{ years }} years & {{ months }} months)',
    targetProbability: 'Target probability',
    targetProbabilityBsm: `Target probability is a selection made by you when you created your goal. You may update your target probability anytime in Settings.\n\n**Choosing a Goal Priority**\nIf you intend to be heavily reliant on this investment in the future, consider a **higher probability target (such as a Need)**.\n\nIf you plan to prioritize other investments or expenditures before contributing to this goal, consider a **lower probability target (such as a Dream)**.\n\n**Need**\nIn alignment with your risk profile, our Advice Engine will target at least **85% probability** of achieving the full amount of your goal and make contribution recommendations to help you meet this probability target.\n\n**Want**\nIn alignment with your risk profile, our Advice Engine will target at least **70% probability** of achieving the full amount of your goal and make contribution recommendations to help you meet this probability target.\n\n**Wish**\nIn alignment with your risk profile, our Advice Engine will target at least **60% probability** of achieving the full amount of your goal and make contribution recommendations to help you meet this probability target.\n\n**Dream**\nIn alignment with your risk profile, our Advice Engine will target at least **50% probability** of achieving the full amount of your goal and make contribution recommendations to help you meet this probability target.`,
    increaseInitialInvestment: 'Increase initial investment',
    increaseAutomatedInvestment: 'Increase automated investments',
    learnMore: 'Learn more about this product',
    learnMoreTitle: 'Additional information about this investment analysis tool',
    learnMoreDescription: `The Goals Optimization Engine (“GOE”) uses a proprietary investment algorithm designed to best achieve a stated investment goal. GOE selects or recommends a portfolio (“GOE Portfolio”) out of several prospective portfolios (constructed by Franklin Advisers (“FAV”)) based on inputted data relating to a client’s risk tolerance, initial investment amount, target goal amount, investment time horizon, priority of goal, acceptable level of investment losses at goal tenure date, and expected future investment contributions and withdrawals, among other factors (“Data Inputs”).

A GOE Portfolio will generally consist of U.S. Registered Funds, and in some cases will favor and may consist entirely of Affiliated Funds (such as Franklin Templeton Funds). For information about Conflicts Related to Investment in Affiliated Funds and Affiliated Accounts, see the FAV Adviser Brochure (Form ADV Part 2A). In discretionary advisory relationships, GOE reassesses the progress of GOE Portfolios (in connection with the reassessment of FAV’s Capital Markets Expectations (“CMEs”), for the GOE algorithm) from time to time, at least annually or if a client makes a change to their risk profile or goal settings, and the GOE algorithm may identify and implement or recommend adjustments to the GOE Portfolio’s holdings or a different GOE Portfolio at those times.

GOE’s recommendations are limited by the client-related data inputs and portfolios constructed for recommendation. GOE’s recommendations – and the probability of goal success percentage displayed in this app – involve calculations that incorporate certain CMEs, (key assumptions generated by FAV), generated by FAV, which estimate the likelihood of returns and the volatility of various asset classes.

GOE recommendations generated at different times will vary. GOE recommends portfolios created by FAV; these portfolios include affiliated funds (such as Franklin Templeton Funds) as well as non-affiliated funds. GOE recommends pre-constructed portfolios based on Data Inputs in an effort to maximize the likelihood of achieving an investment goal. Investments that are not part of the portfolios may have characteristics similar, or superior to, the investments contained in the portfolios.“`,
    goeSuggestion:
      'Consider one of the following modifications to increase the probability of reaching the full amount of your goal of {{ amount }} from **{{ probability }}** to **{{ targetProbability }}:**',
    applyGoeSuggestionTitle: 'Apply recommendation',
    applyGoeSuggestionDescription:
      'Select ‘Continue’ to automatically apply the recommended modification to your goal inputs.',
    currentGoalValue: 'Current goal value',
    currentGoalValueDescription: 'Your goal’s value as of today.',
    recurringContributions: 'Recurring contributions',
    recurringContributionsDescription:
      'The sum of your remaining ongoing contributions (automated investments), assuming you will not withdraw any funds until your goal’s target date.',
    targetProbabilityDescription:
      'The estimated value of your goal associated with your Goal Priority selection. Read more about Goal Priority here.',
    probabilityRange: 'Probability range',
    probabilityRangeDescription:
      'A hypothetical projection representing the values over time that you are 90% likely to achieve (the lower boundary line) and 35% likely to achieve (the upper boundary line).',
    targetAmountDescription:
      'The goal amount you indicated you need as of your goal’s target date. Refer to your goal’s actual probability to understand the likelihood of reaching the full amount of your goal within the desired timeframe.',
    howToReadFooter:
      'The data shown on this chart is a hypothetical projection; it is not a guarantee of any outcomes.  These projections will change over time and/or with any modification to your goal.',
  },
  goalAgreement: {
    createGoalTitle: 'Create goal.',
    createGoalDescription: `By submitting this goal, ${FinTronConfig.robo.name} will initiate your initial deposit and your automated additional investments. You can always delete your goal in the app settings.`,
    goalCreatedTitle: 'Goal Created',
    goalCreatedDescription:
      'Congratulations! You have successfully created a new goal. View your goal details and history on this page.',
  },
  goalAnalyze: {
    title: 'Working on your plan.',
    subtitle:
      'Our investment algorithms are processing the details of your goal to create an investment plan just for you.',
  },
  substituteEtf: {
    title: 'Substitute ETF',
    description:
      'You may exercise your ability to impose reasonable restrictions on your goal’s portfolio by substituting one ETF for another in the same sector.\n\nYou may substitute an ETF or revert the substitution at any time.',
    substituteMsg: 'The current selection:',
    alternativeMsg: 'Can be replaced with:',
    subDescription: 'The target allocation for this security is **{{ target }}%**.',
    yesSubstitute: 'Yes, substitute',
    pendingTitle: 'Substitute ETF selected',
    pendingDescription: 'Trades are currently in progress.',
    extraText: '',
    etfTab: {
      sectors: 'Sectors',
      top10Holdings: 'Top 10 holdings',
      footerDisclosure:
        "Before investing, carefully consider a fund's investment objectives, risks, charges and expenses. You can find this and other information in the [fund prospectus.]({{fundProspectusUrl}})",
    },
  },
  settings: {
    investorProfile: {
      title: `Investor profile (${FinTronConfig.clearingFirm})`,
      information: 'Personal Information',
      exposure: 'Exposure',
      footer:
        'Investor profile information is required by Apex Clearing Co. to open and maintain your investment account. It is not related to and may conflict with the information you provide for your Franklin Templeton Goals.',
    },
    investingQuestions: {
      title: 'Investing questions',
      editInvestmentQuestions: 'Edit investment questions',
      investorProfileInformation: 'Investor profile information',
      riskProfile: 'Risk profile',
      investing: 'Investing',
      personal: 'Personal',
      exposure: 'Exposure',
      investmentExperience: 'Investment Experience',
      investmentObjective: 'Investment Objective',
      investmentTimeHorizon: 'Investment Time Horizon',
      liquidityNeeds: 'Importance of Cash on Hand',
      maritalStatus: 'Mariage Status',
      employmentStatus: 'Employment Status',
      annualIncome: 'Annual Income',
      netWorth: 'Net Worth',
      controlPerson: 'Control person',
      politicallyExposed: 'Politically exposed',
      finraStockExchangeMember: 'FINRA/Stock exchange member',
      securitiesProfessional: 'Securities professional',
    },
    controlPerson: {
      title: 'Are you a control person?',
      subtitle: 'If you aren’t sure, it likely doesn’t apply.',
      tooltip: 'I am a control person.',
    },
    account: {
      roboAccount: 'Robo account',
      accountHistory: 'Account history',
      documents: 'Documents',
      portfolios: 'Portfolios',
      closeAccountTitle: 'Your account has been closed',
      closeAccountDescription: `If you ever want to re-open this account, contact ${FinTronConfig.robo.supportEmail}.`,
      home: 'Home',
    },
  },
  cancelWithdrawal: {
    title: 'Cancel withdrawal',
    description:
      'Are you sure you want to cancel this withdrawal?\n\nThis may affect your goal probability or your portfolio allocations, or both.',
    tax: 'The potential reallocation triggered by this change may have tax implications.',
    yes: 'Yes, cancel withdrawal',
    no: 'No, go back',
  },
  cancelInvestment: {
    title: 'Cancel next investment',
    description:
      'Are you sure you want to cancel your next automated investment?\n\nThis may affect your goal probability or your portfolio allocations, or both.',
    nextInfusion:
      'The potential reallocation triggered by this change may have tax implications.\n\nThis will cancel only your next scheduled investment. Every investment following this will be completed normally.\n\nYour investments would resume on {{ date }}.',
    yes: 'Yes, cancel next investment',
    no: 'No, go back',
  },
  existingScreen: {
    allocation: 'Allocation',
    projection: 'Projection',
    investmentDetails: {
      title: 'Investment details',
      editDetails: 'Edit Details',
      automatedInvestments: 'Automated investments',
      frequency: 'Frequency',
    },
    progress: 'Progress',
    progressBsmTitle: 'Goal progress',
    progressBsmDescription:
      'Goal progress calculates your current goal value as a percentage of your target amount. It should not be confused with goal probability, which estimates the possibility of reaching the full amount of your goal within the desired timeframe. See goal probability for more information.',
    editValues: 'Edit values',
    lessons: 'Lessons',
    accountHistory: 'Account history',
    footer: `Advisory services are provided by ${FinTronConfig.robo.name}, and SEC registered investment advisor. Brokerage services offered to self-directed customers by ${FinTronConfig.selfDirected.name}. Cash and securities are custodied by ${FinTronConfig.clearingFirm}.`,
  },
};
