import { defaultCountries, parseCountry, type CountryData } from 'react-international-phone';

export const filterCountries = (allowedCountries: string[] | undefined): CountryData[] => {
  if (!allowedCountries?.length) return defaultCountries;

  return defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return allowedCountries.includes(iso2);
  });
};
