import { getOneOfEnumSchema } from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';
import { FORM_ERROR_MESSAGES } from '@fintronners/react-api/src/utils/formUtils/schemas/constants';
import { YES_OR_NO } from '@fintronners/react-api/src/utils/formUtils/schemas/types';
import * as yup from 'yup';

export const ControlPersonSchema = yup.object({
  isControlPerson: getOneOfEnumSchema(YES_OR_NO).required(),
  companySymbols: yup.array().when('isControlPerson', {
    is: YES_OR_NO.YES,
    then: (schema) =>
      schema
        .of(yup.object({ value: yup.string().required(FORM_ERROR_MESSAGES.required) }))
        .required(),
  }),
});

export type ControlPersonFormData = yup.InferType<typeof ControlPersonSchema>;
