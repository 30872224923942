import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { PlaidAccount } from 'react-plaid-link';

// FinTron
import fLogger from '@fintronners/react-utils/src/fLogger';
import isLoadingIcon from '@/assets/icons/loading.svg';
import { useSidePanel } from '@/context/SidePanelContext';
import { Button } from '@/components/common/Button';
import {
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from '@/components/common/Sheet';
import AccountSelectorPanel from '@/components/features/bankLink/AccountSelectorPanel';
import usePlaidApiService from '@/hooks/plaid/usePlaidApiService';
import { AddACHAccountSidePanelSuccess } from './AddACHAccountSidePanelSuccess';
import { AddACHAccountSidePanelError } from './AddACHAccountSidePanelError';

const displayStrings = {
  title: 'Bank accounts',
  description: 'Please select the account you wish to link.',
  cancel: 'Cancel',
  linkAccount: 'Link Account',
};

const AddACHAccountSidePanel: React.FC = () => {
  const { closeSidePanel, sidePanelProps, currentFlowStep, goTo } = useSidePanel();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [accounts, setAccounts] = useState<PlaidAccount[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);

  const plaidService = usePlaidApiService();

  useEffect(() => {
    if (sidePanelProps.accounts && sidePanelProps.accounts.length > 0) {
      setAccounts(sidePanelProps.accounts);
      setSelectedAccount(sidePanelProps.accounts[0].id);
    } else {
      goTo(2);
    }
  }, [sidePanelProps]);

  const linkAccountAction = async () => {
    setIsLoading(true);

    try {
      await plaidService.plaidServiceLinkAccount({
        accountId: selectedAccount || '',
        publicToken: sidePanelProps.publicToken,
        relationshipOwnerUid: sidePanelProps.relationshipOwnerUid,
      });
      setIsSaving(true);
      goTo(1);
    } catch (error) {
      fLogger.error('Error linking account', error);
      goTo(2);
    } finally {
      sidePanelProps.refetchExternalAccounts();
    }
  };

  const handleRefreshAccounts = async () => {
    await sidePanelProps.refetchExternalAccounts();
  };

  return (
    <>
      {currentFlowStep === 0 && (
        <SheetContent>
          <div className="h-full ">
            <SheetHeader>
              <SheetTitle>{displayStrings.title}</SheetTitle>
              <SheetDescription>{displayStrings.description}</SheetDescription>
            </SheetHeader>

            {accounts.length > 0 && (
              <AccountSelectorPanel
                className="mt-4 h-full"
                accounts={accounts}
                onValueChange={setSelectedAccount}
                loading={isLoading}
                isSaving={isSaving}
              />
            )}
          </div>

          <SheetFooter>
            <div className="w-full flex flex-col mt-8">
              {accounts.length > 0 && (
                <Button
                  type="button"
                  className="w-full mb-4"
                  onClick={linkAccountAction}
                  disabled={accounts.length === 0}
                >
                  {isLoading && (
                    <Image
                      src={isLoadingIcon}
                      className="mr-2 h-4 w-4 text-white animate-spin"
                      alt="isLoading"
                    />
                  )}
                  {displayStrings.linkAccount}
                </Button>
              )}

              <Button variant="secondary" type="button" onClick={closeSidePanel}>
                {displayStrings.cancel}
              </Button>
            </div>
          </SheetFooter>
        </SheetContent>
      )}

      {currentFlowStep === 1 && <AddACHAccountSidePanelSuccess />}
      {currentFlowStep === 2 && <AddACHAccountSidePanelError onClose={handleRefreshAccounts} />}
    </>
  );
};

export default AddACHAccountSidePanel;
