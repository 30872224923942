import { gql } from '@apollo/client';

export const GET_USER_SETTINGS = gql`
  query getUserSettings {
    userSettings {
      retirement {
        defaultWithholding {
          state
          federalWithholding {
            waive
            percentage
          }
          stateWithholding {
            waive
            percentage
          }
        }
      }
    }
  }
`;
