import { differenceInYears, differenceInMonths, differenceInDays } from 'date-fns';
import { WITHDRAWAL_MINIMUM_BANK_ACCOUNT_AGE, WITHDRAWAL_RMD_AGE } from './globalConstants';

/**
 * Returns boolean based on whether the age penalties message should be shown or not
 */
export const getShowAgePenalties = (dateOfBirth: string): boolean => {
  const userAgeInMonths = differenceInMonths(new Date(), new Date(dateOfBirth));
  const targetAgeInMonths = 59 * 12 + 6;
  return userAgeInMonths < targetAgeInMonths;
};

/**
 * Returns boolean based on whether the RMDs message should be shown or not
 */
export const getShowRmdWarning = (dateOfBirth: string): boolean => {
  const userAgeInYears = differenceInYears(new Date(), new Date(dateOfBirth));
  return userAgeInYears >= WITHDRAWAL_RMD_AGE;
};

/**
 * Returns boolean based on whether the bank account age message should be shown or not
 */
export const getShowBankAccountAgeRule = (accountCreateTime: string): boolean => {
  const bankAccountAge = differenceInDays(new Date(), new Date(accountCreateTime));

  return bankAccountAge <= WITHDRAWAL_MINIMUM_BANK_ACCOUNT_AGE;
};
