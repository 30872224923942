import React, { forwardRef, useImperativeHandle, useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { SheetHeader, SheetTitle } from '@/components/common/Sheet';
import SingleSelectQuestion, {
  type SingleSelectQuestionType,
} from '@/components/common/SingleSelectQuestion/SingleSelectQuestion';

import { useAppStore } from '@/stores/app-store';
import CurrencyInput from 'react-currency-input-field';
import InfoIcon from '@/assets/icons/info.svg';

import {
  WebBodyText14,
  WebBodyText16,
  WebBodyText20,
  WebHeading24,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import Button from '@/components/common/Button/Button';
import { HelpInfoDialog } from '@/components/common/HelpInfoDialog/HelpInfoDialog';

import questionsJson from '../questions/filingAndAGI.json';
import type { SupplementalQuestionsOnboardingData } from '@/api/getOnboardingData';
import type {
  SupplementalQuestionStepProps,
  SupplementalQuestionSubmitHandler,
} from '../SupplementalQuestionsSidePanel';
import type { MutableRefObject } from 'react';
import { V1UserFilingStatus } from '@fintronners/react-api/src';

type FilingStatusAndAGISimpleQuestion = {
  title: string;
  description: string;
  name: string;
  sentence: string;
};

type FilingStatusAndAGIQuestions = [
  SingleSelectQuestionType & FilingStatusAndAGISimpleQuestion,
  FilingStatusAndAGISimpleQuestion,
];

const displayStrings = {
  title: 'Filing status and annual adjusted gross income.',
  question: {
    info: 'Learn more',
  },
};

type FilingStatusAndAGIStepProps = SupplementalQuestionStepProps & {
  initialAnswersRef: MutableRefObject<FilingStatusAndAGIAnswers | undefined>;
};

type OptionValue = V1UserFilingStatus;

export type FilingStatusAndAGIAnswers = {
  filingStatus: OptionValue;
  annualAGI: string;
};

const questions = questionsJson as FilingStatusAndAGIQuestions;

export const dialogContent = {
  filingStatus: {
    title: 'Filing Status',
    content:
      'Your filing status can impact how much you can contribute to a Roth IRA.\n\n' +
      "If you're single, your contribution limit may start to decrease once your income reaches a certain level. Once it surpasses another threshold, you might not be eligible to contribute at all.\n\n" +
      'Couples filing jointly typically have a higher income threshold before their contribution limit starts to decrease. However, once their combined income exceeds a certain level, their ability to contribute may be reduced or eliminated.\n\n' +
      "If you're married but choose to file separately, your contribution limits might be more restricted. Even at lower income levels, you may face limitations or complete ineligibility for Roth IRA contributions.\n\n" +
      'Understanding your filing status and its impact on your Roth IRA contributions is essential for maximizing your retirement savings. Consult a tax advisor or financial expert for personalized guidance.',
  },
  annualAGI: {
    title: 'Annual adjusted gross income (AGI)',
    content:
      'Your annual adjusted gross income (AGI) influences your Roth IRA contribution limits\n\n' +
      'If your AGI falls below a certain threshold, you can typically contribute up to the maximum limit allowed for the tax year.\n\n' +
      'As your AGI increases, your contribution limit may start to decrease. Once your income surpasses a specific threshold, your ability to contribute to a Roth IRA might be reduced or eliminated.\n\n' +
      'Understanding how your AGI affects your Roth IRA contributions is crucial for planning your retirement savings strategy effectively. AGI thresholds can be impacted by your filing status. For personalized advice, consult a tax professional or financial advisor.',
  },
};

const FilingStatusAndAGIStep = forwardRef<
  SupplementalQuestionSubmitHandler,
  FilingStatusAndAGIStepProps
>(({ setBlockNextStep, initialAnswersRef }, ref) => {
  const [dialogConfig, setDialogConfig] = useState<{
    isOpen: boolean;
    title: string;
    content: string;
  }>({ isOpen: false, title: '', content: '' });

  const handleOpenDialog = (type: 'filingStatus' | 'annualAGI') => {
    setDialogConfig({
      isOpen: true,
      title: dialogContent[type].title,
      content: dialogContent[type].content,
    });
  };

  const { control } = useForm<FilingStatusAndAGIAnswers>({
    defaultValues: initialAnswersRef.current,
  });

  const responses = useWatch({ control });

  const userDetails = useAppStore((state) => state.userDetails);

  const submitHandler = useCallback<SupplementalQuestionSubmitHandler>(() => {
    if (!userDetails?.id) return false;

    let currentOnboardingStoredData = localStorage.getItem('onboardingStoredData');
    if (!currentOnboardingStoredData) currentOnboardingStoredData = '{}';

    const allOnboardingStoredData: Record<string, SupplementalQuestionsOnboardingData> = JSON.parse(
      currentOnboardingStoredData,
    );

    const userOnboardongStoredData = allOnboardingStoredData[userDetails.id];

    const newOnboardingStoredData = {
      ...allOnboardingStoredData,
      [userDetails.id]: {
        ...userOnboardongStoredData,
        hasFilingStatusAndAGIDataStored: true,
        answers: {
          ...(userOnboardongStoredData?.answers ?? {}),
          filingStatusAndAGI: responses,
        },
      },
    };

    localStorage.setItem('onboardingStoredData', JSON.stringify(newOnboardingStoredData));
    return true;
  }, [userDetails, responses]);

  // connect handler with parent
  useImperativeHandle(ref, () => submitHandler, [submitHandler]);

  useEffect(() => {
    // update responses to parent (local persitence)
    initialAnswersRef.current = responses as FilingStatusAndAGIAnswers;

    const allAnswered = questions.every(
      (question) => responses[question.name as keyof FilingStatusAndAGIAnswers],
    );

    const amountGratherThanZero =
      Number(responses[questions[1].name as keyof FilingStatusAndAGIAnswers]) > 0;

    if (allAnswered && amountGratherThanZero) setBlockNextStep(false);
    else setBlockNextStep(true);
  }, [responses]);

  return (
    <>
      <HelpInfoDialog
        open={dialogConfig.isOpen}
        setModalOpen={(isOpen) => setDialogConfig((prev) => ({ ...prev, isOpen }))}
        title={dialogConfig.title}
        content={dialogConfig.content}
      />
      <div className="flex flex-col space-y-6">
        {/* Step Header */}
        <div>
          <SheetHeader>
            <SheetTitle>
              <WebHeading24.Bold className="text-grey84">{displayStrings.title}</WebHeading24.Bold>
            </SheetTitle>
          </SheetHeader>
        </div>

        {/* Questions */}
        <div className="flex flex-col space-y-10">
          {/* Filing status */}
          <div className="flex flex-col space-y-2">
            <WebBodyText20.Bold className="text-grey84">{questions[0].title}</WebBodyText20.Bold>
            <WebBodyText16.Regular>{questions[0].description}</WebBodyText16.Regular>

            <Controller
              name={questions[0].name as keyof FilingStatusAndAGIAnswers}
              key={questions[0].name}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <SingleSelectQuestion
                  onClickInfo={() => handleOpenDialog('filingStatus')}
                  customHelpText={
                    <WebBodyText14.Regular className="text-grey41 text-left">
                      {questions[0].helpText}
                    </WebBodyText14.Regular>
                  }
                  {...questions[0]}
                  {...field}
                />
              )}
            />
          </div>

          {/* Annual AGI */}
          <div className="flex flex-col space-y-2">
            <WebBodyText20.Bold className="text-grey84">{questions[1].title}</WebBodyText20.Bold>
            <WebBodyText16.Regular>{questions[1].description}</WebBodyText16.Regular>

            <div>
              <Button
                variant="ghost"
                className="flex items-start space-x-1 px-0 font-normal border-0"
                onClick={() => handleOpenDialog('annualAGI')}
              >
                <Image src={InfoIcon} className="relative top-0.5 inline h-4 w-4" alt="" />
                <WebBodyText14.Regular className="text-grey41">
                  {displayStrings.question.info}
                </WebBodyText14.Regular>
              </Button>

              <div className="mt-2">
                <WebBodyText14.Regular className="text-grey72">
                  {questions[1].sentence}
                </WebBodyText14.Regular>

                <Controller
                  name={questions[1].name as keyof FilingStatusAndAGIAnswers}
                  key={questions[1].name}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, ...field } }) => (
                    <CurrencyInput
                      className="text-grey84 w-full text-[60px] outline-none"
                      prefix="$"
                      defaultValue={0}
                      decimalsLimit={2}
                      {...field}
                      intlConfig={{ locale: 'en-US' }}
                      onValueChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

FilingStatusAndAGIStep.displayName = 'FilingStatusAndAGIStep';
export default FilingStatusAndAGIStep;
