import { WebBodyText18, WebHeading28 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import React from 'react';
import Image from 'next/image';
import { InfoPrimaryIcon } from '@/assets/icons';
import { Button } from '@/components/common/Button';
import { SheetContent, SheetFooter, SheetTitle } from '~components/common/Sheet';
import { useSidePanel } from '~context/SidePanelContext';

interface ErrorSidePanelProps {
  onBack?: () => void;
}

export const ErrorSidePanel = ({ onBack }: ErrorSidePanelProps) => {
  const { closeSidePanel } = useSidePanel();

  return (
    <SheetContent>
      <SheetTitle className="sr-only">Something is wrong</SheetTitle>
      <div className="flex flex-col items-center gap-7 text-center mt-40">
        <Image alt="Success icon" width={50} height={50} src={InfoPrimaryIcon.src} />
        <WebHeading28.Bold className="text-darkestBlue">Sorry</WebHeading28.Bold>
        <WebBodyText18.Regular className="text-grey72">
          It looks like something is wrong. Please try again. If the problem continues, contact
          email@support.com.
        </WebBodyText18.Regular>
      </div>
      <SheetFooter className="text-center mb-5">
        {onBack ? (
          <Button className="w-full" onClick={onBack}>
            Back
          </Button>
        ) : (
          <Button className="w-full" onClick={closeSidePanel}>
            Close
          </Button>
        )}
      </SheetFooter>
    </SheetContent>
  );
};
