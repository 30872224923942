import { create } from 'zustand';
import { tenantConfigs } from '../config/tenant';
import { defaultTheme } from '../config/defaultTheme';
import { defaultConfig } from '../config/defaultConfig';

export interface ConfigStore {
  theme: {
    colors: {
      primary: string;
      primaryDark: string;
      background: string;
    };
    fonts: {
      regular?: string | null;
      medium?: string | null;
      bold?: string | null;
    };
    borderRadius: string;
  };
  config: {
    images: {
      lightBrandLogoL: Logo;
      darkBrandLogoL: Logo;
      colorBrandLogoL: Logo;
      darkBrandLogoS: Logo;
      mobileLogo?: Logo;
    };
  };
  setConfig: (tenant: string | null, config?: ConfigStore) => void;
}

interface Logo {
  logoRatio: number;
  uri: string;
}

export const useConfigStore = create<ConfigStore>((set) => ({
  theme: defaultTheme,
  config: defaultConfig,
  setConfig: (tenant: string | null, config?: ConfigStore) =>
    set(() => {
      const currentTenant = (tenant as keyof typeof tenantConfigs) ?? 'default';
      const tenantConfig = config ?? tenantConfigs[currentTenant] ?? tenantConfigs.default;

      return {
        theme: tenantConfig.theme,
        config: tenantConfig.config,
      };
    }),
}));
