import { gql } from '@apollo/client';
import { SECURITY_ASSET_PAGE } from './securitySearch';

export const GET_WATCHLIST_SECURITIES_QUERY = gql`
  query GetWatchlistSecurities {
    watchlists {
      name
      list {
        id
        type
        ...SecurityAssetPage
      }
    }
  }
  ${SECURITY_ASSET_PAGE}
`;
