import { create } from 'zustand';
import { UserProfile } from '@fintronners/react-api/src';

export type UserData = Pick<
  UserProfile,
  | 'firstName'
  | 'lastName'
  | 'phoneNumber'
  | 'streetAddress'
  | 'city'
  | 'state'
  | 'zipCode'
  | 'email'
>;

type UserStore = {
  userData: Partial<UserData> | null;
  setUserData: (data: Partial<UserData> | null) => void;
};

export const useUserStore = create<UserStore>((set) => ({
  userData: null,
  setUserData: (data) => set({ userData: data }),
}));
