import React, { createContext, useContext } from 'react';
import cn from '@/utils/tailwind';
import { formatPercent, calculatePercent } from '@fintronners/react-utils/src/numberUtilsTSX';
import { VariantProps, cva } from 'class-variance-authority';
import type { ClassValue } from 'clsx';

const Context = createContext<{ total?: number | string }>({});

type ProgressBarProps = React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> & {
  className?: ClassValue;
  total?: number | string;
  noBg?: boolean;
} & VariantProps<typeof progressBarVariants>;

const progressBarVariants = cva('my-2 flex w-full rounded-f0', {
  variants: {
    size: {
      default: 'h-2 gap-1',
      lg: 'h-3 gap-1',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

const ProgressBar = function ({
  children,
  className,
  total = 100,
  noBg = false,
  size,
}: ProgressBarProps) {
  return (
    <Context.Provider value={{ total }}>
      <div className={cn(progressBarVariants({ size }), { 'bg-grey6': !noBg }, className)}>
        {children}
      </div>
    </Context.Provider>
  );
};

type ProgressBarSegmentProps = React.HTMLAttributes<HTMLDivElement> & {
  className?: ClassValue;
  value?: number | string;
  fill?: boolean;
} & VariantProps<typeof progressBarSegmentVariants>;

const progressBarSegmentVariants = cva('rounded-f0', {
  variants: {
    variant: {
      primary: 'bg-primary',
      secondary: 'bg-purple',
      ghost: 'bg-grey6',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

const Segment = ({ className, value = 0, fill = false, variant }: ProgressBarSegmentProps) => {
  const { total } = useContext(Context);

  return (
    <div
      className={cn(
        progressBarSegmentVariants({ variant: fill && !variant ? 'ghost' : variant }),
        { 'flex-1': fill },
        className,
      )}
      style={{ width: formatPercent(calculatePercent(Number(value), Number(total))) }}
    />
  );
};

ProgressBar.Segment = Segment;
export default ProgressBar;
