import { WebBodyText18 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import Image from 'next/image';
import React, { useState } from 'react';
import { InfoIcon, InfoPrimaryIcon } from '~assets/icons';
import { Button } from '~components/common/Button';
import useBreakpoint from '@/hooks/utils/useBreakpoint';
import useResponsiveDialog from '@/hooks/utils/useResponsiveDialog';

const displayStrings = {
  title: 'Waive Withholding',
  description:
    'By waiving the Federal or State tax withholding, you are electing to be wholly responsible for any tax implications that result from withdrawals.',
  descriptionComp:
    'If you have any questions it is recommended you seek guidance from a competent tax advisor.',
  close: 'Close',
};

export const WaiveWithholdingDialog = () => {
  const { isBreakpoint } = useBreakpoint();
  const { ResponsiveDialog } = useResponsiveDialog();

  const [showModal, setShowModal] = useState(false);

  return (
    <ResponsiveDialog.Root open={showModal} onOpenChange={(isOpen) => setShowModal(isOpen)}>
      <ResponsiveDialog.Trigger>
        <Image alt="Info icon" width={14} height={14} src={InfoIcon.src} />
      </ResponsiveDialog.Trigger>
      <ResponsiveDialog.Content>
        <ResponsiveDialog.Header>
          <Image alt="Info icon" width={50} height={50} src={InfoPrimaryIcon.src} />
          <ResponsiveDialog.Title>{displayStrings.title}</ResponsiveDialog.Title>
        </ResponsiveDialog.Header>

        <WebBodyText18.Regular className="text-grey72 text-center">
          {displayStrings.description}
        </WebBodyText18.Regular>
        <WebBodyText18.Regular className="text-grey72 text-center">
          {displayStrings.descriptionComp}
        </WebBodyText18.Regular>

        <ResponsiveDialog.Footer>
          <ResponsiveDialog.Close asChild>
            <Button className="w-full" variant={isBreakpoint('xs') ? 'ghost' : 'primary'}>
              {displayStrings.close}
            </Button>
          </ResponsiveDialog.Close>
        </ResponsiveDialog.Footer>
      </ResponsiveDialog.Content>
    </ResponsiveDialog.Root>
  );
};
