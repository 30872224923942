import useUserAccount from '@/hooks/api/useUserAccount';
import { Commonpbv1Date, V1RecurringRuleInterval } from '@fintronners/react-api/src';
import { convertISODateStringToObject } from '@fintronners/react-api/src/utils/dateUtils';
import useRecurringRules from '@fintronners/react-widgets/src/hooks/useRecurringRules';
import { UseExternalAccount } from '@fintronners/react-widgets/src/hooks/useExternalAccounts';

import useTransferService from './useTransferService';
import useCurrentAccountType from '../utils/useCurrentAccountType';

type CreateRecuringDepositParams = {
  amount: string;
  recurringInterval?: V1RecurringRuleInterval;
  selectedDate?: string;
  account?: UseExternalAccount;
};

export const useRecurringTransfer = () => {
  const transferService = useTransferService();
  const accountType = useCurrentAccountType();
  const { userAccount } = useUserAccount(accountType);

  const { recurringRules } = useRecurringRules();

  const existingRule = recurringRules.at(0)?.node;
  const existingRuleId = existingRule?.id;

  const portfolioId = userAccount?.firstPortfolioId || '';

  const createRecurringDeposit = async ({
    amount,
    recurringInterval,
    selectedDate,
    account,
  }: CreateRecuringDepositParams) => {
    // if undefined the BE just uses the next available date
    const selectedV1Date: Commonpbv1Date | undefined = convertISODateStringToObject(selectedDate);

    const externalBankAccountId = account?.id;

    const commomData = {
      amount,
      interval: recurringInterval,
      fundingAccountUid: externalBankAccountId || '',
    };

    if (existingRuleId) {
      return await transferService.transferServiceUpdateRepeatTransfer(existingRuleId, {
        ...commomData,
        next: selectedV1Date,
      });
    }

    return await transferService.transferServiceCreateRepeatTransfer({
      ...commomData,
      start: selectedV1Date,
      portfolioUid: portfolioId,
    });
  };

  return {
    createRecurringDeposit,
  };
};
