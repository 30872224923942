import { useQuery } from '@apollo/client';
import { GetNbboQuoteQuery } from '@fintronners/react-api';
import { GET_NBBO_QUOTE } from '@fintronners/react-api/src/graphql/queries/nbboQuote';

interface useNbboQuoteDataProps {
  securityId: string;
  isBuy: boolean;
  skip?: boolean;
}

export const useNbboQuoteData = ({ securityId, isBuy, skip }: useNbboQuoteDataProps) => {
  return useQuery<GetNbboQuoteQuery>(GET_NBBO_QUOTE, {
    skip: skip || !securityId,
    fetchPolicy: 'no-cache',
    variables: {
      isBuy,
      securityId,
    },
  });
};
