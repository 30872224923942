import React from 'react';

interface SkeletonTableProps {
  columns: number;
  rows: number;
  cellHeight: number;
  rowPadding?: number;
  showHeader?: boolean;
  rowStyle?: React.HTMLAttributes<HTMLDivElement>['style'];
}

const SkeletonTable = ({
  columns,
  rows,
  cellHeight,
  rowPadding = 0,
  showHeader = false,
  rowStyle = {},
}: SkeletonTableProps) => {
  return (
    <div className="w-full" style={{ paddingTop: rowPadding }}>
      {/* Table header */}
      {showHeader && (
        <div className="flex">
          {Array(columns)
            .fill(null)
            .map((_, index) => (
              <div key={`header-${index}`} className="flex-1 p-4">
                <div className="h-4 animate-pulse rounded bg-gray-300" />
              </div>
            ))}
        </div>
      )}

      {/* Table body */}
      {Array(rows)
        .fill(null)
        .map((_, rowIndex) => (
          <div key={`row-${rowIndex}`} className="flex">
            {Array(columns)
              .fill(null)
              .map((__, cellIndex) => (
                <div
                  key={`cell-${rowIndex}-${cellIndex}`}
                  className="flex-1"
                  style={{ padding: rowPadding, ...rowStyle }}
                >
                  <div className="animate-pulse rounded bg-grey6" style={{ height: cellHeight }} />
                </div>
              ))}
          </div>
        ))}
    </div>
  );
};

export default SkeletonTable;
