import { useState, useLayoutEffect } from 'react';
import useBreakpoint from '@/hooks/utils/useBreakpoint';

export type Callback = (isMobile: boolean) => void;

/**
 * Returns true|false if the window size is mobile. Optionally receives a callback
 * which is called everytime the window size changes.
 */
const useIsMobile = (callback?: Callback): boolean => {
  const [isMobile, setIsMobile] = useState(false);
  const { isMobileBreakpoint } = useBreakpoint();

  // resize handler
  useLayoutEffect(() => {
    const windowResizeHandler = () => {
      const x = isMobileBreakpoint();
      setIsMobile(x);
      callback?.(x);
    };

    window.addEventListener('resize', windowResizeHandler);
    return () => window.removeEventListener('resize', windowResizeHandler);
  }, [callback]);

  // init
  useLayoutEffect(() => {
    const x = isMobileBreakpoint();
    setIsMobile(x);
    callback?.(x);
  }, [callback]);

  return isMobile;
};

export default useIsMobile;
