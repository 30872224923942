import { WebBodyText18, WebHeading28 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import React, { MouseEventHandler } from 'react';
import Image from 'next/image';
import { SuccessCircleIcon } from '~assets/icons';
import { Button } from '@/components/common/Button';
import { SheetContent, SheetFooter, SheetTitle } from '@/components/common/Sheet';

interface TradeSidePanelSuccessProps {
  onClose?: MouseEventHandler;
}

export const TradeSidePanelSuccess = ({ onClose }: TradeSidePanelSuccessProps) => {
  return (
    <SheetContent>
      <div className="flex flex-col items-center gap-7 text-center mt-20">
        <Image alt="Success icon" width={50} height={50} src={SuccessCircleIcon.src} />
        <SheetTitle>
          <WebHeading28.Bold className="text-darkestBlue">Congratulations!</WebHeading28.Bold>
        </SheetTitle>
        <WebBodyText18.Regular className="text-grey72">
          Check your activity history for updates about this transaction.
        </WebBodyText18.Regular>
      </div>
      <SheetFooter className="text-center mb-5">
        <Button className="w-full" onClick={onClose}>
          Done
        </Button>
      </SheetFooter>
    </SheetContent>
  );
};
