import React from 'react';
import Image from 'next/image';
import { Button } from '@/components/common/Button';
import { WebBodyText18 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import useResponsiveDialog from '@/hooks/utils/useResponsiveDialog';
import { InfoPrimaryIcon } from '@/assets/icons';

const displayStrings: Record<string, string> = {
  title: 'Withholding elections',
  description:
    'Since this is your first withdrawal, you have not yet specified any tax withholding choices. Please review and edit your withholding elections before proceeding.',
  confirm: 'Review withholding elections',
  cancel: 'Close',
};

interface FirstWithdrawDialogProps {
  open: boolean;
  setModalOpen: (open: boolean) => void;
  onReviewWithholdingElections?: () => void;
}

const FirstWithdrawDialog: React.FC<FirstWithdrawDialogProps> = ({
  open,
  setModalOpen,
  onReviewWithholdingElections,
}) => {
  const { ResponsiveDialog } = useResponsiveDialog();

  return (
    <ResponsiveDialog.Root open={open} onOpenChange={(isOpen) => setModalOpen(isOpen)}>
      <ResponsiveDialog.Content>
        <ResponsiveDialog.Header>
          <Image alt="Info icon" width={50} height={50} src={InfoPrimaryIcon.src} />
          <ResponsiveDialog.Title>{displayStrings.title}</ResponsiveDialog.Title>
        </ResponsiveDialog.Header>

        <WebBodyText18.Regular className="text-grey72 text-center">
          {displayStrings.description}
        </WebBodyText18.Regular>

        <ResponsiveDialog.Footer>
          <ResponsiveDialog.Close asChild>
            <div className="flex w-full flex-col gap-5">
              <Button className="w-full" onClick={onReviewWithholdingElections}>
                {displayStrings.confirm}
              </Button>
              <Button className="w-full">{displayStrings.cancel}</Button>
            </div>
          </ResponsiveDialog.Close>
        </ResponsiveDialog.Footer>
      </ResponsiveDialog.Content>
    </ResponsiveDialog.Root>
  );
};

export default FirstWithdrawDialog;
