import { TradeSide } from './schema';

export const MIN_SELL = 1;
export const MIN_BUY = 5;

export const getMinimumTradeAmount = (side: TradeSide) => {
  if (side === TradeSide.BUY) {
    return MIN_BUY;
  } else {
    return MIN_SELL;
  }
};
