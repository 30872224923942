import { CSSProperties, ReactNode, useState } from 'react';

export type TSubmenuEntry = {
  icon?: ReactNode;
  title: string;
  url: string;
  style?: CSSProperties;
  children: TSubmenuEntry[];
};

const useSubmenu = () => {
  const [entriesArray, setEntriesArray] = useState<Array<TSubmenuEntry[]>>([]);
  const [selectedTopLevelIndex, setSelectedTopLevelIndex] = useState<number>(-1);
  const [selectedPathIndices, setSelectedPathIndices] = useState<number[]>([]);

  const goBack = () => {
    if (selectedPathIndices.length > 0) {
      const tempIndexList = [...selectedPathIndices];
      tempIndexList.pop();
      setSelectedPathIndices(tempIndexList);
    }
  };

  return {
    entriesArray: entriesArray,
    setEntriesArray: setEntriesArray,
    selectedIndex: selectedTopLevelIndex,
    setSelectedIndex: setSelectedTopLevelIndex,
    selectedPathIndices: selectedPathIndices,
    setSelectedPathIndices: setSelectedPathIndices,
    goBack: goBack,
  };
};

export default useSubmenu;
