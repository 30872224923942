import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { useController, useFormContext } from 'react-hook-form';
import { RiskProfileSchemaType, riskTolerancesSurvey } from '../riskProfileSidePanel.utils';
import { RadioTag } from './RadioTag';

const displayStrings = {
  question: 'What is your risk tolerance?',
};

type RadioGroupProps = {
  pending?: boolean;
  loading?: boolean;
};

export const RadioGroup = ({ pending, loading }: RadioGroupProps) => {
  const { control } = useFormContext<RiskProfileSchemaType>();
  const {
    field: { ...field },
  } = useController({
    control,
    name: 'riskTolerance',
  });

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <WebBodyText14.Regular>{displayStrings.question}</WebBodyText14.Regular>
      </div>

      <div className="flex w-full flex-wrap gap-2">
        {riskTolerancesSurvey.answers.map((answer) => (
          <RadioTag
            key={answer.value}
            pending={pending}
            label={answer.label}
            loading={loading}
            {...field}
            checked={field.value === answer.value}
            value={answer.value}
          />
        ))}
      </div>
    </div>
  );
};
