import { useQuery } from '@apollo/client';
import { GET_DEPOSIT_INFO, GetDepositInfoQuery } from '@fintronners/react-api/src';
import useUserAccount from '@/hooks/api/useUserAccount';
import useCurrentAccountType from '../utils/useCurrentAccountType';

export const useDepositInfo = () => {
  const accountType = useCurrentAccountType();

  const { userAccount } = useUserAccount(accountType);

  const portfolioId = userAccount?.firstPortfolioId || '';

  const depositInfo = useQuery<GetDepositInfoQuery>(GET_DEPOSIT_INFO, {
    skip: !portfolioId,
    fetchPolicy: 'network-only',
    variables: {
      portfolioID: portfolioId,
    },
  });

  return depositInfo;
};
