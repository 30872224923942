import { gql } from '@apollo/client';
import { GetRoboGoalsScreenQuery } from '../types/graphql';
import { GET_USER_ROBO_GOAL_DETAIL_FRAGMENT } from './allAccounts';

/**
 * @deprecated Use a gql fragment instead
 */
export type QueryRoboGoal = NonNullable<
  NonNullable<GetRoboGoalsScreenQuery['roboGoals']['edges']>[number]
>['node'];
/**
 * @deprecated Use a gql fragment instead
 */
export type QueryRoboAllocation = NonNullable<QueryRoboGoal>['allocation'];

export const GET_ROBO_GOAL_PARAMETERS_FRAGMENT = gql`
  fragment RoboGoalParameters on RoboGoalParameters {
    endDate
    riskProfile
    targetAmount
    infusionAmount
    infusionFreq
    initialInvestment
    goalPriority
    infusionStartDate
  }
`;

export const GET_ROBO_GOAL_PROJECTION_FRAGMENT = gql`
  fragment RoboGoalProjection on RoboGoalProjection {
    goalProbability
    goalTargetProbability
    suggestedOneTimeTopUp
    suggestedMonthlyTopUpAccumulation
    wealthPath {
      years
      default {
        wealthPath
      }
      pessimistic {
        wealthPath
      }
      optimistic {
        wealthPath
      }
    }
  }
`;

export const GET_ROBO_GOALS_DATA = gql`
  query GetRoboGoalsScreen($goalUid: ID) {
    roboGoals(where: { id: $goalUid }) {
      edges {
        node {
          id
          name
          createTime
          nextScheduledInfusion
          allocation {
            fiatPct
            stockPct
            bondPct
            totalValue
            drift
          }
          projection {
            ...RoboGoalProjection
          }
          parameters {
            ...RoboGoalParameters
          }
          targetPortfolio {
            fiatPct
            stockPct
            bondPct
          }
        }
      }
    }
  }
  ${GET_ROBO_GOAL_PARAMETERS_FRAGMENT}
  ${GET_ROBO_GOAL_PROJECTION_FRAGMENT}
`;

export const GET_ROBO_ETF_DETAILS = gql`
  query GetRoboETFDetails($id: ID, $secId: ID!) {
    securityAssets(where: { id: $secId }) {
      edges {
        node {
          id
          name
          symbol
          description
          market {
            last
            previousDayClose
          }
          details {
            ... on SecurityETFDetails {
              netAssetValue
              yield30Day
              expenseRatio
            }
          }
        }
      }
    }
    roboGoals(where: { id: $id }) {
      edges {
        node {
          allocation {
            allocation(where: { asset: { id: $secId } }) {
              asset {
                id
              }
              percentage
            }
          }
          accountPortfolio {
            assetBalances {
              edges {
                node {
                  asset {
                    id
                  }
                  balances {
                    costBasis
                    share: balance
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ROBO_ETF_SECTORS = gql`
  query GetRoboETFSector($secId: ID!) {
    securityAssets(where: { id: $secId }) {
      edges {
        node {
          details {
            ... on SecurityETFDetails {
              sectorCount
              holdingsCount
              sectors {
                name
                percentage
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ROBO_ETF_HOLDINGS = gql`
  query GetRoboETFHolding($secId: ID!) {
    securityAssets(where: { id: $secId }) {
      edges {
        node {
          name
          details {
            ... on SecurityETFDetails {
              holdingsCount
              holdings {
                asset {
                  name
                  symbol
                }
                percentage
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ROBO_ETF_SUBSTITUTIONS = gql`
  query GetRoboETFSubstitution($id: ID!) {
    roboGoals(where: { id: $id }) {
      edges {
        node {
          targetPortfolio {
            availableSubstitutions {
              id
              default {
                id
                name
                symbol
                ... on CryptoAsset {
                  iconURI
                }
                ... on FiatAsset {
                  iconURI
                }
                ... on SecurityAsset {
                  iconURI
                }
              }
              substitution {
                id
                name
                symbol
                ... on CryptoAsset {
                  iconURI
                }
                ... on FiatAsset {
                  iconURI
                }
                ... on SecurityAsset {
                  iconURI
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ROBO_ETF_DISTRIBUTIONS = gql`
  query GetRoboETFDistributions($id: ID!) {
    securityAssets(where: { id: $id }) {
      edges {
        node {
          id
          name
          symbol
          financials {
            dividends {
              cashAmount
              frequency
              payDate
            }
          }
        }
      }
    }
  }
`;

export const GET_ROBO_ALL_DETAILS = gql`
  query GetAllRoboDetails {
    users {
      edges {
        node {
          ...UserRoboGoalDetail
        }
      }
    }
  }
  ${GET_USER_ROBO_GOAL_DETAIL_FRAGMENT}
`;

export const GET_ROBO_GOALS_V2 = gql`
  query GetRoboGoalsV2($where: RoboGoalWhereInput) {
    roboGoals(where: $where) {
      edges {
        node {
          id
          targetPortfolioID
          accountPortfolioID
          name
          isActive
          nextScheduledInfusion
          accountPortfolio {
            totalValue
            account {
              id
              externalID
            }
          }
          isAutoInfusionPaused
          allocation {
            totalValue
            stockPct
            bondPct
            fiatPct
          }
          parameters {
            targetAmount
            riskProfile
            goalPriority
            initialInvestment
            infusionAmount
            infusionFreq
            endDate
          }
          projection {
            goalProbability
          }
          accountPortfolio {
            id
            name
            totalValue
          }
          fundingAccount {
            accountNumber
          }
        }
      }
    }
  }
`;

export const GET_ROBO_KYC = gql`
  query GetRoboKyc {
    userAccounts(where: { type: ACCOUNT_TYPE_BROKERAGE_DISCRETIONARY }) {
      edges {
        node {
          type
          discretionaryAccountKyc {
            riskTolerance
            employmentStatus
            employmentDetails {
              EmployerName
              Position
            }
            investmentObjective
            annualIncome
            netWorth
          }
        }
      }
    }
  }
`;

export const GET_SUBSTITUTIONS = gql`
  query GetSubstitutions {
    modelPortfolios {
      edges {
        node {
          id
          availableSubstitutions {
            id
            createTime
            default {
              id
              name
              symbol
              ... on CryptoAsset {
                iconURI
              }
              ... on FiatAsset {
                iconURI
              }
              ... on SecurityAsset {
                iconURI
              }
            }
            substitution {
              id
              name
              symbol
              ... on CryptoAsset {
                iconURI
              }
              ... on FiatAsset {
                iconURI
              }
              ... on SecurityAsset {
                iconURI
              }
            }
          }
        }
      }
    }
  }
`;
