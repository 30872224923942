import { gql } from '@apollo/client';

export const GET_ACAT_TRANSFERS = gql`
  query GetAcatTransfers {
    response: acatTransfers {
      edges {
        node {
          id
          createTime
          updateTime
          statusChangeLog {
            status
            eventTime
          }
          transfers {
            id
            asset {
              id
              symbol
              ... on SecurityAsset {
                cusip
              }
            }
            createTime
            updateTime
            quantity
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
