import * as yup from 'yup';
import { V1RecurringRuleInterval } from '@fintronners/react-api/src';
import { getOneOfEnumSchema } from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';

export enum DepositTypes {
  ONE_TIME = 'one-time',
  RECURRING_DEPOSIT = 'recurring-deposit',
}

export enum TransferTypes {
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
}

export const intervals = [
  V1RecurringRuleInterval.EveryWeek,
  V1RecurringRuleInterval.EveryTwoWeeks,
  V1RecurringRuleInterval.EveryMonth,
];

export const transferSchema = yup.object({
  transferType: getOneOfEnumSchema(TransferTypes),
  depositType: getOneOfEnumSchema(DepositTypes),
  amount: yup.string().default('0').required(),
  interval: yup.string().oneOf(Object.values(V1RecurringRuleInterval)).required(),
  selectedStartDate: yup.string().required(),
  availableWithdrawalAmount: yup.string().default('0'),
  taxYear: yup.number().optional(),
});

export type TransferFormData = yup.InferType<typeof transferSchema>;
