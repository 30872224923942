type calculateAccountMetricsParams = {
  brokerage: {
    totalValue: string;
    gainsLosses: number;
    returnRate: number;
  };
  rothIra: {
    totalValue: string;
    gainsLosses: number;
    returnRate: number;
  };
  tradIra: {
    totalValue: string;
    gainsLosses: number;
    returnRate: number;
  };
};

export function calculateAccountMetrics({
  brokerage,
  rothIra,
  tradIra,
}: calculateAccountMetricsParams) {
  const allAccountsValue =
    parseFloat(brokerage.totalValue) +
    parseFloat(rothIra.totalValue) +
    parseFloat(tradIra.totalValue);
  const allAccountsGainsLosses = brokerage.gainsLosses + rothIra.gainsLosses + tradIra.gainsLosses;
  const allAccountsReturnRate = brokerage.returnRate + rothIra.returnRate + tradIra.returnRate;

  return {
    allAccountsReturnRate,
    allAccountsGainsLosses,
    allAccountsValue,
  };
}
