import { DaysOfWeek, getBeginningOfTodayUtc } from '../dateUtils';

export const MINUTES_IN_A_TRADING_DAY = 390;
export const MINUTES_IN_AN_EXTENDED_TRADING_DAY = 780;

export const TRADING_DAY = {
  START_HOUR_UTC: 13,
  START_MINUTE_UTC: 30,
  END_HOUR_UTC: 20,
  END_MINUTE_UTC: 0,
};

export const EXTENDED_TRADING_DAY = {
  START_HOUR_UTC: 10,
  START_MINUTE_UTC: 0,
  END_HOUR_UTC: 24,
  END_MINUTE_UTC: 0,
};

/**
 * @deprecated Potentially no longer need as we are trying to use the BE provided market hours.
 *
 * Gets the trading day hours based on current time. For example, if it is within trading hours,
 * the current day's trading hours are returned. If it is before trading opens, it returns the previous
 * trading day's hours.
 *
 * @param includeExtendedHours Whether or not to include extended trading hours
 */
export function getLatestTradingDayStartEndTime(includeExtendedHours = true) {
  const currentDate = new Date();
  const todayTradingDateTime = getBeginningOfTodayUtc();

  const tradingDayTimes = includeExtendedHours ? EXTENDED_TRADING_DAY : TRADING_DAY;

  todayTradingDateTime.setUTCHours(
    tradingDayTimes.START_HOUR_UTC,
    tradingDayTimes.START_MINUTE_UTC,
  );

  let daysToSubtract = 0;

  switch (currentDate.getUTCDay()) {
    case DaysOfWeek.Sunday:
      daysToSubtract = 2;
      break;
    case DaysOfWeek.Saturday:
      daysToSubtract = 1;
      break;
    case DaysOfWeek.Monday:
      if (currentDate < todayTradingDateTime) {
        daysToSubtract = 3;
      }
      break;
    default:
      if (currentDate < todayTradingDateTime) {
        daysToSubtract = 1;
      }
  }

  const latestTradingDate = new Date();
  latestTradingDate.setUTCDate(latestTradingDate.getUTCDate() - daysToSubtract);

  latestTradingDate.setUTCHours(
    tradingDayTimes.START_HOUR_UTC,
    tradingDayTimes.START_MINUTE_UTC,
    0,
    0,
  );
  const start = latestTradingDate.toISOString();

  latestTradingDate.setUTCHours(tradingDayTimes.END_HOUR_UTC, tradingDayTimes.END_MINUTE_UTC, 0, 0);
  const end = latestTradingDate.toISOString();
  return { start, end };
}
