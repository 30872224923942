import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import Image from 'next/image';
import { SearchIcon } from '@/assets/icons';
import { Input } from '../Input/Input';
import { useRouter } from 'next/router';

interface SearchProps {
  placeholder?: string;
  initialValue?: string;
  onSearch: (value: string) => void;
}

const SearchInput = ({ placeholder, onSearch, initialValue = '' }: SearchProps) => {
  const [displayValue, setDisplayValue] = useState<string>(initialValue);
  const router = useRouter();

  const debouncedSearch = useCallback(
    debounce((value) => {
      onSearch(value);
    }, 200),
    [onSearch],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setDisplayValue(value);
      debouncedSearch(value);
    },
    [debouncedSearch],
  );

  useEffect(() => {
    const { searchParam } = router.query;

    if (searchParam && searchParam?.length > 0) {
      setDisplayValue(String(searchParam));
      debouncedSearch(searchParam);
    }
  }, []);

  return (
    <div className="relative">
      <Image
        className="absolute bottom-[9px] left-3"
        alt="Search icon"
        width={15}
        height={15}
        src={SearchIcon.src}
      />
      <Input
        className="pl-9 pr-2"
        type="text"
        value={displayValue}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchInput;
