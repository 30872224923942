import React, { forwardRef } from 'react';
import Image from 'next/image';
import { Radio } from '@/components/common/Radio/Radio';
import {
  WebButton16,
  WebBodyText12,
  WebBodyText16,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';

import cn from '@/utils/tailwind';
import InfoIcon from '@/assets/icons/info.svg';
import { type ClassValue } from 'clsx';
import type { ControllerRenderProps } from 'react-hook-form';

export type SingleSelectQuestionIcon = 'info';
export type SingleSelectQuestionType = {
  name: string;
  options: Array<{ label: string; value: string }>;
  sentence?: string;
  helpText?: string;
  icon?: SingleSelectQuestionIcon;
};

type SingleSelectQuestionProps = SingleSelectQuestionType &
  ControllerRenderProps & {
    className?: ClassValue;
    onClickInfo?: () => void;
    fullWidth?: boolean;
    customSentence?: React.ReactNode;
    customHelpText?: React.ReactNode;
    disabled?: boolean;
  };

const Icons: Record<SingleSelectQuestionIcon, any> = {
  info: InfoIcon,
};

const SingleSelectQuestion = forwardRef<HTMLInputElement, SingleSelectQuestionProps>(
  (props, ref) => {
    const {
      sentence,
      helpText,
      options,
      icon,
      className,
      onClickInfo = undefined,
      fullWidth = false,
      customSentence,
      customHelpText,
      ...field
    } = props;

    return (
      <div className={cn('flex w-auto flex-col space-y-3', className)}>
        {/* Main sentence */}
        {sentence && !customSentence && (
          <WebBodyText16.Bold className="text-grey84">{sentence}</WebBodyText16.Bold>
        )}

        {/* if any */}
        {customSentence}

        <div
          className={cn('flex items-start space-x-1', {
            'cursor-auto': typeof onClickInfo !== 'function',
          })}
          onClick={onClickInfo}
          role={typeof onClickInfo === 'function' ? 'button' : undefined}
          tabIndex={typeof onClickInfo === 'function' ? 0 : undefined}
        >
          {/* Icon */}
          {icon && (
            <Image src={Icons[icon]} className="relative top-0.5 inline h-[14px] w-[14px]" alt="" />
          )}

          {/* Help Text */}
          {helpText && !customHelpText && (
            <WebBodyText12.Regular className="text-grey41 text-left">
              {helpText}
            </WebBodyText12.Regular>
          )}

          {/* if any */}
          {customHelpText}
        </div>

        {/* Options */}
        <div className={cn('flex w-full flex-1 flex-row flex-wrap gap-3')}>
          {options.map(({ label, value: optionValue }) => {
            const id = crypto.randomUUID();

            return (
              // Option
              <div key={id} className={cn(fullWidth ? 'flex-1' : '')}>
                <Radio.Input
                  id={id}
                  {...field}
                  value={optionValue}
                  checked={field.value === optionValue}
                  ref={ref}
                />

                <Radio.Label
                  variant="secondary"
                  htmlFor={id}
                  disabled={field.disabled}
                  className={cn({
                    'peer-checked:bg-grey41': field.disabled,
                  })}
                >
                  <WebButton16.Bold
                    className={cn('min-w-24 whitespace-nowrap px-3 text-center', {
                      'text-white': field.disabled && optionValue === field.value,
                    })}
                  >
                    {label}
                  </WebButton16.Bold>
                </Radio.Label>
              </div>
            );
          })}
        </div>
      </div>
    );
  },
);

SingleSelectQuestion.displayName = 'SingleSelectQuestion';
export default SingleSelectQuestion;
