import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { WebBodyText16 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';

import useAuthService from '@/hooks/api/useAuthService';
import { useSidePanel } from '@/context/SidePanelContext';
import { SheetFooter, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import FormTextField from '@/components/common/Forms/fields/FormTextField';
import { Button } from '@/components/common/Button';
import { type UsernameData, usernameSchema } from '../schema';

const displayStrings = {
  updatePasswordSidePanelTitle: 'Change Password',
  updatePasswordSidePanelDescripton:
    'Please enter the username associated with your account and we will send you a password reset code via email.',
  updatePasswordSidePanelUsernamePlaceholder: 'Username',
  updatePasswordSidePanelSend: 'Send',
  updatePasswordSidePanelCancel: 'Cancel',
  updatePasswordSidePanelLoading: 'Sending...',
};

export const UsernameForm = () => {
  const { closeSidePanel, goTo, sidePanelProps } = useSidePanel();
  const authService = useAuthService();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<UsernameData>({
    mode: 'onChange',
    resolver: yupResolver(usernameSchema),
    defaultValues: {
      username: sidePanelProps?.username || '',
    },
  });

  const onSubmit = async ({ username }: UsernameData) => {
    if (!isValid) {
      return;
    }

    try {
      await authService.authServiceForgotPassword({
        username,
      });

      goTo(1);
    } catch (error) {
      goTo(4);
    }
  };

  return (
    <form className="flex flex-col gap-5 h-full">
      <SheetHeader>
        <SheetTitle>{displayStrings.updatePasswordSidePanelTitle}</SheetTitle>
      </SheetHeader>
      <div className="flex flex-col h-full justify-between">
        <div>
          <WebBodyText16.Regular className="text-grey72 mb-5">
            {displayStrings.updatePasswordSidePanelDescripton}
          </WebBodyText16.Regular>
          <FormTextField
            containerClassName="w-full"
            placeholder={displayStrings.updatePasswordSidePanelUsernamePlaceholder}
            fieldError={errors.username}
            {...register('username')}
          />
        </div>
        <SheetFooter>
          <div className="flex flex-col justify-center items-center w-full gap-5">
            <Button
              className="w-full"
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={isSubmitting || !isValid}
            >
              {isSubmitting
                ? displayStrings.updatePasswordSidePanelLoading
                : displayStrings.updatePasswordSidePanelSend}
            </Button>
            <Button onClick={closeSidePanel} className="w-full" variant="secondary">
              {displayStrings.updatePasswordSidePanelCancel}
            </Button>
          </div>
        </SheetFooter>
      </div>
    </form>
  );
};
