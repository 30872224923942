import {
  NonDiscretionaryAccountKycUserLiquidityNeeds,
  NonDiscretionaryAccountKycUserTimeHorizon,
  V1UserInvestmentExperience,
  V1UserInvestmentObjective,
} from '@fintronners/react-api';
import { getOneOfEnumSchema } from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';
import * as yup from 'yup';

export const investingSchema = yup.object({
  investmentExperience: getOneOfEnumSchema(V1UserInvestmentExperience),
  investmentObjective: getOneOfEnumSchema(V1UserInvestmentObjective),
  timeHorizon: getOneOfEnumSchema(NonDiscretionaryAccountKycUserTimeHorizon),
  liquidityNeeds: getOneOfEnumSchema(NonDiscretionaryAccountKycUserLiquidityNeeds),
});

export type InvestingFormData = yup.InferType<typeof investingSchema>;
