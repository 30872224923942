import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import useFeatureFlags from '@/hooks/config/useFeatureFlags';
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { FinTronGraphQlProvider } from '@/components/graphql';
import { SidePanelProvider } from '@/context/SidePanelContext';
import createEmotionCache from '@/utils/createEmotionCache';
import { I18nextProvider } from 'react-i18next';
import i18n from '@fintronners/react-language/src/Lang/lang';
import { GrpcApiV1ConfigProvider } from '@/context/grpcApiV1ConfigContext';
import ConfigProvider from '@/providers/ConfigProvider';
import LayoutProvider from '@/providers/LayoutProvider';
import OnboardingProvider from '@/providers/OnboardingProvider';
import AppProvider from '@/providers/AppProvider';
import { ThemeProvider } from '@/components/theme';
import { useConfigStore } from '@/stores/config-store';
import { transformToOldThemeConfig } from '@/config/utils';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme as muiCreateTheme,
} from '@mui/material/styles';
import { Toaster } from '@/components/common/Toaster/Toaster';

import '@/components/common/SidePanels/utils/SidePanelsRegistration';
import 'react-international-phone/style.css';
import '@/assets/css/_app.css';
import '@/assets/css/tailwind.css';

// Client-side cache shared for the whole session
const clientSideEmotionCache = createEmotionCache();

export default function App(
  props: AppProps & { emotionCache: ReturnType<typeof createEmotionCache> },
) {
  const { Component: Page, pageProps } = props;
  const { featureFlags: ff } = useFeatureFlags();
  const { theme, config } = useConfigStore();
  // TODO: this is only for supporting dynamic theme to RN components
  const oldThemeConfig = transformToOldThemeConfig(theme, config);
  const router = useRouter();

  /**
   * These styles are applied to Material UI components using createTheme() and
   * a ThemeProvider. Only global styles that affect MUI components, that can't
   * be achieved with className or StyleSheet, should go here.
   *
   * @see https://mui.com/material-ui/customization/theme-components/
   */
  const muiTheme = muiCreateTheme({
    components: {
      // @see https://mui.com/material-ui/api/button-base/
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        variants: [
          {
            props: { disabled: true },
            style: {
              opacity: 0.5,
            },
          },
        ],
      },
      // @see https://mui.com/material-ui/api/outlined-input/
      MuiOutlinedInput: {
        variants: [
          {
            props: { disabled: true },
            style: {
              '&.Mui-disabled .MuiOutlinedInput-input': {
                backgroundColor: '#fafafa',
              },

              '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: '#f0f0f0',
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#f0f0f0',
            },
          },
        },
      },
    },
  });

  // NOTE: prevent visit settings/security if Alight
  useEffect(() => {
    if (ff.shouldHideSettingsSecurity && router.pathname === '/settings/security') {
      router.push('/accounts');
    }
  }, [ff, router]);

  return (
    <AppCacheProvider emotionCache={props.emotionCache || clientSideEmotionCache}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      <AppProvider {...pageProps}>
        <ConfigProvider {...pageProps}>
          <FinTronGraphQlProvider>
            <GrpcApiV1ConfigProvider
              basePath={pageProps.newApiUrl}
              authApiBaseUrl={pageProps.authApiBaseUrl}
              accessToken={pageProps.accessToken}
              idToken={pageProps.idToken}
            >
              <ThemeProvider theme={oldThemeConfig}>
                <LayoutProvider {...pageProps}>
                  <SidePanelProvider>
                    <I18nextProvider i18n={i18n}>
                      <MuiThemeProvider theme={muiTheme}>
                        <OnboardingProvider {...pageProps}>
                          <Page {...pageProps} />
                          <Toaster />
                        </OnboardingProvider>
                      </MuiThemeProvider>
                    </I18nextProvider>
                  </SidePanelProvider>
                </LayoutProvider>
              </ThemeProvider>
            </GrpcApiV1ConfigProvider>
          </FinTronGraphQlProvider>
        </ConfigProvider>
      </AppProvider>
    </AppCacheProvider>
  );
}
