import { getOneOfEnumSchema } from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';
import { FORM_ERROR_MESSAGES } from '@fintronners/react-api/src/utils/formUtils/schemas/constants';
import { YES_OR_NO } from '@fintronners/react-api/src/utils/formUtils/schemas/types';
import * as yup from 'yup';

export const PoliticallyExposedSchema = yup.object({
  isPoliticallyExposed: getOneOfEnumSchema(YES_OR_NO).required(),
  familyMembers: yup.array().when('isPoliticallyExposed', {
    is: YES_OR_NO.YES,
    then: (schema) =>
      schema
        .of(yup.object({ value: yup.string().required(FORM_ERROR_MESSAGES.required) }))
        .required(),
  }),
  organizationName: yup.string().when('employmentStatus', {
    is: YES_OR_NO.YES,
    then: (schema) => schema.required(FORM_ERROR_MESSAGES.required),
  }),
});

export type PoliticallyExposedFormData = yup.InferType<typeof PoliticallyExposedSchema>;
