import Image from 'next/image';

import { WebBodyText18, WebHeading28 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';

import { SuccessCircleIcon } from '@/assets/icons';
import { Button } from '@/components/common/Button';
import { SheetContent, SheetFooter, SheetTitle } from '@/components/common/Sheet';
import { useSidePanel } from '@/context/SidePanelContext';

const displayStrings = {
  updatePasswordSidePanelSuccessTitle: 'Success!',
  updatePasswordSidePanelSuccessDescription: 'Your password has been reset.',
  updatePasswordSidePanelDone: 'Done',
};

export const Success = () => {
  const { closeSidePanel } = useSidePanel();

  const handleClose = () => closeSidePanel();

  return (
    <SheetContent>
      <div className="flex flex-col items-center gap-7 text-center mt-20">
        <Image alt="Success icon" width={50} height={50} src={SuccessCircleIcon.src} />
        <SheetTitle>
          <WebHeading28.Bold className="text-darkestBlue">
            {displayStrings.updatePasswordSidePanelSuccessTitle}
          </WebHeading28.Bold>
        </SheetTitle>
        <WebBodyText18.Regular className="text-grey72">
          {displayStrings.updatePasswordSidePanelSuccessDescription}
        </WebBodyText18.Regular>
      </div>
      <SheetFooter className="text-center mb-5">
        <Button className="w-full" onClick={handleClose}>
          {displayStrings.updatePasswordSidePanelDone}
        </Button>
      </SheetFooter>
    </SheetContent>
  );
};
