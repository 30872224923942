import * as yup from 'yup';
import { PRODUCT_TYPES, TICKET_TYPES } from './constants';

export const contactFormSchema = yup.object({
  ticketType: yup
    .string()
    .oneOf(TICKET_TYPES, 'Invalid ticket type')
    .nullable()
    .required('Ticket type is required'),
  productType: yup
    .string()
    .oneOf(PRODUCT_TYPES, 'Invalid product type')
    .nullable()
    .required('Product type is required'),
  category: yup.string().nullable().required('Category is required'),
  message: yup
    .string()
    .min(30, 'Your message must be at least 30 characters')
    .max(200, 'Your message must be less than 200 characters')
    .required('Message is required'),
});

export type FormValues = yup.InferType<typeof contactFormSchema>;
