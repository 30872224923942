import { Configuration, UserSettingsServiceApi } from '@fintronners/react-api/src';

import RuntimeEnvGRPCAPI from '@/utils/RuntimeEnvGRPCAPI';
import { useGrpcApiV1Config } from '@/hooks/api/useGrpcApiV1Config';

const useUserSettingsService = () => {
  const { basePath, accessToken } = useGrpcApiV1Config();

  const userSettingsApiService = RuntimeEnvGRPCAPI.getV1Service(
    UserSettingsServiceApi,
    new Configuration({
      basePath,
      accessToken,
      baseOptions: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    }),
  );

  return userSettingsApiService;
};

export default useUserSettingsService;
