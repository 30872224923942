import { GainsAndLosses } from '@/components/common/GainsAndLosses/GainsAndLosses';
import { BodyText40 } from '@fintronners/react-ui/src/GlobalStyling';
import { WebBodyText18 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { thousandFormatNumbroCurrency } from '@fintronners/react-utils/src/numberUtilsTSX';
import { useRouter } from 'next/router';
import React from 'react';

export const Account: React.FC<{
  title: string;
  icon: React.ReactNode;
  total: number;
  accountType: string;
}> = (props) => {
  const router = useRouter();

  return (
    <div
      className="mb-4 flex flex-row flex-nowrap items-center border-b border-grey55 pb-6 pt-2"
      role="button"
      tabIndex={0}
      onClick={() => router.push(`/accounts/${props.accountType}`)}
    >
      <div className="mr-2 flex-shrink-0 flex-grow-0">{props.icon}</div>
      <div className="flex-shrink flex-grow">
        <BodyText40.Bold>{props.title}</BodyText40.Bold>
      </div>
      <div className="flex flex-col items-end">
        <WebBodyText18.Bold className="text-darkestBlue">
          {thousandFormatNumbroCurrency(props.total)}
        </WebBodyText18.Bold>
        <GainsAndLosses type={props.accountType} />
      </div>
    </div>
  );
};
