import { useQuery } from '@apollo/client';
import {
  ExternalAccountConnectionStatus,
  ExternalAccountExternalAccountType,
  ExternalAccountFragmentFragment,
  GetPlaidV2Query,
} from '@fintronners/react-api';
import { GET_PLAID_V2 } from '@fintronners/react-api/src/graphql/queries/plaid';
import { useEffect, useState } from 'react';
import fLogger from '@fintronners/react-utils/src/fLogger';

export const ACCOUNT_TYPES = {
  [ExternalAccountExternalAccountType.ExternalAccountTypeChecking]: 'CHECKING',
  [ExternalAccountExternalAccountType.ExternalAccountTypeSavings]: 'SAVINGS',
};

export enum AccountType {
  BROKERAGE = 'BROKERAGE',
  ADVISORY = 'ADVISORY',
}

export interface UseExternalAccount extends ExternalAccountFragmentFragment {
  displayName: string;
}

type ExternalAccountsState = {
  externalAccounts?: UseExternalAccount[];
  // While we are only supporting one account per plaid relationship, this is a explicit helper to get the first account
  firstExternalAccount?: UseExternalAccount;
  relationshipId?: string;
  refetch: () => Promise<any>;
  fetchingAccounts: boolean;
};

// region helpers
/**
 * Extracts the relationship id for the active account from the gql data
 */
const getRelationshipId = (accountType: AccountType, data?: GetPlaidV2Query) => {
  switch (accountType) {
    case AccountType.ADVISORY:
      return data?.tenant?.advisory?.plaidRelationshipOwner?.id;
    case AccountType.BROKERAGE:
      return data?.tenant?.brokerage?.plaidRelationshipOwner?.id;
    default:
      return;
  }
};

/**
 * Filters the external accounts based on the connection status and the relationshipId
 */
const getMatchingExternalAccounts = (
  connectionStatuses: ExternalAccountConnectionStatus[],
  relationshipId?: string,
  data?: GetPlaidV2Query,
  shouldIgnoreICT?: boolean,
) => {
  return data?.externalAccounts?.edges?.flatMap((account) => {
    if (account?.node === undefined) return [];
    if (account?.node?.connectionStatus === undefined) return [];
    if (!connectionStatuses.includes(account?.node?.connectionStatus)) return [];
    if (
      account.node.connectionDetails?.__typename === 'ExternalAccountConnectionDetailsPlaid' &&
      account?.node?.connectionDetails?.relationshipOwner?.id !== relationshipId
    )
      return [];

    //FIXME: Remove this condition when backend filters are in place
    if (
      shouldIgnoreICT &&
      account.node.connectionDetails?.__typename === 'ExternalAccountConnectionDetailsICT'
    )
      return [];

    return [account.node];
  });
};

/**
 * Returns the display name for the external account
 */
const getDisplayName = (externalAccount: ExternalAccountFragmentFragment) => {
  return `${externalAccount.institution?.name} ${ACCOUNT_TYPES[externalAccount?.type]} ******${
    externalAccount.mask
  }`;
};
//endregion

/**
 * Hook to get the external accounts for the given account type.
 *
 * @param accountType The account type for which the external accounts are needed
 * @param connectionStatuses The connection statuses to filter the external accounts
 */
const useExternalAccounts = (
  accountType: AccountType,
  connectionStatuses = [ExternalAccountConnectionStatus.Active],
  skip = false,
  shouldIgnoreICT = false,
) => {
  const { data, loading, refetch } = useQuery<GetPlaidV2Query>(GET_PLAID_V2, {
    fetchPolicy: 'network-only',
    skip,
  });
  const [singleState, setSingleState] = useState<ExternalAccountsState>({
    refetch,
    fetchingAccounts: true,
  });

  /**
   * Refetches the external accounts for the given account type and updates the state
   */
  const refetchAccount = async () => {
    setSingleState((prevState) => ({ ...prevState, fetchingAccounts: true }));
    await refetch();
  };

  useEffect(() => {
    if (loading || !data) {
      !data && setSingleState((prevState) => ({ ...prevState, fetchingAccounts: false }));
      return;
    }
    const relationshipId = getRelationshipId(accountType, data);
    const externalAccounts = getMatchingExternalAccounts(
      connectionStatuses,
      relationshipId,
      data,
      shouldIgnoreICT,
    )?.map((externalAccount) => {
      return {
        ...externalAccount,
        displayName: getDisplayName(externalAccount),
      };
    });
    if (externalAccounts && externalAccounts?.length > 1) {
      fLogger.error(
        'Multiple active external accounts found for the relationship. This is not supported yet.',
      );
    }
    setSingleState({
      externalAccounts,
      firstExternalAccount: externalAccounts?.[0],
      relationshipId,
      refetch: refetchAccount,
      fetchingAccounts: false,
    });
  }, [data, loading, accountType]);

  return singleState;
};

export default useExternalAccounts;
