import { gql } from '@apollo/client';

export const GET_ADMIN_TRADES = gql`
  query GetAdminTrades(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $allFilters: [TradeWhereInput!]
    $criteria: String
    $startDate: Time
    $endDate: Time
    $statusIn: [TradeTradeStatus!]
    $orderDirection: OrderDirection!
    $orderfield: TradeOrderField!
  ) {
    response: trades(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        and: $allFilters
        unsettledTimeGTE: $startDate
        unsettledTimeLTE: $endDate
        statusIn: $statusIn
        hasUserWith: {
          hasProfileWith: {
            or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
          }
        }
      }
      orderBy: { direction: $orderDirection, field: $orderfield }
    ) {
      edges {
        cursor
        node {
          id
          portfolio {
            account {
              type
            }
          }
          user {
            id
            profile {
              firstName
              lastName
            }
          }
          status
          orderStatus
          base {
            symbol
            ... on SecurityAsset {
              market {
                last
              }
            }
          }
          side
          type
          requestInQuote
          requestAmount
          baseAmount
          quoteAmount
          completedAt: statusChangeLog(status: TRADE_STATUS_UNSETTLED) {
            eventTime
          }
          createdAt: statusChangeLog(status: TRADE_STATUS_NEW) {
            eventTime
          }
          createTime
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_TRADE_BY_ID = gql`
  query GetTradeById($id: ID!) {
    response: trades(where: { id: $id }) {
      edges {
        node {
          id
          portfolio {
            account {
              type
              externalID
            }
            user {
              username
              profile {
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;

export const ADMIN_TRADE_FRAGMENT = gql`
  fragment AdminTradeFragment on Trade {
    id
    orderStatus
    user {
      id
    }
    portfolio {
      name
      account {
        id
        type
        externalID
        user {
          profile {
            firstName
            lastName
          }
          username
        }
      }
    }
    expirationType
    status
    reason
    note
    requestDetails {
      requestUUID
      requestor
      admin {
        id
        email
        createTime
        externalID
        updateTime
        firstName
        lastName
      }
    }
    statusChangeLog {
      status
      eventTime
      note
    }
    base {
      symbol
      ... on SecurityAsset {
        market {
          last
        }
      }
    }
    side
    stop
    limit
    type
    requestAmount
    requestInQuote
    baseAmount
    quoteAmount
    completedAt: statusChangeLog(status: TRADE_STATUS_UNSETTLED) {
      eventTime
    }
    createdAt: statusChangeLog(status: TRADE_STATUS_NEW) {
      eventTime
    }
    recurringRuleInstance {
      id
      rule {
        id
      }
    }
  }
`;

export const GET_ADMIN_TRADE = gql`
  query GetAdminTrade($tradeId: ID) {
    trades(where: { id: $tradeId }) {
      edges {
        cursor
        node {
          ...AdminTradeFragment
        }
      }
    }
  }
  ${ADMIN_TRADE_FRAGMENT}
`;
