import Image from 'next/image';
import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '~assets/icons';

type CalendarChevronProps = {
  className?: string;
  size?: number;
  disabled?: boolean;
  orientation?: 'up' | 'down' | 'left' | 'right';
};

const CalendarChevron: React.FC<CalendarChevronProps> = (props) => {
  if (props.orientation === 'left') {
    return <Image alt="" width={15} height={15} src={ChevronLeftIcon.src} />;
  }

  return <Image alt="" width={15} height={15} src={ChevronRightIcon.src} />;
};

CalendarChevron.displayName = 'CalendarChevron';

export { CalendarChevron };
