import { GetServerSidePropsContext, NextApiRequest } from 'next';

// Trabian
import {
  FontEnum,
  getAlightTheme,
  getCustomTheme,
  getFinTronTheme,
  getRandomTheme,
  getTrabianTheme,
} from '~components/theme';
import { setResCookie } from '~utils/cookie';

// FinTron
import { AppTheme } from '@fintronners/react-utils/types/styled-components';

/**
 * This object is similar to the FinTron colors object but is specific to the
 * web application to avoid crossing the streams.
 *
 * @see /AKOiOS/packages/FinTronUI/src/Themes/colors.tsx
 */
export const colors = {
  lightGray: '#f0f0f0',
  gray: '#747474',
};

const firstFont = (multipleFontFamilies: string) => {
  let match = multipleFontFamilies.match(/'([^']*)'/);
  if (match) {
    const firstFontFamily = match[1];
    return firstFontFamily;
  } else {
    return undefined;
  }
};

const buildToUniversalFont = (fontFamily: string) => {
  const mainFontFamily = firstFont(fontFamily);
  if (mainFontFamily) {
    const fontName = Object.keys(FontEnum).find(
      (enumKey) => firstFont(FontEnum[enumKey].style.fontFamily) === mainFontFamily,
    );
    if (!fontName) throw new Error('Font name not found');
    return { fontFamily: fontName };
  } else {
    return undefined;
  }
};

// const universalToBuildFont = (fontFamily: string) => {
//   return { fontFamily: `"${FontEnum[fontFamily].style.fontFamily}"` };
// };

/**
 * Converts a build theme object to a universal theme object (with its build-time randomness removed).
 * @param buildTheme - The build theme object to convert.
 * @returns The converted universal theme object.
 * @throws {Error} If a universal font is not found.
 */
export const buildToUniversalTheme = (buildTheme: AppTheme) => {
  return Object.keys(buildTheme).reduce((acc, key) => {
    const value = buildTheme[key];
    if (key === 'text') {
      const universalFont = buildToUniversalFont(value.fontFamily);
      if (!universalFont) throw new Error('Universal font not found');
      acc[key] = universalFont;
    } else {
      acc[key] = value;
    }
    return acc;
  }, {} as AppTheme);
};

/**
 * Converts a universal theme object to a build theme object (with its build-time randomness added) for use with this particular deployment's resources.
 * @param universalTheme - The universal theme object to convert.
 * @returns The converted build theme object.
 * @throws {Error} If a build font is not found.
 */
// const universalToBuildTheme = (universalTheme: AppTheme) => {
//   return Object.keys(universalTheme).reduce((acc, key) => {
//     const value = universalTheme[key];
//     if (key === 'text') {
//       const buildFont = universalToBuildFont(value.fontFamily);
//       if (!buildFont) throw new Error('Build font not found');
//       acc[key] = buildFont;
//     } else {
//       acc[key] = value;
//     }
//     return acc;
//   }, {} as AppTheme);
// };

const setBuildThemeAsUniversalThemeCookie = (
  res: GetServerSidePropsContext['res'],
  theme: AppTheme,
) => {
  const universalTheme = buildToUniversalTheme(theme);
  const themeString = JSON.stringify(universalTheme);
  setResCookie(res, 'theme', themeString, {
    maxAge: 30 * 24 * 60 * 60, // Set cookie to expire in 30 days
    path: '/',
    sameSite: 'Strict',
  });
};

// const reprThemeCookie = (theme?: AppTheme) => {
//   return theme
//     ? {
//         'text.fontFamily': theme.text.fontFamily,
//         'colors.primary': theme.colors.primary,
//         'images.colorBrandLogoL.uri': theme.images.colorBrandLogoL.uri,
//       }
//     : theme;
// };

export const initializeThemeCookie = (
  res: GetServerSidePropsContext['res'],
  _?: string,
  tenant?: Record<string, boolean>,
): AppTheme => {
  // TODO: tech debt ticket https://fintroninvest.atlassian.net/browse/TRIO-295
  // Note: for now we'll support only alight and fintron theme based on tenant
  if (tenant?.isAlight) {
    return setAlightThemeCookie(res);
  } else {
    return setFinTronThemeCookie(res);
  }
};

/**
 * Sets the theme cookie using FinTron's brand.
 *
 * @param res
 * @returns
 */
export const setFinTronThemeCookie = (res: GetServerSidePropsContext['res']) => {
  const buildTheme = getFinTronTheme();
  setBuildThemeAsUniversalThemeCookie(res, buildTheme);
  return buildTheme;
};

/**
 * Sets the theme cookie using Alight's brand.
 *
 * @param res
 * @returns
 */
export const setAlightThemeCookie = (res: GetServerSidePropsContext['res']) => {
  const buildTheme = getAlightTheme();
  setBuildThemeAsUniversalThemeCookie(res, buildTheme);
  return buildTheme;
};

/**
 * Sets the theme cookie using Trabian's "Thrive" brand.
 *
 * @param res
 * @returns
 */
export const setTrabianThemeCookie = (res: GetServerSidePropsContext['res']) => {
  const buildTheme = getTrabianTheme();
  setBuildThemeAsUniversalThemeCookie(res, buildTheme);
  return buildTheme;
};

/**
 * Sets the theme cookie using the specified request values.
 *
 * @param req
 * @param res
 * @returns
 */
export const setCustomThemeCookie = (
  req: NextApiRequest,
  res: GetServerSidePropsContext['res'],
) => {
  const buildTheme = getCustomTheme(req);
  setBuildThemeAsUniversalThemeCookie(res, buildTheme);
  return buildTheme;
};

/**
 * Sets the theme cookie using random values.
 *
 * @param res
 * @returns
 */
export const setRandomThemeCookie = (res: GetServerSidePropsContext['res']) => {
  const buildTheme = getRandomTheme();
  setBuildThemeAsUniversalThemeCookie(res, buildTheme);
  return buildTheme;
};
