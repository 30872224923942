import { WebBodyText18 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import Image from 'next/image';
import React from 'react';
import { InfoPrimaryIcon } from '~assets/icons';
import { Button } from '~components/common/Button';
import useBreakpoint from '@/hooks/utils/useBreakpoint';
import useResponsiveDialog from '@/hooks/utils/useResponsiveDialog';
import { thousandFormatNumbroCurrency } from '@fintronners/react-utils/src/numberUtilsTSX';

interface FriendlyReminderDialogProps {
  leftoverCash: number;
  securitySymbol: string;
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onSellAllPressed: () => void;
  onKeepPressed: () => void;
}

const displayStrings = {
  title: 'Caution',
  yes: 'Yes, Sell All',
  keep: (amount: string, symbol: string) => `Keep ${amount} of ${symbol}`,
};

export const FriendlyReminderDialog = ({
  leftoverCash,
  securitySymbol,
  open,
  onOpenChange,
  onSellAllPressed,
  onKeepPressed,
}: FriendlyReminderDialogProps) => {
  const { isBreakpoint } = useBreakpoint();
  const { ResponsiveDialog } = useResponsiveDialog();

  const formattedLeftoverCash = thousandFormatNumbroCurrency(leftoverCash);

  return (
    <ResponsiveDialog.Root open={open} onOpenChange={onOpenChange}>
      <ResponsiveDialog.Content>
        <ResponsiveDialog.Header>
          <Image alt="Info icon" width={50} height={50} src={InfoPrimaryIcon.src} />
          <ResponsiveDialog.Title>{displayStrings.title}</ResponsiveDialog.Title>
        </ResponsiveDialog.Header>

        <WebBodyText18.Regular className="text-grey72">
          After this sell order, you would be left with <strong>{formattedLeftoverCash}</strong>{' '}
          worth of <strong>{securitySymbol}</strong>, which is less than the $1 sell order minimum.
        </WebBodyText18.Regular>

        <WebBodyText18.Regular className="text-grey72">
          Would you like to include the remaining <strong>{formattedLeftoverCash}</strong> of{' '}
          <strong>{securitySymbol}</strong> in this order?
        </WebBodyText18.Regular>

        <ResponsiveDialog.Footer>
          <ResponsiveDialog.Close asChild>
            <div className="flex flex-col gap-4 w-full">
              <Button className="w-full" variant="primary" onClick={onSellAllPressed}>
                {displayStrings.yes}
              </Button>
              <Button variant={isBreakpoint('xs') ? 'ghost' : 'secondary'} onClick={onKeepPressed}>
                {displayStrings.keep(formattedLeftoverCash, securitySymbol)}
              </Button>
            </div>
          </ResponsiveDialog.Close>
        </ResponsiveDialog.Footer>
      </ResponsiveDialog.Content>
    </ResponsiveDialog.Root>
  );
};
