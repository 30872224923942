import { WebBodyText18 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import Image from 'next/image';
import React from 'react';
import { InfoPrimaryIcon } from '~assets/icons';
import { Button } from '~components/common/Button';
import useBreakpoint from '@/hooks/utils/useBreakpoint';
import useResponsiveDialog from '@/hooks/utils/useResponsiveDialog';

const displayStrings = {
  close: 'Close',
  stopLossPreviewInfo: (
    sharesToBeSold: string,
    securitySymbol: string,
    availableAtStopLossPrice: string,
    stopLoss: string,
  ) => {
    return `This value is what your current holdings (minus any pending sell orders) would be worth at the stop price you specified.\n\nIn this instance, your ${sharesToBeSold} shares in ${securitySymbol} would be worth ${availableAtStopLossPrice} if ${securitySymbol} traded at ${stopLoss}, your specified stop price.`;
  },
};

interface StopLossDialogProps {
  sharesToBeSold: string;
  securitySymbol: string;
  availableAtStopLossPrice: string;
  stopLoss: string;
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export const StopLossDialog = ({
  sharesToBeSold,
  securitySymbol,
  availableAtStopLossPrice,
  stopLoss,
  open,
  onOpenChange,
}: StopLossDialogProps) => {
  const { isBreakpoint } = useBreakpoint();
  const { ResponsiveDialog } = useResponsiveDialog();

  return (
    <ResponsiveDialog.Root open={open} onOpenChange={onOpenChange}>
      <ResponsiveDialog.Content>
        <ResponsiveDialog.Header>
          <Image alt="Info icon" width={50} height={50} src={InfoPrimaryIcon.src} />
        </ResponsiveDialog.Header>

        <WebBodyText18.Regular className="text-grey72">
          {displayStrings.stopLossPreviewInfo(
            sharesToBeSold,
            securitySymbol,
            availableAtStopLossPrice,
            stopLoss,
          )}
        </WebBodyText18.Regular>
        <ResponsiveDialog.Footer>
          <ResponsiveDialog.Close asChild>
            <Button className="w-full" variant={isBreakpoint('xs') ? 'ghost' : 'primary'}>
              {displayStrings.close}
            </Button>
          </ResponsiveDialog.Close>
        </ResponsiveDialog.Footer>
      </ResponsiveDialog.Content>
    </ResponsiveDialog.Root>
  );
};
