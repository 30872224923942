import { gql } from '@apollo/client';
import {
  COMMON_USER_INVESTOR_INFORMATION_NON_DISCRETIONARY_FRAGMENT,
  COMMON_USER_PROFILE_FRAGMENT,
  COMMON_USERACCOUNT_DETAILS_FRAGMENT,
} from './common/fragments';
import {
  GET_ALL_USER_ACCOUNTS_STATUS_FRAGMENT,
  TRUSTED_CONTACT_DETAILS_FRAGMENT,
} from '../queries';

export const GET_ADMIN_USERACCOUNTS = gql`
  query GetAdminAccounts(
    $first: Int
    $after: Cursor
    $last: Int
    $before: Cursor
    $status: [UserAccountAccountStatus!]
    $criteria: String = ""
    $startDate: Time
    $endDate: Time
    $allFilters: [UserAccountWhereInput!]
  ) {
    response: userAccounts(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {
        statusIn: $status
        hasUserWith: {
          hasProfileWith: {
            or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
          }
        }
        createTimeGTE: $startDate
        createTimeLTE: $endDate
        and: $allFilters
      }
      orderBy: { direction: DESC, field: create_time }
    ) {
      edges {
        cursor
        node {
          ...CommonUserAccountDetailsFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
    userIds: userAccounts(
      where: {
        statusIn: $status
        hasUserWith: {
          hasProfileWith: {
            or: [{ firstNameContainsFold: $criteria }, { lastNameContainsFold: $criteria }]
          }
        }
        createTimeGTE: $startDate
        createTimeLTE: $endDate
      }
    ) {
      edges {
        node {
          user {
            id
          }
        }
      }
    }
  }
  ${COMMON_USERACCOUNT_DETAILS_FRAGMENT}
`;

export const GET_ADMIN_USERACCOUNT_BY_ID = gql`
  query GetAdminAccountById($id: ID) {
    userAccounts(where: { id: $id }) {
      edges {
        cursor
        node {
          ...CommonUserAccountDetailsFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
    }
  }
  ${COMMON_USERACCOUNT_DETAILS_FRAGMENT}
`;

export const GET_ADMIN_USER_PROFILE = gql`
  query GetAdminUserProfile(
    $id: ID
    $first: Int
    $after: Cursor
    $before: Cursor
    $last: Int
    $accountFilter: UserAccountWhereInput
  ) {
    users(first: $first, after: $after, last: $last, before: $before, where: { id: $id }) {
      edges {
        cursor
        node {
          id
          username
          accounts(where: $accountFilter) {
            edges {
              node {
                type
                externalID
                status
                programEnrollmentHistory {
                  id
                  electionPreference
                  approvalStatus
                  programType
                  reviewedAt
                  reason
                  submissionStatus
                  submittedAt
                  createTime
                }
              }
            }
          }
          profile {
            ...CommonUserProfileDetails
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${COMMON_USER_PROFILE_FRAGMENT}
`;

export const GET_ADMIN_USER_KYCS = gql`
  query GetAdminUserKycs($id: ID, $discretionaryEnabled: Boolean = true) {
    users(where: { id: $id }) {
      edges {
        node {
          accounts {
            edges {
              node {
                ...GetAllUserAccountsStatusFragment
                trustedContacts {
                  ...TrustedContactDetails
                }
              }
            }
          }
          profile {
            ssn
            dateOfBirth
            controlPersonDetails {
              companySymbols
              isControlPerson
            }
            affiliatedExchangeOrFinraDetails {
              firmName
              isAffiliated
            }
            politicallyExposedDetails {
              organizationName
              familyMembers
              isPoliticallyExposed
            }
            marketDataAnswers {
              advisorOrConsultant
              investForOther
              personalUse
              registeredWithSecOrCftc
              requiresSecOrCftcRegistration
              investForTheBenefitOfOther
              gettingDataFromExternalSource
              sharesProfit
              registeredWithEntity
            }
          }
        }
      }
    }
    nonDiscretionaryAccountKycs(where: { hasAccountWith: { hasUserWith: { id: $id } } }) {
      edges {
        node {
          ...CommonUserInvestorInformationNonDiscretionaryDetails
        }
      }
    }
    discretionaryAccountKycs(where: { hasAccountWith: { hasUserWith: { id: $id } } })
      @include(if: $discretionaryEnabled) {
      edges {
        node {
          employmentStatus
          employmentDetails {
            EmployerName
            Position
          }
          annualIncome
          netWorth
          investmentObjective
          riskTolerance
        }
      }
    }
  }
  ${TRUSTED_CONTACT_DETAILS_FRAGMENT}
  ${COMMON_USER_INVESTOR_INFORMATION_NON_DISCRETIONARY_FRAGMENT}
  ${GET_ALL_USER_ACCOUNTS_STATUS_FRAGMENT}
`;

export const GET_ADMIN_USER_DOCUMENTS = gql`
  query getUserDocuments($id: ID) {
    userDocuments(where: { id: $id }) {
      edges {
        node {
          id
          requestor
          requestUUID
          name
          path
          mimeType
          url
          documentType
        }
      }
    }
  }
`;

export const GET_ADMIN_USER_DOCUMENT_SETS = gql`
  query getUserDocumentSets($id: ID) {
    userDocumentSets(where: { isactive: true, hasUserWith: { id: $id } }) {
      edges {
        node {
          id
          isactive
          documentType
          document {
            id
            requestor
            requestUUID
            name
            path
            mimeType
            url
            documentType
          }
        }
      }
    }
  }
`;

export const GET_ADMIN_USER_DOCUMENT_SETS_BY_DOCUMENT_TYPE = gql`
  query getUserDocumentSetsByDocumentType($id: ID!, $documentType: UserDocumentUserDocumentType) {
    userDocumentSets(
      where: {
        hasDocumentWith: { documentType: $documentType }
        isactive: true
        hasUserWith: { id: $id }
      }
    ) {
      edges {
        node {
          id
          isactive
          documentType
          document {
            id
            requestor
            requestUUID
            name
            path
            mimeType
            url
            documentType
          }
        }
      }
    }
  }
`;

export const GET_ADMIN_GI_PORTFOLIOS_BY_USER_ID = gql`
  query GetAdminGIPortfoliosByUserId($userId: ID!) {
    roboGeneralInvestments(where: { hasUserWith: { id: $userId } }) {
      edges {
        node {
          id
          targetPortfolioID
          accountPortfolioID
          createTime
        }
      }
    }
  }
`;

export const GET_USER_ACCOUNTS_BY_USER_ID_FOR_ADMIN_SIDEBAR = gql`
  query GetUserAccountsByUserIdForAdminSidebar($userId: ID!) {
    users(where: { id: $userId }) {
      edges {
        node {
          accounts {
            edges {
              node {
                type
              }
            }
          }
          roboGeneralInvestments {
            edges {
              node {
                isActive
              }
            }
          }
        }
      }
    }
  }
`;
