import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { convertObjectToISODateString } from '@fintronners/react-api/src/utils/dateUtils';
import { convertISODateStringToDisplayString } from '@fintronners/react-utils/src/dateUtils';
import { type ISODateString } from '@fintronners/react-experience/customTypes/DateTypes';
import {
  WebBodyText14,
  WebBodyText20,
  WebCaption10,
  WebCaption12,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import {
  formatPercent,
  NumberNoNaN,
  thousandFormatNumbroCurrency,
} from '@fintronners/react-utils/src/numberUtilsTSX';
import {
  FrequencyDisplayStringMap,
  UserAccountAccountType,
  V1CreateDepositSummaryResponse,
} from '@fintronners/react-api/src';

import { type UseExternalAccount } from '@fintronners/react-widgets/src/hooks/useExternalAccounts';
import useUserDetails from '@fintronners/react-widgets/src/hooks/useUserDetails';
import { useGetUserSettings } from '@fintronners/react-api/src/hooks/useGetUserSettings';
import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';

import { Button } from '@/components/common/Button';
import { SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import SkeletonTransferConfirmation from '@/components/common/SkeletonTransferConfirmation/SkeletonTransferConfirmation';
import { DepositTypes, TransferFormData, TransferTypes } from './schema';
import useCurrentAccountType, { useIsIRAAccount } from '@/hooks/utils/useCurrentAccountType';
import { useRetirementInfo } from '@/hooks/api/useRetirementInfo';
import { Alert } from '@/components/common/Alert/Alert';
import InfoInline from '@/components/common/InfoInline/InfoInline';
import { useTenantBasedStrings } from '@/hooks/config/useTenantBasedStrings';
import { InfoErrorIcon } from '@/assets/icons';
import { getShowAgePenalties } from '@fintronners/react-utils/src/withdrawalUtils';
import { StateWithholdingClass, useWithholdings } from '@/hooks/api/useWithholdings';

const { withdrawalMin } = FinTronConfig.selfDirected.transfers;

const displayStrings = {
  transferConfirmationTitle: {
    [TransferTypes.DEPOSIT]: 'Deposit summary',
    [TransferTypes.WITHDRAWAL]: 'Withdrawal summary',
  },
  transferWithdrawalConfirmationTitle: 'Withdrawal summary',
  transferConfirmationDollarValue: 'Dollar value',
  transferType: {
    [TransferTypes.DEPOSIT]: 'Deposit type:',
    [TransferTypes.WITHDRAWAL]: 'Withdrawal type:',
  },
  transferConfirmationBankAccount: 'Bank account:',
  transferConfirmationContributionYear: 'Contribution year:',
  transferConfirmationWithdrawalBreakdown: 'Withdrawal breakdown',
  transferConfirmationInstant: 'Instant',
  transferConfirmationRegular: 'Regular',
  transferConfirmationFrequency: 'Frequency:',
  transferConfirmationWithdrawalEstimatedSettlementDate: (date: string) =>
    `Estimated settlement ${date}`,
  transferConfirmationFederalTax: (isWaived?: boolean, taxRate?: string) =>
    isWaived ? 'Federal:' : `Federal (${taxRate}):`,
  transferConfirmationStateTax: (isWaived?: boolean, taxRate?: string) =>
    isWaived ? 'State:' : `State (${taxRate})`,
  transferConfirmationOneTimeDepositTotal: 'Total amount:',
  transferConfirmationRecurringDepositTotal: 'Dollar value:',
  transferConfirmationWithdrawalTotal: 'Estimated dollar value:',
  transferConfirmationTradIraWithdrawalTotal: 'Final withdrawal:',
  transferConfirmationCaption: (brokerDealerName: string, clearingFirmName: string) =>
    `Cash deposits are custodied by ${clearingFirmName}. If you elect to participate in the Apex FDIC-Insured Sweep Program, Apex will automatically place your cash deposits in this program. If you do not elect to participate in the Apex FDIC-Insured Sweep Program, your deposits are not FDIC insured. You may un-enroll from the Apex FDIC-Insured Sweep Program at any time. Brokerage services are offered through ${brokerDealerName}, a registered broker dealer (member FINRA & SIPC).`,
  recurringTransferConfirmationCaption: ({
    startDate,
    interval,
  }: {
    startDate: string;
    interval: string;
  }) =>
    `We will initiate a deposit ${interval}, starting on ${startDate}. Deposits will settle within 3 business days after it has been initiated.`,
  transferConfirmationSubmit: 'Submit',
  transferConfirmationBack: 'Back',
  transferConfirmationEstimatedSettlementDate: (date?: ISODateString) =>
    `Estimated Settlement ${date}`,
  transfeConfirmationAgePenalties:
    'Withdrawals before the age of 59½ may be subject to 10% penalty.',
  transferConfimationWithdrawalUnderMin: `Amount after withholdings is less than ${thousandFormatNumbroCurrency(withdrawalMin, { mantissa: 0 })}, the minimum amount to withdraw.`,
};

type TransferSidePanelConfirmationProps = {
  account?: UseExternalAccount;
  summary: V1CreateDepositSummaryResponse | null;
  isSummaryLoading: boolean;
  onContinue: () => void;
  onBack: () => void;
};

export const TransferSidePanelConfirmation = ({
  account,
  summary,
  isSummaryLoading,
  onContinue,
  onBack,
}: TransferSidePanelConfirmationProps) => {
  const { getValues } = useFormContext<TransferFormData>();

  const { clearingFirm, brokerDealer } = useTenantBasedStrings();

  const { amount, depositType, interval, selectedStartDate, transferType, taxYear } = getValues();
  const isIRAAccount = useIsIRAAccount();
  const currentAccountType = useCurrentAccountType();

  const shouldShowRegular = NumberNoNaN(summary?.amountAvailableAfterSettle) > 0;

  // Get user details to check age penalties
  const { userDetails } = useUserDetails();
  const dateOfBirth = userDetails?.profile?.dateOfBirth;
  const showAgePenalties = isIRAAccount && getShowAgePenalties(dateOfBirth);

  const isRecurringDeposit = depositType === DepositTypes.RECURRING_DEPOSIT;
  const isDeposit = transferType === TransferTypes.DEPOSIT;
  const isWithdrawal = transferType === TransferTypes.WITHDRAWAL;
  const isTradIraAcc = currentAccountType === UserAccountAccountType.AccountTypeIra;

  const { retirementFundamentalInfo } = useRetirementInfo({ skip: !isWithdrawal || !isTradIraAcc });
  const { userSettings } = useGetUserSettings();

  const { state, federalWithholdingRate, stateWithholdingRate, isFederalWaived, isStateWaived } =
    useWithholdings();

  // Using the default values from the user settings if available
  // If not, using the values from the retirementFundamentalInfo, which is the default state values.
  const taxes = useMemo(() => {
    const federalWithholdingRateFormatted = formatPercent(federalWithholdingRate / 100, {
      mantissa: 0,
    });

    const rawFederalWithholding = isFederalWaived
      ? 0
      : NumberNoNaN((parseFloat(amount) * federalWithholdingRate) / 100);

    const totalFederalWithholding = isFederalWaived
      ? 'Waived'
      : thousandFormatNumbroCurrency(rawFederalWithholding, {
          roundingFunction: Math.floor,
        });

    const rawStateWithholding =
      isStateWaived || state.class === StateWithholdingClass.MNBE
        ? 0
        : NumberNoNaN((parseFloat(amount) * stateWithholdingRate) / 100);

    const stateWithholdingRateFormatted = formatPercent(
      (stateWithholdingRate || (state?.rate ? Number(stateWithholdingRate) : 0)) / 100,
      { mantissa: 0 },
    );

    const totalStateWithholding = isStateWaived
      ? 'Waived'
      : thousandFormatNumbroCurrency(rawStateWithholding, {
          roundingFunction: Math.floor,
        });

    const totalWithTaxes = parseFloat(amount) - (rawFederalWithholding + rawStateWithholding);

    return {
      state,
      isFederalWaived,
      isStateWaived,
      federalWithholdingRateFormatted,
      stateWithholdingRateFormatted,
      totalFederalWithholding,
      totalStateWithholding,
      totalWithTaxes,
    };
  }, [userSettings, retirementFundamentalInfo]);

  const formattedEstimatedSettlementDate =
    displayStrings.transferConfirmationEstimatedSettlementDate(
      convertISODateStringToDisplayString(
        convertObjectToISODateString(summary?.expectedSettlementDate),
      ),
    );

  const formattedEstimatedUnsettledDate =
    displayStrings.transferConfirmationEstimatedSettlementDate(
      convertISODateStringToDisplayString(
        convertObjectToISODateString(summary?.expectedUnsettledDate),
      ),
    );

  const isSubmitDisabled =
    depositType === DepositTypes.ONE_TIME &&
    (isSummaryLoading || !summary || taxes.totalWithTaxes < withdrawalMin);

  if (isSummaryLoading) {
    return <SkeletonTransferConfirmation />;
  }

  return (
    <SheetContent>
      <div className="space-y-8">
        <SheetHeader>
          <SheetTitle>
            <WebBodyText20.Bold className="text-darkestBlue">
              {displayStrings.transferConfirmationTitle[transferType]}
            </WebBodyText20.Bold>
          </SheetTitle>
        </SheetHeader>
        <div className="space-y-5">
          <div className="grid grid-cols-2">
            <WebBodyText14.Regular className="text-grey72">
              {displayStrings.transferType[transferType]}
            </WebBodyText14.Regular>
            <WebBodyText14.Bold className="text-darkestBlue justify-self-end first-letter:capitalize">
              {depositType.replace(/-/g, ' ')}
            </WebBodyText14.Bold>
          </div>
          <div className="grid grid-cols-2">
            <WebBodyText14.Regular className="text-grey72">
              {displayStrings.transferConfirmationBankAccount}
            </WebBodyText14.Regular>
            <WebBodyText14.Bold className="text-darkestBlue justify-self-end text-end">
              {account?.displayName}
            </WebBodyText14.Bold>
          </div>

          {isDeposit && isIRAAccount && (
            <div className="grid grid-cols-2">
              <WebBodyText14.Regular className="text-grey72">
                {displayStrings.transferConfirmationContributionYear}
              </WebBodyText14.Regular>
              <WebBodyText14.Bold className="text-darkestBlue justify-self-end text-end">
                {taxYear}
              </WebBodyText14.Bold>
            </div>
          )}

          {isWithdrawal && isTradIraAcc && (
            <div className="space-y-2">
              <WebBodyText14.Regular className="text-darkestBlue font-bold">
                {displayStrings.transferConfirmationWithdrawalBreakdown}
              </WebBodyText14.Regular>
              <div className="grid grid-cols-2">
                <WebBodyText14.Regular className="text-grey72">
                  {displayStrings.transferConfirmationOneTimeDepositTotal}
                </WebBodyText14.Regular>
                <WebBodyText14.Regular className="text-darkestBlue font-bold justify-self-end">
                  {thousandFormatNumbroCurrency(amount)}
                </WebBodyText14.Regular>
              </div>
              <div className="grid grid-cols-2">
                <WebBodyText14.Regular className="text-grey72">
                  {displayStrings.transferConfirmationFederalTax(
                    taxes.isFederalWaived,
                    taxes.federalWithholdingRateFormatted,
                  )}
                </WebBodyText14.Regular>
                <WebBodyText14.Regular className="text-darkestBlue font-bold justify-self-end">
                  {taxes.totalFederalWithholding}
                </WebBodyText14.Regular>
              </div>
              {taxes.state.class !== StateWithholdingClass.MNBE && (
                <div className="grid grid-cols-2">
                  <WebBodyText14.Regular className="text-grey72">
                    {displayStrings.transferConfirmationStateTax(
                      taxes.isStateWaived,
                      taxes.stateWithholdingRateFormatted,
                    )}
                  </WebBodyText14.Regular>
                  <WebBodyText14.Regular className="text-darkestBlue font-bold justify-self-end">
                    {taxes.totalStateWithholding}
                  </WebBodyText14.Regular>
                </div>
              )}
            </div>
          )}

          {isDeposit && !isRecurringDeposit && !isIRAAccount && (
            <>
              <WebBodyText14.Bold className="text-darkestBlue justify-self-end">
                {displayStrings.transferConfirmationDollarValue}
              </WebBodyText14.Bold>
              <div className="pl-4 grid grid-cols-3">
                <div className="col-span-2">
                  <WebBodyText14.Regular className="text-grey72">
                    {displayStrings.transferConfirmationInstant}
                  </WebBodyText14.Regular>
                  <WebBodyText14.Regular className="text-grey72">
                    {formattedEstimatedSettlementDate}
                  </WebBodyText14.Regular>
                </div>
                <WebBodyText14.Bold className="text-darkestBlue justify-self-end">
                  {thousandFormatNumbroCurrency(summary?.amountAvailableInstantly)}
                </WebBodyText14.Bold>
              </div>

              {shouldShowRegular ? (
                <div className="pl-4 grid grid-cols-3">
                  <div className="col-span-2">
                    <WebBodyText14.Regular className="text-grey72">
                      {displayStrings.transferConfirmationRegular}
                    </WebBodyText14.Regular>
                    <WebBodyText14.Regular className="text-grey72">
                      {formattedEstimatedUnsettledDate}
                    </WebBodyText14.Regular>
                  </div>
                  <WebBodyText14.Bold className="text-darkestBlue justify-self-end">
                    {thousandFormatNumbroCurrency(summary?.amountAvailableAfterSettle)}
                  </WebBodyText14.Bold>
                </div>
              ) : null}
            </>
          )}

          {isDeposit && isRecurringDeposit && !isIRAAccount && (
            <div className="grid grid-cols-2">
              <WebBodyText14.Regular className="text-grey72">
                {displayStrings.transferConfirmationFrequency}
              </WebBodyText14.Regular>
              <WebBodyText14.Bold className="text-darkestBlue justify-self-end first-letter:capitalize">
                {FrequencyDisplayStringMap[interval]}
              </WebBodyText14.Bold>
            </div>
          )}
        </div>

        <div className="space-y-5">
          <hr className="border-grey55" />
          <div className="grid grid-cols-2">
            <WebBodyText14.Bold className="text-darkestBlue">
              {depositType === DepositTypes.ONE_TIME &&
                transferType === TransferTypes.DEPOSIT &&
                displayStrings.transferConfirmationOneTimeDepositTotal}
              {depositType === DepositTypes.RECURRING_DEPOSIT &&
                displayStrings.transferConfirmationRecurringDepositTotal}
              {transferType === TransferTypes.WITHDRAWAL &&
                !isTradIraAcc &&
                displayStrings.transferConfirmationWithdrawalTotal}
              {transferType === TransferTypes.WITHDRAWAL &&
                isTradIraAcc &&
                displayStrings.transferConfirmationTradIraWithdrawalTotal}
            </WebBodyText14.Bold>
            <WebBodyText14.Bold className="text-darkestBlue justify-self-end">
              {thousandFormatNumbroCurrency(
                !isWithdrawal || !isTradIraAcc ? amount : taxes.totalWithTaxes,
              )}
            </WebBodyText14.Bold>
          </div>
          {isWithdrawal && isIRAAccount && showAgePenalties && (
            <Alert variant="warning">
              <WebBodyText14.Regular className="text-grey72">
                {displayStrings.transfeConfirmationAgePenalties}
              </WebBodyText14.Regular>
            </Alert>
          )}

          {isWithdrawal && isTradIraAcc && taxes.totalWithTaxes < withdrawalMin && (
            <InfoInline
              className="text-red"
              message={displayStrings.transferConfimationWithdrawalUnderMin}
              IconSrc={InfoErrorIcon.src}
            />
          )}
        </div>

        {isRecurringDeposit ? (
          <div className="mt-8 flex">
            <WebCaption12.Regular className="text-grey55">
              {displayStrings.recurringTransferConfirmationCaption({
                startDate: convertISODateStringToDisplayString(selectedStartDate, '/'),
                interval: FrequencyDisplayStringMap[interval],
              })}
            </WebCaption12.Regular>
          </div>
        ) : null}

        {isDeposit && !isIRAAccount && (
          <div className="flex text-center">
            <WebCaption10.Regular className="text-grey55">
              {displayStrings.transferConfirmationCaption(brokerDealer.name, clearingFirm.name)}
            </WebCaption10.Regular>
          </div>
        )}
      </div>

      <SheetFooter className="my-5 text-center">
        <div className="w-full flex flex-col gap-2">
          <Button onClick={onContinue} disabled={isSubmitDisabled}>
            {displayStrings.transferConfirmationSubmit}
          </Button>
          <Button variant="secondary" onClick={onBack}>
            {displayStrings.transferConfirmationBack}
          </Button>
        </div>
      </SheetFooter>
    </SheetContent>
  );
};
