import React, { useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/common/Sheet';
import { Button } from '@/components/common/Button';
import { useSidePanel } from '@/context/SidePanelContext';
import { ErrorSidePanel } from '@/components/common/SidePanels/ErrorSidePanel/ErrorSidePanel';
import { yupResolver } from '@hookform/resolvers/yup';
import { YES_OR_NO } from '@fintronners/react-api/src/utils/formUtils/schemas/types';
import useUserDetails from '@fintronners/react-widgets/src/hooks/useUserDetails';
import { getSimpleEnumKeysAsArray } from '@fintronners/react-utils/src';
import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import FormTextField from '@/components/common/Forms/fields/FormTextField';
import { V1UpdateProfileRequest } from '@fintronners/react-api/src';
import { InvestmentQuestionsRadio } from '../InvestmentQuestionsRadio';
import useProfileService from '@/hooks/api/useProfileService';
import { FinraStockExchangeMemberFormData, finraStockExchangeMemberFormSchema } from './schema';
import { Input } from '@/components/common/Input/Input';
import useBlobService from '@/hooks/api/useBlobService';
import { UserDocumentSetUserDocumentType } from '@fintronners/react-api';
import Image from 'next/image';
import { DeleteIcon } from '@/assets/icons';
import PendingChangesNotice from '@/components/notices/PendingChangesNotice';
import useCanUpdateProfile, { ApexRequestType } from '@/hooks/api/useCanUpdateProfile';

const PHOTO_3210_MAX_FILESIZE = 10 * 1024 * 1024;

const displayStrings = {
  title: 'Do you, or someone in your family, work at a FINRA member or Stock Exchange?',
  description: `If you aren't sure, it likely doesn't apply.`,
  answer: 'I, or someone in my family, work at a FINRA member or Stock Exchange',
  affiliatedOptions: {
    yes: 'Yes',
    no: 'No',
  },
  employingFirm: 'Enter the name of your employing firm.',
  nameOfFirm: 'Name of firm',
  letterTitle: 'Upload 3210 letter',
  fileTooLarge: 'File size is too large',
  isAffiliatedExchangeOrFINRADescriptionTitle: 'Working for FINRA or a stock exchange',
  isAffiliatedExchangeOrFINRADescription:
    'Are you, your spouse, any member of your immediate household or anyone who contributes 25% or more towards your annual income a FINRA employee (or a registered exchange employee) with a financial interest in the account or that will control trading in the account?',
  save: 'Save',
  back: 'Back',
};

export const FinraStockExchangeMemberForm = () => {
  const userApiService = useProfileService();
  const blobApiService = useBlobService();
  const { userDetails } = useUserDetails();

  const { goBack } = useSidePanel();
  const [hasError, setHasError] = useState(false);
  const [fileMaxSizeError, setFileMaxSizeError] = useState(false);

  const affiliatedExchangeOrFinraDetails = userDetails?.profile?.affiliatedExchangeOrFinraDetails;

  const {
    control,
    formState: { isValid, isDirty },
    setValue,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(finraStockExchangeMemberFormSchema),
    values: {
      isAffiliatedExchangeOrFINRA: affiliatedExchangeOrFinraDetails?.isAffiliated
        ? YES_OR_NO.YES
        : YES_OR_NO.NO,
      firmName: affiliatedExchangeOrFinraDetails?.firmName ?? '',
      letter: affiliatedExchangeOrFinraDetails?.firmName ?? '',
    },
  });

  const { isProfilePending, refetchUpdateProfileStatus } = useCanUpdateProfile(
    ApexRequestType.STOCK_PROFILE,
  );
  const fileToDataUri = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });

  const updateProfile = async (data: FinraStockExchangeMemberFormData) => {
    const request: V1UpdateProfileRequest = {
      profileParameters: {
        affiliatedExchangeOrFinraDetails: {
          isAffiliated: data.isAffiliatedExchangeOrFINRA === YES_OR_NO.YES,
          firmName: data.isAffiliatedExchangeOrFINRA === YES_OR_NO.YES ? data.firmName : '',
        },
      },
    };

    try {
      await userApiService.userServiceUpdateProfile(request);

      refetchUpdateProfileStatus();
      goBack();
    } catch (error) {
      setHasError(true);
    }
  };

  const onUpdateFinraStockExchange = async (data: FinraStockExchangeMemberFormData) => {
    if (data.isAffiliatedExchangeOrFINRA === YES_OR_NO.NO) {
      updateProfile(data);

      return;
    }

    try {
      const dataUri = await fileToDataUri(data.letter);
      const blobFile = dataUri as string;
      const letter = data.letter as File;

      const response = await blobApiService.blobServiceUpload({
        name: letter.name,
        documentType: UserDocumentSetUserDocumentType.UserDocumentTypeAffiliatedApproval,
        content: (blobFile.split(',')[1] as string) ?? '',
      });

      try {
        await userApiService.userServiceAddDocumentSet({
          affiliatedApproval: { documentId: response.data.uid },
        });
      } catch (err) {
        console.log('Failed to set active the finra letter.', err);
        setHasError(true);
      }

      updateProfile(data);
    } catch (err) {
      console.log('An error occurred:', err);
      setHasError(true);
    }
  };

  const getLetterTitle = (letter: any) => {
    if (typeof letter === 'string') {
      return letter;
    }

    return letter.name;
  };

  const [isAffiliatedExchangeOrFINRA, letter] = useWatch({
    control,
    name: ['isAffiliatedExchangeOrFINRA', 'letter'],
  });

  if (hasError) return <ErrorSidePanel onBack={() => setHasError(false)} />;

  return (
    <SheetContent>
      <form className="flex h-full flex-col" onSubmit={handleSubmit(onUpdateFinraStockExchange)}>
        <SheetHeader>
          <SheetTitle>{displayStrings.title}</SheetTitle>
        </SheetHeader>
        <div className="flex h-full flex-col gap-7">
          <WebBodyText14.Regular className="text-grey72">
            {displayStrings.description}
          </WebBodyText14.Regular>

          {isProfilePending && <PendingChangesNotice />}

          <InvestmentQuestionsRadio
            title={displayStrings.answer}
            name="isAffiliatedExchangeOrFINRA"
            dialogTitle={displayStrings.isAffiliatedExchangeOrFINRADescriptionTitle}
            dialogContent={displayStrings.isAffiliatedExchangeOrFINRADescription}
            items={getSimpleEnumKeysAsArray(
              YES_OR_NO,
              displayStrings.affiliatedOptions as Record<string, string>,
            )}
            control={control}
          />

          {isAffiliatedExchangeOrFINRA === YES_OR_NO.YES && (
            <>
              <Controller
                control={control}
                name="firmName"
                render={({ field, fieldState }) => (
                  <FormTextField
                    {...field}
                    fieldError={fieldState.error}
                    placeholder={displayStrings.nameOfFirm}
                    label={displayStrings.employingFirm}
                  />
                )}
              />
              <div className="flex flex-col gap-1">
                <WebBodyText14.Medium className="text-grey72">
                  {displayStrings.letterTitle}
                </WebBodyText14.Medium>
                {letter ? (
                  <div className="flex justify-between">
                    <WebBodyText14.Regular>{getLetterTitle(letter)}</WebBodyText14.Regular>
                    <button type="button" onClick={() => setValue('letter', '')}>
                      <Image alt="Delete value icon" width={16} height={16} src={DeleteIcon.src} />
                    </button>
                  </div>
                ) : (
                  <>
                    <Controller
                      control={control}
                      name={'letter'}
                      rules={{ required: 'Recipe picture is required' }}
                      render={({ field: { value: _, onChange, ...field } }) => {
                        return (
                          <Input
                            {...field}
                            onChange={(event) => {
                              const file = event?.target?.files?.[0];

                              if (!file) return;

                              if (file.size > PHOTO_3210_MAX_FILESIZE) {
                                setFileMaxSizeError(true);
                              } else {
                                setFileMaxSizeError(false);
                                onChange(file);
                              }
                            }}
                            type="file"
                            accept="image/*, .pdf"
                          />
                        );
                      }}
                    />
                    {fileMaxSizeError && (
                      <WebBodyText14.Regular className="text-red">
                        {displayStrings.fileTooLarge}
                      </WebBodyText14.Regular>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>

        <SheetFooter>
          <div className="mt-8 flex w-full flex-col">
            <Button
              variant="primary"
              type="submit"
              className="mb-4"
              disabled={!isDirty || !isValid}
            >
              {displayStrings.save}
            </Button>
            <Button variant="secondary" type="button" onClick={goBack}>
              {displayStrings.back}
            </Button>
          </div>
        </SheetFooter>
      </form>
    </SheetContent>
  );
};
