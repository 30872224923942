import { BlobServiceApi, Configuration } from '@fintronners/react-api/src';

import RuntimeEnvGRPCAPI from '@/utils/RuntimeEnvGRPCAPI';
import { useGrpcApiV1Config } from '@/hooks/api/useGrpcApiV1Config';

const useBlobService = () => {
  const { basePath, accessToken } = useGrpcApiV1Config();

  const profileApiService = RuntimeEnvGRPCAPI.getV1Service(
    BlobServiceApi,
    new Configuration({
      basePath,
      accessToken,
      baseOptions: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    }),
  );

  return profileApiService;
};

export default useBlobService;
