import { V1TradeExpirationType } from '@fintronners/react-api';
import { WebBodyText14, WebCaption12 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import CurrencyInput from 'react-currency-input-field';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio } from '@/components/common/Radio/Radio';
import { GoodForDialog } from '@/components/features/trade/GoodForDialog/GoodForDialog';
import { TradeMarketBuyForm } from './TradeMarketBuyForm';

const displayStrings = {
  [V1TradeExpirationType.Day]: '1 Day',
  [V1TradeExpirationType.GoodTilCancel]: 'Good Till Cancel',
};

interface TradeLimitBuyFormProps {
  tradableInCash: number;
}

export const TradeLimitBuyForm = ({ tradableInCash }: TradeLimitBuyFormProps) => {
  const { control } = useFormContext();

  return (
    <>
      <TradeMarketBuyForm tradableInCash={tradableInCash} />
      <div>
        <WebBodyText14.Regular className="text-grey72">Enter Limit Price</WebBodyText14.Regular>
        <WebCaption12.Regular className="text-grey72">
          Buy when the share price falls below:
        </WebCaption12.Regular>
        <Controller
          control={control}
          name="limitPrice"
          render={({ field: { value, onChange } }) => (
            <CurrencyInput
              className="mt-2 w-full border rounded h-[35px] px-3 py-2 text-sm text-darkestBlue focus-visible:outline-primary"
              prefix="$"
              value={value}
              placeholder="$0.00"
              decimalsLimit={2}
              intlConfig={{ locale: 'en-US' }}
              onValueChange={(_value) => onChange(_value)}
            />
          )}
        />
      </div>
      <div>
        <GoodForDialog />
        <ul className="flex gap-4 mt-2">
          {[V1TradeExpirationType.Day, V1TradeExpirationType.GoodTilCancel].map(
            (expirationType) => (
              <li key={expirationType} className="w-full">
                <Controller
                  name="duration"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <Radio.Input
                        id={expirationType}
                        value={expirationType}
                        onChange={() => onChange?.(expirationType as V1TradeExpirationType)}
                        checked={expirationType === value}
                      />
                      <Radio.Label variant="secondary" htmlFor={expirationType.toString()}>
                        {displayStrings[expirationType]}
                      </Radio.Label>
                    </>
                  )}
                />
              </li>
            ),
          )}
        </ul>
      </div>
    </>
  );
};
