const useBreakpoint = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height,
    };
  };

  /**
   * Returns whether the browser window is at the specified Tailwind breakpoint.
   *
   * @see https://tailwindcss.com/docs/responsive-design
   *
   * @param breakpoint
   * @returns
   */
  const isBreakpoint = (breakpoint: string): boolean => {
    const { width } = getWindowDimensions();

    switch (breakpoint) {
      case 'xs':
        return width < 640;
      case 'sm':
        return width >= 640 && width < 768;
      case 'md':
        return width >= 768 && width < 1024;
      case 'lg':
        return width >= 1024 && width < 1280;
      case 'xl':
        return width >= 1280 && width < 1536;
      case '2xl':
        return width >= 1536;
    }

    return false;
  };

  const isMobileBreakpoint = (): boolean => {
    return isBreakpoint('xs') || isBreakpoint('sm') || isBreakpoint('md');
  };

  const isDesktopBreakpoint = (): boolean => {
    return !isMobileBreakpoint();
  };

  return {
    isBreakpoint,
    isDesktopBreakpoint,
    isMobileBreakpoint,
  };
};

export default useBreakpoint;
