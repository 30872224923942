import { Configuration, TransferServiceApi } from '@fintronners/react-api/src';

import RuntimeEnvGRPCAPI from '@/utils/RuntimeEnvGRPCAPI';
import { useGrpcApiV1Config } from '@/hooks/api/useGrpcApiV1Config';

const useTransferService = () => {
  const { basePath, accessToken } = useGrpcApiV1Config();

  const transferApiService = RuntimeEnvGRPCAPI.getV1Service(
    TransferServiceApi,
    new Configuration({
      basePath,
      accessToken,
      baseOptions: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    }),
  );

  return transferApiService;
};

export default useTransferService;
