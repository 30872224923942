import { format } from 'date-fns';
import * as React from 'react';
import { DayPicker } from 'react-day-picker';

import cn from '@/utils/tailwind';
import { buttonVariants } from '~components/common/Button/Button';
import { CalendarChevron } from './parts/Chevron';

export type CalendarProps = React.ComponentProps<typeof DayPicker> & { showMonthAndYear: boolean };

const Calendar: React.FC<CalendarProps> = ({
  className,
  classNames,
  showOutsideDays = true,
  showMonthAndYear,
  ...props
}: CalendarProps) => {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('w-fit', className)}
      formatters={
        showMonthAndYear ? undefined : { formatCaption: (month) => format(month, 'LLLL') }
      }
      fixedWeeks={true}
      classNames={{
        months: 'flex relative',
        month: 'space-y-4',
        month_caption: 'flex justify-center pt-1 items-center',
        caption_label: 'text-sm font-[700]',
        nav: 'flex items-center',
        button_previous: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-7 w-7 bg-transparent p-0 opacity-75 hover:opacity-100 absolute left-1 top-0',
        ),
        button_next: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-7 w-7 bg-transparent p-0 opacity-75 hover:opacity-100 absolute right-1 top-0',
        ),
        month_grid: 'w-full border-collapse space-y-1',
        weekdays: 'flex',
        weekday: 'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
        week: 'flex w-full mt-2',
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'text-grey72 h-9 w-9 font-normal text-sm hover:border-primary rounded-full h-9 w-9',
          'text-center text-sm p-0 relative [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent',
          'first:[&:has([aria-selected])]:rounded-l-md',
          'last:[&:has([aria-selected])]:rounded-r-md',
          'focus-within:relative focus-within:z-20',
        ),
        day_button: cn('w-full h-full'),
        range_end: 'day-range-end',
        selected:
          'bg-primary text-white hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
        today: 'bg-accent text-accent-foreground',
        outside:
          'day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
        disabled: 'text-muted-foreground opacity-50 border-none',
        range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
        hidden: 'invisible',
        ...classNames,
      }}
      components={{ Chevron: CalendarChevron as any }}
      {...props}
    />
  );
};

Calendar.displayName = 'Calendar';

export { Calendar };
