import * as yup from 'yup';
import { V1RecurringRuleInterval } from '@fintronners/react-api/src';
import { V1TradeExpirationType } from '@fintronners/react-api';
import { getOneOfEnumSchema } from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';

export enum OrderType {
  MARKET_BUY = 'marketBuy',
  MARKET_SELL = 'marketSell',
  STOP_LOSS = 'stopLoss',
  LIMIT_BUY = 'limitBuy',
  REPEAT_INVESTMENT = 'repeatInvestment',
}

export const intervals = [
  V1RecurringRuleInterval.EveryWeek,
  V1RecurringRuleInterval.EveryTwoWeeks,
  V1RecurringRuleInterval.EveryMonth,
];

export enum TradeSide {
  BUY = 'buy',
  SELL = 'sell',
}

export const tradeSchema = yup.object({
  orderType: getOneOfEnumSchema(OrderType),
  amount: yup.string().default('0').required(),
  duration: getOneOfEnumSchema(V1TradeExpirationType),
  limitPrice: yup.number(),
  interval: getOneOfEnumSchema(V1RecurringRuleInterval),
  selectedStartDate: yup.string(),
  stopLoss: yup.string().default('0'),
});

export type TradeFormData = yup.InferType<typeof tradeSchema>;
