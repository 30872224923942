import { UserRiskTolerance } from '@fintronners/react-api/src';
import {
  WebBodyText14,
  WebBodyText16,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { useFormContext, useWatch } from 'react-hook-form';
import { RiskProfileSchemaType, riskTolerancesSurvey } from '../riskProfileSidePanel.utils';

export const InfoBox = () => {
  const { control } = useFormContext<RiskProfileSchemaType>();

  const { riskTolerance } = useWatch({ control });
  const answer = riskTolerancesSurvey.getAnswerFromValue(
    riskTolerance ?? UserRiskTolerance.UserRiskToleranceLow,
  );

  return (
    <div className="flex rounded-f1 overflow-hidden min-h-min">
      <div className="flex items-center justify-center p-3 bg-primary text-white">
        <WebBodyText16.Bold className="capitalize">{answer?.label}</WebBodyText16.Bold>
      </div>

      <div className="flex items-center justify-center p-4 bg-grey7">
        <WebBodyText14.Regular>{answer?.description}</WebBodyText14.Regular>
      </div>
    </div>
  );
};
