import { robotoRegular, robotoBold, robotoMedium } from './fonts';

export const defaultTheme = {
  colors: {
    background: '#ffffff',
    primary: '#52b9e5',
    primaryDark: '#0000ff',
  },
  fonts: {
    regular: robotoRegular.style.fontFamily,
    medium: robotoMedium.style.fontFamily,
    bold: robotoBold.style.fontFamily,
  },
  borderRadius: '4',
};
