import { create } from 'zustand';
import type { User } from '@fintronners/react-api/src';

type UserDetails = Pick<User, 'id'> | null;

type AppStore = {
  // getters
  newApiUrl: string | null;
  tenant: string | null;
  userDetails: UserDetails;
  tenantSupportEmail: string | null;

  // setters
  setNewApiUrl: (apiNewUrl: string) => void;
  setTenant: (tenant: string | null) => void;
  setUserDetails: (userDetails: UserDetails) => void;
  setTenantSupportEmail: (supportEmail: string) => void;
};

export const useAppStore = create<AppStore>((set) => ({
  // getters
  newApiUrl: null,
  tenant: null,
  userDetails: null,
  tenantSupportEmail: null,

  // setters
  setNewApiUrl: (newApiUrl) => set({ newApiUrl }),
  setTenant: (tenant) => set({ tenant }),
  setUserDetails: (userDetails) => set({ userDetails }),
  setTenantSupportEmail: (tenantSupportEmail: string) => set({ tenantSupportEmail }),
}));
