import {
  V1UserAnnualIncome,
  V1UserEmploymentStatus,
  V1UserMaritalStatus,
  V1UserNetWorth,
} from '@fintronners/react-api';
import { getOneOfEnumSchema } from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';
import { FORM_ERROR_MESSAGES } from '@fintronners/react-api/src/utils/formUtils/schemas/constants';
import * as yup from 'yup';

export const personalFormSchema = yup.object({
  maritalStatus: getOneOfEnumSchema(V1UserMaritalStatus),
  employmentStatus: getOneOfEnumSchema(V1UserEmploymentStatus),
  annualIncome: getOneOfEnumSchema(V1UserAnnualIncome),
  netWorth: getOneOfEnumSchema(V1UserNetWorth),
  employerName: yup.string().when('employmentStatus', {
    is: V1UserEmploymentStatus.Employed,
    then: (schema) => schema.required(FORM_ERROR_MESSAGES.required),
  }),
  jobPosition: yup.string().when('employmentStatus', {
    is: V1UserEmploymentStatus.Employed,
    then: (schema) => schema.required(FORM_ERROR_MESSAGES.required),
  }),
});

export type PersonalFormData = yup.InferType<typeof personalFormSchema>;
