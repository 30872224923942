import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSidePanel } from '@/context/SidePanelContext';
import {
  onboardingDataInterceptor,
  getShouldByPassSupplementalQuestions,
} from '@/utils/onboarding';
import type { ServerSideProps } from '@/ssr/common';

import {
  shouldDisplaySupplementalQuestions,
  getInitialStep,
} from '@/components/common/SidePanels/SupplementalQuestionsSidePanel/utils';

type OnboardingProviderProps = React.PropsWithChildren<ServerSideProps>;

const ERROR_MESSAGES = {
  onboardingData:
    '"onboardingData" is undefined. Make sure it is exported from getServerSideProps.',
};

const EXCLUDED_PATHNAMES = ['/', '/login', '/logout'];
const SUPPLEMENTAL_QUESTIONS_EXCLUDED_PATHNAMES = EXCLUDED_PATHNAMES;
// const IS_DEV = process.env.NODE_ENV === 'development';

/**
 * This Provider is used to show and handle Onboarding logic that will block the page.
 * Use one Effect per Onboarding flow.
 */
const OnboardingProvider: React.FC<OnboardingProviderProps> = ({
  children,
  onboardingData: serverOnboardingData,
  userDetails,
  tenant,
}) => {
  const { pathname } = useRouter();
  const { openSidePanel } = useSidePanel();

  /**
   * Supplemental Questions
   */
  useEffect(() => {
    if (tenant !== 'Alight') return;
    if (SUPPLEMENTAL_QUESTIONS_EXCLUDED_PATHNAMES.includes(pathname)) return;

    const shouldBypassSupplementalQuestions = getShouldByPassSupplementalQuestions();
    if (shouldBypassSupplementalQuestions /* && IS_DEV */) return;

    if (!serverOnboardingData) return console.error(ERROR_MESSAGES.onboardingData);

    const user = userDetails?.users.edges?.[0]?.node;
    if (!user) return;

    const onboardingData = onboardingDataInterceptor(serverOnboardingData, user.id);
    const displaySupplementalQuestions = shouldDisplaySupplementalQuestions(onboardingData);

    if (displaySupplementalQuestions) {
      const initialStep = getInitialStep(onboardingData);
      openSidePanel('SupplementalQuestions', { data: onboardingData, initialStep });
    }
  }, [pathname]);

  return <>{children}</>;
};

export default OnboardingProvider;
