/**
 * @deprecated use '@fintronners/react-utils/src/regexUtils'; instead
 */
export const REG_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REG_LETTERS = /^[A-Za-z]+$/;
export const REG_LETTER_NUMBER = /^[A-Za-z0-9]+$/;
/**
 * @deprecated use '@fintronners/react-utils/src/regexUtils'; instead
 */
export const REG_FIRST_LAST_NAME = /^[a-zA-Z'’0-]+$/;
export const REG_LETTER_NUMBER_SPACE = /^[A-Za-z0-9\s]+$/;
export const REG_LETTER_SPACE = /^[A-Za-z\s]+$/;
export const REG_NO_SPACE = /^\S+$/;
export const REG_SPACE_SPECIAL_CHARACTERS = /^[a-zA-Z0-9 !@#$%^&*)(]/;
export const REG_PHONE_NUMBER = /^[0-9]{10}$/;
export const REG_CODE_CHECK = /^[0-9]{6}$/;
export const REG_POST_BOX =
  /^ *((#\d+)|((box|bin)[-. /\\]?\d+)|(.*p[ .]? ?(o|0)[-. /\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i;
export const REG_POSTAL_CODE = /^[0-9]{5}$/;
export const REG_SECURITY_NUMBER = /^[0-9]{9}$/;
export const REG_SSN = /(?:\d{3})-(?:\d{2})-(\d{4})/;
export const REG_USERNAME = /^[A-Za-z0-9]+(?:[@+_.-][A-Za-z0-9]+)*$/;
export const REG_US_STATE =
  /^(?:(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY]))$/;
export const REG_NO_FIRST_SPACE = /^[^\s].*/;
export const REG_PASSWORD_SPECIAL_CHARACTERS = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+/;
export const REG_NOT_SPECIAL_CHARACTERS = /^[^!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+$/;
