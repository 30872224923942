import React, { useEffect } from 'react';
import Image from 'next/image';
import { WebBodyText18, WebHeading28 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { SuccessCircleIcon } from '@/assets/icons';
import { Button } from '@/components/common/Button';
import { SheetContent, SheetFooter } from '@/components/common/Sheet';
import { useSidePanel } from '@/context/SidePanelContext';

const displayStrings = {
  congratulations: 'Congratulations!',
  checkActivityHistory: 'Your risk tolerance was updated successfully.',
  done: 'Done',
};

export const RiskProfileSidePanelSuccess = () => {
  const { closeSidePanel, setShowBackLink } = useSidePanel();

  useEffect(() => {
    setShowBackLink(false);
  }, []);

  return (
    <SheetContent>
      <div className="flex flex-col items-center gap-7 text-center mt-20">
        <Image alt="Success icon" width={50} height={50} src={SuccessCircleIcon.src} />
        <WebHeading28.Bold className="text-darkestBlue">
          {displayStrings.congratulations}
        </WebHeading28.Bold>
        <WebBodyText18.Regular className="text-grey72">
          {displayStrings.checkActivityHistory}
        </WebBodyText18.Regular>
      </div>

      <SheetFooter className="my-5 text-center">
        <div className="w-full flex flex-col gap-2">
          <Button onClick={closeSidePanel}>{displayStrings.done}</Button>
        </div>
      </SheetFooter>
    </SheetContent>
  );
};
