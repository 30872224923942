import { useQuery } from '@apollo/client';
import { GET_USER_SETTINGS } from '@fintronners/react-api/src/graphql/queries/userSettings';

export const useGetUserSettings = (skip: boolean = false) => {
  const { data: userSettings, loading: userSettingsDataLoading } = useQuery(GET_USER_SETTINGS, {
    skip,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  return {
    ...userSettings,
    userSettingsDataLoading,
  };
};
