import { ReactNode } from 'react';
import {
  DatepickerActionContainer,
  DatepickerApplyButton,
  DatepickerCalendar,
  DatepickerContent,
  DatepickerInput,
  DatepickerResetButton,
  DatepickerRoot,
  DatepickerRootProps,
} from './base';

export type DatepickerProps = Omit<DatepickerRootProps, 'children'>;

type GenericDatepickerProps = {
  trigger: ReactNode;
  showMonthAndYear?: boolean;
} & DatepickerProps;

const GenericDatepicker = ({
  trigger,
  showMonthAndYear = false,
  ...props
}: GenericDatepickerProps) => {
  return (
    <DatepickerRoot {...props}>
      {trigger}
      <DatepickerContent>
        <DatepickerInput />

        <DatepickerCalendar showMonthAndYear={showMonthAndYear} />

        <DatepickerActionContainer>
          <DatepickerApplyButton />
          <DatepickerResetButton />
        </DatepickerActionContainer>
      </DatepickerContent>
    </DatepickerRoot>
  );
};

GenericDatepicker.displayName = 'GenericDatepicker';

export { GenericDatepicker };
