enum ErrorKeys {
  CodeMismatchException = 'CodeMismatchException',
  LimitExceededException = 'LimitExceededException',
  ExpiredCodeException = 'ExpiredCodeException',
}

const errorCodes: { [key in ErrorKeys]: string } = {
  [ErrorKeys.CodeMismatchException]: 'The verification code you entered is incorrect.',
  [ErrorKeys.LimitExceededException]:
    'You have exceeded the maximum number of attempts. Please try again later.',
  [ErrorKeys.ExpiredCodeException]: 'The verification code you entered has expired.',
};

/**
 * Get the specific error message based on the error key.
 * @param errorStr The error string to check.
 * @returns The specific error message or null if not found.
 * @example
 * ```ts
 * getSpecificErrorMessage('CodeMismatchException: The verification code you entered is incorrect.');
 * // 'The verification code you entered is incorrect.'
 * ```
 */
export const getSpecificErrorMessage = (errorStr: string): string | null => {
  if (!errorStr) {
    return null;
  }

  for (const errorKey of Object.values(ErrorKeys)) {
    if (errorStr.includes(errorKey)) {
      return errorCodes[errorKey];
    }
  }

  return null;
};
