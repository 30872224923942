import Image from 'next/image';
import { PropsWithChildren } from 'react';
import { WebCaption12 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';

import { ExclamationBubble } from '@/assets/icons';

type ErrorMessageProps = {
  hasIcon?: boolean;
};

export const ErrorMessage = ({ children, hasIcon }: PropsWithChildren<ErrorMessageProps>) => {
  return (
    <div className="flex items-center gap-1 my-4">
      {hasIcon ? (
        <Image alt="Info icon" width={14} height={14} src={ExclamationBubble.src} />
      ) : null}
      <WebCaption12.Regular className="text-red">{children}</WebCaption12.Regular>
    </div>
  );
};
